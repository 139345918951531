import React, { Component } from "react";
import { toast } from "react-toastify";
import Form from "./common/form";
import Swal from "sweetalert2";
import imageCompression from "browser-image-compression";
import {
  getSystem,
  getSystemControls,
  setSystemControls,
  setSystemControlsObject,
  setSystemLimitsObject,
  getSystemIDfromHandle,
  findSystemBySerialNumber,
  getSystems,
  getSystemInfo,
  getSystemLimits,
  getControlsUnsavedChanges,
  setControlsUnsavedChanges,
  uploadImage,
  getImageUrl,
  deleteImage,
  changeImagesSN,
  getImagesSN,
} from "../services/systemService";
import { setJwt } from "../services/httpService";
import { getUser } from "../services/userService";
import {
  createCompany,
  updateCompanyContact,
  getCompanies,
  updateCompanyObject,
  getFleetInfo,
  getContactInfo,
} from "../services/companyService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faWrench,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import Modal from "react-bootstrap/Modal";
import BackGroundRectangle from "../components/backgroundRect";
import Clipboard from "react-clipboard.js";
import ReactExport from "react-data-export";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import "../excel-2007.css";
import { Empty } from "antd";
import QrContainer from "./qrContainer";
import { animateScroll as scroll } from "react-scroll";
import NavBar from "./navbar";
import { faPaste, faCopy } from "@fortawesome/free-solid-svg-icons";
//import { node } from "react-svg/node_modules/@types/prop-types";

class SimplNodesControls extends Component {
  constructor() {
    super();
  }
  state = {
    dataLoaded: false,
    message: "",
    systemID: "",
    systemSN: "",
    systemName: "",
    loadType: "Choose here",
    fleetControl: 0,
    earlyStart: 0,
    earlyStop: 0,
    ductMountReturn: 0,
    overRide: 0,
    fossilHeat: 0,
    OBActive: 0,
    rthOnly: 0,
    auxHeat: 0,
    saveFleet: false,
    nodeSchTemp: "AAF,ABE,ACD,BDC,CEB,DFA,BAF,CBE",
    nodeHealth: "01,05,10,15",
    nodeScheduleSun: "A",
    nodeOnTempSun: "A",
    nodeOffTempSun: "A",
    nodeScheduleMon: "A",
    nodeOnTempMon: "A",
    nodeOffTempMon: "A",
    nodeScheduleTue: "A",
    nodeOnTempTue: "A",
    nodeOffTempTue: "A",
    nodeScheduleWed: "A",
    nodeOnTempWed: "A",
    nodeOffTempWed: "A",
    nodeScheduleThu: "A",
    nodeOnTempThu: "A",
    nodeOffTempThu: "A",
    nodeScheduleFri: "A",
    nodeOnTempFri: "A",
    nodeOffTempFri: "A",
    nodeScheduleSat: "A",
    nodeOnTempSat: "A",
    nodeOffTempSat: "A",
    nodeScheduleHol: "A",
    nodeOnTempHol: "A",
    nodeOffTempHol: "A",

    nodeSensorSN: "none",
    nodeSensorSN2: "none",
    nodeSensorSN3: "none",
    nodeSensorSN4: "none",

    nodeDoorSensorSN: "none",
    nodeRelaySN: "none",
    nodeSM2SN: "none",
    nodeThermostatSN: "none",
    nodeWalkInSN: "none",
    nodeWHSN: "none",

    nodeTempSetHeatOc: "68",
    nodeTempSetCoolOc: "72",

    nodeTempSetHeatUn: "50",
    nodeTempSetCoolUn: "80",

    nodeDeltaTempSet: "2",
    nodeRHSet: "35",
    nodeFanSetting: "Auto",
    nodeMode: "Auto",
    nodeHvacType: "Multi-stage RTU",

    scheduleMode: "Schedule",

    nodeMinToHealth: 1,
    nodeBadTemp: 5,
    nodePoorTemp: 10,
    nodeGoodTemp: 15,

    nodePositionX: 0,
    nodePositionY: 0,

    copyFleetName: "Choose Here",
    username: "unknown",
    savedChanges: false,
    errors: {},

    setImages: false,
    currSerialNum: "",
    nodenameSerialSN: "",

    slot1: null,
    slot2: null,
    slot3: null,
    slot4: null,
  };

  async componentDidMount() {
    const setControlsUpload = 0;
    const handleId = this.props.match.params.systemHandleID;
    console.log("Simpl Node Controls HandleID: ", handleId);
    const displayWidth = this.props.match.params.displayWidth;
    console.log("Display Width: ", displayWidth);
    const systemID = (await getSystemIDfromHandle(handleId)).data.data
      .system_id;
    const system = (await getSystem(systemID)).data.data[0];
    const systemName = system.systemID.name;
    console.log("System Info", system);

    let systemControls = (await getSystemControls(handleId)).data.data.controls;
    console.log("System Controls: ", systemControls);
    let systemControlsLength = 0;
    if (!systemControls) {
      console.log("System Controls is undefined.........");
      systemControls = {};
      systemControlsLength = 0;
    } else systemControlsLength = systemControls.length;
    console.log("System Controls Length:", systemControlsLength);
    let systemControlsJson = JSON.stringify(systemControls);
    console.log("Initial System Controls:", systemControls);

    await setSystemControlsObject(systemID, systemControlsJson);
    const systemLimits = (await getSystemLimits(handleId)).data.data.limits;
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    const userInfo = (await getUser()).data.data;
    const companyName = userInfo.company;
    this.setState({ companyName });

    // const companies = (await getCompanies()).data.data;
    // const company = companies.filter(
    //   (thisCompany) => thisCompany.companyName === companyName
    // );
    // console.log("This company:", company);
    // const contactInfo = company[0].contactInfo;

    const contactInfo = await getContactInfo(companyName);
    console.log("Contact Info:", contactInfo);

    /*const systemControls = (await getSystemControls(this.state.handleId)).data
      .data.controls;
    const systemLimits = (await getSystemLimits(this.state.handleId)).data.data
      .limits;*/
    console.log("System Controls: ", systemControls);
    console.log("System Limits: ", systemLimits);
    console.log("system limit autoheatcool", systemLimits.autoHeatCool);
    console.log(
      "type of system limit autoheatcool",
      typeof systemLimits.autoHeatCool
    );
    this.setState({ autoHeatCool: parseInt(systemLimits.autoHeatCool) });
    console.log("auto heat and cool set: ", this.state.autoHeatCool);
    // let fleetInfo = (await getFleetInfo(companyName)).data.data[0].fleetInfo;
    // console.log("Fleet Info: ", fleetInfo);
    // this.setState({ fleetOptions: fleetInfo });

    this.setState({
      systemID,
      systemName,
      handleId,
      systemControlsLength,
      systemControls,
      systemControlsJson,
      setControlsUpload,

      startTimeA: systemLimits.startTimeA,
      startTimeB: systemLimits.startTimeB,
      startTimeC: systemLimits.startTimeC,
      startTimeD: systemLimits.startTimeD,
      startTimeE: systemLimits.startTimeE,
      startTimeF: systemLimits.startTimeF,
      startTimeG: systemLimits.startTimeG,
      startTimeH: systemLimits.startTimeH,

      endTimeA: systemLimits.endTimeA,
      endTimeB: systemLimits.endTimeB,
      endTimeC: systemLimits.endTimeC,
      endTimeD: systemLimits.endTimeD,
      endTimeE: systemLimits.endTimeE,
      endTimeF: systemLimits.endTimeF,
      endTimeG: systemLimits.endTimeG,
      endTimeH: systemLimits.endTimeH,

      OffTimeOptionA: systemLimits.OffTimeOptionA,
      OffTimeOptionB: systemLimits.OffTimeOptionB,
      OffTimeOptionC: systemLimits.OffTimeOptionC,
      OffTimeOptionD: systemLimits.OffTimeOptionD,
      OffTimeOptionE: systemLimits.OffTimeOptionE,
      OffTimeOptionF: systemLimits.OffTimeOptionF,

      lowTempA: systemLimits.lowTempA,
      lowTempB: systemLimits.lowTempB,
      lowTempC: systemLimits.lowTempC,
      lowTempD: systemLimits.lowTempD,
      lowTempE: systemLimits.lowTempE,
      lowTempF: systemLimits.lowTempF,

      hiTempA: systemLimits.hiTempA,
      hiTempB: systemLimits.hiTempB,
      hiTempC: systemLimits.hiTempC,
      hiTempD: systemLimits.hiTempD,
      hiTempE: systemLimits.hiTempE,
      hiTempF: systemLimits.hiTempF,

      contactInfo,

      // For history when temp is changed
      prevNodeTempSetHeatOc: this.state.nodeTempSetHeatOc,
      prevNodeTempSetCoolOc: this.state.nodeTempSetCoolOc,
      prevNodeTempSetHeatUn: this.state.nodeTempSetHeatUn,
      prevNodeTempSetCoolUn: this.state.nodeTempSetCoolUn,
    });

    // const user = await getUser();
    this.setState({ username: userInfo.username });

    // Login to open nodename in edit mode if it is clicked from the listview

    const nodenamePassed = sessionStorage.getItem("nodePassed");

    if (nodenamePassed) {
      this.setState({ nodenamePassed });
      this.handleEdit(nodenamePassed);
    }

    // console.log("NODENAMEPASSED", this.props.match.params.nodenamePassed);

    // GO BACK EVENT CHECK IN DB
    // const unsavedChanges = JSON.parse(localStorage.getItem("unsavedChanges"));

    // console.log("unsaved changes from localstorage", unsavedChanges);
    // if (unsavedChanges) {
    //   if (unsavedChanges.nodeName) {
    //     this.handleEdit(unsavedChanges.nodeName);
    //     toast.success(
    //       `There is unsaved settings in "${unsavedChanges.nodeName}"`
    //     );
    //   } else {
    //     toast.info("There is unsaved configuration but without nodeName");
    //   }
    //   this.setState({ unsavedChanges: unsavedChanges });
    // }
    // localStorage.removeItem("unsavedChanges");
    // console.log(
    //   "removed unsaved changes",
    //   localStorage.getItem(unsavedChanges)
    // );

    // For initializing state when there is a copied node
    // in sessionStorage
    if (sessionStorage.getItem("copiedNode")) {
      const copiedNode = JSON.parse(sessionStorage.getItem("copiedNode"));
      console.log("copied node in didmount", copiedNode.loadType);
      this.setState({ copiedNodeLoadType: copiedNode.loadType });
    }
  }

  handleButtonPressed = async (e) => {
    e.preventDefault();
    await this.handleNodes(e);
    this.reset(false);
  };

  reset = (loadTypeChange) => {
    this.setState({
      loadType: loadTypeChange ? this.state.loadType : "Choose here",
      fleetControl: 0,
      overRide: 0,
      fossilHeat: 0,
      OBActive: 0,
      auxHeat: 0,
      earlyStart: 0,
      earlyStop: 0,
      ductMountReturn: 0,
      rthOnly: 0,
      saveFleet: false,
      nodeSchTemp: "AAF,ABE,ACD,BDC,CEB,DFA,BAF,CBE",
      nodeHealth: "01,05,10,15",
      nodeScheduleSun: "A",
      nodeOnTempSun: "A",
      nodeOffTempSun: "A",
      nodeScheduleMon: "A",
      nodeOnTempMon: "A",
      nodeOffTempMon: "A",
      nodeScheduleTue: "A",
      nodeOnTempTue: "A",
      nodeOffTempTue: "A",
      nodeScheduleWed: "A",
      nodeOnTempWed: "A",
      nodeOffTempWed: "A",
      nodeScheduleThu: "A",
      nodeOnTempThu: "A",
      nodeOffTempThu: "A",
      nodeScheduleFri: "A",
      nodeOnTempFri: "A",
      nodeOffTempFri: "A",
      nodeScheduleSat: "A",
      nodeOnTempSat: "A",
      nodeOffTempSat: "A",
      nodeScheduleHol: "A",
      nodeOnTempHol: "A",
      nodeOffTempHol: "A",

      nodeSensorSN: "none",
      nodeSensorSN2: "none",
      nodeSensorSN3: "none",
      nodeSensorSN4: "none",

      nodeDoorSensorSN: "none",
      nodeRelaySN: "none",
      nodeSM2SN: "none",
      nodeThermostatSN: "none",
      nodeWalkInSN: "none",
      nodeWHSN: "none",

      nodeTempSetHeatOc: "68",
      nodeTempSetCoolOc: "72",

      nodeTempSetHeatUn: "50",
      nodeTempSetCoolUn: "80",

      nodeDeltaTempSet: "2",
      nodeRHSet: "35",
      nodeFanSetting: "Auto",
      nodeMode: "Auto",
      nodeHvacType: "Multi-stage RTU",

      scheduleMode: "Schedule",

      nodeMinToHealth: 1,
      nodeBadTemp: 5,
      nodePoorTemp: 10,
      nodeGoodTemp: 15,

      nodePositionX: 0,
      nodePositionY: 0,

      copyFleetName: "Choose Here",
      username: "unknown",
      savedChanges: false,
      errors: {},

      nodeName: "",
      nodeDescription: "",
      nodeUpdateRate: "Choose here",
      nodePriority: "Choose here",
      nodeOVRT: "3 Mins",
      nodePowerRating: "",
      nodeMinOffTime: "",
      nodeMaxOffTime: "",
      nodeMinOnTime: "",
      meterNum: "Choose",
      ctType: "Choose",
      voltWiring: "Choose",
      voltagePhase: "Choose",
      gridFreq: "Choose",
      nodeFanSetting: "Auto",
      nodeMode: "Auto",
      thermostatSensor1: "Return Sensor",
      thermostatSensor2: "Choose here",
      thermostatSensor3: "Choose here",
      thermostatSensor4: "Choose here",

      stage1Cooling: "Choose",
      setImages: false,
      currSerialNum: "",
      nodenameSerialSN: "",

      slot1: null,
      slot2: null,
      slot3: null,
      slot4: null,
    });
  };

  confirmationImagesSaving = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
    });

    return swalWithBootstrapButtons
      .fire({
        title: "Save images",
        text: "Do you want to copy images to the new serial number?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
        allowOutsideClick: false,
      })
      .then(async (result) => {
        return result;
      });
  };

  handleNodes = async (e) => {
    let simplNodes = [];
    let systemControls = {};
    const systemID = this.state.systemID;
    let selectedFunction = this.state.selectedFunction;
    const selectedIndex = this.state.selectedIndex;
    console.log("Selected Index: ", selectedIndex);
    //Convert Sensor and Relay Serial Numbers to MAC Addresses used by Lora Modules
    let nodeSensorMAC = "none";
    let nodeSensorMAC2 = "none";
    let nodeSensorMAC3 = "none";
    let nodeSensorMAC4 = "none";
    if (this.state.nodeSensorSN !== "") {
      try {
        const systemId = (
          await findSystemBySerialNumber(this.state.nodeSensorSN)
        ).data.data.system_id;
        const systemMAC = (await getSystem(systemId)).data.data[0].systemID.MAC;
        console.log("Found System ID:", systemMAC);
        nodeSensorMAC = systemMAC;
      } catch (ex) {
        if (
          ex.response &&
          ex.response.status !== 200 &&
          this.state.nodeSensorSN !== "none"
        ) {
          // toast.error("Invalid Node Sensor Serial Number.");
        }
      }
    } else {
      this.state.nodeSensorSN = "none";
    }

    if (this.state.nodeSensorSN2 !== "") {
      try {
        const systemId = (
          await findSystemBySerialNumber(this.state.nodeSensorSN2)
        ).data.data.system_id;
        const systemMAC = (await getSystem(systemId)).data.data[0].systemID.MAC;
        console.log("Found System ID:", systemMAC);
        nodeSensorMAC2 = systemMAC;
      } catch (ex) {
        if (
          ex.response &&
          ex.response.status !== 200 &&
          this.state.nodeSensorSN2 !== "none"
        ) {
          //toast.error("Invalid Node Sensor Serial Number.");
        }
      }
    } else {
      this.state.nodeSensorSN2 = "none";
    }

    if (this.state.nodeSensorSN3 !== "") {
      try {
        const systemId = (
          await findSystemBySerialNumber(this.state.nodeSensorSN3)
        ).data.data.system_id;
        const systemMAC = (await getSystem(systemId)).data.data[0].systemID.MAC;
        console.log("Found System ID:", systemMAC);
        nodeSensorMAC3 = systemMAC;
      } catch (ex) {
        if (
          ex.response &&
          ex.response.status !== 200 &&
          this.state.nodeSensorSN3 !== "none"
        ) {
          //toast.error("Invalid Node Sensor Serial Number.");
        }
      }
    } else {
      this.state.nodeSensorSN3 = "none";
    }

    if (this.state.nodeSensorSN4 !== "") {
      try {
        const systemId = (
          await findSystemBySerialNumber(this.state.nodeSensorSN4)
        ).data.data.system_id;
        const systemMAC = (await getSystem(systemId)).data.data[0].systemID.MAC;
        console.log("Found System ID:", systemMAC);
        nodeSensorMAC4 = systemMAC;
      } catch (ex) {
        if (
          ex.response &&
          ex.response.status !== 200 &&
          this.state.nodeSensorSN4 !== "none"
        ) {
          //toast.error("Invalid Node Sensor Serial Number.");
        }
      }
    } else {
      this.state.nodeSensorSN4 = "none";
    }

    let nodeThermostatMAC = "none";
    try {
      const systemId = (
        await findSystemBySerialNumber(this.state.nodeThermostatSN)
      ).data.data.system_id;
      const systemMAC = (await getSystem(systemId)).data.data[0].systemID.MAC;
      console.log("Found System ID:", systemMAC);
      nodeThermostatMAC = systemMAC;
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status !== 200 &&
        this.state.nodeThermostatSN !== "none"
      ) {
        //toast.error("Invalid Node Thermostat Serial Number.");
      }
    }

    let nodeDoorSensorMAC = "none";
    try {
      const systemId = (
        await findSystemBySerialNumber(this.state.nodeDoorSensorSN)
      ).data.data.system_id;
      const systemMAC = (await getSystem(systemId)).data.data[0].systemID.MAC;
      console.log("Found System ID:", systemMAC);
      nodeDoorSensorMAC = systemMAC;
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status !== 200 &&
        this.state.nodeDoorSensorSN !== "none"
      ) {
        //toast.error("Invalid Node Door Sensor Serial Number.");
      }
    }

    let nodeRelayMAC = "none";
    try {
      const systemId = (await findSystemBySerialNumber(this.state.nodeRelaySN))
        .data.data.system_id;
      const systemMAC = (await getSystem(systemId)).data.data[0].systemID.MAC;
      console.log("Found System ID:", systemMAC);
      nodeRelayMAC = systemMAC;
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status !== 200 &&
        this.state.nodeSensorSN !== "none"
      ) {
        //toast.error("Invalid Relay Serial Number.");
      }
    }

    let nodeSM2MAC = "none";
    try {
      const systemId = (await findSystemBySerialNumber(this.state.nodeSM2SN))
        .data.data.system_id;
      const systemMAC = (await getSystem(systemId)).data.data[0].systemID.MAC;
      console.log("Found System ID:", systemMAC);
      nodeSM2MAC = systemMAC;
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status !== 200 &&
        this.state.nodeSensorSN !== "none"
      ) {
        //toast.error("Invalid Relay Serial Number.");
      }
    }

    if (this.state.nodeOnTempSun === undefined)
      console.log("Somethings are undefined!");
    systemControls = (await getSystemControls(this.state.handleId)).data.data
      .controls;
    console.log("System Controls before uppacking:", systemControls);

    let nodenameSerialSN = "";
    let isnodeRelaySN = false;

    if (systemControls.length > 0) {
      for (let i = 0; i < systemControls.length; i++)
        if (
          selectedFunction === "addFunction" ||
          (selectedFunction === "editFunction" && i !== selectedIndex)
        ) {
          simplNodes.push(systemControls[i]);
        } else {
          if (selectedFunction === "editFunction") {
            if (systemControls[i].loadType === "HVAC w SimplTherm") {
              nodenameSerialSN = systemControls[i].nodeThermostatSN;
            } else if (systemControls[i].loadType === "SimplMeter") {
              nodenameSerialSN = systemControls[i].nodeSM2SN;
            } else if (
              systemControls[i].loadType === "Ambient Temp Sensor" ||
              systemControls[i].loadType === "Avg. Ambient Temp" ||
              systemControls[i].loadType === "External Temp Sensor" ||
              systemControls[i].loadType === "Light Sensor"
            ) {
              nodenameSerialSN = systemControls[i].nodeSensorSN;
            } else if (systemControls[i].loadType === "Door Monitor") {
              nodenameSerialSN = systemControls[i].nodeDoorSensorSN;
            } else if (
              systemControls[i].loadType === "HVAC Unit" ||
              systemControls[i].loadType === "Relay Driver" ||
              systemControls[i].loadType === "Relay Driver w Light Sensor" ||
              systemControls[i].loadType === "Quad 30A Relays" ||
              systemControls[i].loadType === "Quad 30A w Light Sensor" ||
              systemControls[i].loadType === "Pole Lights" ||
              systemControls[i].loadType === "Outdoor Sensed Lights" ||
              systemControls[i].loadType === "Indoor Lights" ||
              systemControls[i].loadType === "Heat Lamp" ||
              systemControls[i].loadType === "Space Heater" ||
              systemControls[i].loadType === "Water Heater" ||
              systemControls[i].loadType === "Water Heater No Sensor" ||
              systemControls[i].loadType === "SimplWalkIn" ||
              systemControls[i].loadType === "SimplHeater" ||
              systemControls[i].loadType === "Reach-in" ||
              systemControls[i].loadType === "Counter Reach-in" ||
              systemControls[i].loadType === "Anti-sweat Heaters" ||
              systemControls[i].loadType === "Oven" ||
              systemControls[i].loadType === "Hood" ||
              systemControls[i].loadType === "Misc. Appliance" ||
              systemControls[i].loadType === "Misc. Sheddable"
            ) {
              isnodeRelaySN = true;
              nodenameSerialSN = systemControls[i].nodeRelaySN;
            }
          }
        }
    }

    this.setState({ nodenameSerialSN });

    const images = await this.getSerialNumberImages();

    if (selectedFunction === "editFunction") {
      if (
        images.length === 0 &&
        nodenameSerialSN !== this.state.currSerialNum
      ) {
        console.log("Do not show confirmation message");
      } else if (
        nodenameSerialSN !== "" &&
        isnodeRelaySN &&
        nodenameSerialSN !== this.state.currSerialNum
      ) {
        const currNodesRelaysSN = systemControls.filter(
          (obj) => obj.nodeRelaySN == nodenameSerialSN
        );

        if (currNodesRelaysSN.length === 1) {
          const ans = await this.confirmationImagesSaving();

          if (ans.isDismissed) {
            return false;
          } else if (ans.isConfirmed) {
            await changeImagesSN(
              this.state.handleId.trim(),
              nodenameSerialSN,
              this.state.currSerialNum,
              true
            );
          } else {
            await changeImagesSN(
              this.state.handleId.trim(),
              nodenameSerialSN,
              this.state.currSerialNum,
              false
            );
          }
        }
      } else if (
        nodenameSerialSN !== "" &&
        nodenameSerialSN !== this.state.currSerialNum
      ) {
        const ans = await this.confirmationImagesSaving();

        if (ans.isDismissed) {
          return false;
        } else if (ans.isConfirmed) {
          await changeImagesSN(
            this.state.handleId.trim(),
            nodenameSerialSN,
            this.state.currSerialNum,
            true
          );
        } else {
          await changeImagesSN(
            this.state.handleId.trim(),
            nodenameSerialSN,
            this.state.currSerialNum,
            false
          );
        }
      } else {
        console.log("Nodename didn't change!");
      }
    }

    console.log("SimplNodes:", simplNodes);
    let nodeSchTemp = "";
    nodeSchTemp = nodeSchTemp.concat(
      this.state.nodeScheduleSun,
      this.state.nodeOnTempSun,
      this.state.nodeOffTempSun,
      ",",
      this.state.nodeScheduleMon,
      this.state.nodeOnTempMon,
      this.state.nodeOffTempMon,
      ",",
      this.state.nodeScheduleTue,
      this.state.nodeOnTempTue,
      this.state.nodeOffTempTue,
      ",",
      this.state.nodeScheduleWed,
      this.state.nodeOnTempWed,
      this.state.nodeOffTempWed,
      ",",
      this.state.nodeScheduleThu,
      this.state.nodeOnTempThu,
      this.state.nodeOffTempThu,
      ",",
      this.state.nodeScheduleFri,
      this.state.nodeOnTempFri,
      this.state.nodeOffTempFri,
      ",",
      this.state.nodeScheduleSat,
      this.state.nodeOnTempSat,
      this.state.nodeOffTempSat,
      ",",
      this.state.nodeScheduleHol,
      this.state.nodeOnTempHol,
      this.state.nodeOffTempHol
    );
    let nodeHealth = "";
    nodeHealth = nodeHealth.concat(
      this.state.nodeMinToHealth,
      ",",
      this.state.nodeBadTemp,
      ",",
      this.state.nodePoorTemp,
      ",",
      this.state.nodeGoodTemp
    );

    /********************* Changing heating temperature before sending ***********************/
    if (this.state.loadType === "HVAC w SimplTherm") {
      console.log(
        "loadtype before if : ",
        this.state.loadType === "HVAC w SimplTherm"
      );
      // if (this.state.loadtype === "HVAC w SimplTherm") {
      console.log("Inside if ", this.state.nodeTempSetCoolOc);
      let nodeTempSetCoolOc = parseInt(this.state.nodeTempSetCoolOc);
      let nodeTempSetHeatOc = parseInt(this.state.nodeTempSetHeatOc);
      let nodeTempSetCoolUn = parseInt(this.state.nodeTempSetCoolUn);
      let nodeTempSetHeatUn = parseInt(this.state.nodeTempSetHeatUn);
      const autoHeatCool = parseInt(this.state.autoHeatCool);

      console.log("autoheatcool inside if", autoHeatCool);

      if (nodeTempSetCoolOc - nodeTempSetHeatOc < autoHeatCool) {
        const nodeTempSetHeatOcInt = nodeTempSetCoolOc - autoHeatCool;
        nodeTempSetHeatOc = nodeTempSetHeatOcInt.toString();
        this.setState({ nodeTempSetHeatOc });
        toast.info(
          `Heating temperature setpoint occupied value changed to ${nodeTempSetHeatOc}°F`
        );
      }
      if (nodeTempSetCoolUn - nodeTempSetHeatUn < autoHeatCool) {
        const nodeTempSetHeatUnInt = nodeTempSetCoolUn - autoHeatCool;
        nodeTempSetHeatUn = nodeTempSetHeatUnInt.toString();
        this.setState({ nodeTempSetHeatUn });
        toast.info(
          `Heating temperature setpoint unoccupied value changed to ${nodeTempSetHeatUn}°F`
        );
      }
      // }
    }
    /****************************************************************************************/
    let newNode = {
      loadType: this.state.loadType,
      nodeName: this.state.nodeName,
      nodeDescription: this.state.nodeDescription,
      nodeSensorSN: this.state.nodeSensorSN,
      nodeSensorSN2: this.state.nodeSensorSN2,
      nodeSensorSN3: this.state.nodeSensorSN3,
      nodeSensorSN4: this.state.nodeSensorSN4,
      ////////////////////////////////////////////
      nodeSensorMAC: nodeSensorMAC,
      nodeSensorMAC2: nodeSensorMAC2,
      nodeSensorMAC3: nodeSensorMAC3,
      nodeSensorMAC4: nodeSensorMAC4,
      ////////////////////////////////////////////////////
      nodeDoorSensorSN: this.state.nodeDoorSensorSN,
      nodeDoorSensorMAC: nodeDoorSensorMAC,
      ///////////////////////////////////////////////////
      nodeThermostatSN: this.state.nodeThermostatSN,
      ///////////////////////////////////////////////////
      nodeThermostatMAC: nodeThermostatMAC,
      ///////////////////////////////////////////////////

      thermostatSensor1: this.state.thermostatSensor1,
      thermostatSensor2: this.state.thermostatSensor2,
      thermostatSensor3: this.state.thermostatSensor3,
      thermostatSensor4: this.state.thermostatSensor4,

      nodeTempSetHeatOc: this.state.nodeTempSetHeatOc,
      nodeTempSetCoolOc: this.state.nodeTempSetCoolOc,

      nodeTempSetHeatUn: this.state.nodeTempSetHeatUn,
      nodeTempSetCoolUn: this.state.nodeTempSetCoolUn,

      nodeDeltaTempSet: this.state.nodeDeltaTempSet,
      nodeRHSet: this.state.nodeRHSet,
      nodeFanSetting: this.state.nodeFanSetting,
      nodeMode: this.state.nodeMode,
      nodeHvacType: this.state.nodeHvacType,

      scheduleMode: this.state.scheduleMode,

      nodeDoorOrientation: this.state.nodeDoorOrientation,
      ///////////////////////////////////////////////////
      fleetControl: this.state.fleetControl,
      overRide: this.state.overRide,
      fossilHeat: this.state.fossilHeat,
      OBActive: this.state.OBActive,
      auxHeat: this.state.auxHeat,
      rthOnly: this.state.rthOnly,
      ///////////////////////////////////////////////////
      nodeSchTemp: nodeSchTemp,
      ///////////////////////////////////////////////////
      nodeUpdateRate: this.state.nodeUpdateRate,
      ///////////////////////////////////////////////////
      nodePriority: this.state.nodePriority,
      nodeOVRT: this.state.nodeOVRT,

      nodePowerRating: this.state.nodePowerRating,
      nodeMinOffTime: this.state.nodeMinOffTime,
      nodeMaxOffTime: this.state.nodeMaxOffTime,
      nodeMinOnTime: this.state.nodeMinOnTime,

      nodeRelaySN: this.state.nodeRelaySN,
      nodeSM2SN: this.state.nodeSM2SN,
      ///////////////////////////////////////////////////
      nodeRelayMAC: nodeRelayMAC,
      nodeSM2MAC: nodeSM2MAC,
      ctType: this.state.ctType,
      meterNum: this.state.meterNum,
      voltWiring: this.state.voltWiring,
      gridFreq: this.state.gridFreq,
      voltagePhase: this.state.voltagePhase,

      ///////////////////////////////////////////////////

      stage1Cooling: this.state.stage1Cooling,
      stage2Cooling: this.state.stage2Cooling,
      stage1Heating: this.state.stage1Heating,
      stage2Heating: this.state.stage2Heating,

      nodePositionX: this.state.nodePositionX,
      nodePositionY: this.state.nodePositionY,

      nodeHealth: nodeHealth,
      earlyStart: this.state.earlyStart,
      earlyStop: this.state.earlyStop,
      ductMountReturn: this.state.ductMountReturn,
    };
    if (this.state.nodeName) {
      simplNodes.push(newNode);
      systemControls = JSON.stringify(simplNodes);
      console.log("System Controls to be sent to DB:", systemControls);
      localStorage.setItem("systemControlsLocal", systemControls);
      if (
        this.state.prevNodeTempSetHeatOc !== this.state.nodeTempSetHeatOc ||
        this.state.prevNodeTempSetCoolOc !== this.state.nodeTempSetCoolOc ||
        this.state.prevNodeTempSetHeatUn !== this.state.nodeTempSetHeatUn ||
        this.state.prevNodeTempSetCoolUn !== this.state.nodeTempSetCoolUn
      ) {
        console.log(
          `Previous set heat temp occupied ${this.state.prevNodeTempSetHeatOc} / new set temp heat temp occupied ${this.state.nodeTempSetHeatOc}`
        );
        console.log(
          `Previous set cool temp unoccupied ${this.state.prevNodeTempSetCoolOc} / new set temp cool temp unoccupied ${this.state.nodeTempSetCoolOc}`
        );
        console.log(
          `Previous set heat temp occupied ${this.state.prevNodeTempSetHeatUn} / new set temp heat temp occupied ${this.state.nodeTempSetHeatUn}`
        );
        console.log(
          `Previous set cool temp unoccupied ${this.state.prevNodeTempSetCoolUn} / new set temp cool temp unoccupied ${this.state.nodeTempSetCoolUn}`
        );
        const controlsSet = await setSystemControlsObject(
          systemID,
          systemControls,
          this.state.username,
          this.state.selectedFunction,
          this.state.nodeName,

          this.state.prevNodeTempSetHeatOc,
          this.state.nodeTempSetHeatOc,
          this.state.prevNodeTempSetCoolOc,
          this.state.nodeTempSetCoolOc,
          this.state.prevNodeTempSetHeatUn,
          this.state.nodeTempSetHeatUn,
          this.state.prevNodeTempSetCoolUn,
          this.state.nodeTempSetCoolUn
        );
        console.log("Response of system set controls function: ", controlsSet);
        systemControls = (await getSystemControls(this.state.handleId)).data
          .data.controls;
        console.log("System Controls:", systemControls);
        toast.success("Setting succesfully saved");

        selectedFunction = null;
        this.setState({ selectedFunction, systemControls });
      } else {
        const controlsSet = await setSystemControlsObject(
          systemID,
          systemControls,
          this.state.username,
          this.state.selectedFunction,
          this.state.nodeName
        );
        console.log("Response of system set controls function: ", controlsSet);
        systemControls = (await getSystemControls(this.state.handleId)).data
          .data.controls;
        console.log("System Controls:", systemControls);
        toast.success("Setting succesfully saved");

        selectedFunction = null;
        this.setState({ selectedFunction, systemControls });

        localStorage.removeItem("unsavedChanges");
      }
    } else toast.error("Node name is required.");
    scroll.scrollToTop();

    window.removeEventListener("popstate", this.handlePopstate, true);
    // window.scrollX(0);
    // this.props.history.goBack();
    if (this.state.nodenamePassed) window.history.back();
  };

  handleDescription = (e) => {
    const nodeDescription = e.currentTarget.value;
    this.setState({ nodeDescription });
  };

  handleName = (e) => {
    const nodeName = e.currentTarget.value;
    this.setState({ nodeName });
  };

  handleLoads = (e) => {
    const loadType = e.currentTarget.value;
    console.log("Load Type:", loadType);
    this.setState({ loadType }, () => {
      this.reset(true);
    });
  };
  handleSensor1 = (e) => {
    const thermostatSensor1 = e.currentTarget.value;
    this.setState({ thermostatSensor1 });
  };
  handleSensor2 = (e) => {
    const thermostatSensor2 = e.currentTarget.value;
    this.setState({ thermostatSensor2 });
  };
  handleSensor3 = (e) => {
    const thermostatSensor3 = e.currentTarget.value;
    this.setState({ thermostatSensor3 });
  };
  handleSensor4 = (e) => {
    const thermostatSensor4 = e.currentTarget.value;
    this.setState({ thermostatSensor4 });
  };

  handleFanSetting = (e) => {
    const nodeFanSetting = e.currentTarget.value;
    this.setState({ nodeFanSetting });
  };

  handleMode = (e) => {
    const nodeMode = e.currentTarget.value;
    this.setState({ nodeMode });
  };
  handleHvacType = (e) => {
    const nodeHvacType = e.currentTarget.value;
    this.setState({ nodeHvacType });
  };
  handleSensorSN = async (e) => {
    let nodeSensorSN = e.currentTarget.value;
    this.setState({ nodeSensorSN, currSerialNum: nodeSensorSN });
  };
  handleSensorSN2 = async (e) => {
    let nodeSensorSN2 = e.currentTarget.value;
    this.setState({ nodeSensorSN2 });
  };
  handleSensorSN3 = async (e) => {
    let nodeSensorSN3 = e.currentTarget.value;
    this.setState({ nodeSensorSN3 });
  };
  handleSensorSN4 = async (e) => {
    let nodeSensorSN4 = e.currentTarget.value;
    this.setState({ nodeSensorSN4 });
  };

  handleThermostatSN = async (e) => {
    const nodeThermostatSN = e.currentTarget.value;
    this.setState({ nodeThermostatSN, currSerialNum: nodeThermostatSN });
  };
  handleDoorSensorSN = (e) => {
    const nodeDoorSensorSN = e.currentTarget.value;
    this.setState({ nodeDoorSensorSN, currSerialNum: nodeDoorSensorSN });
  };
  handleNodeRelay = (e) => {
    const nodeRelaySN = e.currentTarget.value;
    this.setState({ nodeRelaySN, currSerialNum: nodeRelaySN });
  };
  handleNodeSM2 = (e) => {
    const nodeSM2SN = e.currentTarget.value;
    this.setState({ nodeSM2SN, currSerialNum: nodeSM2SN });
  };

  handleMeterNum = (e) => {
    const meterNum = e.currentTarget.value;
    this.setState({ meterNum });
  };
  handleCtType = (e) => {
    const ctType = e.currentTarget.value;
    this.setState({ ctType });
  };
  handleVoltWiring = (e) => {
    const voltWiring = e.currentTarget.value;
    this.setState({ voltWiring });
  };
  handleGridFreq = (e) => {
    const gridFreq = e.currentTarget.value;
    this.setState({ gridFreq });
  };
  handleVoltagePhase = (e) => {
    const voltagePhase = e.currentTarget.value;
    this.setState({ voltagePhase });
  };

  handleStage1Cooling = (e) => {
    const stage1Cooling = e.currentTarget.value;
    this.setState({ stage1Cooling });
  };

  handleStage2Cooling = (e) => {
    const stage2Cooling = e.currentTarget.value;
    this.setState({ stage2Cooling });
  };

  handleStage1Heating = (e) => {
    const stage1Heating = e.currentTarget.value;
    this.setState({ stage1Heating });
  };

  handleStage2Heating = (e) => {
    const stage2Heating = e.currentTarget.value;
    this.setState({ stage2Heating });
  };

  handleScheduleMon = (e) => {
    const nodeScheduleMon = e.currentTarget.value;
    this.setState({ nodeScheduleMon });
  };
  handleScheduleTue = (e) => {
    const nodeScheduleTue = e.currentTarget.value;
    this.setState({ nodeScheduleTue });
  };
  handleScheduleWed = (e) => {
    const nodeScheduleWed = e.currentTarget.value;
    this.setState({ nodeScheduleWed });
  };
  handleScheduleThu = (e) => {
    const nodeScheduleThu = e.currentTarget.value;
    this.setState({ nodeScheduleThu });
  };
  handleScheduleFri = (e) => {
    const nodeScheduleFri = e.currentTarget.value;
    this.setState({ nodeScheduleFri });
  };
  handleScheduleSat = (e) => {
    const nodeScheduleSat = e.currentTarget.value;
    this.setState({ nodeScheduleSat });
  };
  handleScheduleSun = (e) => {
    const nodeScheduleSun = e.currentTarget.value;
    this.setState({ nodeScheduleSun });
  };
  handleScheduleHol = (e) => {
    const nodeScheduleHol = e.currentTarget.value;
    this.setState({ nodeScheduleHol });
  };

  handleOnTempMon = (e) => {
    const nodeOnTempMon = e.currentTarget.value;
    this.setState({ nodeOnTempMon });
  };
  handleOnTempTue = (e) => {
    const nodeOnTempTue = e.currentTarget.value;
    this.setState({ nodeOnTempTue });
  };
  handleOnTempWed = (e) => {
    const nodeOnTempWed = e.currentTarget.value;
    this.setState({ nodeOnTempWed });
  };
  handleOnTempThu = (e) => {
    const nodeOnTempThu = e.currentTarget.value;
    this.setState({ nodeOnTempThu });
  };
  handleOnTempFri = (e) => {
    const nodeOnTempFri = e.currentTarget.value;
    this.setState({ nodeOnTempFri });
  };
  handleOnTempSat = (e) => {
    const nodeOnTempSat = e.currentTarget.value;
    this.setState({ nodeOnTempSat });
  };
  handleOnTempSun = (e) => {
    const nodeOnTempSun = e.currentTarget.value;
    this.setState({ nodeOnTempSun });
  };
  handleOnTempHol = (e) => {
    const nodeOnTempHol = e.currentTarget.value;
    this.setState({ nodeOnTempHol });
  };

  handleOffTempMon = (e) => {
    const nodeOffTempMon = e.currentTarget.value;
    this.setState({ nodeOffTempMon });
  };
  handleOffTempTue = (e) => {
    const nodeOffTempTue = e.currentTarget.value;
    this.setState({ nodeOffTempTue });
  };
  handleOffTempWed = (e) => {
    const nodeOffTempWed = e.currentTarget.value;
    this.setState({ nodeOffTempWed });
  };
  handleOffTempThu = (e) => {
    const nodeOffTempThu = e.currentTarget.value;
    this.setState({ nodeOffTempThu });
  };
  handleOffTempFri = (e) => {
    const nodeOffTempFri = e.currentTarget.value;
    this.setState({ nodeOffTempFri });
  };
  handleOffTempSat = (e) => {
    const nodeOffTempSat = e.currentTarget.value;
    this.setState({ nodeOffTempSat });
  };
  handleOffTempSun = (e) => {
    const nodeOffTempSun = e.currentTarget.value;
    this.setState({ nodeOffTempSun });
  };
  handleOffTempHol = (e) => {
    const nodeOffTempHol = e.currentTarget.value;
    console.log("Edit Hol Temp Off", nodeOffTempHol);
    this.setState({ nodeOffTempHol });
  };

  handlePriority = (e) => {
    const nodePriority = e.currentTarget.value;
    this.setState({ nodePriority });
  };

  handleOVRT = (e) => {
    const nodeOVRT = e.currentTarget.value;
    this.setState({ nodeOVRT });
  };

  handlePowerRating = (e) => {
    const nodePowerRating = e.currentTarget.value;
    this.setState({ nodePowerRating });
  };

  handleMinOffTime = (e) => {
    const nodeMinOffTime = e.currentTarget.value;
    this.setState({ nodeMinOffTime });
  };
  handleMaxOffTime = (e) => {
    const nodeMaxOffTime = e.currentTarget.value;
    this.setState({ nodeMaxOffTime });
  };
  handleMinOnTime = (e) => {
    const nodeMinOnTime = e.currentTarget.value;
    this.setState({ nodeMinOnTime });
  };

  handleUpdateRate = (e) => {
    const nodeUpdateRate = e.currentTarget.value;
    this.setState({ nodeUpdateRate });
  };

  handleTempSetHeatOc = (e) => {
    const nodeTempSetHeatOc = e.currentTarget.value;
    this.setState({ nodeTempSetHeatOc });
  };
  handleTempSetCoolOc = (e) => {
    const nodeTempSetCoolOc = e.currentTarget.value;
    this.setState({ nodeTempSetCoolOc });
  };

  handleTempSetHeatUn = (e) => {
    const nodeTempSetHeatUn = e.currentTarget.value;
    this.setState({ nodeTempSetHeatUn });
  };
  handleTempSetCoolUn = (e) => {
    const nodeTempSetCoolUn = e.currentTarget.value;
    this.setState({ nodeTempSetCoolUn });
  };

  handleDeltaTempSet = (e) => {
    const nodeDeltaTempSet = e.currentTarget.value;
    this.setState({ nodeDeltaTempSet });
  };
  handleRHSet = (e) => {
    const nodeRHSet = e.currentTarget.value;
    this.setState({ nodeRHSet });
  };
  handleScheduleMode = (e) => {
    const scheduleMode = e.currentTarget.value;
    this.setState({ scheduleMode });
  };

  handleEdit = async (selectedNodeName) => {
    console.log(
      "Editing...Selected Node Name:",
      /*this.state.*/ selectedNodeName
    );
    let systemControls = (await getSystemControls(this.state.handleId)).data
      .data.controls;
    console.log("System Controls Edit:", systemControls);
    let selectedIndex = 5000;
    for (let i = 0; i < systemControls.length; i++) {
      console.log("systemControls[i]:", systemControls[i]);
      if (systemControls[i].nodeName === selectedNodeName) selectedIndex = i;
    }
    console.log("Value of i is:", selectedIndex);
    const selectedFunction = "editFunction";
    this.setState({
      selectedFunction,
      selectedIndex,
    });
    console.log("systemControls[i]:", systemControls[selectedIndex]);
    //////////////////////////////////
    //selectedIndex = 0;
    //////////////////////////////////
    let loadType = systemControls[selectedIndex].loadType;
    let nodeName = systemControls[selectedIndex].nodeName;
    let nodeDescription = systemControls[selectedIndex].nodeDescription;
    let nodeSensorSN = systemControls[selectedIndex].nodeSensorSN;
    let nodeSensorSN2 = systemControls[selectedIndex].nodeSensorSN2;
    let nodeSensorSN3 = systemControls[selectedIndex].nodeSensorSN3;
    let nodeSensorSN4 = systemControls[selectedIndex].nodeSensorSN4;

    let nodeDoorSensorSN = systemControls[selectedIndex].nodeDoorSensorSN;
    let nodeThermostatSN = systemControls[selectedIndex].nodeThermostatSN;

    let thermostatSensor1 = systemControls[selectedIndex].thermostatSensor1;
    let thermostatSensor2 = systemControls[selectedIndex].thermostatSensor2;
    let thermostatSensor3 = systemControls[selectedIndex].thermostatSensor3;
    let thermostatSensor4 = systemControls[selectedIndex].thermostatSensor4;

    let nodeTempSetHeatOc = systemControls[selectedIndex].nodeTempSetHeatOc;
    let nodeTempSetCoolOc = systemControls[selectedIndex].nodeTempSetCoolOc;

    let nodeTempSetHeatUn = systemControls[selectedIndex].nodeTempSetHeatUn;
    let nodeTempSetCoolUn = systemControls[selectedIndex].nodeTempSetCoolUn;

    let nodeDeltaTempSet = systemControls[selectedIndex].nodeDeltaTempSet;
    let nodeRHSet = systemControls[selectedIndex].nodeRHSet;
    let nodeFanSetting = systemControls[selectedIndex].nodeFanSetting;
    let nodeMode = systemControls[selectedIndex].nodeMode;
    let nodeHvacType = systemControls[selectedIndex].nodeHvacType;

    let scheduleMode = systemControls[selectedIndex].scheduleMode;

    const fleetControl = systemControls[selectedIndex].fleetControl;
    let overRide = systemControls[selectedIndex].overRide;
    let fossilHeat = systemControls[selectedIndex].fossilHeat;
    let OBActive = systemControls[selectedIndex].OBActive;
    let rthOnly = systemControls[selectedIndex].rthOnly;
    let auxHeat = systemControls[selectedIndex].auxHeat;

    const earlyStart = systemControls[selectedIndex].earlyStart;
    const earlyStop = systemControls[selectedIndex].earlyStop;
    const ductMountReturn = systemControls[selectedIndex].ductMountReturn;

    const nodeDoorOrientation =
      systemControls[selectedIndex].nodeDoorOrientation;

    let nodeUpdateRate = systemControls[selectedIndex].nodeUpdateRate;
    let nodePriority = systemControls[selectedIndex].nodePriority;
    let nodeOVRT = systemControls[selectedIndex].nodeOVRT;

    let nodePowerRating = systemControls[selectedIndex].nodePowerRating;
    let nodeMinOffTime = systemControls[selectedIndex].nodeMinOffTime;
    let nodeMaxOffTime = systemControls[selectedIndex].nodeMaxOffTime;
    let nodeMinOnTime = systemControls[selectedIndex].nodeMinOnTime;

    let nodeRelaySN = systemControls[selectedIndex].nodeRelaySN;
    let nodeSM2SN = systemControls[selectedIndex].nodeSM2SN;
    let meterNum = systemControls[selectedIndex].meterNum;
    let ctType = systemControls[selectedIndex].ctType;
    let voltWiring = systemControls[selectedIndex].voltWiring;
    let gridFreq = systemControls[selectedIndex].gridFreq;
    let voltagePhase = systemControls[selectedIndex].voltagePhase;

    let stage1Cooling = systemControls[selectedIndex].stage1Cooling;
    let stage2Cooling = systemControls[selectedIndex].stage2Cooling;
    let stage1Heating = systemControls[selectedIndex].stage1Heating;
    let stage2Heating = systemControls[selectedIndex].stage2Heating;

    let nodenameSerialSN = "";

    if (systemControls[selectedIndex].loadType === "HVAC w SimplTherm") {
      nodenameSerialSN = systemControls[selectedIndex].nodeThermostatSN;
    } else if (systemControls[selectedIndex].loadType === "SimplMeter") {
      nodenameSerialSN = systemControls[selectedIndex].nodeSM2SN;
    } else if (
      systemControls[selectedIndex].loadType === "Ambient Temp Sensor" ||
      systemControls[selectedIndex].loadType === "Avg. Ambient Temp" ||
      systemControls[selectedIndex].loadType === "External Temp Sensor" ||
      systemControls[selectedIndex].loadType === "Light Sensor"
    ) {
      nodenameSerialSN = systemControls[selectedIndex].nodeSensorSN;
    } else if (systemControls[selectedIndex].loadType === "Door Monitor") {
      nodenameSerialSN = systemControls[selectedIndex].nodeDoorSensorSN;
    } else if (
      systemControls[selectedIndex].loadType === "HVAC Unit" ||
      systemControls[selectedIndex].loadType === "Relay Driver" ||
      systemControls[selectedIndex].loadType ===
        "Relay Driver w Light Sensor" ||
      systemControls[selectedIndex].loadType === "Quad 30A Relays" ||
      systemControls[selectedIndex].loadType === "Quad 30A w Light Sensor" ||
      systemControls[selectedIndex].loadType === "Pole Lights" ||
      systemControls[selectedIndex].loadType === "Outdoor Sensed Lights" ||
      systemControls[selectedIndex].loadType === "Indoor Lights" ||
      systemControls[selectedIndex].loadType === "Heat Lamp" ||
      systemControls[selectedIndex].loadType === "Space Heater" ||
      systemControls[selectedIndex].loadType === "Water Heater" ||
      systemControls[selectedIndex].loadType === "Water Heater No Sensor" ||
      systemControls[selectedIndex].loadType === "SimplWalkIn" ||
      systemControls[selectedIndex].loadType === "SimplHeater" ||
      systemControls[selectedIndex].loadType === "Reach-in" ||
      systemControls[selectedIndex].loadType === "Counter Reach-in" ||
      systemControls[selectedIndex].loadType === "Anti-sweat Heaters" ||
      systemControls[selectedIndex].loadType === "Oven" ||
      systemControls[selectedIndex].loadType === "Hood" ||
      systemControls[selectedIndex].loadType === "Misc. Appliance" ||
      systemControls[selectedIndex].loadType === "Misc. Sheddable"
    ) {
      nodenameSerialSN = systemControls[selectedIndex].nodeRelaySN;
    }

    this.setState({
      slot1: null,
      slot2: null,
      slot3: null,
      slot4: null,
      setImages: false,
      currSerialNum: nodenameSerialSN,
      nodenameSerialSN,
    });

    const nodeSchTemp = systemControls[selectedIndex].nodeSchTemp;
    let i = 0;
    const sunSchedule = nodeSchTemp.slice(i, i + 1);
    const sunOnTemp = nodeSchTemp.slice(++i, i + 1);
    const sunOffTemp = nodeSchTemp.slice(++i, i + 1);
    console.log("Sundays Schedule:", sunSchedule, sunOnTemp, sunOffTemp);
    i += 1;
    const monSchedule = nodeSchTemp.slice(++i, i + 1);
    const monOnTemp = nodeSchTemp.slice(++i, i + 1);
    const monOffTemp = nodeSchTemp.slice(++i, i + 1);
    console.log("Mondays Schedule:", monSchedule, monOnTemp, monOffTemp);
    i += 1;
    const tueSchedule = nodeSchTemp.slice(++i, i + 1);
    const tueOnTemp = nodeSchTemp.slice(++i, i + 1);
    const tueOffTemp = nodeSchTemp.slice(++i, i + 1);
    console.log("Tuesdays Schedule:", tueSchedule, tueOnTemp, tueOffTemp);
    i += 1;
    const wedSchedule = nodeSchTemp.slice(++i, i + 1);
    const wedOnTemp = nodeSchTemp.slice(++i, i + 1);
    const wedOffTemp = nodeSchTemp.slice(++i, i + 1);
    i += 1;
    console.log("Wednesdays Schedule:", wedSchedule, wedOnTemp, wedOffTemp);
    const thuSchedule = nodeSchTemp.slice(++i, i + 1);
    const thuOnTemp = nodeSchTemp.slice(++i, i + 1);
    const thuOffTemp = nodeSchTemp.slice(++i, i + 1);
    i += 1;
    console.log("Thursdays Schedule:", thuSchedule, thuOnTemp, thuOffTemp);
    const friSchedule = nodeSchTemp.slice(++i, i + 1);
    const friOnTemp = nodeSchTemp.slice(++i, i + 1);
    const friOffTemp = nodeSchTemp.slice(++i, i + 1);
    i += 1;
    console.log("Fridays Schedule:", friSchedule, friOnTemp, friOffTemp);
    const satSchedule = nodeSchTemp.slice(++i, i + 1);
    const satOnTemp = nodeSchTemp.slice(++i, i + 1);
    const satOffTemp = nodeSchTemp.slice(++i, i + 1);
    i += 1;
    console.log("Saturdays Schedule:", satSchedule, satOnTemp, satOffTemp);
    const holSchedule = nodeSchTemp.slice(++i, i + 1);
    const holOnTemp = nodeSchTemp.slice(++i, i + 1);
    const holOffTemp = nodeSchTemp.slice(++i, i + 1);
    console.log("Holidays Schedule:", holSchedule, holOnTemp, holOffTemp);

    let nodeMinToHealth = 1;
    let nodeBadTemp = 5;
    let nodePoorTemp = 10;
    let nodeGoodTemp = 15;
    const nodeHealth = systemControls[selectedIndex].nodeHealth;
    console.log("Node Health String:", nodeHealth);
    if (nodeHealth !== undefined && nodeHealth !== null) {
      const tempArray = nodeHealth.split(",");
      nodeMinToHealth = tempArray[0];
      console.log("Node Mon To Health: ", nodeMinToHealth);
      nodeBadTemp = tempArray[1];
      nodePoorTemp = tempArray[2];
      nodeGoodTemp = tempArray[3];
    }

    this.setState({
      selectedFunction,
      selectedIndex,
      loadType,
      nodeName,
      nodeDescription,
      nodeSensorSN,
      nodeSensorSN2,
      nodeSensorSN3,
      nodeSensorSN4,

      nodeDoorSensorSN,
      nodeThermostatSN,

      thermostatSensor1,
      thermostatSensor2,
      thermostatSensor3,
      thermostatSensor4,

      nodeTempSetHeatOc,
      nodeTempSetCoolOc,

      nodeTempSetHeatUn,
      nodeTempSetCoolUn,

      nodeDeltaTempSet,
      nodeRHSet,
      nodeFanSetting,
      nodeMode,
      nodeHvacType,

      scheduleMode,

      fleetControl,
      overRide,
      fossilHeat,
      OBActive,
      rthOnly,
      auxHeat,

      earlyStart,
      earlyStop,
      ductMountReturn,

      nodeDoorOrientation,

      nodeScheduleMon: monSchedule,
      nodeScheduleTue: tueSchedule,
      nodeScheduleWed: wedSchedule,
      nodeScheduleThu: thuSchedule,
      nodeScheduleFri: friSchedule,
      nodeScheduleSat: satSchedule,
      nodeScheduleSun: sunSchedule,
      nodeScheduleHol: holSchedule,
      nodeUpdateRate,
      nodePriority,
      nodeOVRT,
      nodePowerRating,
      nodeMinOffTime,
      nodeMaxOffTime,
      nodeMinOnTime,

      nodeRelaySN,
      nodeSM2SN,
      meterNum,
      ctType,
      voltWiring,
      gridFreq,
      voltagePhase,

      stage1Cooling,
      stage2Cooling,
      stage1Heating,
      stage2Heating,

      nodeOnTempMon: monOnTemp,
      nodeOnTempTue: tueOnTemp,
      nodeOnTempWed: wedOnTemp,
      nodeOnTempThu: thuOnTemp,
      nodeOnTempFri: friOnTemp,
      nodeOnTempSat: satOnTemp,
      nodeOnTempSun: sunOnTemp,
      nodeOnTempHol: holOnTemp,

      nodeOffTempMon: monOffTemp,
      nodeOffTempTue: tueOffTemp,
      nodeOffTempWed: wedOffTemp,
      nodeOffTempThu: thuOffTemp,
      nodeOffTempFri: friOffTemp,
      nodeOffTempSat: satOffTemp,
      nodeOffTempSun: sunOffTemp,
      nodeOffTempHol: holOffTemp,

      nodeMinToHealth,
      nodeBadTemp,
      nodePoorTemp,
      nodeGoodTemp,

      prevNodeTempSetHeatOc: nodeTempSetHeatOc,
      prevNodeTempSetCoolOc: nodeTempSetCoolOc,

      prevNodeTempSetHeatUn: nodeTempSetHeatUn,
      prevNodeTempSetCoolUn: nodeTempSetCoolUn,

      // slotImages
    });

    for (let i = 1; i <= 4; i++) {
      await this.setImageUrl(i);
    }
  };

  handleAdd = async (e) => {
    e.preventDefault();
    const selectedFunction = "addFunction";
    this.setState({ selectedFunction });
  };
  handleDelete = async (selectedNodeName /*e*/) => {
    //selectedNodeName.preventDefault();
    let simplNodes = [];
    let systemControls = {};

    systemControls = (await getSystemControls(this.state.handleId)).data.data
      .controls;
    console.log("System Controls in Delete:", systemControls);
    for (let i = 0; i < systemControls.length; i++) {
      let objLength = Object.keys(systemControls[i]).length;
      if (systemControls[i].nodeName !== selectedNodeName && objLength !== 0)
        simplNodes.push(systemControls[i]);
    }
    systemControls = JSON.stringify(simplNodes);
    await setSystemControlsObject(
      this.state.systemID,
      systemControls,
      this.state.username,
      "deleteFunction",
      this.state.nodeName
    );
    localStorage.setItem("systemControlsLocal", systemControls);
    systemControls = (await getSystemControls(this.state.handleId)).data.data
      .controls;
    this.setState({ systemControls });
  };

  handleFleetControl = (e) => {
    console.log("In Fleet Control");
    let fleetControl = this.state.fleetControl;
    if (fleetControl === 0) fleetControl = 1;
    else fleetControl = 0;
    console.log("fleet control:", fleetControl);
    this.setState({ fleetControl });
  };

  handleEarlyStart = (e) => {
    let earlyStart = this.state.earlyStart;
    if (earlyStart === 0) earlyStart = 1;
    else earlyStart = 0;
    console.log("earlyStart:", earlyStart);
    this.setState({ earlyStart });
  };

  handleEarlyStop = (e) => {
    let earlyStop = this.state.earlyStop;
    if (earlyStop === 0) earlyStop = 1;
    else earlyStop = 0;
    console.log("earlyStop:", earlyStop);
    this.setState({ earlyStop });
  };

  handleDuctMountReturn = (e) => {
    let ductMountReturn = this.state.ductMountReturn;
    if (ductMountReturn === 0) ductMountReturn = 1;
    else ductMountReturn = 0;
    console.log("ductMountReturn:", ductMountReturn);
    this.setState({ ductMountReturn });
  };

  handleSaveFleet = (e) => {
    console.log("In Fleet Control");
    let saveFleet = this.state.saveFleet;
    if (!saveFleet) saveFleet = true;
    else saveFleet = false;
    this.setState({ saveFleet });
  };
  handleCopyFleet = (e) => {
    console.log("In Fleet Control");
    let copyFleet = this.state.copyFleet;
    if (!copyFleet) copyFleet = true;
    else copyFleet = false;
    this.setState({ copyFleet });
  };

  handleFleetName = async (e) => {
    const fleetName = e.currentTarget.value;
    this.setState({ fleetName });
  };

  handleFleetDescription = async (e) => {
    const fleetDescription = e.currentTarget.value;
    this.setState({ fleetDescription });
  };

  copyFleet = async (e) => {
    e.preventDefault();
    const copyingFromFleetName = this.state.copyFleetName;
    console.log("Copying From Fleet: ", copyingFromFleetName);
    const fleet = this.state.fleetOptions.filter(
      (thisFleet) => thisFleet.fleetName === copyingFromFleetName
    );
    const systemControlsNow = (await getSystemControls(this.state.handleId))
      .data.data.controls;
    console.log("System Controls now:", systemControlsNow);
    let fleetControls = fleet[0].systemControls;
    let foundNode = {};
    fleetControls.map((obj) => {
      foundNode =
        systemControlsNow.find((o) => o.nodeName === obj.nodeName) || obj;
      Object.assign(obj, {
        nodeDoorSensorMAC: foundNode.nodeDoorSensorMAC,
        nodeDoorSensorSN: foundNode.nodeDoorSensorSN,
        nodeRelayMAC: foundNode.nodeRelayMAC,
        nodeSM2MAC: foundNode.nodeSM2MAC,
        nodeRelaySN: foundNode.nodeRelaySN,
        nodeSM2SN: foundNode.nodeSM2SN,
        nodeSensorMAC: foundNode.nodeSensorMAC,
        nodeThermostatMAC: foundNode.nodeThermostatMAC,
        nodeSensorSN: foundNode.nodeSensorSN,
        nodeSensorSN2: foundNode.nodeSensorSN2,
        nodeSensorSN3: foundNode.nodeSensorSN3,
        nodeSensorSN4: foundNode.nodeSensorSN4,
        nodeThermostatSN: foundNode.nodeThermostatSN,
      });
    });
    console.log("System Controls afterwards:", fleet[0].systemControls);
    const systemControls = JSON.stringify(fleet[0].systemControls);
    await setSystemControlsObject(this.state.systemID, systemControls);
    localStorage.setItem("systemControlsLocal", systemControls);
    const systemLimits = JSON.stringify(fleet[0].systemLimits);
    await setSystemLimitsObject(this.state.systemID, systemLimits);
    const copyFleet = false;
    this.setState({ copyFleet, systemControls: fleet[0].systemControls });
  };
  handleSelectCopyFleet = (e) => {
    const copyFleetName = e.currentTarget.value;
    console.log("Copy Fleet Name:", copyFleetName);
    const fleetOptions = this.state.fleetOptions;
    console.log("Fleet Options in Select Fleet Name", fleetOptions);
    this.setState({ copyFleetName });
  };
  handleFleets = async (e) => {
    e.preventDefault();

    let newFleetInfo = [];
    const jwt = localStorage.getItem("token");
    setJwt(jwt);
    const companyName = (await getUser()).data.data.company;
    this.setState({ companyName });

    // const companies = (await getCompanies()).data.data;
    // const company = companies.filter(
    //   (thisCompany) => thisCompany.companyName === companyName
    // );
    // console.log("This company:", company);
    const contactInfo = this.state.contactInfo;
    console.log("Contact Info:", contactInfo);
    let newContactObject = {};

    console.log("Fleet Info", fleetInfo);
    const systemControls = (await getSystemControls(this.state.handleId)).data
      .data.controls;
    const systemLimits = (await getSystemLimits(this.state.handleId)).data.data
      .limits;
    console.log("System Controls: ", systemControls);
    console.log("System Limits: ", systemLimits);
    let newFleet = {
      fleetName: this.state.fleetName,
      fleetDescription: this.state.fleetDescription,
      fleetOriginHandle: this.state.handleId,
      systemControls: systemControls,
      systemLimits: systemLimits,
    };
    console.log("New Fleet", newFleet);
    // let fleetInfo = company[0].contactInfo.fleetInfo;
    let fleetInfo = this.state.fleetOptions;

    if (fleetInfo) {
      for (let i = 0; i < fleetInfo.length; i++)
        newFleetInfo.push(fleetInfo[i]);
    }
    console.log("New Fleet Info:", newFleetInfo);
    newFleetInfo.push(newFleet);
    console.log("Fleet Info after push:", newFleetInfo);
    let newCompanyContact = {};
    Object.assign(
      newCompanyContact,
      { contactName: contactInfo.contactName },
      { contactEmail: contactInfo.contactEmail },
      { contactPhone: contactInfo.contactPhone },
      { companyAddress: contactInfo.companyAddress },
      { fleetInfo: newFleetInfo }
    );
    console.log("New Company Contact:", newCompanyContact);
    const updateObject = JSON.stringify(newCompanyContact);
    console.log("New Contact Object:", newCompanyContact);
    await updateCompanyObject(companyName, updateObject);
    const saveFleet = false;
    this.setState({ saveFleet });
  };

  handleOverRide = (e) => {
    let overRide = this.state.overRide;
    if (overRide === 0) overRide = 1;
    else overRide = 0;
    console.log("Override control:", overRide);
    this.setState({ overRide });
  };

  handleFossilHeat = (e) => {
    let fossilHeat = this.state.fossilHeat;
    if (fossilHeat === 0) fossilHeat = 1;
    else fossilHeat = 0;
    console.log("Fossil Fuel control:", fossilHeat);
    this.setState({ fossilHeat });
  };

  handleOBactive = (e) => {
    let OBActive = this.state.OBActive;
    if (OBActive === 0) OBActive = 1;
    else OBActive = 0;
    console.log("Fossil Fuel control:", OBActive);
    this.setState({ OBActive });
  };

  handleRthOnly = (e) => {
    let rthOnly = this.state.rthOnly;
    if (rthOnly === 0) rthOnly = 1;
    else rthOnly = 0;
    console.log("External Sensor used Rth only", rthOnly);
    this.setState({ rthOnly });
  };

  handleAuxHeat = (e) => {
    let auxHeat = this.state.auxHeat;
    if (auxHeat === 0) auxHeat = 1;
    else auxHeat = 0;
    console.log("Fossil Fuel control:", auxHeat);
    this.setState({ auxHeat });
  };
  handleDoorOrientation = (e) => {
    const nodeDoorOrientation = e.currentTarget.value;
    this.setState({ nodeDoorOrientation });
  };

  handleExcelUpload = async (e) => {
    e.preventDefault();
    const setControlsUpload = 1;
    this.setState({ setControlsUpload });
  };
  handleExcelOutput = async (e) => {
    let excelDataSet = [0];
    const systemControls = (await getSystemControls(this.state.handleId)).data
      .data.controls;
    console.log("Excel Output systemControls", systemControls);

    for (let i = 0; i < systemControls.length; i++) {
      excelDataSet[i] = JSON.parse(
        JSON.stringify({
          Index: parseInt(i),
          Type: systemControls[i].loadType,
          Name: systemControls[i].nodeName,
          Description: systemControls[i].nodeDescription,
          Sensor_SN: systemControls[i].nodeSensorSN,
          Sensor_SN2: systemControls[i].nodeSensorSN2,
          Sensor_SN3: systemControls[i].nodeSensorSN3,
          Sensor_SN4: systemControls[i].nodeSensorSN4,

          Sensor_MAC: systemControls[i].nodeSensorMAC,
          Sensor_MAC2: systemControls[i].nodeSensorMAC2,
          Sensor_MAC3: systemControls[i].nodeSensorMAC3,
          Sensor_MAC4: systemControls[i].nodeSensorMAC4,

          DoorSensor_SN: systemControls[i].nodeDoorSensorSN,
          DoorSensor_MAC: systemControls[i].nodeDoorSensorMAC,

          Thermostat_SN: systemControls[i].nodeThermostatSN,

          Thermostat_MAC: systemControls[i].nodeThermostatMAC,

          Thermostat_Sensor1: systemControls[i].thermostatSensor1,
          Thermostat_Sensor2: systemControls[i].thermostatSensor2,
          Thermostat_Sensor3: systemControls[i].thermostatSensor3,
          Thermostat_Sensor4: systemControls[i].thermostatSensor4,
          TsetHeatOc: systemControls[i].nodeTempSetHeatOc,
          TsetCoolOc: systemControls[i].nodeTempSetCoolOc,
          TsetHeatUn: systemControls[i].nodeTempSetHeatUn,
          TsetCoolUn: systemControls[i].nodeTempSetCoolUn,
          TDeltaSet: systemControls[i].nodeDeltaTempSet,
          RHset: systemControls[i].nodeRHSet,
          FanSetting: systemControls[i].nodeFanSetting,
          nodeMode: systemControls[i].nodeMode,
          nodeHvacType: systemControls[i].nodeHvacType,

          ScheduleMode: systemControls[i].scheduleMode,

          nodeUpdateRate: systemControls[i].nodeUpdateRate,

          Priority: systemControls[i].nodePriority,
          OVRDTime: systemControls[i].nodeOVRT,

          Power_Rating: systemControls[i].nodePowerRating,
          Min_Off_Time: systemControls[i].nodeMinOffTime,
          Max_Off_Time: systemControls[i].nodeMaxOffTime,
          Min_On_Time: systemControls[i].nodeMinOnTime,
          Relay_SN: systemControls[i].nodeRelaySN,

          Relay_MAC: systemControls[i].nodeRelayMAC,

          stage1_Cooling_Relay: systemControls[i].stage1Cooling,
          stage2_Cooling_Relay: systemControls[i].stage2Cooling,
          stage1_Heating_Relay: systemControls[i].stage1Heating,
          stage2_Heating_Relay: systemControls[i].stage2Heating,

          fleetControl: systemControls[i].fleetControl,
          overRide: systemControls[i].overRide,
          fossilHeat: systemControls[i].fossilHeat,
          OBActive: systemControls[i].OBActive,
          auxHeat: systemControls[i].auxHeat,

          hourAndTemps: systemControls[i].nodeSchTemp,

          nodePositionX: systemControls[i].nodePositionX,
          nodePositionY: systemControls[i].nodePositionY,

          nodeHealth: systemControls[i].nodeHealth,
          rthOnly: systemControls[i].rthOnly,

          SM2_SN: systemControls[i].nodeSM2SN,
          SM2_MAC: systemControls[i].nodeSM2MAC,
          SM2_MeterNum: systemControls[i].meterNum,
          SM2_CTtype: systemControls[i].ctType,
          SM2_gridFreq: systemControls[i].gridFreq,
          SM2_VWiring: systemControls[i].voltWiring,
          SM2_VoltagePhase: systemControls[i].voltagePhase,

          earlyStart: systemControls[i].earlyStart,
          earlyStop: systemControls[i].earlyStop,
          ductMountReturn: systemControls[i].ductMountReturn,
        })
      );
    }
    this.setState({ excelDataSet });
  };
  fileHandler = (event) => {
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        this.setState({
          tariffJson: resp,
          cols: resp.cols,
          rows: resp.rows,
          dataLoaded: true,
        });
      }
    });
  };

  handleControlsUpload = async (e) => {
    e.preventDefault();
    console.log("Uploading Controls File");
    console.log("Columns:", this.state.cols);
    console.log("Rows", this.state.rows);
    let systemControls = {};
    let simplNodes = [];
    const rows = this.state.rows;
    for (let i = 1; i < rows.length; i++) {
      let newNode = {
        loadType: rows[i][1],
        nodeName: rows[i][2],
        nodeDescription: rows[i][3],

        nodeSensorSN: rows[i][4],
        nodeSensorMAC: rows[i][5],

        nodeDoorSensorSN: rows[i][6],
        nodeDoorSensorMAC: rows[i][7],

        nodeThermostatSN: rows[i][8],
        nodeThermostatMAC: rows[i][9],

        thermostatSensor1: rows[i][10],
        thermostatSensor2: rows[i][11],
        thermostatSensor3: rows[i][12],
        thermostatSensor4: rows[i][13],

        nodeTempSetHeatOc: rows[i][14],
        nodeTempSetCoolOc: rows[i][15],
        nodeTempSetHeatUn: rows[i][16],
        nodeTempSetCoolUn: rows[i][17],
        nodeDeltaTempSet: rows[i][18],
        nodeRHSet: rows[i][19],
        nodeFanSetting: rows[i][20],
        nodeHvacType: rows[i][21],

        nodeUpdateRate: rows[i][22],
        nodePriority: rows[i][23],
        nodePowerRating: rows[i][24],

        nodeMinOffTime: rows[i][25],
        nodeMaxOffTime: rows[i][26],
        nodeMinOnTime: rows[i][27],

        nodeRelaySN: rows[i][28],
        nodeRelayMAC: rows[i][29],
        stage1Cooling: rows[i][30],
        stage2Cooling: rows[i][31],
        stage1Heating: rows[i][32],
        stage2Heating: rows[i][33],

        nodeSchTemp: rows[i][34],

        fleetControl: rows[i][35],
        overRide: rows[i][36],
        fossilHeat: rows[i][37],
        OBActive: rows[i][38],
        auxHeat: rows[i][39],

        nodePositionX: rows[i][40],
        nodePositionY: rows[i][41],

        nodeMode: rows[i][42],
        scheduleMode: rows[i][43],

        nodeHealth: rows[i][44],

        nodeSensorSN2: rows[i][45],
        nodeSensorMAC2: rows[i][46],
        nodeSensorSN3: rows[i][47],
        nodeSensorMAC3: rows[i][48],
        nodeSensorSN4: rows[i][49],
        nodeSensorMAC4: rows[i][50],

        nodeSM2SN: rows[i][51],
        nodeSM2MAC: rows[i][52],
        meterNum: rows[i][53],
        ctType: rows[i][54],
        voltWiring: rows[i][55],
        gridFreq: rows[i][56],
        voltagePhase: rows[i][57],

        earlyStart: rows[i][58],
        earlyStop: rows[i][59],
        ductMountReturn: rows[i][60],
        nodeOVRT: rows[i][61],
      };
      simplNodes.push(newNode);
    }
    systemControls = JSON.stringify(simplNodes);

    await setSystemControlsObject(this.state.systemID, systemControls);
    localStorage.setItem("systemControlsLocal", systemControls);
    systemControls = (await getSystemControls(this.state.handleId)).data.data
      .controls;
    const selectedFunction = "noFunction";
    this.setState({ systemControls, selectedFunction });
  };

  handleScanSensor = (data) => {
    if (data) {
      this.setState({
        nodeSensorSN: data,
        currSerialNum: data,
      });
    }
  };
  handleScanSensor2 = (data) => {
    if (data) {
      this.setState({
        nodeSensorSN2: data,
      });
    }
  };
  handleScanSensor3 = (data) => {
    if (data) {
      this.setState({
        nodeSensorSN3: data,
      });
    }
  };
  handleScanSensor4 = (data) => {
    if (data) {
      this.setState({
        nodeSensorSN4: data,
      });
    }
  };
  handleScanThermostat = (data) => {
    if (data) {
      this.setState({
        nodeThermostatSN: data,
        currSerialNum: data,
      });
    }
  };
  handleScanWalkIn = (data) => {
    if (data) {
      this.setState({
        nodeWalkInSN: data,
      });
    }
  };
  handleScanWH = (data) => {
    if (data) {
      this.setState({
        nodeWHSN: data,
      });
    }
  };
  handleScanDoorSensor = (data) => {
    if (data) {
      this.setState({
        currSerialNum: data,
        nodeDoorSensorSN: data,
      });
    }
  };
  handleScanRelay = (data) => {
    if (data) {
      this.setState({
        nodeRelaySN: data,
        currSerialNum: data,
      });
    }
  };
  handleScanSM2 = (data) => {
    if (data) {
      this.setState({
        nodeSM2SN: data,
        currSerialNum: data,
      });
    }
  };

  handleMinToHealth = (e) => {
    const nodeMinToHealth = e.currentTarget.value;
    console.log("Min Time to Helath: ", nodeMinToHealth);
    this.setState({ nodeMinToHealth });
  };
  handleMinBadTemp = (e) => {
    const nodeBadTemp = e.currentTarget.value;
    console.log("Bad Temp: ", nodeBadTemp);
    this.setState({ nodeBadTemp });
  };
  handleMinPoorTemp = (e) => {
    const nodePoorTemp = e.currentTarget.value;
    console.log("Poor Temp: ", nodePoorTemp);
    this.setState({ nodePoorTemp });
  };
  handleMinGoodTemp = (e) => {
    const nodeGoodTemp = e.currentTarget.value;
    console.log("Poor Temp: ", nodeGoodTemp);
    this.setState({ nodeGoodTemp });
  };

  getSerialNumberImages = async () => {
    const res = (
      await getImagesSN(this.state.handleId.trim(), this.state.nodenameSerialSN)
    ).data;
    return res.images;
  };

  setImageUrl = async (slot) => {
    try {
      // console.log("SLOT NUMBER: ", slot);
      // console.log("handle id: ", this.state.handleId);
      // console.log("serial: ", this.state.currSerialNum);

      const res = await getImageUrl(
        this.state.handleId.trim(),
        this.state.nodenameSerialSN,
        slot
      );

      console.log("Res:");
      console.log(res);

      if (res.data.status === "Not found") {
        if (slot === 1) {
          this.setState({ slot1: null });
        } else if (slot === 2) {
          this.setState({ slot2: null });
        } else if (slot === 3) {
          this.setState({ slot3: null });
        } else if (slot === 4) {
          this.setState({ slot4: null });
        }

        return "Not Found";
      } else {
        const url = res.data.url;

        // console.log("Url:", url);
        // console.log("Res in set image url:");
        // console.log(res);

        if (slot === 1) {
          this.setState({ slot1: url });
        } else if (slot === 2) {
          this.setState({ slot2: url });
        } else if (slot === 3) {
          this.setState({ slot3: url });
        } else if (slot === 4) {
          this.setState({ slot4: url });
        }
      }
    } catch (e) {
      console.log("Error:");
      console.log(e);
    }
  };

  handleFileInfo = async (e, slot) => {
    try {
      let file = e.target.files[0];
      const extension = file.name.split(".").pop();
      const FormData = window.FormData || global.FormData;
      const formData = new FormData();

      if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
        toast.error("Please select valid image JPG,JPEG,PNG");
      } else {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        file = await imageCompression(file, options);

        //FILE INFO NAME WILL BE "my-image-file"
        formData.append("image", file, file.name);
        formData.append("handleId", this.state.handleId.trim());
        formData.append("extension", extension);
        formData.append("slot", slot);

        if (this.state.selectedFunction === "addFunction") {
          if (this.state.nodenameSerialSN === "") {
            const regex = /^[a-zA-Z]{5}-\d{6}$/;

            if (!regex.test(this.state.currSerialNum)) {
              toast.warning("First add a valid Serial Number please");
              return false;
            } else {
              formData.append("serialNumber", this.state.currSerialNum);
              this.setState({ nodenameSerialSN: this.state.currSerialNum });
            }
          } else {
            if (this.state.nodenameSerialSN !== this.state.currSerialNum) {
              toast.warning(
                `Please use the same Serial Number you used previously: ${this.state.nodenameSerialSN}`
              );
              return false;
            } else {
              formData.append("serialNumber", this.state.nodenameSerialSN);
            }
          }
        } else if (this.state.selectedFunction === "editFunction") {
          formData.append("serialNumber", this.state.nodenameSerialSN);
        }

        const res = await uploadImage(formData);

        // for (var pair of formData.entries()) {
        //   console.log(pair[0]);
        //   console.log(pair[1]);
        // }
        // console.log("Res");
        // console.log(res);
        // console.log('handleFileInput working!')
        toast.success("Image uploaded");
      }
    } catch (e) {
      console.log("ERROR");
      console.log(e);
    }
  };

  // Handle copy button of node's parameters
  handleCopy = () => {
    toast.success("Node's settings copied!");
    let copiedNode = {
      selectedIndex: this.state.selectedIndex,
      loadType: this.state.loadType,
      nodeName: this.state.nodeName,
      nodeDescription: this.state.nodeDescription,
      nodeSensorSN: this.state.nodeSensorSN,
      nodeSensorSN2: this.state.nodeSensorSN2,
      nodeSensorSN3: this.state.nodeSensorSN3,
      nodeSensorSN4: this.state.nodeSensorSN4,
      ////////////////////////////////////////////
      // nodeSensorMAC: nodeSensorMAC,
      // nodeSensorMAC2: nodeSensorMAC2,
      // nodeSensorMAC3: nodeSensorMAC3,
      // nodeSensorMAC4: nodeSensorMAC4,
      ////////////////////////////////////////////////////
      nodeDoorSensorSN: this.state.nodeDoorSensorSN,
      // nodeDoorSensorMAC: nodeDoorSensorMAC,
      ///////////////////////////////////////////////////
      nodeThermostatSN: this.state.nodeThermostatSN,
      ///////////////////////////////////////////////////
      // nodeThermostatMAC: nodeThermostatMAC,
      ///////////////////////////////////////////////////

      thermostatSensor1: this.state.thermostatSensor1,
      thermostatSensor2: this.state.thermostatSensor2,
      thermostatSensor3: this.state.thermostatSensor3,
      thermostatSensor4: this.state.thermostatSensor4,

      nodeTempSetHeatOc: this.state.nodeTempSetHeatOc,
      nodeTempSetCoolOc: this.state.nodeTempSetCoolOc,

      nodeTempSetHeatUn: this.state.nodeTempSetHeatUn,
      nodeTempSetCoolUn: this.state.nodeTempSetCoolUn,

      nodeDeltaTempSet: this.state.nodeDeltaTempSet,
      nodeRHSet: this.state.nodeRHSet,
      nodeFanSetting: this.state.nodeFanSetting,
      nodeMode: this.state.nodeMode,
      nodeHvacType: this.state.nodeHvacType,

      scheduleMode: this.state.scheduleMode,
      nodeScheduleMon: this.state.nodeScheduleMon,
      nodeScheduleTue: this.state.nodeScheduleTue,
      nodeScheduleWed: this.state.nodeScheduleWed,
      nodeScheduleThu: this.state.nodeScheduleThu,
      nodeScheduleFri: this.state.nodeScheduleFri,
      nodeScheduleSat: this.state.nodeScheduleSat,
      nodeScheduleSun: this.state.nodeScheduleSun,
      nodeScheduleHol: this.state.nodeScheduleHol,

      nodeDoorOrientation: this.state.nodeDoorOrientation,
      ///////////////////////////////////////////////////
      fleetControl: this.state.fleetControl,
      overRide: this.state.overRide,
      fossilHeat: this.state.fossilHeat,
      OBActive: this.state.OBActive,
      auxHeat: this.state.auxHeat,
      rthOnly: this.state.rthOnly,
      ///////////////////////////////////////////////////
      // nodeSchTemp: nodeSchTemp,
      ///////////////////////////////////////////////////
      nodeUpdateRate: this.state.nodeUpdateRate,
      ///////////////////////////////////////////////////
      nodePriority: this.state.nodePriority,
      nodeOVRT: this.state.nodeOVRT,

      nodePowerRating: this.state.nodePowerRating,
      nodeMinOffTime: this.state.nodeMinOffTime,
      nodeMaxOffTime: this.state.nodeMaxOffTime,
      nodeMinOnTime: this.state.nodeMinOnTime,

      nodeRelaySN: this.state.nodeRelaySN,
      nodeSM2SN: this.state.nodeSM2SN,
      ///////////////////////////////////////////////////
      // nodeRelayMAC: nodeRelayMAC,
      // nodeSM2MAC: nodeSM2MAC,
      ctType: this.state.ctType,
      meterNum: this.state.meterNum,
      voltWiring: this.state.voltWiring,
      gridFreq: this.state.gridFreq,
      voltagePhase: this.state.voltagePhase,

      ///////////////////////////////////////////////////

      stage1Cooling: this.state.stage1Cooling,
      stage2Cooling: this.state.stage2Cooling,
      stage1Heating: this.state.stage1Heating,
      stage2Heating: this.state.stage2Heating,

      nodePositionX: this.state.nodePositionX,
      nodePositionY: this.state.nodePositionY,

      nodeHealth: this.state.nodeHealth,
      earlyStart: this.state.earlyStart,
      earlyStop: this.state.earlyStop,
      ductMountReturn: this.state.ductMountReturn,

      nodeOnTempMon: this.state.nodeOnTempMon,
      nodeOnTempTue: this.state.nodeOnTempTue,
      nodeOnTempWed: this.state.nodeOnTempWed,
      nodeOnTempThu: this.state.nodeOnTempThu,
      nodeOnTempFri: this.state.nodeOnTempFri,
      nodeOnTempSat: this.state.nodeOnTempSat,
      nodeOnTempSun: this.state.nodeOnTempSun,
      nodeOnTempHol: this.state.nodeOnTempHol,

      nodeOffTempMon: this.state.nodeOffTempMon,
      nodeOffTempTue: this.state.nodeOffTempTue,
      nodeOffTempWed: this.state.nodeOffTempWed,
      nodeOffTempThu: this.state.nodeOffTempThu,
      nodeOffTempFri: this.state.nodeOffTempFri,
      nodeOffTempSat: this.state.nodeOffTempSat,
      nodeOffTempSun: this.state.nodeOffTempSun,
      nodeOffTempHol: this.state.nodeOffTempHol,
    };
    this.setState({ copiedNodeLoadType: copiedNode.loadType });
    copiedNode = JSON.stringify(copiedNode);
    sessionStorage.setItem("copiedNode", copiedNode);
  };

  // Handle paste button of node's parameters
  handlePaste = () => {
    toast.success("settings pasted");
    const copiedNodeStr = sessionStorage.getItem("copiedNode");
    const copiedNode = JSON.parse(copiedNodeStr);

    const loadType = copiedNode.loadType;
    const nodeName = copiedNode.nodeName;
    const nodeDescription = copiedNode.nodeDescription;
    const nodeSensorSN = copiedNode.nodeSensorSN;
    const nodeSensorSN2 = copiedNode.nodeSensorSN2;
    const nodeSensorSN3 = copiedNode.nodeSensorSN3;
    const nodeSensorSN4 = copiedNode.nodeSensorSN4;

    // nodeDoorSensorSN = copiedNode.nodeDoorSensorSN;
    const nodeThermostatSN = copiedNode.nodeThermostatSN;

    const thermostatSensor1 = copiedNode.thermostatSensor1;
    const thermostatSensor2 = copiedNode.thermostatSensor2;
    const thermostatSensor3 = copiedNode.thermostatSensor3;
    const thermostatSensor4 = copiedNode.thermostatSensor4;

    const nodeTempSetHeatOc = copiedNode.nodeTempSetHeatOc;
    const nodeTempSetCoolOc = copiedNode.nodeTempSetCoolOc;

    const nodeTempSetHeatUn = copiedNode.nodeTempSetHeatUn;
    const nodeTempSetCoolUn = copiedNode.nodeTempSetCoolUn;

    const nodeDeltaTempSet = copiedNode.nodeDeltaTempSet;
    const nodeRHSet = copiedNode.nodeRHSet;
    const nodeFanSetting = copiedNode.nodeFanSetting;
    const nodeMode = copiedNode.nodeMode;
    const nodeHvacType = copiedNode.nodeHvacType;

    const scheduleMode = copiedNode.scheduleMode;
    const overRide = copiedNode.overRide;
    const fossilHeat = copiedNode.fossilHeat;
    const OBActive = copiedNode.OBActive;
    const auxHeat = copiedNode.auxHeat;
    const rthOnly = copiedNode.rthOnly;

    const nodeUpdateRate = copiedNode.nodeUpdateRate;

    const nodePriority = copiedNode.nodePriority;
    const nodeOVRT = copiedNode.nodeOVRT;

    const nodePowerRating = copiedNode.nodePowerRating;
    const nodeMinOffTime = copiedNode.nodeMinOffTime;
    const nodeMaxOffTime = copiedNode.nodeMaxOffTime;
    const nodeMinOnTime = copiedNode.nodeMinOnTime;

    const nodeRelaySN = copiedNode.nodeRelaySN;
    const nodeSM2SN = copiedNode.nodeSM2SN;

    const ctType = copiedNode.ctType;
    const meterNum = copiedNode.meterNum;
    const voltWiring = copiedNode.voltWiring;
    const gridFreq = copiedNode.gridFreq;
    const voltagePhase = copiedNode.voltagePhase;

    const stage1Cooling = copiedNode.stage1Cooling;
    const stage2Cooling = copiedNode.stage2Cooling;
    const stage1Heating = copiedNode.stage1Heating;
    const stage2Heating = copiedNode.stage2Heating;

    const nodeScheduleMon = copiedNode.nodeScheduleMon;
    const nodeScheduleTue = copiedNode.nodeScheduleTue;
    const nodeScheduleWed = copiedNode.nodeScheduleWed;
    const nodeScheduleThu = copiedNode.nodeScheduleThu;
    const nodeScheduleFri = copiedNode.nodeScheduleFri;
    const nodeScheduleSat = copiedNode.nodeScheduleSat;
    const nodeScheduleSun = copiedNode.nodeScheduleSun;
    const nodeScheduleHol = copiedNode.nodeScheduleHol;

    const nodeOnTempMon = copiedNode.nodeOnTempMon;
    const nodeOnTempTue = copiedNode.nodeOnTempTue;
    const nodeOnTempWed = copiedNode.nodeOnTempWed;
    const nodeOnTempThu = copiedNode.nodeOnTempThu;
    const nodeOnTempFri = copiedNode.nodeOnTempFri;
    const nodeOnTempSat = copiedNode.nodeOnTempSat;
    const nodeOnTempSun = copiedNode.nodeOnTempSun;
    const nodeOnTempHol = copiedNode.nodeOnTempHol;

    const nodeOffTempMon = copiedNode.nodeOffTempMon;
    const nodeOffTempTue = copiedNode.nodeOffTempTue;
    const nodeOffTempWed = copiedNode.nodeOffTempWed;
    const nodeOffTempThu = copiedNode.nodeOffTempThu;
    const nodeOffTempFri = copiedNode.nodeOffTempFri;
    const nodeOffTempSat = copiedNode.nodeOffTempSat;
    const nodeOffTempSun = copiedNode.nodeOffTempSun;
    const nodeOffTempHol = copiedNode.nodeOffTempHol;
    /******************************************************************************* */

    this.setState({
      // selectedFunction,
      // selectedIndex,
      loadType,
      nodeName,
      nodeDescription,
      nodeSensorSN,
      nodeSensorSN2,
      nodeSensorSN3,
      nodeSensorSN4,

      // nodeDoorSensorSN,
      nodeThermostatSN,

      thermostatSensor1,
      thermostatSensor2,
      thermostatSensor3,
      thermostatSensor4,

      nodeTempSetHeatOc,
      nodeTempSetCoolOc,

      nodeTempSetHeatUn,
      nodeTempSetCoolUn,

      nodeDeltaTempSet,
      nodeRHSet,
      nodeFanSetting,
      nodeMode,
      nodeHvacType,

      scheduleMode,

      // fleetControl,
      overRide,
      fossilHeat,
      OBActive,
      rthOnly,
      auxHeat,

      // earlyStart,
      // earlyStop,
      // ductMountReturn,

      // nodeDoorOrientation,

      nodeScheduleMon,
      nodeScheduleTue,
      nodeScheduleWed,
      nodeScheduleThu,
      nodeScheduleFri,
      nodeScheduleSat,
      nodeScheduleSun,
      nodeScheduleHol,
      nodeUpdateRate,
      nodePriority,
      nodeOVRT,
      nodePowerRating,
      nodeMinOffTime,
      nodeMaxOffTime,
      nodeMinOnTime,

      nodeRelaySN,
      nodeSM2SN,
      meterNum,
      ctType,
      voltWiring,
      gridFreq,
      voltagePhase,

      stage1Cooling,
      stage2Cooling,
      stage1Heating,
      stage2Heating,

      nodeOnTempMon,
      nodeOnTempTue,
      nodeOnTempWed,
      nodeOnTempThu,
      nodeOnTempFri,
      nodeOnTempSat,
      nodeOnTempSun,
      nodeOnTempHol,

      nodeOffTempMon,
      nodeOffTempTue,
      nodeOffTempWed,
      nodeOffTempThu,
      nodeOffTempFri,
      nodeOffTempSat,
      nodeOffTempSun,
      nodeOffTempHol,

      // nodeMinToHealth,
      // nodeBadTemp,
      // nodePoorTemp,
      // nodeGoodTemp,

      prevNodeTempSetHeatOc: nodeTempSetHeatOc,
      prevNodeTempSetCoolOc: nodeTempSetCoolOc,

      prevNodeTempSetHeatUn: nodeTempSetHeatUn,
      prevNodeTempSetCoolUn: nodeTempSetCoolUn,
    });
  };

  render() {
    console.log("EarlyStart: ", this.state.earlyStart);
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return (
      <React.Fragment>
        <div
          style={{
            width: "360px",
            height: "auto",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "120px",
            marginBottom: "250px",
            paddingBottom: "200px",
            zIndex: 1,
          }}
        >
          {/* <p
            className="basicParagraph"
            style={{
              width: "350px",
              fontSize: "12px",
              color: "#777575",
              textAlign: "center",
            }}
          >
            {this.state.systemName}
          </p> */}

          <div className="d-flex justify-content-center mb-2">
            <p
              className="basicParagraph text-center"
              style={{
                width: "350px",
                fontSize: "12px",
                color: "#2D5C88",
                marginTop: "10px",
              }}
            >
              {" "}
              {this.state.systemName}
            </p>
          </div>

          {(this.state.selectedFunction === "addFunction" ||
            this.state.selectedFunction === "editFunction") && (
            <form
              onSubmit={this.handleButtonPressed}
              style={{ marginBottom: "15px" }}
            >
              {this.state.selectedFunction === "addFunction" && (
                <div>
                  <button
                    className="btn btn-outline-light"
                    style={{
                      float: "right",
                      borderWidth: "0px",
                    }}
                    onClick={this.handleExcelUpload}
                  >
                    <FontAwesomeIcon
                      icon="file-excel"
                      size="lg"
                      color="#777575"
                      title="Import System Data from Excel"
                    />
                  </button>

                  {this.state.setControlsUpload === 0 && (
                    <div>
                      <label className="basicParagraph" htmlFor="">
                        Add a Controlled Load:
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleLoads}
                        value={this.state.loadType}
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          border: "none",
                          borderRadius: "0px",
                          borderBottom: "2px solid lightgrey",
                        }}
                      >
                        <option value="Choose here">Choose here</option>
                        {/*<option value="HVAC Unit">HVAC Unit</option>
                        <option value="SimplMeter">SimplMeter</option>*/}
                        <hr></hr>
                        <hr></hr>
                        <option
                          style={{
                            backgroundColor: "#bebebe",
                            color: "green",
                            fontWeight: "bold",
                          }}
                          value=""
                        >
                          Simpl Devices:
                        </option>
                        <hr></hr>
                        <hr></hr>
                        <option value="HVAC w SimplTherm">
                          SimplThermostat
                        </option>
                        <option value="SimplHeater">SimplHeater</option>
                        <option value="SimplWalkIn">SimplWalkIn</option>

                        <hr></hr>
                        <hr></hr>
                        <option
                          style={{
                            backgroundColor: "#bebebe",
                            color: "green",
                            fontWeight: "bold",
                          }}
                          value=""
                        >
                          Lighting:
                        </option>
                        <hr></hr>
                        <hr></hr>
                        <option value="Pole Lights">Pole Lights</option>
                        <option value="Quad 30A w Light Sensor">
                          Quad 30A w Light Sensor
                        </option>
                        <option value="Relay Driver w Light Sensor">
                          Relay Driver w Light Sensor
                        </option>
                        <hr></hr>
                        <hr></hr>
                        <option
                          style={{
                            backgroundColor: "#bebebe",
                            color: "green",
                            fontWeight: "bold",
                          }}
                          value=""
                        >
                          Appliances:
                        </option>
                        <hr></hr>
                        <hr></hr>
                        <option value="Relay Driver">Relay Driver</option>
                        {/*<option value="Quad 30A Relays">Quad 30A Relays</option>*/}
                        {/*<option value="Outdoor Sensed Lights">
                          Outdoor Sensed Lights
                        </option>
                        <option value="Indoor Lights">Indoor Lights</option>
                        <option value="Heat Lamp">Heat Lamp</option>
                        <option value="Space Heater">Space Heater</option>*/}

                        {/*<option value="Water Heater No Sensor">
                          Water Heater No Sensor
                        </option>*/}

                        {/* <option value="Reach-in">Reach-in</option>
                        <option value="Counter Reach-in">
                          Counter Reach-in
                        </option>
                        <option value="Anti-sweat Heaters">
                          Anti-sweat Heaters
                        </option>
                        <option value="EV Charger">EV Charger</option>
                        <option value="Oven">Oven</option>
                        <option value="Hood">Hood</option>*/}
                        <option value="Water Heater">Water Heater</option>
                        <option value="Misc. Appliance">
                          Unsheddable Appliance
                        </option>
                        <option value="Misc. Sheddable">
                          Sheddable Appliance
                        </option>
                        <hr></hr>
                        <hr></hr>
                        <option
                          style={{
                            backgroundColor: "#bebebe",
                            color: "green",
                            fontWeight: "bold",
                          }}
                          value=""
                        >
                          Sensors:
                        </option>
                        <hr></hr>
                        <hr></hr>
                        <option value="Ambient Temp Sensor">
                          Ambient Temp Sensor
                        </option>
                        <option value="Avg. Ambient Temp">
                          Avg. Ambient Temp
                        </option>
                        <option value="External Temp Sensor">
                          External Temp Sensor
                        </option>
                        <option value="Light Sensor">Light Sensor</option>
                        <option value="Door Monitor">Door Monitor</option>
                        {/*<option value="Perimeter Outline">
                          Perimeter Outline
                        </option>
                        <option value="Compass Indicator">
                          Compass Indicator
                        </option>
                        <option value="System Gateway">System Gateway</option>*/}
                      </select>
                    </div>
                  )}

                  {this.state.setControlsUpload !== 0 && (
                    <div>
                      <p
                        className="basicParagraph"
                        style={{ color: "#777575" }}
                      >
                        {" "}
                        Step 1: Select an excel spreadsheet file:
                      </p>
                      <input
                        type="file"
                        onChange={this.fileHandler.bind(this)}
                        className="inputTariff"
                        style={{
                          padding: "10px",
                          backgroundColor: "#777575",
                          color: "#ffc000",
                        }}
                      />
                      <hr></hr>
                      {this.state.dataLoaded && (
                        <div>
                          {" "}
                          <p
                            className="basicParagraph"
                            style={{ marginTop: "15px", color: "#777575" }}
                          >
                            {" "}
                            Step 2: Review the Controls Input File:
                          </p>
                          <div
                            style={{
                              overflow: "auto",
                            }}
                          >
                            <OutTable
                              data={this.state.rows}
                              columns={this.state.cols}
                              tableClassName="ExcelTable2007"
                              tableHeaderRowClass="heading"
                            />
                          </div>
                          <hr></hr>
                          <p
                            className="basicParagraph"
                            style={{ marginTop: "15px", color: "#777575" }}
                          >
                            {" "}
                            Step 3: Approve the File:
                          </p>
                          <button
                            className="basicButton"
                            onClick={this.handleControlsUpload}
                          >
                            Approve File
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              {this.state.loadType !== "Choose here" && (
                <div>
                  {/*} {this.state.loadType !== "Perimeter Outline" &&
                    this.state.loadType !== "Compass Indicator" &&
                    this.state.loadType !== "SimplMeter" &&
                    this.state.loadType !== "System Gateway" && (
                      <div>
                        {this.state.fleetControl === 1 && (
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="registerReason"
                            id="flexCheckChecked"
                            checked
                            onChange={this.handleFleetControl}
                            value="reason1"
                          ></input>
                        )}
                        {this.state.fleetControl === 0 && (
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="registerReason"
                            id="flexCheckDefault"
                            onChange={this.handleFleetControl}
                            value="reason1"
                          ></input>
                        )}
                        <label
                          className="basicParagraph"
                          style={{ height: "30px", width: "370px" }}
                        >
                          Check box to control this unit at fleet level
                        </label>
                        {this.state.overRide === 1 && (
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="registerReason"
                            id="flexCheckChecked"
                            checked
                            onChange={this.handleOverRide}
                            value="reason1"
                          ></input>
                        )}
                        {this.state.overRide === 0 && (
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="registerReason"
                            id="flexCheckDefault"
                            onChange={this.handleOverRide}
                            value="reason1"
                          ></input>
                        )}
                        <label
                          className="basicParagraph"
                          style={{ height: "30px", width: "300px" }}
                        >
                          Check box to override this node's control system
                        </label>
                      </div>
                    )}*/}

                  {this.state.loadType !== "Perimeter Outline" &&
                    this.state.loadType !== "Compass Indicator" &&
                    this.state.loadType !== "System Gateway" && (
                      <div>
                        <label
                          className="basicParagraph"
                          style={{ height: "30px", width: "150px" }}
                        >
                          Enter a name for this unit:
                        </label>
                        <input
                          value={this.state.nodeName}
                          onChange={this.handleName}
                          id="serialNumber"
                          type="text"
                          className="form-control2"
                          style={{
                            float: "right",
                            height: "30px",
                            width: "200px",
                          }}
                        />
                        {/* {this.state.selectedFunction === "editFunction" && (
                          <FontAwesomeIcon 
                            icon="copy"
                            size="2x"
                            color="Darkblue"
                            title="Copy node's settings"
                            onClick={this.handleCopy}
                          />
                        )}

                        {this.state.selectedFunction === "addFunction" && this.state.loadType === this.state.copiedNodeLoadType && (
                          <FontAwesomeIcon 
                            icon={faPaste}
                            size="2x"
                            color="Darkblue"
                            title="Paste node's settings"
                            onClick={this.handlePaste}
                          />
                        )} */}
                      </div>
                    )}

                  {this.state.loadType === "Perimeter Outline" && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Select a Perimeter Type:
                      </label>
                      <select
                        className="form-control2"
                        value={this.state.nodeName}
                        onChange={this.handleName}
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          border: "none",
                          borderRadius: "0px",
                          borderBottom: "2px solid lightgrey",
                        }}
                      >
                        <option value="Choose here">Choose here</option>
                        <option value="Rectangle">Rectangle</option>
                        <option value="Square">Square</option>
                        <option value="Convex">Convex</option>
                        <option value="H-shaped">H-shaped</option>
                        <option value="L-shaped">L-shaped</option>
                        <option value="U-shaped">U-shaped</option>
                        <option value="W-shaped">W-shaped</option>
                        <option value="X-shaped">X-shaped</option>
                        <option value="Y-shaped">Y-shaped</option>
                        <option value="Z-shaped">Z-shaped</option>
                      </select>
                    </div>
                  )}

                  {this.state.loadType === "Compass Indicator" && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Input the Orientation in degrees:
                      </label>
                      <input
                        value={this.state.nodeName}
                        onChange={this.handleName}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                        }}
                      />
                    </div>
                  )}
                  {this.state.loadType === "System Gateway" && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Select System gateway Type:
                      </label>
                      <input
                        value={this.state.nodeName}
                        onChange={this.handleName}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                        }}
                      />
                    </div>
                  )}

                  {this.state.loadType !== "Perimeter Outline" &&
                    this.state.loadType !== "Compass Indicator" &&
                    this.state.loadType !== "System Gateway" && (
                      <div>
                        <div>
                          <label
                            className="basicParagraph"
                            style={{ height: "65px", width: "150px" }}
                            htmlFor=""
                          >
                            Enter a brief description:
                          </label>
                          <input
                            value={this.state.nodeDescription}
                            onChange={this.handleDescription}
                            id="serialNumber"
                            type="text"
                            className="form-control2"
                            style={{
                              float: "right",
                              height: "65px",
                              width: "200px",
                            }}
                          />
                        </div>
                        <hr></hr>
                        <div>
                          <div className="col-12 d-flex justify-content-between">
                            <button
                              style={{
                                padding: "0px",
                                borderWidth: "0px",
                                color: "transparent",
                                backgroundColor: "transparent",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                sessionStorage.setItem(
                                  "nodePassed",
                                  this.state.nodeName
                                );

                                this.props.history.push(
                                  `/systems/simplEquipment/${this.state.handleId}`
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faWrench}
                                size="2x"
                                color="orange"
                                title="Equipments"
                              />
                            </button>

                            {this.state.selectedFunction === "editFunction" && (
                              <FontAwesomeIcon
                                icon="copy"
                                size="2x"
                                color="green"
                                title="Copy node's settings"
                                onClick={this.handleCopy}
                              />
                            )}

                            {this.state.selectedFunction === "addFunction" &&
                              this.state.loadType ===
                                this.state.copiedNodeLoadType && (
                                <FontAwesomeIcon
                                  icon={faPaste}
                                  size="2x"
                                  color="green"
                                  title="Paste node's settings"
                                  onClick={this.handlePaste}
                                />
                              )}

                            <button
                              style={{
                                padding: "0px",
                                borderWidth: "0px",
                                color: "transparent",
                                backgroundColor: "transparent",
                              }}
                              onClick={(e) => {
                                e.preventDefault();

                                if (this.setImages) {
                                  this.setState({ setImages: false });
                                } else {
                                  this.setState({ setImages: true });
                                }
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faImage}
                                size="2x"
                                color="Darkblue"
                                title="Add Images"
                              />
                            </button>
                          </div>
                          {this.state.setImages && (
                            <>
                              <div className="col-12 d-flex flex-wrap flex-direction-row justify-content-around mt-5">
                                {this.state.slot1 ? (
                                  <>
                                    <ImagesModal
                                      setImageUrl={this.setImageUrl}
                                      handleId={this.state.handleId}
                                      serialNumber={this.state.nodenameSerialSN}
                                      url={this.state.slot1}
                                      slot={1}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <label
                                      htmlFor="file-upload-1"
                                      style={{ height: "150px" }}
                                      className="col-5 border d-flex justify-content-center align-items-center label-cursor"
                                    >
                                      <FontAwesomeIcon
                                        icon="plus-circle"
                                        size="lg"
                                        color="dodgerblue"
                                        title="Add a new image slot"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      id="file-upload-1"
                                      onChange={async (e) => {
                                        await this.handleFileInfo(e, 1);
                                        await this.setImageUrl(1);
                                      }}
                                    />
                                  </>
                                )}

                                {this.state.slot2 ? (
                                  <>
                                    <ImagesModal
                                      setImageUrl={this.setImageUrl}
                                      handleId={this.state.handleId}
                                      serialNumber={this.state.nodenameSerialSN}
                                      url={this.state.slot2}
                                      slot={2}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <label
                                      htmlFor="file-upload-2"
                                      style={{ height: "150px" }}
                                      className="col-5 border d-flex justify-content-center align-items-center label-cursor"
                                    >
                                      <FontAwesomeIcon
                                        icon="plus-circle"
                                        size="lg"
                                        color="dodgerblue"
                                        title="Add a new image slot"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      id="file-upload-2"
                                      onChange={async (e) => {
                                        await this.handleFileInfo(e, 2);
                                        await this.setImageUrl(2);
                                      }}
                                    />
                                  </>
                                )}

                                {this.state.slot3 ? (
                                  <>
                                    <ImagesModal
                                      setImageUrl={this.setImageUrl}
                                      handleId={this.state.handleId}
                                      serialNumber={this.state.nodenameSerialSN}
                                      url={this.state.slot3}
                                      slot={3}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <label
                                      htmlFor="file-upload-3"
                                      style={{
                                        height: "150px",
                                        marginTop: "16px",
                                      }}
                                      className="col-5 border d-flex justify-content-center align-items-center label-cursor"
                                    >
                                      <FontAwesomeIcon
                                        icon="plus-circle"
                                        size="lg"
                                        color="dodgerblue"
                                        title="Add a new image slot"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      id="file-upload-3"
                                      onChange={async (e) => {
                                        await this.handleFileInfo(e, 3);
                                        await this.setImageUrl(3);
                                      }}
                                    />
                                  </>
                                )}

                                {this.state.slot4 ? (
                                  <>
                                    <ImagesModal
                                      setImageUrl={this.setImageUrl}
                                      handleId={this.state.handleId}
                                      serialNumber={this.state.nodenameSerialSN}
                                      url={this.state.slot4}
                                      slot={4}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <label
                                      htmlFor="file-upload-4"
                                      style={{
                                        height: "150px",
                                        marginTop: "16px",
                                      }}
                                      className="col-5 border d-flex justify-content-center align-items-center label-cursor mt-3"
                                    >
                                      <FontAwesomeIcon
                                        icon="plus-circle"
                                        size="lg"
                                        color="dodgerblue"
                                        title="Add a new image slot"
                                      />
                                    </label>
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      id="file-upload-4"
                                      onChange={async (e) => {
                                        await this.handleFileInfo(e, 4);
                                        await this.setImageUrl(4);
                                      }}
                                    />
                                  </>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        <hr></hr>
                      </div>
                    )}

                  {/*////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                  {this.state.loadType === "SimplMeter" && (
                    <div style={{ height: "175px" }}>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px" }}
                      >
                        Input the serial number for a SimplMeter:
                      </label>
                      {!this.state.nodeSM2SN && (
                        <div
                          style={{
                            float: "right",
                            marginBottom: "10px",
                            marginLeft: "150px",
                          }}
                        >
                          <QrContainer onScan={this.handleScanSM2.bind(this)} />
                        </div>
                      )}

                      <input
                        value={this.state.nodeSM2SN}
                        onChange={this.handleNodeSM2}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "200px",
                        }}
                      />
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Select Meter 1 or Meter 2:
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleMeterNum}
                        value={this.state.meterNum}
                        style={{
                          float: "right",
                          width: "180px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          height: "30px",
                        }}
                      >
                        <option value="Choose">Choose</option>
                        <option value="Meter 1">Meter 1</option>
                        <option value="Meter 2">Meter 2</option>
                      </select>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Select CT Type:
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleCtType}
                        value={this.state.ctType}
                        style={{
                          float: "right",
                          width: "180px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          height: "30px",
                        }}
                      >
                        <option value="Choose">Choose</option>
                        <option value="MRC">1-in Diameter, MRC-24</option>
                        <option value="SRC">4-in. Diameter, SRC-100</option>
                        <option value="NRC">8-in. Diameter, NRC-200</option>
                      </select>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Select Voltage Wiring:
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleVoltWiring}
                        value={this.state.voltWiring}
                        style={{
                          float: "right",
                          width: "180px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          height: "30px",
                        }}
                      >
                        <option value="Choose">Choose</option>
                        <option value="0">3LN</option>
                        <option value="1">1LN</option>
                        <option value="2">2LL</option>
                        <option value="3">3LL</option>
                        <option value="4">1LL</option>
                      </select>
                      {this.state.voltWiring !== "0" && (
                        <div>
                          <label
                            className="basicParagraph"
                            style={{ height: "30px", width: "150px" }}
                          >
                            Select Phase:
                          </label>
                          <select
                            className="form-control2"
                            onChange={this.handleVoltagePhase}
                            value={this.state.voltagePhase}
                            style={{
                              float: "right",
                              width: "180px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              height: "30px",
                            }}
                          >
                            <option value="Choose">Choose</option>
                            <option value="0">Phase A</option>
                            <option value="1">Phase B</option>
                            <option value="2">Phase C</option>
                          </select>
                        </div>
                      )}

                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Select Grid Frequency:
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleGridFreq}
                        value={this.state.gridFreq}
                        style={{
                          float: "right",
                          width: "180px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          height: "30px",
                        }}
                      >
                        <option value="Choose">Choose</option>
                        <option value="60 Hz">60 Hz</option>
                        <option value="50 Hz">50 Hz</option>
                      </select>
                    </div>
                  )}
                  {/*////////////////////////////////////////////////////////////////////////////////////////////////////*/}

                  {(this.state.loadType === "Relay Driver" ||
                    this.state.loadType === "Relay Driver w Light Sensor" ||
                    this.state.loadType === "Quad 30A Relays" ||
                    this.state.loadType === "Quad 30A w Light Sensor") && (
                    <div style={{ height: "100px" }}>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px" }}
                      >
                        Input the serial number for a Quad Relay Module:
                      </label>
                      {!this.state.nodeRelaySN && (
                        <div
                          style={{
                            float: "right",
                            marginBottom: "10px",
                            marginLeft: "150px",
                          }}
                        >
                          <QrContainer
                            onScan={this.handleScanRelay.bind(this)}
                          />
                        </div>
                      )}

                      <input
                        value={this.state.nodeRelaySN}
                        onChange={this.handleNodeRelay}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "200px",
                        }}
                      />
                    </div>
                  )}
                  {/*////////////////////////////////////////////////////////////////////////////////////////////////////*/}

                  {this.state.loadType === "SimplWalkIn" && (
                    <div style={{ height: "100px" }}>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px" }}
                      >
                        Input the serial number for a SimplWalkIn:
                      </label>
                      {!this.state.nodeRelaySN && (
                        <div
                          style={{
                            float: "right",
                            marginBottom: "10px",
                            marginLeft: "150px",
                          }}
                        >
                          <QrContainer
                            onScan={this.handleScanRelay.bind(this)}
                          />
                        </div>
                      )}

                      <input
                        value={this.state.nodeRelaySN}
                        onChange={this.handleNodeRelay}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "200px",
                        }}
                      />
                      {/* <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Temperature Setpoint (&#176;F):
                      </label>
                      <input
                        value={this.state.nodeTempSet}
                        onChange={this.handleTempSet}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "10px",
                          paddingLeft: "10px",
                        }}
                      />*/}
                    </div>
                  )}

                  {this.state.loadType === "SimplHeater" && (
                    <div style={{ height: "100px" }}>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px" }}
                      >
                        Input the serial number for a SimplHeater:
                      </label>
                      {!this.state.nodeRelaySN && (
                        <div
                          style={{
                            float: "right",
                            marginBottom: "10px",
                            marginLeft: "150px",
                          }}
                        >
                          <QrContainer
                            onScan={this.handleScanRelay.bind(this)}
                          />
                        </div>
                      )}

                      <input
                        value={this.state.nodeRelaySN}
                        onChange={this.handleNodeRelay}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "200px",
                        }}
                      />
                      {/*<label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Temperature Setpoint (&#176;F):
                      </label>
                      <input
                        value={this.state.nodeTempSet}
                        onChange={this.handleTempSet}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "10px",
                          paddingLeft: "10px",
                        }}
                      />*/}
                    </div>
                  )}

                  {/*////////////////////////////////////////////////////////////////////////////////////////////////////*/}
                  {this.state.loadType === "HVAC w SimplTherm" && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "130px" }}
                      >
                        SimplThermostat serial number:
                      </label>
                      {!this.state.nodeThermostatSN && (
                        <div
                          style={{
                            float: "right",
                            marginBottom: "10px",
                            marginLeft: "150px",
                          }}
                        >
                          <QrContainer
                            onScan={this.handleScanThermostat.bind(this)}
                          />
                        </div>
                      )}

                      <input
                        value={this.state.nodeThermostatSN}
                        onChange={this.handleThermostatSN}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "10px",
                          paddingLeft: "10px",
                        }}
                      />
                      <hr></hr>

                      <p className="basicParagraph">Temperature Settings:</p>
                      <hr></hr>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                        for="serialHeatNumber"
                      >
                        Heating Temperature Setpoint (&#176;F):
                      </label>
                      <input
                        value={this.state.nodeTempSetHeatOc}
                        onChange={this.handleTempSetHeatOc}
                        // id="serialNumber"
                        id="serialHeatNumber"
                        type="number"
                        min="45"
                        max="95"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "10px",
                          paddingLeft: "10px",
                        }}
                      />
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Unoccupied Heating Temperature Setpoint (&#176;F):
                      </label>
                      <input
                        value={this.state.nodeTempSetHeatUn}
                        onChange={this.handleTempSetHeatUn}
                        id="serialNumber"
                        type="number"
                        min="45"
                        max="95"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "10px",
                          paddingLeft: "10px",
                        }}
                      />
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Cooling Temperature Setpoint (&#176;F):
                      </label>
                      <input
                        value={this.state.nodeTempSetCoolOc}
                        onChange={this.handleTempSetCoolOc}
                        id="serialNumber"
                        type="number"
                        min="45"
                        max="95"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "10px",
                          paddingLeft: "10px",
                        }}
                      />
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        UnOccupied Cooling Temperature Setpoint (&#176;F):
                      </label>

                      <input
                        value={this.state.nodeTempSetCoolUn}
                        onChange={this.handleTempSetCoolUn}
                        id="serialNumber"
                        type="number"
                        min="45"
                        max="95"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "10px",
                          paddingLeft: "10px",
                        }}
                      />
                      <hr></hr>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Relative Humidity Setpoint (%):
                      </label>
                      <input
                        value={this.state.nodeRHSet}
                        onChange={this.handleRHSet}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "10px",
                          paddingLeft: "10px",
                        }}
                      />
                      <hr></hr>
                    </div>
                  )}
                  {/********************************************************************************* */}

                  {this.state.loadType === "HVAC w SimplTherm" && (
                    <div>
                      <p className="basicParagraph">System Configuration:</p>
                      <hr></hr>
                      <label
                        className="basicParagraph"
                        style={{
                          height: "40px",
                          width: "150px",
                          marginTop: "30px",
                        }}
                      >
                        HVAC Type:
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleHvacType}
                        value={this.state.nodeHvacType}
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          border: "none",
                          borderRadius: "0px",
                          borderBottom: "2px solid lightgrey",
                        }}
                      >
                        <option value="Choose here">Choose here</option>
                        <option value="Multi-stage RTU">Multi-stage RTU</option>
                        <option value="Heat Pump">Heat Pump</option>
                      </select>
                    </div>
                  )}
                  {this.state.loadType === "HVAC w SimplTherm" &&
                    this.state.nodeHvacType === "Heat Pump" && (
                      <div>
                        {this.state.OBActive === 1 && (
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="registerReason"
                            id="flexCheckChecked"
                            checked
                            onChange={this.handleOBactive}
                            value="reason1"
                          ></input>
                        )}
                        {this.state.OBActive !== 1 && (
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="registerReason"
                            id="flexCheckDefault"
                            onChange={this.handleOBactive}
                            value="reason1"
                          ></input>
                        )}
                        <label
                          className="basicParagraph"
                          style={{ height: "30px", width: "370px" }}
                        >
                          Heat Pump O/B: Check for Active when Heating
                        </label>
                      </div>
                    )}

                  {this.state.loadType === "HVAC w SimplTherm" &&
                    this.state.nodeHvacType === "Heat Pump" && (
                      <div>
                        {" "}
                        {this.state.auxHeat === 1 && (
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="registerReason"
                            id="flexCheckChecked"
                            checked
                            onChange={this.handleAuxHeat}
                            value="reason1"
                          ></input>
                        )}
                        {this.state.auxHeat !== 1 && (
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="registerReason"
                            id="flexCheckDefault"
                            onChange={this.handleAuxHeat}
                            value="reason1"
                          ></input>
                        )}
                        <label
                          className="basicParagraph"
                          style={{ height: "30px", width: "370px" }}
                        >
                          Check if Heat Pump is equipped with Auxiliary Heat
                        </label>
                      </div>
                    )}
                  {/**************************************************************************** */}

                  {this.state.loadType === "Misc. Appliance" && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Mode:
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleMode}
                        value={this.state.nodeMode}
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          border: "none",
                          borderRadius: "0px",
                          borderBottom: "2px solid lightgrey",
                        }}
                      >
                        <option value="Choose here">Choose here</option>
                        <option value="Always On">Always On</option>
                        <option value="Always Off">Always Off</option>
                        <option value="Schedule">Schedule</option>
                      </select>
                    </div>
                  )}
                  {/**************************************************************************** */}
                  {this.state.loadType === "HVAC w SimplTherm" && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Fan Setting:
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleFanSetting}
                        value={this.state.nodeFanSetting}
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          border: "none",
                          borderRadius: "0px",
                          borderBottom: "2px solid lightgrey",
                        }}
                      >
                        <option value="Choose here">Choose here</option>
                        <option value="Auto">Auto</option>
                        <option value="On">On</option>
                        <option value="Off">Off</option>
                      </select>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Mode:
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleMode}
                        value={this.state.nodeMode}
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          border: "none",
                          borderRadius: "0px",
                          borderBottom: "2px solid lightgrey",
                        }}
                      >
                        <option value="Choose here">Choose here</option>
                        <option value="Auto">Auto</option>
                        <option value="Heat">Heat</option>
                        <option value="Cool">Cool</option>
                        <option value="Heat On">Heat On</option>
                        <option value="Heat On 2">Heat On 2</option>
                        <option value="Cool On">Cool On</option>
                        <option value="Cool On 2">Cool On 2</option>
                      </select>
                      <hr></hr>
                      {this.state.fossilHeat === 1 && (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="registerReason"
                          id="flexCheckChecked"
                          checked
                          onChange={this.handleFossilHeat}
                          value="reason1"
                        ></input>
                      )}
                      {this.state.fossilHeat !== 1 && (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="registerReason"
                          id="flexCheckDefault"
                          onChange={this.handleFossilHeat}
                          value="reason1"
                        ></input>
                      )}
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "300px" }}
                      >
                        Check box if heating with Fossil Fuels (not electric)
                      </label>

                      {/************************************************************************************************************** 
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Thermostat Sensor 1:
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleSensor1}
                        value={this.state.thermostatSensor1}
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          border: "none",
                          borderRadius: "0px",
                          borderBottom: "2px solid lightgrey",
                        }}
                      >
                        <option value="Choose here">Choose here</option>
                        <option value="Return Sensor">Return Sensor</option>
                        <option value="Supply Sensor">Supply Sensor</option>
                        <option value="Economizer Sensor">
                          Economizer Sensor
                        </option>
                        <option value="Outside Sensor">Outside Sensor</option>
                      </select>

                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Thermostat Sensor 2:
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleSensor2}
                        value={this.state.thermostatSensor2}
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          border: "none",
                          borderRadius: "0px",
                          borderBottom: "2px solid lightgrey",
                        }}
                      >
                        <option value="Choose here">Choose here</option>
                        <option value="Return Sensor">Return Sensor</option>
                        <option value="Supply Sensor">Supply Sensor</option>
                        <option value="Economizer Sensor">
                          Economizer Sensor
                        </option>
                        <option value="Outside Sensor">Outside Sensor</option>
                      </select>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Thermostat Sensor 3:
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleSensor3}
                        value={this.state.thermostatSensor3}
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          border: "none",
                          borderRadius: "0px",
                          borderBottom: "2px solid lightgrey",
                        }}
                      >
                        <option value="Choose here">Choose here</option>
                        <option value="Return Sensor">Return Sensor</option>
                        <option value="Supply Sensor">Supply Sensor</option>
                        <option value="Economizer Sensor">
                          Economizer Sensor
                        </option>
                        <option value="Outside Sensor">Outside Sensor</option>
                      </select>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Thermostat Sensor 4:
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleSensor4}
                        value={this.state.thermostatSensor4}
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          border: "none",
                          borderRadius: "0px",
                          borderBottom: "2px solid lightgrey",
                        }}
                      >
                        <option value="Choose here">Choose here</option>
                        <option value="Return Sensor">Return Sensor</option>
                        <option value="Supply Sensor">Supply Sensor</option>
                        <option value="Economizer Sensor">
                          Economizer Sensor
                        </option>
                        <option value="Outside Sensor">Outside Sensor</option>
                      </select>
                      ********************************************************************************* */}
                    </div>
                  )}
                  {this.state.loadType === "HVAC w SimplTherm" && (
                    <div>
                      <p className="basicParagraph">Sensor Setup:</p>
                      <hr></hr>
                      {this.state.ductMountReturn === 1 && (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="registerReason"
                          id="flexCheckChecked"
                          checked
                          onChange={this.handleDuctMountReturn}
                          value="reason1"
                        ></input>
                      )}
                      {(this.state.ductMountReturn === 0 ||
                        this.state.ductMountReturn === undefined) && (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="registerReason"
                          id="flexCheckDefault"
                          onChange={this.handleDuctMountReturn}
                          value="reason1"
                        ></input>
                      )}
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "370px" }}
                      >
                        Check box if duct-mount return sensor is installed
                      </label>
                      {(this.state.ductMountReturn === 0 ||
                        this.state.ductMountReturn === undefined) && (
                        <div>
                          <label
                            className="basicParagraph"
                            style={{ height: "30px", width: "150px" }}
                          >
                            Temp. Difference between room and return (&#176;F):
                          </label>
                          <input
                            value={this.state.nodeDeltaTempSet}
                            onChange={this.handleDeltaTempSet}
                            id="serialNumber"
                            type="text"
                            className="form-control2"
                            style={{
                              float: "right",
                              height: "30px",
                              width: "200px",
                              marginLeft: "10px",
                              paddingLeft: "10px",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {(this.state.loadType === "HVAC w SimplTherm" ||
                    this.state.loadType === "HVAC Unit" ||
                    this.state.loadType === "Space Heater" ||
                    this.state.loadType === "Water Heater" ||
                    this.state.loadType === "Walk-in" ||
                    this.state.loadType === "Reach-in" ||
                    this.state.loadType === "Counter Reach-in" ||
                    this.state.loadType === "Ambient Temp Sensor" ||
                    this.state.loadType === "Avg. Ambient Temp" ||
                    this.state.loadType === "External Temp Sensor") && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        External Temp Sensor serial number:
                      </label>
                      {!this.state.nodeSensorSN && (
                        <div
                          style={{
                            width: "200px",
                            marginBottom: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          <QrContainer
                            onScan={this.handleScanSensor.bind(this)}
                          />
                        </div>
                      )}

                      <input
                        value={this.state.nodeSensorSN}
                        onChange={this.handleSensorSN}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          height: "30px",
                          width: "200px",
                          marginLeft: "10px",
                        }}
                      />
                    </div>
                  )}
                  {/********************************************************************************* */}
                  {(this.state.loadType === "Space Heater" ||
                    this.state.loadType === "SimplHeater") && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Temperature Setpoint (&#176;F):
                      </label>
                      <input
                        value={this.state.nodeTempSetHeatOc}
                        onChange={this.handleTempSetHeatOc}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "10px",
                          paddingLeft: "10px",
                        }}
                      />
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Unoccupied Temperature Setpoint (&#176;F):
                      </label>
                      <input
                        value={this.state.nodeTempSetHeatUn}
                        onChange={this.handleTempSetHeatUn}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "10px",
                          paddingLeft: "10px",
                        }}
                      />
                    </div>
                  )}
                  {/*....................rthOnly     handleRthOnly..............................................................*/}

                  {this.state.loadType === "HVAC w SimplTherm" &&
                    this.state.nodeSensorSN !== "none" && (
                      <div style={{ marginLeft: "40px" }}>
                        {this.state.rthOnly === 1 && (
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="registerReason"
                            id="flexCheckChecked"
                            checked
                            onChange={this.handleRthOnly}
                            value="reason1"
                          ></input>
                        )}
                        {this.state.rthOnly !== 1 && (
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="registerReason"
                            id="flexCheckDefault"
                            onChange={this.handleRthOnly}
                            value="reason1"
                          ></input>
                        )}
                        <label
                          className="basicParagraph"
                          style={{ height: "30px", width: "370px" }}
                        >
                          Check if external sensor provides supply temp. only
                        </label>
                      </div>
                    )}

                  {/*.........................................................................................*/}
                  {this.state.loadType === "Avg. Ambient Temp" && (
                    <div>
                      <hr
                        style={{ marginTop: "15px", paddingTop: "15px" }}
                      ></hr>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Sensor 2 serial number Averaging:
                      </label>
                      {!this.state.nodeSensorSN2 && (
                        <div
                          style={{
                            width: "200px",
                            marginBottom: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          <QrContainer
                            onScan={this.handleScanSensor2.bind(this)}
                          />
                        </div>
                      )}

                      <input
                        value={this.state.nodeSensorSN2}
                        onChange={this.handleSensorSN2}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          height: "30px",
                          width: "200px",
                          marginLeft: "10px",
                        }}
                      />
                      <hr
                        style={{ marginTop: "15px", paddingTop: "15px" }}
                      ></hr>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Sensor 3 serial number Averaging:
                      </label>
                      {!this.state.nodeSensorSN3 && (
                        <div
                          style={{
                            width: "200px",
                            marginBottom: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          <QrContainer
                            onScan={this.handleScanSensor3.bind(this)}
                          />
                        </div>
                      )}

                      <input
                        value={this.state.nodeSensorSN3}
                        onChange={this.handleSensorSN3}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          height: "30px",
                          width: "200px",
                          marginLeft: "10px",
                        }}
                      />
                      <hr
                        style={{ marginTop: "15px", paddingTop: "15px" }}
                      ></hr>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Sensor 4 serial number Averaging:
                      </label>
                      {!this.state.nodeSensorSN4 && (
                        <div
                          style={{
                            width: "200px",
                            marginBottom: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          <QrContainer
                            onScan={this.handleScanSensor4.bind(this)}
                          />
                        </div>
                      )}

                      <input
                        value={this.state.nodeSensorSN4}
                        onChange={this.handleSensorSN4}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          height: "30px",
                          width: "200px",
                          marginLeft: "10px",
                        }}
                      />
                    </div>
                  )}

                  {/*.........................................................................................*/}

                  {(this.state.loadType === "HVAC Unit" ||
                    this.state.loadType === "Space Heater" ||
                    this.state.loadType === "Pole Lights" ||
                    this.state.loadType === "Outdoor Sensed Lights" ||
                    this.state.loadType === "Indoor Lights" ||
                    this.state.loadType === "Heat Lamp" ||
                    this.state.loadType === "Water Heater" ||
                    this.state.loadType === "Water Heater No Sensor" ||
                    this.state.loadType === "Walk-in" ||
                    this.state.loadType === "Reach-in" ||
                    this.state.loadType === "Counter Reach-in" ||
                    this.state.loadType === "Anti-sweat Heaters" ||
                    this.state.loadType === "EV Charger" ||
                    this.state.loadType === "Oven" ||
                    this.state.loadType === "Hood" ||
                    this.state.loadType === "Misc. Appliance" ||
                    this.state.loadType === "Misc. Sheddable") && (
                    <div style={{ height: "100px" }}>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Input the serial number for the relay device:
                      </label>
                      {!this.state.nodeRelaySN && (
                        <div
                          style={{
                            float: "right",
                            marginBottom: "10px",
                            marginLeft: "150px",
                          }}
                        >
                          <QrContainer
                            onScan={this.handleScanRelay.bind(this)}
                          />
                        </div>
                      )}

                      <input
                        value={this.state.nodeRelaySN}
                        onChange={this.handleNodeRelay}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "200px",
                        }}
                      />
                    </div>
                  )}

                  {(this.state.loadType === "Space Heater" ||
                    this.state.loadType === "Pole Lights" ||
                    this.state.loadType === "Outdoor Sensed Lights" ||
                    this.state.loadType === "Indoor Lights" ||
                    this.state.loadType === "Heat Lamp" ||
                    this.state.loadType === "Water Heater" ||
                    this.state.loadType === "Water Heater No Sensor" ||
                    this.state.loadType === "Walk-in" ||
                    this.state.loadType === "Reach-in" ||
                    this.state.loadType === "Anti-sweat Heaters" ||
                    this.state.loadType === "EV Charger" ||
                    this.state.loadType === "Oven" ||
                    this.state.loadType === "Hood" ||
                    this.state.loadType === "Misc. Appliance" ||
                    this.state.loadType === "Misc. Sheddable") && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{
                          height: "30px",
                          width: "150px",
                        }}
                      >
                        Select Relay Number:
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleStage1Cooling}
                        value={this.state.stage1Cooling}
                        style={{
                          float: "right",
                          width: "100px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          height: "30px",
                        }}
                      >
                        <option value="Choose">Choose</option>
                        <option value="Relay1">Relay1</option>
                        <option value="Relay2">Relay2</option>
                        <option value="Relay3">Relay3</option>
                        <option value="Relay4">Relay4</option>
                      </select>
                    </div>
                  )}

                  {/*.......................................................................................*/}

                  {(this.state.loadType === "Relay Driver" ||
                    this.state.loadType === "Relay Driver w Light Sensor" ||
                    this.state.loadType === "Quad 30A Relays" ||
                    this.state.loadType === "Quad 30A w Light Sensor") && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{
                          height: "30px",
                          width: "150px",
                        }}
                      >
                        Select Relay Number:
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleStage1Cooling}
                        value={this.state.stage1Cooling}
                        style={{
                          float: "right",
                          width: "100px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          height: "30px",
                        }}
                      >
                        <option value="Choose">Choose</option>
                        <option value="Relay1">Relay1</option>
                        <option value="Relay2">Relay2</option>
                        <option value="Relay3">Relay3</option>
                        <option value="Relay4">Relay4</option>
                      </select>
                    </div>
                  )}

                  {(this.state.loadType === "Relay Driver" ||
                    this.state.loadType === "Quad 30A Relays" ||
                    this.state.loadType === "Quad 30A w Light Sensor" ||
                    this.state.loadType === "Pole Lights") && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{
                          height: "30px",
                          width: "150px",
                        }}
                      >
                        Select Schedule Mode:
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleScheduleMode}
                        value={this.state.scheduleMode}
                        //selected={this.state.scheduleMode}
                        style={{
                          float: "right",
                          width: "100px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          height: "30px",
                        }}
                      >
                        <option value="choose">Choose</option>
                        <option value="Sun">Sun</option>
                        <option value="Schedule">Schedule</option>
                      </select>
                    </div>
                  )}
                  {(this.state.loadType === "Relay Driver" ||
                    this.state.loadType === "Quad 30A Relays" ||
                    this.state.loadType === "Quad 30A w Light Sensor" ||
                    this.state.loadType === "Pole Lights") &&
                    this.state.scheduleMode == "Sun" && (
                      <div>
                        <label
                          className="basicParagraph"
                          style={{
                            height: "30px",
                            width: "300px",
                            color: "Orange",
                            fontSize: "12px",
                            bottomMargin: "20px",
                            bottomPadding: "15px",
                            transform: "translateX(40px)",
                          }}
                        >
                          Caution: Unpredictable Stand-alone Operation!
                        </label>
                      </div>
                    )}
                  {(this.state.loadType === "Lighting" ||
                    this.state.loadType === "Outdoor Sensed Lights" ||
                    this.state.loadType === "Relay Driver w Light Sensor" ||
                    this.state.loadType === "Quad 30A w Light Sensor" ||
                    this.state.loadType === "Light Sensor") && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Light Sensor serial number:
                      </label>
                      {!this.state.nodeSensorSN && (
                        <div
                          style={{
                            float: "right",
                            marginBottom: "10px",
                            marginLeft: "150px",
                          }}
                        >
                          <QrContainer
                            onScan={this.handleScanSensor.bind(this)}
                          />
                        </div>
                      )}

                      <input
                        value={this.state.nodeSensorSN}
                        onChange={this.handleSensorSN}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "175px",
                        }}
                      />
                    </div>
                  )}
                  {(this.state.loadType === "Walk-in" ||
                    this.state.loadType === "Door Monitor") && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Door sensor serial number:
                      </label>
                      {!this.state.nodeDoorSensorSN && (
                        <div
                          style={{
                            float: "right",
                            marginBottom: "10px",
                            marginLeft: "150px",
                          }}
                        >
                          <QrContainer
                            onScan={this.handleScanDoorSensor.bind(this)}
                          />
                        </div>
                      )}

                      <input
                        value={this.state.nodeDoorSensorSN}
                        onChange={this.handleDoorSensorSN}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                          marginLeft: "175px",
                        }}
                      />
                    </div>
                  )}
                  {(this.state.loadType === "HVAC Unit" ||
                    this.state.loadType === "HVAC w SimplTherm" ||
                    this.state.loadType === "Space Heater" ||
                    (this.state.loadType === "Pole Lights" &&
                      this.state.scheduleMode === "Schedule") ||
                    this.state.loadType === "Outdoor Sensed Lights" ||
                    (this.state.loadType === "Relay Driver" &&
                      this.state.scheduleMode === "Schedule") ||
                    (this.state.loadType === "Quad 30A Relays" &&
                      this.state.scheduleMode === "Schedule") ||
                    this.state.loadType === "Relay Driver w Light Sensor" ||
                    this.state.loadType === "Quad 30A w Light Sensor" ||
                    this.state.loadType === "Indoor Lights" ||
                    this.state.loadType === "Heat Lamp" ||
                    this.state.loadType === "Water Heater" ||
                    this.state.loadType === "Water Heater No Sensor" ||
                    this.state.loadType === "Walk-in" ||
                    this.state.loadType === "SimplWalkIn" ||
                    this.state.loadType === "SimplHeater" ||
                    this.state.loadType === "Reach-in" ||
                    this.state.loadType === "Counter Reach-in" ||
                    this.state.loadType === "Anti-sweat Heaters" ||
                    this.state.loadType === "EV Charger" ||
                    this.state.loadType === "Oven" ||
                    this.state.loadType === "Hood" ||
                    this.state.loadType === "Misc. Appliance" ||
                    this.state.loadType === "Misc. Sheddable") && (
                    <div>
                      {/*///////////////////////////////////////////////////////////////////////////////////////////////*/}
                      {this.state.loadType !== "HVAC w SimplTherm" && (
                        <div>
                          <label
                            className="basicParagraph"
                            style={{
                              height: "30px",
                              width: "150px",
                              marginTop: "5px",
                              paddingTop: "5px",
                            }}
                          >
                            OverRide Period:
                          </label>
                          <select
                            className="form-control2"
                            onChange={this.handleOVRT}
                            value={this.state.nodeOVRT}
                            style={{
                              float: "right",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            <option value="3">3 Mins</option>
                            <option value="15">15 Mins</option>
                            <option value="30">30 Mins</option>
                            <option value="60">One hour</option>
                            <option value="120">Two Hours</option>
                            <option value="180">Three Hours</option>
                            <option value="300">Five Hours</option>
                            <option value="1440">24 Hours</option>
                          </select>
                        </div>
                      )}

                      {/*////////////////////////////////////////////////////////////////////////////////////////////////*/}
                      <p className="basicParagraph">
                        Operating Hours Weekly Schedule:
                      </p>

                      <table
                        className="table"
                        style={{
                          marginTop: "20px",
                          width: "350px",
                        }}
                      >
                        <th></th>
                        <th>S</th>
                        <th>M</th>
                        <th>T</th>
                        <th>W</th>
                        <th>T</th>
                        <th>F</th>
                        <th>S</th>
                        <th>H</th>
                        <tr>
                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            Daily Hours
                          </td>
                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              padding: "0px",
                              margin: "0px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <select
                              className="form-control2"
                              onChange={this.handleScheduleSun}
                              value={this.state.nodeScheduleSun}
                              style={{
                                float: "right",
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "0px",
                                width: "40px",
                              }}
                            >
                              <option value="?">?</option>
                              {this.state.OffTimeOptionA === "G" && (
                                <option value="A">
                                  A: ON: {this.state.startTimeA} -{" "}
                                  {this.state.endTimeA} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionA === "H" && (
                                <option value="A">
                                  A: ON: {this.state.startTimeA} -{" "}
                                  {this.state.endTimeA} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionA !== "G" &&
                                this.state.OffTimeOptionA !== "H" && (
                                  <option value="A">
                                    A: ON: {this.state.startTimeA} -{" "}
                                    {this.state.endTimeA}
                                  </option>
                                )}

                              {this.state.OffTimeOptionB === "G" && (
                                <option value="B">
                                  B: ON: {this.state.startTimeB} -{" "}
                                  {this.state.endTimeB} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionB === "H" && (
                                <option value="B">
                                  B: ON: {this.state.startTimeB} -{" "}
                                  {this.state.endTimeB} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionB !== "G" &&
                                this.state.OffTimeOptionB !== "H" && (
                                  <option value="B">
                                    B: ON: {this.state.startTimeB} -{" "}
                                    {this.state.endTimeB}
                                  </option>
                                )}
                              {this.state.OffTimeOptionC === "G" && (
                                <option value="C">
                                  C: ON: {this.state.startTimeC} -{" "}
                                  {this.state.endTimeC} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionC === "H" && (
                                <option value="C">
                                  C: ON: {this.state.startTimeC} -{" "}
                                  {this.state.endTimeC} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionC !== "G" &&
                                this.state.OffTimeOptionC !== "H" && (
                                  <option value="C">
                                    C: ON: {this.state.startTimeC} -{" "}
                                    {this.state.endTimeC}
                                  </option>
                                )}
                              {this.state.OffTimeOptionD === "G" && (
                                <option value="D">
                                  D: ON: {this.state.startTimeD} -{" "}
                                  {this.state.endTimeD} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionA === "H" && (
                                <option value="D">
                                  D: ON: {this.state.startTimeD} -{" "}
                                  {this.state.endTimeD} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionD !== "G" &&
                                this.state.OffTimeOptionD !== "H" && (
                                  <option value="D">
                                    D: ON: {this.state.startTimeD} -{" "}
                                    {this.state.endTimeD}
                                  </option>
                                )}
                              {this.state.OffTimeOptionE === "G" && (
                                <option value="E">
                                  E: ON: {this.state.startTimeE} -{" "}
                                  {this.state.endTimeE} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionE === "H" && (
                                <option value="E">
                                  E: ON: {this.state.startTimeE} -{" "}
                                  {this.state.endTimeE} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionE !== "G" &&
                                this.state.OffTimeOptionE !== "H" && (
                                  <option value="E">
                                    E: ON: {this.state.startTimeE} -{" "}
                                    {this.state.endTimeE}
                                  </option>
                                )}
                              {this.state.OffTimeOptionF === "G" && (
                                <option value="F">
                                  F: ON: {this.state.startTimeF} -{" "}
                                  {this.state.endTimeF} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionF === "H" && (
                                <option value="F">
                                  F: ON: {this.state.startTimeF} -{" "}
                                  {this.state.endTimeF} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionF !== "G" &&
                                this.state.OffTimeOptionF !== "H" && (
                                  <option value="F">
                                    F: ON: {this.state.startTimeF} -{" "}
                                    {this.state.endTimeF}
                                  </option>
                                )}
                            </select>
                          </td>

                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              padding: "0px",
                              margin: "0px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <select
                              className="form-control2"
                              onChange={this.handleScheduleMon}
                              value={this.state.nodeScheduleMon}
                              style={{
                                float: "right",
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "0px",
                                width: "40px",
                              }}
                            >
                              {" "}
                              <option value="?">?</option>
                              {this.state.OffTimeOptionA === "G" && (
                                <option value="A">
                                  A: ON: {this.state.startTimeA} -{" "}
                                  {this.state.endTimeA} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionA === "H" && (
                                <option value="A">
                                  A: ON: {this.state.startTimeA} -{" "}
                                  {this.state.endTimeA} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionA !== "G" &&
                                this.state.OffTimeOptionA !== "H" && (
                                  <option value="A">
                                    A: ON: {this.state.startTimeA} -{" "}
                                    {this.state.endTimeA}
                                  </option>
                                )}
                              {this.state.OffTimeOptionB === "G" && (
                                <option value="B">
                                  B: ON: {this.state.startTimeB} -{" "}
                                  {this.state.endTimeB} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionB === "H" && (
                                <option value="B">
                                  B: ON: {this.state.startTimeB} -{" "}
                                  {this.state.endTimeB} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionB !== "G" &&
                                this.state.OffTimeOptionB !== "H" && (
                                  <option value="B">
                                    B: ON: {this.state.startTimeB} -{" "}
                                    {this.state.endTimeB}
                                  </option>
                                )}
                              {this.state.OffTimeOptionC === "G" && (
                                <option value="C">
                                  C: ON: {this.state.startTimeC} -{" "}
                                  {this.state.endTimeC} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionC === "H" && (
                                <option value="C">
                                  C: ON: {this.state.startTimeC} -{" "}
                                  {this.state.endTimeC} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionC !== "G" &&
                                this.state.OffTimeOptionC !== "H" && (
                                  <option value="C">
                                    C: ON: {this.state.startTimeC} -{" "}
                                    {this.state.endTimeC}
                                  </option>
                                )}
                              {this.state.OffTimeOptionD === "G" && (
                                <option value="D">
                                  D: ON: {this.state.startTimeD} -{" "}
                                  {this.state.endTimeD} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionA === "H" && (
                                <option value="D">
                                  D: ON: {this.state.startTimeD} -{" "}
                                  {this.state.endTimeD} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionD !== "G" &&
                                this.state.OffTimeOptionD !== "H" && (
                                  <option value="D">
                                    D: ON: {this.state.startTimeD} -{" "}
                                    {this.state.endTimeD}
                                  </option>
                                )}
                              {this.state.OffTimeOptionE === "G" && (
                                <option value="E">
                                  E: ON: {this.state.startTimeE} -{" "}
                                  {this.state.endTimeE} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionE === "H" && (
                                <option value="E">
                                  E: ON: {this.state.startTimeE} -{" "}
                                  {this.state.endTimeE} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionE !== "G" &&
                                this.state.OffTimeOptionE !== "H" && (
                                  <option value="E">
                                    E: ON: {this.state.startTimeE} -{" "}
                                    {this.state.endTimeE}
                                  </option>
                                )}
                              {this.state.OffTimeOptionF === "G" && (
                                <option value="F">
                                  F: ON: {this.state.startTimeF} -{" "}
                                  {this.state.endTimeF} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionF === "H" && (
                                <option value="F">
                                  F: ON: {this.state.startTimeF} -{" "}
                                  {this.state.endTimeF} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionF !== "G" &&
                                this.state.OffTimeOptionF !== "H" && (
                                  <option value="F">
                                    F: ON: {this.state.startTimeF} -{" "}
                                    {this.state.endTimeF}
                                  </option>
                                )}
                            </select>
                          </td>
                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              padding: "0px",
                              margin: "0px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <select
                              className="form-control2"
                              onChange={this.handleScheduleTue}
                              value={this.state.nodeScheduleTue}
                              style={{
                                float: "right",
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "0px",
                                width: "40px",
                              }}
                            >
                              <option value="?">?</option>
                              {this.state.OffTimeOptionA === "G" && (
                                <option value="A">
                                  A: ON: {this.state.startTimeA} -{" "}
                                  {this.state.endTimeA} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionA === "H" && (
                                <option value="A">
                                  A: ON: {this.state.startTimeA} -{" "}
                                  {this.state.endTimeA} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionA !== "G" &&
                                this.state.OffTimeOptionA !== "H" && (
                                  <option value="A">
                                    A: ON: {this.state.startTimeA} -{" "}
                                    {this.state.endTimeA}
                                  </option>
                                )}

                              {this.state.OffTimeOptionB === "G" && (
                                <option value="B">
                                  B: ON: {this.state.startTimeB} -{" "}
                                  {this.state.endTimeB} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionB === "H" && (
                                <option value="B">
                                  B: ON: {this.state.startTimeB} -{" "}
                                  {this.state.endTimeB} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionB !== "G" &&
                                this.state.OffTimeOptionB !== "H" && (
                                  <option value="B">
                                    B: ON: {this.state.startTimeB} -{" "}
                                    {this.state.endTimeB}
                                  </option>
                                )}
                              {this.state.OffTimeOptionC === "G" && (
                                <option value="C">
                                  C: ON: {this.state.startTimeC} -{" "}
                                  {this.state.endTimeC} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionC === "H" && (
                                <option value="C">
                                  C: ON: {this.state.startTimeC} -{" "}
                                  {this.state.endTimeC} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionC !== "G" &&
                                this.state.OffTimeOptionC !== "H" && (
                                  <option value="C">
                                    C: ON: {this.state.startTimeC} -{" "}
                                    {this.state.endTimeC}
                                  </option>
                                )}
                              {this.state.OffTimeOptionD === "G" && (
                                <option value="D">
                                  D: ON: {this.state.startTimeD} -{" "}
                                  {this.state.endTimeD} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionA === "H" && (
                                <option value="D">
                                  D: ON: {this.state.startTimeD} -{" "}
                                  {this.state.endTimeD} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionD !== "G" &&
                                this.state.OffTimeOptionD !== "H" && (
                                  <option value="D">
                                    D: ON: {this.state.startTimeD} -{" "}
                                    {this.state.endTimeD}
                                  </option>
                                )}
                              {this.state.OffTimeOptionE === "G" && (
                                <option value="E">
                                  E: ON: {this.state.startTimeE} -{" "}
                                  {this.state.endTimeE} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionE === "H" && (
                                <option value="E">
                                  E: ON: {this.state.startTimeE} -{" "}
                                  {this.state.endTimeE} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionE !== "G" &&
                                this.state.OffTimeOptionE !== "H" && (
                                  <option value="E">
                                    E: ON: {this.state.startTimeE} -{" "}
                                    {this.state.endTimeE}
                                  </option>
                                )}
                              {this.state.OffTimeOptionF === "G" && (
                                <option value="F">
                                  F: ON: {this.state.startTimeF} -{" "}
                                  {this.state.endTimeF} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionF === "H" && (
                                <option value="F">
                                  F: ON: {this.state.startTimeF} -{" "}
                                  {this.state.endTimeF} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionF !== "G" &&
                                this.state.OffTimeOptionF !== "H" && (
                                  <option value="F">
                                    F: ON: {this.state.startTimeF} -{" "}
                                    {this.state.endTimeF}
                                  </option>
                                )}
                            </select>
                          </td>
                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              padding: "0px",
                              margin: "0px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <select
                              className="form-control2"
                              onChange={this.handleScheduleWed}
                              value={this.state.nodeScheduleWed}
                              style={{
                                float: "right",
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "0px",
                                width: "40px",
                              }}
                            >
                              <option value="?">?</option>
                              {this.state.OffTimeOptionA === "G" && (
                                <option value="A">
                                  A: ON: {this.state.startTimeA} -{" "}
                                  {this.state.endTimeA} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionA === "H" && (
                                <option value="A">
                                  A: ON: {this.state.startTimeA} -{" "}
                                  {this.state.endTimeA} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionA !== "G" &&
                                this.state.OffTimeOptionA !== "H" && (
                                  <option value="A">
                                    A: ON: {this.state.startTimeA} -{" "}
                                    {this.state.endTimeA}
                                  </option>
                                )}

                              {this.state.OffTimeOptionB === "G" && (
                                <option value="B">
                                  B: ON: {this.state.startTimeB} -{" "}
                                  {this.state.endTimeB} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionB === "H" && (
                                <option value="B">
                                  B: ON: {this.state.startTimeB} -{" "}
                                  {this.state.endTimeB} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionB !== "G" &&
                                this.state.OffTimeOptionB !== "H" && (
                                  <option value="B">
                                    B: ON: {this.state.startTimeB} -{" "}
                                    {this.state.endTimeB}
                                  </option>
                                )}
                              {this.state.OffTimeOptionC === "G" && (
                                <option value="C">
                                  C: ON: {this.state.startTimeC} -{" "}
                                  {this.state.endTimeC} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionC === "H" && (
                                <option value="C">
                                  C: ON: {this.state.startTimeC} -{" "}
                                  {this.state.endTimeC} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionC !== "G" &&
                                this.state.OffTimeOptionC !== "H" && (
                                  <option value="C">
                                    C: ON: {this.state.startTimeC} -{" "}
                                    {this.state.endTimeC}
                                  </option>
                                )}
                              {this.state.OffTimeOptionD === "G" && (
                                <option value="D">
                                  D: ON: {this.state.startTimeD} -{" "}
                                  {this.state.endTimeD} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionA === "H" && (
                                <option value="D">
                                  D: ON: {this.state.startTimeD} -{" "}
                                  {this.state.endTimeD} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionD !== "G" &&
                                this.state.OffTimeOptionD !== "H" && (
                                  <option value="D">
                                    D: ON: {this.state.startTimeD} -{" "}
                                    {this.state.endTimeD}
                                  </option>
                                )}
                              {this.state.OffTimeOptionE === "G" && (
                                <option value="E">
                                  E: ON: {this.state.startTimeE} -{" "}
                                  {this.state.endTimeE} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionE === "H" && (
                                <option value="E">
                                  E: ON: {this.state.startTimeE} -{" "}
                                  {this.state.endTimeE} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionE !== "G" &&
                                this.state.OffTimeOptionE !== "H" && (
                                  <option value="E">
                                    E: ON: {this.state.startTimeE} -{" "}
                                    {this.state.endTimeE}
                                  </option>
                                )}
                              {this.state.OffTimeOptionF === "G" && (
                                <option value="F">
                                  F: ON: {this.state.startTimeF} -{" "}
                                  {this.state.endTimeF} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionF === "H" && (
                                <option value="F">
                                  F: ON: {this.state.startTimeF} -{" "}
                                  {this.state.endTimeF} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionF !== "G" &&
                                this.state.OffTimeOptionF !== "H" && (
                                  <option value="F">
                                    F: ON: {this.state.startTimeF} -{" "}
                                    {this.state.endTimeF}
                                  </option>
                                )}
                            </select>
                          </td>
                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              padding: "0px",
                              margin: "0px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <select
                              className="form-control2"
                              onChange={this.handleScheduleThu}
                              value={this.state.nodeScheduleThu}
                              style={{
                                float: "right",
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "0px",
                                width: "40px",
                              }}
                            >
                              <option value="?">?</option>
                              {this.state.OffTimeOptionA === "G" && (
                                <option value="A">
                                  A: ON: {this.state.startTimeA} -{" "}
                                  {this.state.endTimeA} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionA === "H" && (
                                <option value="A">
                                  A: ON: {this.state.startTimeA} -{" "}
                                  {this.state.endTimeA} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionA !== "G" &&
                                this.state.OffTimeOptionA !== "H" && (
                                  <option value="A">
                                    A: ON: {this.state.startTimeA} -{" "}
                                    {this.state.endTimeA}
                                  </option>
                                )}

                              {this.state.OffTimeOptionB === "G" && (
                                <option value="B">
                                  B: ON: {this.state.startTimeB} -{" "}
                                  {this.state.endTimeB} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionB === "H" && (
                                <option value="B">
                                  B: ON: {this.state.startTimeB} -{" "}
                                  {this.state.endTimeB} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionB !== "G" &&
                                this.state.OffTimeOptionB !== "H" && (
                                  <option value="B">
                                    B: ON: {this.state.startTimeB} -{" "}
                                    {this.state.endTimeB}
                                  </option>
                                )}
                              {this.state.OffTimeOptionC === "G" && (
                                <option value="C">
                                  C: ON: {this.state.startTimeC} -{" "}
                                  {this.state.endTimeC} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionC === "H" && (
                                <option value="C">
                                  C: ON: {this.state.startTimeC} -{" "}
                                  {this.state.endTimeC} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionC !== "G" &&
                                this.state.OffTimeOptionC !== "H" && (
                                  <option value="C">
                                    C: ON: {this.state.startTimeC} -{" "}
                                    {this.state.endTimeC}
                                  </option>
                                )}
                              {this.state.OffTimeOptionD === "G" && (
                                <option value="D">
                                  D: ON: {this.state.startTimeD} -{" "}
                                  {this.state.endTimeD} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionA === "H" && (
                                <option value="D">
                                  D: ON: {this.state.startTimeD} -{" "}
                                  {this.state.endTimeD} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionD !== "G" &&
                                this.state.OffTimeOptionD !== "H" && (
                                  <option value="D">
                                    D: ON: {this.state.startTimeD} -{" "}
                                    {this.state.endTimeD}
                                  </option>
                                )}
                              {this.state.OffTimeOptionE === "G" && (
                                <option value="E">
                                  E: ON: {this.state.startTimeE} -{" "}
                                  {this.state.endTimeE} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionE === "H" && (
                                <option value="E">
                                  E: ON: {this.state.startTimeE} -{" "}
                                  {this.state.endTimeE} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionE !== "G" &&
                                this.state.OffTimeOptionE !== "H" && (
                                  <option value="E">
                                    E: ON: {this.state.startTimeE} -{" "}
                                    {this.state.endTimeE}
                                  </option>
                                )}
                              {this.state.OffTimeOptionF === "G" && (
                                <option value="F">
                                  F: ON: {this.state.startTimeF} -{" "}
                                  {this.state.endTimeF} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionF === "H" && (
                                <option value="F">
                                  F: ON: {this.state.startTimeF} -{" "}
                                  {this.state.endTimeF} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionF !== "G" &&
                                this.state.OffTimeOptionF !== "H" && (
                                  <option value="F">
                                    F: ON: {this.state.startTimeF} -{" "}
                                    {this.state.endTimeF}
                                  </option>
                                )}
                            </select>
                          </td>
                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              padding: "0px",
                              margin: "0px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <select
                              className="form-control2"
                              onChange={this.handleScheduleFri}
                              value={this.state.nodeScheduleFri}
                              style={{
                                float: "right",
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "0px",
                                width: "40px",
                              }}
                            >
                              <option value="?">?</option>
                              {this.state.OffTimeOptionA === "G" && (
                                <option value="A">
                                  A: ON: {this.state.startTimeA} -{" "}
                                  {this.state.endTimeA} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionA === "H" && (
                                <option value="A">
                                  A: ON: {this.state.startTimeA} -{" "}
                                  {this.state.endTimeA} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionA !== "G" &&
                                this.state.OffTimeOptionA !== "H" && (
                                  <option value="A">
                                    A: ON: {this.state.startTimeA} -{" "}
                                    {this.state.endTimeA}
                                  </option>
                                )}

                              {this.state.OffTimeOptionB === "G" && (
                                <option value="B">
                                  B: ON: {this.state.startTimeB} -{" "}
                                  {this.state.endTimeB} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionB === "H" && (
                                <option value="B">
                                  B: ON: {this.state.startTimeB} -{" "}
                                  {this.state.endTimeB} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionB !== "G" &&
                                this.state.OffTimeOptionB !== "H" && (
                                  <option value="B">
                                    B: ON: {this.state.startTimeB} -{" "}
                                    {this.state.endTimeB}
                                  </option>
                                )}
                              {this.state.OffTimeOptionC === "G" && (
                                <option value="C">
                                  C: ON: {this.state.startTimeC} -{" "}
                                  {this.state.endTimeC} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionC === "H" && (
                                <option value="C">
                                  C: ON: {this.state.startTimeC} -{" "}
                                  {this.state.endTimeC} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionC !== "G" &&
                                this.state.OffTimeOptionC !== "H" && (
                                  <option value="C">
                                    C: ON: {this.state.startTimeC} -{" "}
                                    {this.state.endTimeC}
                                  </option>
                                )}
                              {this.state.OffTimeOptionD === "G" && (
                                <option value="D">
                                  D: ON: {this.state.startTimeD} -{" "}
                                  {this.state.endTimeD} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionA === "H" && (
                                <option value="D">
                                  D: ON: {this.state.startTimeD} -{" "}
                                  {this.state.endTimeD} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionD !== "G" &&
                                this.state.OffTimeOptionD !== "H" && (
                                  <option value="D">
                                    D: ON: {this.state.startTimeD} -{" "}
                                    {this.state.endTimeD}
                                  </option>
                                )}
                              {this.state.OffTimeOptionE === "G" && (
                                <option value="E">
                                  E: ON: {this.state.startTimeE} -{" "}
                                  {this.state.endTimeE} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionE === "H" && (
                                <option value="E">
                                  E: ON: {this.state.startTimeE} -{" "}
                                  {this.state.endTimeE} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionE !== "G" &&
                                this.state.OffTimeOptionE !== "H" && (
                                  <option value="E">
                                    E: ON: {this.state.startTimeE} -{" "}
                                    {this.state.endTimeE}
                                  </option>
                                )}
                              {this.state.OffTimeOptionF === "G" && (
                                <option value="F">
                                  F: ON: {this.state.startTimeF} -{" "}
                                  {this.state.endTimeF} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionF === "H" && (
                                <option value="F">
                                  F: ON: {this.state.startTimeF} -{" "}
                                  {this.state.endTimeF} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionF !== "G" &&
                                this.state.OffTimeOptionF !== "H" && (
                                  <option value="F">
                                    F: ON: {this.state.startTimeF} -{" "}
                                    {this.state.endTimeF}
                                  </option>
                                )}
                            </select>
                          </td>
                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              padding: "0px",
                              margin: "0px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <select
                              className="form-control2"
                              onChange={this.handleScheduleSat}
                              value={this.state.nodeScheduleSat}
                              style={{
                                float: "right",
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "0px",
                                width: "40px",
                              }}
                            >
                              <option value="?">?</option>
                              {this.state.OffTimeOptionA === "G" && (
                                <option value="A">
                                  A: ON: {this.state.startTimeA} -{" "}
                                  {this.state.endTimeA} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionA === "H" && (
                                <option value="A">
                                  A: ON: {this.state.startTimeA} -{" "}
                                  {this.state.endTimeA} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionA !== "G" &&
                                this.state.OffTimeOptionA !== "H" && (
                                  <option value="A">
                                    A: ON: {this.state.startTimeA} -{" "}
                                    {this.state.endTimeA}
                                  </option>
                                )}

                              {this.state.OffTimeOptionB === "G" && (
                                <option value="B">
                                  B: ON: {this.state.startTimeB} -{" "}
                                  {this.state.endTimeB} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionB === "H" && (
                                <option value="B">
                                  B: ON: {this.state.startTimeB} -{" "}
                                  {this.state.endTimeB} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionB !== "G" &&
                                this.state.OffTimeOptionB !== "H" && (
                                  <option value="B">
                                    B: ON: {this.state.startTimeB} -{" "}
                                    {this.state.endTimeB}
                                  </option>
                                )}
                              {this.state.OffTimeOptionC === "G" && (
                                <option value="C">
                                  C: ON: {this.state.startTimeC} -{" "}
                                  {this.state.endTimeC} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionC === "H" && (
                                <option value="C">
                                  C: ON: {this.state.startTimeC} -{" "}
                                  {this.state.endTimeC} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionC !== "G" &&
                                this.state.OffTimeOptionC !== "H" && (
                                  <option value="C">
                                    C: ON: {this.state.startTimeC} -{" "}
                                    {this.state.endTimeC}
                                  </option>
                                )}
                              {this.state.OffTimeOptionD === "G" && (
                                <option value="D">
                                  D: ON: {this.state.startTimeD} -{" "}
                                  {this.state.endTimeD} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionA === "H" && (
                                <option value="D">
                                  D: ON: {this.state.startTimeD} -{" "}
                                  {this.state.endTimeD} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionD !== "G" &&
                                this.state.OffTimeOptionD !== "H" && (
                                  <option value="D">
                                    D: ON: {this.state.startTimeD} -{" "}
                                    {this.state.endTimeD}
                                  </option>
                                )}
                              {this.state.OffTimeOptionE === "G" && (
                                <option value="E">
                                  E: ON: {this.state.startTimeE} -{" "}
                                  {this.state.endTimeE} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionE === "H" && (
                                <option value="E">
                                  E: ON: {this.state.startTimeE} -{" "}
                                  {this.state.endTimeE} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionE !== "G" &&
                                this.state.OffTimeOptionE !== "H" && (
                                  <option value="E">
                                    E: ON: {this.state.startTimeE} -{" "}
                                    {this.state.endTimeE}
                                  </option>
                                )}
                              {this.state.OffTimeOptionF === "G" && (
                                <option value="F">
                                  F: ON: {this.state.startTimeF} -{" "}
                                  {this.state.endTimeF} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionF === "H" && (
                                <option value="F">
                                  F: ON: {this.state.startTimeF} -{" "}
                                  {this.state.endTimeF} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionF !== "G" &&
                                this.state.OffTimeOptionF !== "H" && (
                                  <option value="F">
                                    F: ON: {this.state.startTimeF} -{" "}
                                    {this.state.endTimeF}
                                  </option>
                                )}
                            </select>
                          </td>

                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              padding: "0px",
                              margin: "0px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <select
                              className="form-control2"
                              onChange={this.handleScheduleHol}
                              value={this.state.nodeScheduleHol}
                              style={{
                                float: "right",
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "0px",
                                width: "40px",
                              }}
                            >
                              <option value="?">?</option>
                              {this.state.OffTimeOptionA === "G" && (
                                <option value="A">
                                  A: ON: {this.state.startTimeA} -{" "}
                                  {this.state.endTimeA} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionA === "H" && (
                                <option value="A">
                                  A: ON: {this.state.startTimeA} -{" "}
                                  {this.state.endTimeA} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionA !== "G" &&
                                this.state.OffTimeOptionA !== "H" && (
                                  <option value="A">
                                    A: ON: {this.state.startTimeA} -{" "}
                                    {this.state.endTimeA}
                                  </option>
                                )}

                              {this.state.OffTimeOptionB === "G" && (
                                <option value="B">
                                  B: ON: {this.state.startTimeB} -{" "}
                                  {this.state.endTimeB} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionB === "H" && (
                                <option value="B">
                                  B: ON: {this.state.startTimeB} -{" "}
                                  {this.state.endTimeB} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionB !== "G" &&
                                this.state.OffTimeOptionB !== "H" && (
                                  <option value="B">
                                    B: ON: {this.state.startTimeB} -{" "}
                                    {this.state.endTimeB}
                                  </option>
                                )}
                              {this.state.OffTimeOptionC === "G" && (
                                <option value="C">
                                  C: ON: {this.state.startTimeC} -{" "}
                                  {this.state.endTimeC} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionC === "H" && (
                                <option value="C">
                                  C: ON: {this.state.startTimeC} -{" "}
                                  {this.state.endTimeC} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionC !== "G" &&
                                this.state.OffTimeOptionC !== "H" && (
                                  <option value="C">
                                    C: ON: {this.state.startTimeC} -{" "}
                                    {this.state.endTimeC}
                                  </option>
                                )}
                              {this.state.OffTimeOptionD === "G" && (
                                <option value="D">
                                  D: ON: {this.state.startTimeD} -{" "}
                                  {this.state.endTimeD} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionA === "H" && (
                                <option value="D">
                                  D: ON: {this.state.startTimeD} -{" "}
                                  {this.state.endTimeD} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionD !== "G" &&
                                this.state.OffTimeOptionD !== "H" && (
                                  <option value="D">
                                    D: ON: {this.state.startTimeD} -{" "}
                                    {this.state.endTimeD}
                                  </option>
                                )}
                              {this.state.OffTimeOptionE === "G" && (
                                <option value="E">
                                  E: ON: {this.state.startTimeE} -{" "}
                                  {this.state.endTimeE} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionE === "H" && (
                                <option value="E">
                                  E: ON: {this.state.startTimeE} -{" "}
                                  {this.state.endTimeE} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionE !== "G" &&
                                this.state.OffTimeOptionE !== "H" && (
                                  <option value="E">
                                    E: ON: {this.state.startTimeE} -{" "}
                                    {this.state.endTimeE}
                                  </option>
                                )}
                              {this.state.OffTimeOptionF === "G" && (
                                <option value="F">
                                  F: ON: {this.state.startTimeF} -{" "}
                                  {this.state.endTimeF} | OFF:{" "}
                                  {this.state.startTimeG} -{" "}
                                  {this.state.endTimeG}
                                </option>
                              )}
                              {this.state.OffTimeOptionF === "H" && (
                                <option value="F">
                                  F: ON: {this.state.startTimeF} -{" "}
                                  {this.state.endTimeF} | OFF:{" "}
                                  {this.state.startTimeH} -{" "}
                                  {this.state.endTimeH}
                                </option>
                              )}
                              {this.state.OffTimeOptionF !== "G" &&
                                this.state.OffTimeOptionF !== "H" && (
                                  <option value="F">
                                    F: ON: {this.state.startTimeF} -{" "}
                                    {this.state.endTimeF}
                                  </option>
                                )}
                            </select>
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}

                  {((this.state.loadType === "Pole Lights" &&
                    this.state.scheduleMode === "Sun") ||
                    (this.state.loadType === "Relay Driver" &&
                      this.state.scheduleMode === "Sun") ||
                    (this.state.loadType === "Quad 30A Relays" &&
                      this.state.scheduleMode === "Sun")) && (
                    <div>
                      <p className="basicParagraph" style={{ color: "red" }}>
                        Sunset & Sunrise Off Hours Weekly Schedule:
                      </p>

                      <table
                        className="table"
                        style={{
                          marginTop: "20px",
                          width: "350px",
                        }}
                      >
                        <th></th>
                        <th>S</th>
                        <th>M</th>
                        <th>T</th>
                        <th>W</th>
                        <th>T</th>
                        <th>F</th>
                        <th>S</th>
                        <th>H</th>
                        <tr>
                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              paddingTop: "20px",
                              fontSize: "10px",
                              color: "red",
                            }}
                          >
                            Daily Off Hours
                          </td>
                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              padding: "0px",
                              margin: "0px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <select
                              className="form-control2"
                              onChange={this.handleScheduleSun}
                              value={this.state.nodeScheduleSun}
                              style={{
                                float: "right",
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "0px",
                                width: "40px",
                                borderColor: "red",
                              }}
                            >
                              <option value="N">N: No Off Time</option>
                              <option value="G">
                                G: OFF: {this.state.startTimeG} -{" "}
                                {this.state.endTimeG}
                              </option>
                              <option value="H">
                                H: OFF: {this.state.startTimeH} -{" "}
                                {this.state.endTimeH}
                              </option>
                            </select>
                          </td>

                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              padding: "0px",
                              margin: "0px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <select
                              className="form-control2"
                              onChange={this.handleScheduleMon}
                              value={this.state.nodeScheduleMon}
                              style={{
                                float: "right",
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "0px",
                                width: "40px",
                                borderColor: "red",
                              }}
                            >
                              <option value="N">N: No Off Time</option>
                              <option value="G">
                                G: OFF: {this.state.startTimeG} -{" "}
                                {this.state.endTimeG}
                              </option>
                              <option value="H">
                                H: OFF: {this.state.startTimeH} -{" "}
                                {this.state.endTimeH}
                              </option>
                            </select>
                          </td>
                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              padding: "0px",
                              margin: "0px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <select
                              className="form-control2"
                              onChange={this.handleScheduleTue}
                              value={this.state.nodeScheduleTue}
                              style={{
                                float: "right",
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "0px",
                                width: "40px",
                                borderColor: "red",
                              }}
                            >
                              <option value="N">N: No Off Time</option>
                              <option value="G">
                                G: OFF: {this.state.startTimeG} -{" "}
                                {this.state.endTimeG}
                              </option>
                              <option value="H">
                                H: OFF: {this.state.startTimeH} -{" "}
                                {this.state.endTimeH}
                              </option>
                            </select>
                          </td>
                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              padding: "0px",
                              margin: "0px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <select
                              className="form-control2"
                              onChange={this.handleScheduleWed}
                              value={this.state.nodeScheduleWed}
                              style={{
                                float: "right",
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "0px",
                                width: "40px",
                                borderColor: "red",
                              }}
                            >
                              <option value="N">N: No Off Time</option>
                              <option value="G">
                                G: OFF: {this.state.startTimeG} -{" "}
                                {this.state.endTimeG}
                              </option>
                              <option value="H">
                                H: OFF: {this.state.startTimeH} -{" "}
                                {this.state.endTimeH}
                              </option>
                            </select>
                          </td>
                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              padding: "0px",
                              margin: "0px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <select
                              className="form-control2"
                              onChange={this.handleScheduleThu}
                              value={this.state.nodeScheduleThu}
                              style={{
                                float: "right",
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "0px",
                                width: "40px",
                                borderColor: "red",
                              }}
                            >
                              <option value="N">N: No Off Time</option>
                              <option value="G">
                                G: OFF: {this.state.startTimeG} -{" "}
                                {this.state.endTimeG}
                              </option>
                              <option value="H">
                                H: OFF: {this.state.startTimeH} -{" "}
                                {this.state.endTimeH}
                              </option>
                            </select>
                          </td>
                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              padding: "0px",
                              margin: "0px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <select
                              className="form-control2"
                              onChange={this.handleScheduleFri}
                              value={this.state.nodeScheduleFri}
                              style={{
                                float: "right",
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "0px",
                                width: "40px",
                                borderColor: "red",
                              }}
                            >
                              <option value="N">N: No Off Time</option>
                              <option value="G">
                                G: OFF: {this.state.startTimeG} -{" "}
                                {this.state.endTimeG}
                              </option>
                              <option value="H">
                                H: OFF: {this.state.startTimeH} -{" "}
                                {this.state.endTimeH}
                              </option>
                            </select>
                          </td>
                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              padding: "0px",
                              margin: "0px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <select
                              className="form-control2"
                              onChange={this.handleScheduleSat}
                              value={this.state.nodeScheduleSat}
                              style={{
                                float: "right",
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "0px",
                                width: "40px",
                                borderColor: "red",
                              }}
                            >
                              <option value="N">N: No Off Time</option>
                              <option value="G">
                                G: OFF: {this.state.startTimeG} -{" "}
                                {this.state.endTimeG}
                              </option>
                              <option value="H">
                                H: OFF: {this.state.startTimeH} -{" "}
                                {this.state.endTimeH}
                              </option>
                            </select>
                          </td>

                          <td
                            style={{
                              float: "center",
                              height: "30px",
                              width: "40px",
                              padding: "0px",
                              margin: "0px",
                              paddingTop: "20px",
                              fontSize: "10px",
                            }}
                          >
                            <select
                              className="form-control2"
                              onChange={this.handleScheduleHol}
                              value={this.state.nodeScheduleHol}
                              style={{
                                float: "right",
                                fontSize: "10px",
                                fontWeight: "bold",
                                padding: "0px",
                                width: "40px",
                                borderColor: "red",
                              }}
                            >
                              <option value="N">N: No Off Time</option>
                              <option value="G">
                                G: OFF: {this.state.startTimeG} -{" "}
                                {this.state.endTimeG}
                              </option>
                              <option value="H">
                                H: OFF: {this.state.startTimeH} -{" "}
                                {this.state.endTimeH}
                              </option>
                            </select>
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}
                  {this.state.loadType === "HVAC w SimplTherm" && (
                    <div>
                      {this.state.earlyStart === 1 && (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="registerReason"
                          id="flexCheckChecked"
                          checked
                          onChange={this.handleEarlyStart}
                          value="reason1"
                        ></input>
                      )}
                      {(this.state.earlyStart === 0 ||
                        this.state.earlyStart === undefined) && (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="registerReason"
                          id="flexCheckDefault"
                          onChange={this.handleEarlyStart}
                          value="reason1"
                        ></input>
                      )}
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "370px" }}
                      >
                        Check box to enable Optimal Start Time
                      </label>
                    </div>
                  )}

                  {this.state.loadType === "HVAC w SimplTherm" && (
                    <div>
                      {this.state.earlyStop === 1 && (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="registerReason"
                          id="flexCheckChecked"
                          checked
                          onChange={this.handleEarlyStop}
                          value="reason1"
                        ></input>
                      )}
                      {(this.state.earlyStop === 0 ||
                        this.state.earlyStop === undefined) && (
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="registerReason"
                          id="flexCheckDefault"
                          onChange={this.handleEarlyStop}
                          value="reason1"
                        ></input>
                      )}
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "370px" }}
                      >
                        Check box to enable Optimal Stop Time
                      </label>
                    </div>
                  )}

                  {(this.state.loadType === "HVAC Unit" ||
                    this.state.loadType === "HVAC w SimplTherm" ||
                    this.state.loadType === "Space Heater" ||
                    this.state.loadType === "Water Heater" ||
                    this.state.loadType === "Walk-in" ||
                    this.state.loadType === "SimplWalkIn" ||
                    this.state.loadType === "SimplHeater" ||
                    this.state.loadType === "Reach-in" ||
                    this.state.loadType === "Counter Reach-in") && (
                    <div>
                      <p className="basicParagraph">Temperature Schedule:</p>
                    </div>
                  )}
                  {(this.state.loadType === "HVAC Unit" ||
                    this.state.loadType === "HVAC w SimplTherm" ||
                    this.state.loadType === "Space Heater" ||
                    this.state.loadType === "Water Heater" ||
                    this.state.loadType === "Walk-in" ||
                    this.state.loadType === "SimplWalkIn" ||
                    this.state.loadType === "SimplHeater" ||
                    this.state.loadType === "Reach-in" ||
                    this.state.loadType === "Counter Reach-in") && (
                    <table
                      className="table"
                      style={{
                        marginTop: "20px",
                        width: "350px",
                      }}
                    >
                      <tr>
                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          ON Temp Range
                        </td>
                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            padding: "0px",
                            margin: "0px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          <select
                            className="form-control2"
                            onChange={this.handleOnTempSun}
                            value={this.state.nodeOnTempSun}
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "bold",
                              padding: "0px",
                              width: "40px",
                            }}
                          >
                            <option value="?">?</option>
                            <option value="A">
                              A: {this.state.lowTempA} - {this.state.hiTempA}
                            </option>
                            <option value="B">
                              B: {this.state.lowTempB} - {this.state.hiTempB}
                            </option>
                            <option value="C">
                              C: {this.state.lowTempC} - {this.state.hiTempC}
                            </option>
                            <option value="D">
                              D: {this.state.lowTempD} - {this.state.hiTempD}
                            </option>
                            <option value="E">
                              E: {this.state.lowTempE} - {this.state.hiTempE}
                            </option>
                            <option value="F">
                              F: {this.state.lowTempF} - {this.state.hiTempF}
                            </option>
                          </select>
                        </td>
                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            padding: "0px",
                            margin: "0px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          <select
                            className="form-control2"
                            onChange={this.handleOnTempMon}
                            value={this.state.nodeOnTempMon}
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "bold",
                              padding: "0px",
                              width: "40px",
                            }}
                          >
                            <option value="?">?</option>
                            <option value="A">
                              A: {this.state.lowTempA} - {this.state.hiTempA}
                            </option>
                            <option value="B">
                              B: {this.state.lowTempB} - {this.state.hiTempB}
                            </option>
                            <option value="C">
                              C: {this.state.lowTempC} - {this.state.hiTempC}
                            </option>
                            <option value="D">
                              D: {this.state.lowTempD} - {this.state.hiTempD}
                            </option>
                            <option value="E">
                              E: {this.state.lowTempE} - {this.state.hiTempE}
                            </option>
                            <option value="F">
                              F: {this.state.lowTempF} - {this.state.hiTempF}
                            </option>
                          </select>
                        </td>
                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            padding: "0px",
                            margin: "0px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          <select
                            className="form-control2"
                            onChange={this.handleOnTempTue}
                            value={this.state.nodeOnTempTue}
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "bold",
                              padding: "0px",
                              width: "40px",
                            }}
                          >
                            <option value="?">?</option>
                            <option value="A">
                              A: {this.state.lowTempA} - {this.state.hiTempA}
                            </option>
                            <option value="B">
                              B: {this.state.lowTempB} - {this.state.hiTempB}
                            </option>
                            <option value="C">
                              C: {this.state.lowTempC} - {this.state.hiTempC}
                            </option>
                            <option value="D">
                              D: {this.state.lowTempD} - {this.state.hiTempD}
                            </option>
                            <option value="E">
                              E: {this.state.lowTempE} - {this.state.hiTempE}
                            </option>
                            <option value="F">
                              F: {this.state.lowTempF} - {this.state.hiTempF}
                            </option>
                          </select>
                        </td>
                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            padding: "0px",
                            margin: "0px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          <select
                            className="form-control2"
                            onChange={this.handleOnTempWed}
                            value={this.state.nodeOnTempWed}
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "bold",
                              padding: "0px",
                              width: "40px",
                            }}
                          >
                            <option value="?">?</option>
                            <option value="A">
                              A: {this.state.lowTempA} - {this.state.hiTempA}
                            </option>
                            <option value="B">
                              B: {this.state.lowTempB} - {this.state.hiTempB}
                            </option>
                            <option value="C">
                              C: {this.state.lowTempC} - {this.state.hiTempC}
                            </option>
                            <option value="D">
                              D: {this.state.lowTempD} - {this.state.hiTempD}
                            </option>
                            <option value="E">
                              E: {this.state.lowTempE} - {this.state.hiTempE}
                            </option>
                            <option value="F">
                              F: {this.state.lowTempF} - {this.state.hiTempF}
                            </option>
                          </select>
                        </td>
                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            padding: "0px",
                            margin: "0px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          <select
                            className="form-control2"
                            onChange={this.handleOnTempThu}
                            value={this.state.nodeOnTempThu}
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "bold",
                              padding: "0px",
                              width: "40px",
                            }}
                          >
                            <option value="?">?</option>
                            <option value="A">
                              A: {this.state.lowTempA} - {this.state.hiTempA}
                            </option>
                            <option value="B">
                              B: {this.state.lowTempB} - {this.state.hiTempB}
                            </option>
                            <option value="C">
                              C: {this.state.lowTempC} - {this.state.hiTempC}
                            </option>
                            <option value="D">
                              D: {this.state.lowTempD} - {this.state.hiTempD}
                            </option>
                            <option value="E">
                              E: {this.state.lowTempE} - {this.state.hiTempE}
                            </option>
                            <option value="F">
                              F: {this.state.lowTempF} - {this.state.hiTempF}
                            </option>
                          </select>
                        </td>
                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            padding: "0px",
                            margin: "0px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          <select
                            className="form-control2"
                            onChange={this.handleOnTempFri}
                            value={this.state.nodeOnTempFri}
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "bold",
                              padding: "0px",
                              width: "40px",
                            }}
                          >
                            <option value="?">?</option>
                            <option value="A">
                              A: {this.state.lowTempA} - {this.state.hiTempA}
                            </option>
                            <option value="B">
                              B: {this.state.lowTempB} - {this.state.hiTempB}
                            </option>
                            <option value="C">
                              C: {this.state.lowTempC} - {this.state.hiTempC}
                            </option>
                            <option value="D">
                              D: {this.state.lowTempD} - {this.state.hiTempD}
                            </option>
                            <option value="E">
                              E: {this.state.lowTempE} - {this.state.hiTempE}
                            </option>
                            <option value="F">
                              F: {this.state.lowTempF} - {this.state.hiTempF}
                            </option>
                          </select>
                        </td>
                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            padding: "0px",
                            margin: "0px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          <select
                            className="form-control2"
                            onChange={this.handleOnTempSat}
                            value={this.state.nodeOnTempSat}
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "bold",
                              padding: "0px",
                              width: "40px",
                            }}
                          >
                            <option value="?">?</option>
                            <option value="A">
                              A: {this.state.lowTempA} - {this.state.hiTempA}
                            </option>
                            <option value="B">
                              B: {this.state.lowTempB} - {this.state.hiTempB}
                            </option>
                            <option value="C">
                              C: {this.state.lowTempC} - {this.state.hiTempC}
                            </option>
                            <option value="D">
                              D: {this.state.lowTempD} - {this.state.hiTempD}
                            </option>
                            <option value="E">
                              E: {this.state.lowTempE} - {this.state.hiTempE}
                            </option>
                            <option value="F">
                              F: {this.state.lowTempF} - {this.state.hiTempF}
                            </option>
                          </select>
                        </td>

                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            padding: "0px",
                            margin: "0px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          <select
                            className="form-control2"
                            onChange={this.handleOnTempHol}
                            value={this.state.nodeOnTempHol}
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "bold",
                              padding: "0px",
                              width: "40px",
                            }}
                          >
                            <option value="?">?</option>
                            <option value="A">
                              A: {this.state.lowTempA} - {this.state.hiTempA}
                            </option>
                            <option value="B">
                              B: {this.state.lowTempB} - {this.state.hiTempB}
                            </option>
                            <option value="C">
                              C: {this.state.lowTempC} - {this.state.hiTempC}
                            </option>
                            <option value="D">
                              D: {this.state.lowTempD} - {this.state.hiTempD}
                            </option>
                            <option value="E">
                              E: {this.state.lowTempE} - {this.state.hiTempE}
                            </option>
                            <option value="F">
                              F: {this.state.lowTempF} - {this.state.hiTempF}
                            </option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          OFF Temp Range
                        </td>
                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            padding: "0px",
                            margin: "0px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          <select
                            className="form-control2"
                            onChange={this.handleOffTempSun}
                            value={this.state.nodeOffTempSun}
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "bold",
                              padding: "0px",
                              width: "40px",
                            }}
                          >
                            <option value="?">?</option>
                            <option value="A">
                              A: {this.state.lowTempA} - {this.state.hiTempA}
                            </option>
                            <option value="B">
                              B: {this.state.lowTempB} - {this.state.hiTempB}
                            </option>
                            <option value="C">
                              C: {this.state.lowTempC} - {this.state.hiTempC}
                            </option>
                            <option value="D">
                              D: {this.state.lowTempD} - {this.state.hiTempD}
                            </option>
                            <option value="E">
                              E: {this.state.lowTempE} - {this.state.hiTempE}
                            </option>
                            <option value="F">
                              F: {this.state.lowTempF} - {this.state.hiTempF}
                            </option>
                          </select>
                        </td>
                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            padding: "0px",
                            margin: "0px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          <select
                            className="form-control2"
                            onChange={this.handleOffTempMon}
                            value={this.state.nodeOffTempMon}
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "bold",
                              padding: "0px",
                              width: "40px",
                            }}
                          >
                            <option value="?">?</option>
                            <option value="A">
                              A: {this.state.lowTempA} - {this.state.hiTempA}
                            </option>
                            <option value="B">
                              B: {this.state.lowTempB} - {this.state.hiTempB}
                            </option>
                            <option value="C">
                              C: {this.state.lowTempC} - {this.state.hiTempC}
                            </option>
                            <option value="D">
                              D: {this.state.lowTempD} - {this.state.hiTempD}
                            </option>
                            <option value="E">
                              E: {this.state.lowTempE} - {this.state.hiTempE}
                            </option>
                            <option value="F">
                              F: {this.state.lowTempF} - {this.state.hiTempF}
                            </option>
                          </select>
                        </td>
                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            padding: "0px",
                            margin: "0px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          <select
                            className="form-control2"
                            onChange={this.handleOffTempTue}
                            value={this.state.nodeOffTempTue}
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "bold",
                              padding: "0px",
                              width: "40px",
                            }}
                          >
                            <option value="?">?</option>
                            <option value="A">
                              A: {this.state.lowTempA} - {this.state.hiTempA}
                            </option>
                            <option value="B">
                              B: {this.state.lowTempB} - {this.state.hiTempB}
                            </option>
                            <option value="C">
                              C: {this.state.lowTempC} - {this.state.hiTempC}
                            </option>
                            <option value="D">
                              D: {this.state.lowTempD} - {this.state.hiTempD}
                            </option>
                            <option value="E">
                              E: {this.state.lowTempE} - {this.state.hiTempE}
                            </option>
                            <option value="F">
                              F: {this.state.lowTempF} - {this.state.hiTempF}
                            </option>
                          </select>
                        </td>
                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            padding: "0px",
                            margin: "0px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          <select
                            className="form-control2"
                            onChange={this.handleOffTempWed}
                            value={this.state.nodeOffTempWed}
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "bold",
                              padding: "0px",
                              width: "40px",
                            }}
                          >
                            <option value="?">?</option>
                            <option value="A">
                              A: {this.state.lowTempA} - {this.state.hiTempA}
                            </option>
                            <option value="B">
                              B: {this.state.lowTempB} - {this.state.hiTempB}
                            </option>
                            <option value="C">
                              C: {this.state.lowTempC} - {this.state.hiTempC}
                            </option>
                            <option value="D">
                              D: {this.state.lowTempD} - {this.state.hiTempD}
                            </option>
                            <option value="E">
                              E: {this.state.lowTempE} - {this.state.hiTempE}
                            </option>
                            <option value="F">
                              F: {this.state.lowTempF} - {this.state.hiTempF}
                            </option>
                          </select>
                        </td>
                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            padding: "0px",
                            margin: "0px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          <select
                            className="form-control2"
                            onChange={this.handleOffTempThu}
                            value={this.state.nodeOffTempThu}
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "bold",
                              padding: "0px",
                              width: "40px",
                            }}
                          >
                            <option value="?">?</option>
                            <option value="A">
                              A: {this.state.lowTempA} - {this.state.hiTempA}
                            </option>
                            <option value="B">
                              B: {this.state.lowTempB} - {this.state.hiTempB}
                            </option>
                            <option value="C">
                              C: {this.state.lowTempC} - {this.state.hiTempC}
                            </option>
                            <option value="D">
                              D: {this.state.lowTempD} - {this.state.hiTempD}
                            </option>
                            <option value="E">
                              E: {this.state.lowTempE} - {this.state.hiTempE}
                            </option>
                            <option value="F">
                              F: {this.state.lowTempF} - {this.state.hiTempF}
                            </option>
                          </select>
                        </td>
                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            padding: "0px",
                            margin: "0px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          <select
                            className="form-control2"
                            onChange={this.handleOffTempFri}
                            value={this.state.nodeOffTempFri}
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "bold",
                              padding: "0px",
                              width: "40px",
                            }}
                          >
                            <option value="?">?</option>
                            <option value="A">
                              A: {this.state.lowTempA} - {this.state.hiTempA}
                            </option>
                            <option value="B">
                              B: {this.state.lowTempB} - {this.state.hiTempB}
                            </option>
                            <option value="C">
                              C: {this.state.lowTempC} - {this.state.hiTempC}
                            </option>
                            <option value="D">
                              D: {this.state.lowTempD} - {this.state.hiTempD}
                            </option>
                            <option value="E">
                              E: {this.state.lowTempE} - {this.state.hiTempE}
                            </option>
                            <option value="F">
                              F: {this.state.lowTempF} - {this.state.hiTempF}
                            </option>
                          </select>
                        </td>
                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            padding: "0px",
                            margin: "0px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          <select
                            className="form-control2"
                            onChange={this.handleOffTempSat}
                            value={this.state.nodeOffTempSat}
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "bold",
                              padding: "0px",
                              width: "40px",
                            }}
                          >
                            <option value="?">?</option>
                            <option value="A">
                              A: {this.state.lowTempA} - {this.state.hiTempA}
                            </option>
                            <option value="B">
                              B: {this.state.lowTempB} - {this.state.hiTempB}
                            </option>
                            <option value="C">
                              C: {this.state.lowTempC} - {this.state.hiTempC}
                            </option>
                            <option value="D">
                              D: {this.state.lowTempD} - {this.state.hiTempD}
                            </option>
                            <option value="E">
                              E: {this.state.lowTempE} - {this.state.hiTempE}
                            </option>
                            <option value="F">
                              F: {this.state.lowTempF} - {this.state.hiTempF}
                            </option>
                          </select>
                        </td>

                        <td
                          style={{
                            float: "center",
                            height: "30px",
                            width: "40px",
                            padding: "0px",
                            margin: "0px",
                            paddingTop: "20px",
                            fontSize: "10px",
                          }}
                        >
                          <select
                            className="form-control2"
                            onChange={this.handleOffTempHol}
                            value={this.state.nodeOffTempHol}
                            style={{
                              float: "right",
                              fontSize: "10px",
                              fontWeight: "bold",
                              padding: "0px",
                              width: "40px",
                            }}
                          >
                            <option value="?">?</option>
                            <option value="A">
                              A: {this.state.lowTempA} - {this.state.hiTempA}
                            </option>
                            <option value="B">
                              B: {this.state.lowTempB} - {this.state.hiTempB}
                            </option>
                            <option value="C">
                              C: {this.state.lowTempC} - {this.state.hiTempC}
                            </option>
                            <option value="D">
                              D: {this.state.lowTempD} - {this.state.hiTempD}
                            </option>
                            <option value="E">
                              E: {this.state.lowTempE} - {this.state.hiTempE}
                            </option>
                            <option value="F">
                              F: {this.state.lowTempF} - {this.state.hiTempF}
                            </option>
                          </select>
                        </td>
                      </tr>
                    </table>
                  )}
                  {(this.state.loadType === "HVAC Unit" ||
                    this.state.loadType === "Space Heater" ||
                    this.state.loadType === "Water Heater" ||
                    this.state.loadType === "Walk-in" ||
                    this.state.loadType === "SimplWalkIn" ||
                    this.state.loadType === "SimplHeater" ||
                    this.state.loadType === "Reach-in" ||
                    this.state.loadType === "Counter Reach-in" ||
                    this.state.loadType === "Ambient Temp Sensor" ||
                    this.state.loadType === "External Temp Sensor" ||
                    this.state.loadType === "Lighting" ||
                    this.state.loadType === "Light Sensor") && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Update Rate (sec):
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handleUpdateRate}
                        value={this.state.nodeUpdateRate}
                        style={{
                          float: "right",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        <option value="Choose here">Choose here</option>
                        <option value="15">15</option>
                        <option value="60">60</option>
                        <option value="300">300</option>
                        <option value="900">900</option>
                        <option value="1800">1800</option>
                      </select>
                    </div>
                  )}
                  {(this.state.loadType === "HVAC Unit" ||
                    this.state.loadType === "HVAC w SimplTherm" ||
                    this.state.loadType === "Space Heater" ||
                    this.state.loadType === "Water Heater" ||
                    this.state.loadType === "Water Heater No Sensor" ||
                    this.state.loadType === "Anti-sweat Heaters" ||
                    this.state.loadType === "EV Charger" ||
                    this.state.loadType === "Walk-in" ||
                    this.state.loadType === "SimplWalkIn" ||
                    this.state.loadType === "SimplHeater" ||
                    this.state.loadType === "Reach-in" ||
                    this.state.loadType === "Counter Reach-in" ||
                    this.state.loadType === "Lighting" ||
                    this.state.loadType === "Misc. Sheddable") && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Priority (1-7):
                      </label>
                      <select
                        className="form-control2"
                        onChange={this.handlePriority}
                        value={this.state.nodePriority}
                        style={{
                          float: "right",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        <option value="Choose here">Choose here</option>
                        <option value="1">First to go: 1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">Last to go: 7</option>
                        <option value="9">Non-Sheddable</option>
                      </select>

                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Nominal Power Rating (kW):
                      </label>
                      <input
                        value={this.state.nodePowerRating}
                        onChange={this.handlePowerRating}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                        }}
                      />
                    </div>
                  )}
                  {(this.state.loadType === "HVAC Unit" ||
                    this.state.loadType === "HVAC w SimplTherm" ||
                    this.state.loadType === "Walk-in" ||
                    this.state.loadType === "SimplWalkIn" ||
                    this.state.loadType === "SimplHeater" ||
                    this.state.loadType === "Reach-in" ||
                    this.state.loadType === "Counter Reach-in" ||
                    this.state.loadType === "Lighting" ||
                    this.state.loadType === "Heat Lamp" ||
                    this.state.loadType === "Space Heater" ||
                    this.state.loadType === "Water Heater" ||
                    this.state.loadType === "Water Heater No Sensor" ||
                    this.state.loadType === "Anti-sweat Heaters" ||
                    this.state.loadType === "EV Charger" ||
                    this.state.loadType === "Oven" ||
                    this.state.loadType === "Misc. Appliance" ||
                    this.state.loadType === "Misc. Sheddable") && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Min Allowed Off Time (Secs): Dflt: 15 Secs.
                      </label>
                      <input
                        value={this.state.nodeMinOffTime}
                        onChange={this.handleMinOffTime}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                        }}
                      />{" "}
                    </div>
                  )}

                  {(this.state.loadType === "HVAC Unit" ||
                    this.state.loadType === "HVAC w SimplTherm" ||
                    this.state.loadType === "Walk-in" ||
                    this.state.loadType === "SimplWalkIn" ||
                    this.state.loadType === "SimplHeater" ||
                    this.state.loadType === "Reach-in" ||
                    this.state.loadType === "Counter Reach-in" ||
                    this.state.loadType === "Lighting" ||
                    this.state.loadType === "Heat Lamp" ||
                    this.state.loadType === "Space Heater" ||
                    this.state.loadType === "Water Heater" ||
                    this.state.loadType === "Water Heater No Sensor" ||
                    this.state.loadType === "Anti-sweat Heaters" ||
                    this.state.loadType === "EV Charger" ||
                    this.state.loadType === "Oven" ||
                    this.state.loadType === "Misc. Appliance" ||
                    this.state.loadType === "Misc. Sheddable") && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Max Allowed Off Time (mins): Dflt: 10 hrs.
                      </label>
                      <input
                        value={this.state.nodeMaxOffTime}
                        onChange={this.handleMaxOffTime}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                        }}
                      />{" "}
                    </div>
                  )}
                  {(this.state.loadType === "HVAC Unit" ||
                    this.state.loadType === "HVAC w SimplTherm" ||
                    this.state.loadType === "Walk-in" ||
                    this.state.loadType === "SimplWalkIn" ||
                    this.state.loadType === "SimplHeater" ||
                    this.state.loadType === "Reach-in" ||
                    this.state.loadType === "Counter Reach-in" ||
                    this.state.loadType === "Lighting" ||
                    this.state.loadType === "Heat Lamp" ||
                    this.state.loadType === "Space Heater" ||
                    this.state.loadType === "Water Heater" ||
                    this.state.loadType === "Water Heater No Sensor" ||
                    this.state.loadType === "Anti-sweat Heaters" ||
                    this.state.loadType === "EV Charger" ||
                    this.state.loadType === "Oven" ||
                    this.state.loadType === "Misc. Appliance" ||
                    this.state.loadType === "Misc. Sheddable") && (
                    <div>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Required Min On Time (Secs): Dflt: 15 Secs.
                      </label>
                      <input
                        value={this.state.nodeMinOnTime}
                        onChange={this.handleMinOnTime}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                        }}
                      />{" "}
                    </div>
                  )}
                  {/********************************************************************************************** */}

                  {this.state.loadType === "HVAC w SimplTherm" && (
                    <div style={{ marginTop: "20px", paddingTop: "20px" }}>
                      <p className="basicParagraph">Health Parameters:</p>
                      <hr></hr>
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Minutes On Prior to Health: Dflt: 1 min.
                      </label>
                      <input
                        value={this.state.nodeMinToHealth}
                        onChange={this.handleMinToHealth}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                        }}
                      />{" "}
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Bad Health Temp Differential *F: 5.0
                      </label>
                      <input
                        value={this.state.nodeBadTemp}
                        onChange={this.handleMinBadTemp}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                        }}
                      />{" "}
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Poor Temp Differential *F: 10.0
                      </label>
                      <input
                        value={this.state.nodePoorTemp}
                        onChange={this.handleMinPoorTemp}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                        }}
                      />{" "}
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Good Temp Differential *F: 15.0
                      </label>
                      <input
                        value={this.state.nodeGoodTemp}
                        onChange={this.handleMinGoodTemp}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                        }}
                      />{" "}
                    </div>
                  )}
                  {/********************************************************************************************** */}

                  <button
                    className="basicButton"
                    style={{
                      width: "200px",
                      transform: "translateX(65px)",
                      marginTop: "40px",
                    }}
                  >
                    Save Unit
                  </button>
                </div>
              )}
            </form>
          )}

          {this.state.systemControls !== undefined && (
            <div>
              {!this.state.nodenamePassed ? (
                <>
                  <div
                    className="systemClock"
                    style={{
                      height: "25px",
                      width: "400px",
                      transform: "translateX(-200px)",
                    }}
                  >
                    {/* <button
                      className="btn btn-primary shadow-none"
                      style={{
                        float: "right",
                        padding: "0px",
                        borderWidth: "0px",
                        borderColor: "tranparent",
                        marginRight: "12px",
                        marginTop: "-7px",
                        color: "transparent",
                        backgroundColor: "transparent",
                      }}
                      onClick={this.handleEdit}
                    >
                      <FontAwesomeIcon
                        icon="edit"
                        size="sm"
                        color="#ffc000"
                        title="Edit Node Parameters"
                        borderColor="transparent"
                      />
                    </button>*/}
                    <button
                      className="btn btn-primary shadow-none"
                      style={{
                        padding: "0px",
                        borderWidth: "0px",
                        marginRight: "12px",
                        marginTop: "-7px",
                        transform: "translate(0px, -2px)",
                        color: "transparent",
                        backgroundColor: "transparent",
                      }}
                      onClick={this.handleAdd}
                    >
                      <FontAwesomeIcon
                        icon="plus-circle"
                        size="2x"
                        color="#ffc000"
                        title="Add a New Node"
                      />
                    </button>
                    {/* <button
                      className="btn btn-primary shadow-none"
                      style={{
                        float: "left",
                        padding: "0px",
                        borderWidth: "0px",
                        marginLeft: "12px",
                        marginTop: "-7px",
                        color: "transparent",
                        backgroundColor: "transparent",
                      }}
                      onClick={this.handleDelete}
                    >
                      <FontAwesomeIcon
                        icon="trash-alt"
                        size="sm"
                        color="#ffc000"
                        title="Delete Node"
                      />
                    </button>*/}
                  </div>

                  {/* <button
                    className="btn btn-primary shadow-none"
                    style={{
                      float: "right",
                      borderWidth: "0px",
                      color: "transparent",
                      backgroundColor: "transparent",
                    }}
                    onClick={this.handleSaveFleet}
                  >
                    <FontAwesomeIcon
                      icon="save"
                      size="lg"
                      color="#777575"
                      title="Save as a Fleet"
                    />
                  </button>
                  <button
                    className="btn btn-primary shadow-none"
                    style={{
                      borderWidth: "0px",
                      transform: "translate(250px, -2px)",
                      color: "transparent",
                      backgroundColor: "transparent",
                    }}
                    onClick={this.handleCopyFleet}
                  >
                    <FontAwesomeIcon
                      icon="copy"
                      size="lg"
                      color="#777575"
                      title="Copy Fleet"
                    />
                  </button> */}
                  {/*------------------------------------------------------------------------------------*/}
                  <ExcelFile
                    filename="Simpl_Controls"
                    element={
                      <button
                        className="btn btn-primary shadow-none"
                        style={{
                          float: "left",
                          borderWidth: "0px",
                          color: "transparent",
                          backgroundColor: "transparent",
                        }}
                        onClick={this.handleExcelOutput}
                      >
                        <FontAwesomeIcon
                          icon="file-excel"
                          size="lg"
                          color="#777575"
                          title="Export System Data to Excel"
                        />
                      </button>
                    }
                  >
                    <ExcelSheet
                      data={this.state.excelDataSet}
                      name="System_Controls"
                    >
                      <ExcelColumn label="Index" value="Index" />
                      <ExcelColumn label="Type" value="Type" />
                      <ExcelColumn label="Name" value="Name" />
                      <ExcelColumn label="Desription" value="Description" />
                      <ExcelColumn label="Sensor Serial#" value="Sensor_SN" />
                      <ExcelColumn label="Sensor MAC" value="Sensor_MAC" />
                      <ExcelColumn
                        label="Door Sensor Serial#"
                        value="DoorSensor_SN"
                      />
                      <ExcelColumn
                        label="Door Sensor MAC"
                        value="DoorSensor_MAC"
                      />
                      <ExcelColumn
                        label="Thermostat Serial#"
                        value="Thermostat_SN"
                      />
                      <ExcelColumn
                        label="Thermostat MAC"
                        value="Thermostat_MAC"
                      />
                      <ExcelColumn
                        label="Thermostat Sensor1"
                        value="Thermostat_Sensor1"
                      />
                      <ExcelColumn
                        label="Thermostat Sensor2"
                        value="Thermostat_Sensor2"
                      />
                      <ExcelColumn
                        label="Thermostat Sensor3"
                        value="Thermostat_Sensor3"
                      />
                      <ExcelColumn
                        label="Thermostat Sensor4"
                        value="Thermostat_Sensor4"
                      />
                      <ExcelColumn
                        label="Heating Thermostat Temp Setting"
                        value="TsetHeat"
                      />
                      <ExcelColumn
                        label="Cooling Thermostat Temp Setting"
                        value="TsetCool"
                      />
                      <ExcelColumn
                        label="Delta T Return and Room"
                        value="TDeltaSet"
                      />
                      <ExcelColumn
                        label="Thermostat RH Setting"
                        value="RHset"
                      />
                      <ExcelColumn
                        label="Thermostat Fan Setting"
                        value="FanSetting"
                      />
                      <ExcelColumn
                        label="Thermostat HVAC Type"
                        value="nodeHvacType"
                      />
                      <ExcelColumn
                        label="Update Rate (sec)"
                        value="nodeUpdateRate"
                      />
                      <ExcelColumn label="Priority" value="Priority" />
                      <ExcelColumn label="Rated Power" value="Power_Rating" />
                      <ExcelColumn label="Min Off Time" value="Min_Off_Time" />
                      <ExcelColumn label="Max Off Time" value="Max_Off_Time" />
                      <ExcelColumn label="Min On Time" value="Min_On_Time" />
                      <ExcelColumn label="Relay Serial#" value="Relay_SN" />
                      <ExcelColumn label="Relay MAC" value="Relay_MAC" />
                      <ExcelColumn
                        label="Stage 1 Cooling Relay#"
                        value="stage1_Cooling_Relay"
                      />
                      <ExcelColumn
                        label="Stage 2 Cooling Relay#"
                        value="stage2_Cooling_Relay"
                      />
                      <ExcelColumn
                        label="Stage 1 Heating Relay#"
                        value="stage1_Heating_Relay"
                      />
                      <ExcelColumn
                        label="Stage 2 Heating Relay#"
                        value="stage2_Heating_Relay"
                      />
                      <ExcelColumn
                        label="Daily Schedule and Temp. Range"
                        value="hourAndTemps"
                      />
                      <ExcelColumn label="Fleet Control" value="fleetControl" />
                      <ExcelColumn label="Over-Ride" value="overRide" />
                      <ExcelColumn label="Fossil Heat" value="fossilHeat" />
                      <ExcelColumn label=" OB Active" value=" OBActive" />
                      <ExcelColumn label=" Rth Only" value=" rthOnly" />
                      <ExcelColumn label="Aux. Heat" value="auxHeat" />
                      <ExcelColumn label="Early Start" value="earlyStart" />
                      <ExcelColumn label="Early Stop" value="earlyStop" />
                      <ExcelColumn
                        label="Duct-Mount Return"
                        value="ductMountReturn"
                      />
                      <ExcelColumn label="X-Position" value="nodePositionX" />
                      <ExcelColumn label="Y-Position" value="nodePositionY" />
                    </ExcelSheet>
                  </ExcelFile>
                  {/*------------------------------------------------------------------------------------*/}
                  {this.state.saveFleet && (
                    <form
                      onSubmit={this.handleFleets}
                      style={{ marginTop: "50px", marginBottom: "15px" }}
                    >
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Enter Fleet Name:
                      </label>
                      <input
                        value={this.state.fleetName}
                        onChange={this.handleFleetName}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                        }}
                      />
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Description:
                      </label>
                      <input
                        value={this.state.fleetDescription}
                        onChange={this.handleFleetDescription}
                        id="serialNumber"
                        type="text"
                        className="form-control2"
                        style={{
                          float: "right",
                          height: "30px",
                          width: "200px",
                        }}
                      />
                      <button
                        className="basicButton"
                        style={{
                          width: "150px",
                          transform: "translateX(75px)",
                          marginTop: "10px",
                        }}
                      >
                        Save Fleet
                      </button>
                    </form>
                  )}
                  {this.state.copyFleet && (
                    <form
                      onSubmit={this.copyFleet}
                      style={{ marginTop: "50px", marginBottom: "15px" }}
                    >
                      <label
                        className="basicParagraph"
                        style={{ height: "30px", width: "150px" }}
                      >
                        Select Fleet:
                      </label>
                      <select
                        name="WTF3"
                        onChange={this.handleSelectCopyFleet}
                        className="form-control2"
                        value={this.state.copyFleetName}
                        id="WTF2"
                      >
                        <option value="Select A Fleet" />
                        {this.state.fleetOptions.map((option) => (
                          <option key={option._id} value={option.fleetName}>
                            {option.fleetName}
                          </option>
                        ))}
                      </select>
                      <button
                        className="basicButton"
                        style={{
                          width: "150px",
                          transform: "translateX(75px)",
                          marginTop: "10px",
                        }}
                      >
                        Copy Fleet
                      </button>
                    </form>
                  )}

                  <div>
                    {this.props.match.params.displayWidth === "Narrow" && (
                      <table
                        className="table"
                        style={{
                          marginTop: "20px",
                          width: "350px",
                          transform: "translateX(20px)",
                        }}
                      >
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Edit
                        </th>
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Node Name
                        </th>
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Type
                        </th>

                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "30px",
                          }}
                        >
                          Serial #
                        </th>
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Priority
                        </th>
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Delete
                        </th>

                        {this.state.systemControls &&
                          this.state.systemControlsLength &&
                          this.state.systemControls.map((node) => (
                            <tr key={node.id}>
                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  width: "10px",
                                  height: "25px",
                                }}
                              >
                                {" "}
                                <button
                                  className="btn btn-primary shadow-none"
                                  style={{
                                    padding: "0px",
                                    borderWidth: "0px",
                                    borderColor: "tranparent",
                                    marginRight: "0px",
                                    marginTop: "0px",
                                    color: "transparent",
                                    backgroundColor: "transparent",
                                    height: "15px",
                                    paddingBottom: "5px",
                                  }}
                                  onClick={() =>
                                    this.handleEdit(`${node.nodeName}`)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="edit"
                                    size="sm"
                                    color="#ffc000"
                                    title="Edit Node Parameters"
                                    borderColor="transparent"
                                  />
                                </button>
                              </td>
                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  width: "70px",
                                  height: "25px",
                                  paddingTop: "5px",
                                }}
                              >
                                {node.nodeName}
                              </td>
                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  height: "25px",
                                  paddingTop: "5px",
                                }}
                              >
                                {node.loadType}
                              </td>

                              {node.loadType === "HVAC w SimplTherm" && (
                                <td
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    height: "25px",
                                    paddingTop: "5px",
                                  }}
                                >
                                  {node.nodeThermostatSN}
                                </td>
                              )}
                              {node.loadType === "SimplMeter" && (
                                <td
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    height: "25px",
                                    paddingTop: "5px",
                                  }}
                                >
                                  {node.nodeSM2SN}
                                </td>
                              )}
                              {(node.loadType === "Ambient Temp Sensor" ||
                                node.loadType === "Avg. Ambient Temp" ||
                                node.loadType === "External Temp Sensor" ||
                                node.loadType === "Light Sensor") && (
                                <td
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    height: "25px",
                                    paddingTop: "5px",
                                  }}
                                >
                                  {node.nodeSensorSN}
                                </td>
                              )}
                              {node.loadType === "Door Monitor" && (
                                <td
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    height: "25px",
                                    paddingTop: "5px",
                                  }}
                                >
                                  {node.nodeDoorSensorSN}
                                </td>
                              )}
                              {(node.loadType === "HVAC Unit" ||
                                node.loadType === "Relay Driver" ||
                                node.loadType ===
                                  "Relay Driver w Light Sensor" ||
                                node.loadType === "Quad 30A Relays" ||
                                node.loadType === "Quad 30A w Light Sensor" ||
                                node.loadType === "Pole Lights" ||
                                node.loadType === "Outdoor Sensed Lights" ||
                                node.loadType === "Indoor Lights" ||
                                node.loadType === "Heat Lamp" ||
                                node.loadType === "Space Heater" ||
                                node.loadType === "Water Heater" ||
                                node.loadType === "Water Heater No Sensor" ||
                                node.loadType === "SimplWalkIn" ||
                                node.loadType === "SimplHeater" ||
                                node.loadType === "Reach-in" ||
                                node.loadType === "Counter Reach-in" ||
                                node.loadType === "Anti-sweat Heaters" ||
                                node.loadType === "Oven" ||
                                node.loadType === "Hood" ||
                                node.loadType === "Misc. Appliance" ||
                                node.loadType === "Misc. Sheddable") && (
                                <td
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    height: "25px",
                                    paddingTop: "5px",
                                  }}
                                >
                                  {node.nodeRelaySN}
                                </td>
                              )}
                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  height: "25px",
                                  paddingTop: "5px",
                                }}
                              >
                                {node.nodePriority}
                              </td>
                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  height: "25px",
                                }}
                              >
                                <button
                                  className="btn btn-primary shadow-none"
                                  style={{
                                    padding: "0px",
                                    borderWidth: "0px",
                                    marginLeft: "0px",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    color: "transparent",
                                    backgroundColor: "transparent",
                                    height: "25px",
                                    paddingBottom: "5px",
                                  }}
                                  onClick={() =>
                                    this.handleDelete(`${node.nodeName}`)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="trash-alt"
                                    size="sm"
                                    color="red"
                                    title="Delete Node"
                                  />
                                </button>
                              </td>
                            </tr>
                          ))}
                      </table>
                    )}
                    {/************************************************************************* */}
                    {/**************************************************************************/}
                    {this.props.match.params.displayWidth !== "Narrow" && (
                      <table
                        className="table"
                        style={{
                          marginTop: "20px",
                          width: "850px",
                          transform: "translateX(-245px)",
                        }}
                      >
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Edit
                        </th>
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Node Name
                        </th>
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Load type
                        </th>

                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          kW Rating
                        </th>
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Priority
                        </th>
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Sensor SN
                        </th>
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Thermostat SN
                        </th>
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Sensor Update Rate
                        </th>
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Door Sensor SN
                        </th>
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Main Serial #
                        </th>
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Relay #
                        </th>
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Override
                        </th>
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Fleet Control
                        </th>
                        <th
                          style={{
                            margin: "0px",
                            padding: "0px",
                            height: "15px",
                          }}
                        >
                          Delete
                        </th>

                        {this.state.systemControls &&
                          this.state.systemControlsLength &&
                          this.state.systemControls.map((node) => (
                            <tr key={node.id}>
                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  width: "10px",
                                  height: "15px",
                                }}
                              >
                                {" "}
                                <button
                                  className="btn btn-primary shadow-none"
                                  style={{
                                    padding: "0px",
                                    borderWidth: "0px",
                                    borderColor: "tranparent",
                                    marginRight: "0px",
                                    marginTop: "0px",
                                    color: "transparent",
                                    backgroundColor: "transparent",
                                    height: "15px",
                                  }}
                                  onClick={() =>
                                    this.handleEdit(`${node.nodeName}`)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="edit"
                                    size="sm"
                                    color="#ffc000"
                                    title="Edit Node Parameters"
                                    borderColor="transparent"
                                  />
                                </button>
                                {/**********************************************************************
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="registerReason"
                                  id="registerReason1"
                                  onClick={() =>
                                    this.handleChangeRadio(`${node.nodeName}`)
                                  }
                                  // value={node.id} //"reason1"
                                  style={{
                                    display: "table-cell",
                                    verticalAlign: "middle",
                                    transform: "translate(23px,0px)",
                                  }}
                                  //checked
                                ></input>
                                **************************************************************************/}
                              </td>

                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  width: "70px",
                                  verticalAlign: "middle",
                                  height: "25px",
                                }}
                              >
                                {node.nodeName}
                              </td>
                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  verticalAlign: "middle",
                                  height: "25px",
                                }}
                              >
                                {node.loadType}
                              </td>

                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  verticalAlign: "middle",
                                  height: "25px",
                                }}
                              >
                                {node.nodePowerRating}
                              </td>
                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  verticalAlign: "middle",
                                  height: "25px",
                                }}
                              >
                                {node.nodePriority}
                              </td>
                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  verticalAlign: "middle",
                                  height: "15px",
                                }}
                              >
                                {node.nodeSensorSN}
                              </td>
                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  verticalAlign: "middle",
                                  height: "15px",
                                }}
                              >
                                {node.nodeThermostatSN}
                              </td>
                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  verticalAlign: "middle",
                                  height: "15px",
                                }}
                              >
                                {node.nodeUpdateRate}
                              </td>
                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  verticalAlign: "middle",
                                  height: "15px",
                                }}
                              >
                                {node.nodeDoorSensorSN}
                              </td>

                              {node.loadType !== "SimplMeter" && (
                                <td
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    verticalAlign: "middle",
                                    height: "15px",
                                  }}
                                >
                                  {node.nodeRelaySN}
                                </td>
                              )}
                              {node.loadType === "SimplMeter" && (
                                <td
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    verticalAlign: "middle",
                                    height: "15px",
                                  }}
                                >
                                  {node.nodeSM2SN}
                                </td>
                              )}

                              {node.loadType !== "SimplMeter" && (
                                <td
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    verticalAlign: "middle",
                                    height: "15px",
                                  }}
                                >
                                  {node.stage1Cooling}
                                </td>
                              )}
                              {node.loadType === "SimplMeter" && (
                                <td
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    verticalAlign: "middle",
                                    height: "15px",
                                  }}
                                >
                                  {node.meterNum}
                                </td>
                              )}
                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  verticalAlign: "middle",
                                  height: "15px",
                                }}
                              >
                                {node.overRide}
                              </td>
                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  verticalAlign: "middle",
                                  height: "15px",
                                }}
                              >
                                {node.fleetControl}
                              </td>
                              <td
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  verticalAlign: "middle",
                                  height: "15px",
                                }}
                              >
                                <button
                                  className="btn btn-primary shadow-none"
                                  style={{
                                    padding: "0px",
                                    borderWidth: "0px",
                                    marginLeft: "0px",
                                    marginTop: "0px",
                                    marginBottom: "5px",
                                    color: "transparent",
                                    backgroundColor: "transparent",
                                    height: "15px",
                                  }}
                                  onClick={() =>
                                    this.handleDelete(`${node.nodeName}`)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="trash-alt"
                                    size="sm"
                                    color="red"
                                    title="Delete Node"
                                  />
                                </button>
                              </td>
                            </tr>
                          ))}
                      </table>
                    )}
                    {/************************************************************************* */}
                  </div>
                </>
              ) : (
                // <div>
                //   <button
                //     // className="btn btn-primary shadow-none"
                //     className="btn-primary basicButton"
                //     style={{
                //       width: "200px",
                //       transform: "translateX(65px)",
                //       marginTop: "5px",
                //     }}
                //     onClick={() => {
                //       // let nodenamePassed = this.state.nodenamePassed;

                //       this.props.history.push(
                //         `/systems/simplEquipment/${this.state.handleId}`
                //       );
                //     }}
                //   >
                //     Edit Equipment
                //   </button>
                // </div>
                <div></div>
              )}
              {/************************************************************************* */}
            </div>
          )}
        </div>

        {/*<BackGroundRectangle />*/}
      </React.Fragment>
    );
  }
}
export default SimplNodesControls;

class BackUpModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            padding: "0px",
            float: "left",
            marginRight: "10px",
            position: "relative",
            left: "10px",
            bottom: "5px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="sm"
            color="dodgerblue"
            title="System Information"
          />
        </button>

        <Modal
          style={{ width: "250px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "12px" }}
            ></Modal.Title>
            Setup Requirements:
          </Modal.Header>

          <Modal.Body>
            {this.props.backUpMode === "2" && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "left",
                  marginBottom: "1px",
                }}
              >
                When using SimplBox with the SimplBackUp (SBU) option, SimplBox
                Units automatically discover if they are equipped with backup
                capabilities. Output Voltage and other parameters may have to be
                specified.
              </p>
            )}
            {this.props.backUpMode === "3" && (
              <div>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  For an AC-coupled system, a SimplMeter must be attached to the
                  system by specifying its Serial Number above.
                </p>
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Important: All three voltages lines are used. Phase A and
                  Phase B lines on the SimplMeter must be connected to H1 and H2
                  of the AC-coupled backup system. Phase C voltage line must be
                  connected to the H1 of the grid input.
                </p>
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Meter 1 rope CTs must be connected to the output of the
                  external AC-coupled backup system.
                </p>{" "}
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Meter 2 rope CTs must be connected to the output of the solar
                  system feeding into the AC-coupled backup system.
                </p>{" "}
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  All CT arrows must point to the breakers for the solar and
                  AC-coupled systems.
                </p>
              </div>
            )}

            {this.props.backUpMode === "4" && (
              <div>
                {" "}
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  When using SimplBox with the SimplBackUp (SBU) option,
                  SimplBox Units automatically discover if they are equipped
                  with backup capabilities. Output Voltage and other parameters
                  may have to be specified.
                </p>
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  When adding an AC-coupled system, a SimplMeter must be
                  attached to the system by specifying its Serial Number above.
                </p>
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Meter 1 must be connected to the external AC-coupled backup
                  system.
                </p>{" "}
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  Meter 2 must be connected to the solar system feeding into the
                  AC-coupled backup system.
                </p>{" "}
                <p></p>
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "left",
                    marginBottom: "1px",
                  }}
                >
                  All SimplCT arrows must point to the breakers for the solar
                  and AC-coupled systems.
                </p>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

class ControlsModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            padding: "0px",
            float: "right",
            marginRight: "5px",
            position: "relative",
            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="sm"
            color="dodgerblue"
            title="System Information"
          />
        </button>

        <Modal
          style={{ width: "250px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px" }}
            ></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "10px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Description of Control Parameters
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Up to three load controllers may be programmed for each system.
              The Load controller capabilities may be expanded by installing
              additional SimplLoads units.
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Name Plate Rating:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Nominal power rating for the load in kW.
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Load Priority: Range: 0 to 4
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              The lower the load priority level the earlier the load will be
              disconnected from power in a power shedding event. 0: Never
              Connected 4: Always Connected
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Turn Off Threshold
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              The load is qualified for being disconnected once grid power level
              reaches this value in kW.
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Turn On Threshold
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              The load is not turned back on until power level is below this
              level in kW. Along with Turn On Threshold, these two parameters
              provide a windown or a range in which the load is kept off once it
              is disconnected from the grid.
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Turn On Duration
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              When a load is turned on, it might take several seconds before its
              power level reaches steady state. Power measurements must be
              ignored in this so-called turn-on stage. Different loads have
              different settling or turn-on duration times. This parameters
              helps the system measure power after it had stabilized.
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Min Time Off
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Once a load is disconnected, in some cases such as when a
              compressor is involved, it is important the the load stays off a
              min period of time before it is turned back on.
            </p>
            <hr></hr>

            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "11px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              Turn Time Before Restart
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "left",
                marginBottom: "1px",
              }}
            >
              If a load is turned off, this parameters is designed to make sure
              the load stays off for a minimum period of time.
            </p>
            <hr></hr>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

class ImagesModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };

    this.deleteImage = async () => {
      const url = this.props.url;
      const serialNumber = this.props.serialNumber;
      const handleId = this.props.handleId.trim();

      const lastSlashIndex = url.lastIndexOf("/");
      const questionMarkIndex = url.indexOf("?");
      const imageName = url.substring(lastSlashIndex + 1, questionMarkIndex);

      console.log("Image to be deleted:");
      console.log(imageName);

      try {
        const res = await deleteImage(
          handleId,
          serialNumber,
          imageName,
          this.props.slot
        );

        this.props.setImageUrl(this.props.slot);
        toast.success("Image successfully removed");
      } catch (e) {
        toast.error("Unexpected error");
      }
    };
  }

  render() {
    return (
      <>
        <a
          className="col-5 p-0"
          style={{
            height: "150px",
            position: "relative",
            marginTop:
              this.props.slot === 3 || this.props.slot === 4 ? "16px" : "0px",
          }}
          onClick={this.handleShow}
        >
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.deleteImage();
            }}
            style={{
              zIndex: "1",
              position: "absolute",
              right: "-13px",
              top: "-10px",
              padding: "0px",
              borderWidth: "0px",
              color: "transparent",
              backgroundColor: "transparent",
            }}
          >
            <FontAwesomeIcon
              icon={faCircleXmark}
              size="lg"
              color="red"
              title="Delete Image"
            />
          </button>

          <img
            style={{
              width: "100%",
              height: "100%",
              filter: "brightness(70%)",
              borderRadius: "5px",
              zIndex: "0",
            }}
            src={this.props.url}
          />
        </a>

        <Modal
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-75w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px" }}
            >
              Slot {this.props.slot}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <img
              style={{ width: "100%", height: "100%" }}
              src={this.props.url}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
