import React, { useEffect, useState, Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {getSystemControls} from '../services/systemService';
import Modal from "react-bootstrap/Modal";

// Table component containing the bar and the table showed with the respective functionalities
function NodeSchedule({ nodeA, nodeB, nodeC, nodeD, nodeE, nodeF, chosen }) {
    const [nodes, setNodes] = useState([]); // Initialize state for nodes

  // Run hook only once after the render of the NotificationsTable comnponent occurs
  useEffect(() => {

    if (chosen === 'A') {
        setNodes(nodeA); // Set the state with nodeA array
    } else if (chosen === 'B') {
        setNodes(nodeB);
    } else if (chosen === 'C') {
        setNodes(nodeC);
    } else if (chosen === 'D') {
        setNodes(nodeD);
    } else if (chosen === 'E') {
        setNodes(nodeE);
    } else if (chosen === 'F') {
        setNodes(nodeF);
    }

  }, []);

  return (
    <>
      <ScheduleModal
          nodes={nodes}
          chosen={chosen}
      />

    </>
  )
}


export default NodeSchedule;

class ScheduleModal extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        show: false,
        alarmInfo: {},
        isMounted: true // Initial state is false
      };
  
      this.handleShow = () => {
        this.setState({ show: true });
        console.log("props in modal", this.props);
      };
  
      this.handleHide = () => {
        this.setState({ show: false });
        setTimeout(() => {this.setState({ isMounted: false })}, 500)
      };
    }
  
    render() {
  
      const { isMounted } = this.state
  
      return (
        <>
          <button
            onClick={(e) => {
                e.preventDefault()
              this.handleShow()
            //   this.setAlarmInfo(this.props.item, this.props.loadTypeObj)
            }}
            className="btn btn-outline-light"
            style={{ padding: "0", marginTop: "-5px", borderWidth: "0px"}}
            title="Click to see nodes using this schedule"
          >
            <p style={{
              color: "black",
              fontSize: "12px",
            }}>
              <b>
                {this.props.chosen}
              </b>
            </p>
          </button>
  
          {(isMounted && this.props.nodes.length !== 0) ? (
            <Modal
            show={this.state.show}
            onHide={this.handleHide}
            dialogClassName="modal-50w"
            aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                Nodes using schedule {this.props.chosen}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.props.nodes.map((element, index) => (
                    <p className="modalTextSmall" key={index}>{element}</p>
                ))}
                <hr className="modalLine" style={{ backgroundColor: "orange" }} />
                <p className="modalTextSmall">
                  
                </p>
              </Modal.Body>
  
            </Modal>
          ) : (
            <Modal
              show={this.state.show}
              onHide={this.handleHide}
              dialogClassName="modal-50w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                Nodes using schedule {this.props.chosen}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <hr className="modalLine" style={{ backgroundColor: "orange" }} />
                <p className="modalTextSmall">
                  No nodes using this schedule.
                </p>
              </Modal.Body>
  
            </Modal>
          )}
  
        </>
      );
    }
}
  