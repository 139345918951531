import "intersection-observer";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystemIntervalData,
  getSystemInfo,
  getSystemBasics,
  getSystemIDfromHandle,
  getSystem,
  getSystemControls,
  setSystemControlsObject,
  getSystemLimits,
  setSystemSignals,
  initSystemUpdate,
  checkConnectionInfo,
  getSystemTasks,
} from "../services/systemService";
import { setJwt } from "../services/httpService";
import { getUser } from "../services/userService";
import "./dashboard.css";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "react-bootstrap/ProgressBar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createPopper } from "@popperjs/core";
import Modal from "react-bootstrap/Modal";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Overlay from "react-bootstrap/Overlay";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Tooltip from "react-bootstrap/Tooltip";
import BackGroundRectangle from "../components/backgroundRect";
import { webSocketMode } from "../config.json"; //0: http; 1:websocket
import { toast } from "react-toastify";

import { isMobile } from "react-device-detect";
import AggregateGraph from "./aggregate-graph";
import IntervalGraph from "./interval-graph";
import MoneyGraph from "./money-graph";
import DemandGraph from "./demand_intervals";
import Icon from "./simplIcon";
import Draggable from "react-draggable";
//import snowFlake from "./icons/snow.svg";
//import snowComplex from "./icons/snowcomplex.svg";
import waterHeater from "./icons/waterHeater.svg";
import HvacSvg from "./icons/HvacSvg";
import WalkInOpenSvg from "./icons/WalkInOpenSvg";
import WalkInClsdSvg from "./icons/WalkInClsdSvg";
import WaterHeaterSvg from "./icons/WaterHeaterSvg";
import NoTempWaterHeaterSvg from "./icons/NoTempWaterHeaterSvg";
import PoleLightSvg from "./icons/PoleLightSvg";
import AshSvg from "./icons/AshSvg";
import OvenSvg from "./icons/OvenSvg";
import HoodSvg from "./icons/HoodSvg";
import LightsSvg from "./icons/LightsSvg";
import HeatLampSvg from "./icons/HeatLampSvg";
import DoorClsdSvg from "./icons/DoorClsdSvg";
import DoorOpenSvg from "./icons/DoorOpenSvg";
import SpaceHeaterSvg from "./icons/SpaceHeaterSvg";
import ThermoSvg from "./icons/ThermoSvg";
import LightSensorSvg from "./icons/LightSensorSvg";
import GatewaySvg from "./icons/GatewaySvg";
import ReachInSvg from "./icons/ReachInSvg";
import CounterReachInSvg from "./icons/CounterReachInSvg";
import ApplianceGeneralSvg from "./icons/ApplianceGeneralSvg";
import EVStationSvg from "./icons/EVStationSvg";
import CompassSvg from "./icons/CompassSvg";
import LoraSvg from "./icons/lora_icon";

import ApplianceModal from "./applianceModal";
import ApplianceIcon from "./applianceIcon";
import LoraModal from "./loraModal";

import EnergyIndepProg from "./energyIndepProg";
import SystemIcons from "./systemIcons";
import EnergyAnimation from "./energyAnimation";
import SimplFooterWide from "./simplFooterWide";
import SimplVuNumbers from "./simplVuNumbers";
import SimplSOC from "./simplSOC";
import MainMenuWide from "./mainMenuWide";
import CostNSavingsModal from "./costSavings";
import SimpleMenu from "./simplDropDownMenu";
import SystemInfoModal from "./systemInfoModal";
import GaugeGraphII from "./gaugeGraph";
import VerticalProgress from "./verticalProgress";
import ClearProgress from "./clearProgress";
import PriorityProgress from "./priorityProgress";
import NodeProgBars from "./nodesProgBars";
import BldgShape from "./bldgShape";
import { string } from "prop-types";

import { ScrollView } from "@cantonjs/react-scroll-view";
import { animateScroll as scroll } from "react-scroll";

class SimplNodesVuWide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: [],
      dateNow: new Date(),

      endDate: new Date().getTime(),
      startDate: new Date().getTime() - 3600000,
      modeBox: "Real-time",
      anchorEl: null,
      companyName: "",
      key: null,
      systemID: "",
      handleID: 0,
      pvPowerUnits: "Watts",
      batteryPowerUnits: "Watts",
      intervalPeriod: 900,
      dataCount: 0,
      pvIntervalTotal: 0,
      battIntervalTotal: 0,
      pvInterval: [],
      response: {
        deviceLedger: {
          realTime: {
            timestamp: 0,
            pvPower: 0,
            batteryPower: 0,
          },
          Interval: {
            pvEnergy: 0,
            batteryEnergy: 0,
          },
        },
      },
      deltaPosition: [],
      activeDrags: 0,
      deltaPosition: {
        x: 0,
        y: 0,
      },
      controlledPosition: {
        x: -400,
        y: 200,
      },
      editModeOn: false,
      editLayoutOn: false,
      openApplianceModal: [],
      shedStatus: [],
      doorStatus: [],
      applianceStatus: [],
      systemControlsError: false,
      connection: 0,
      closedTasks: true,
    };

    this.getSystemData = this.getSystemData.bind(this);
  }

  async componentDidMount() {
    let deskTopView = "deskTop";
    if (isMobile) deskTopView = "notDeskTop";
    const toShowOrNot = localStorage.getItem("showNumbers");
    let showNumbers = false;
    if (toShowOrNot === "show") showNumbers = true;
    else showNumbers = false;

    const userRole = this.props.match.params.userRole;
    console.log("User Role", userRole);
    this.timerID = setInterval(() => this.tick(), 1000);

    const handleID = this.props.match.params.systemHandleID;

    /////////////////////////////////////////Get System TimeZone & Human TimeStamp  /////////////////////
    const response_initial = await getSystemLatestData(handleID);
    console.log("Initial Response Full Info", response_initial);
    const timeZone =
      response_initial.data.data[0].data.deviceLedger.realTime.TimeZone;
    const systemTimeZone = parseInt(
      response_initial.data.data[0].data.deviceLedger.realTime.TimeZone
    );
    const timeStampLatestData = parseInt(response_initial.timestamp / 1000);
    console.log("Latest Data's Time Stamp", timeStampLatestData);
    const epochLocalTime = Math.round(new Date().getTime() / 1000);
    console.log("Epoch Time", epochLocalTime);
    const systemTime = parseInt(
      response_initial.data.data[0].data.deviceLedger.realTime
        .SystemLocalTimeSeconds
    );

    //////////////////////////////////////////////////////////////////////////////////////

    const companyName = this.props.match.params.companyName;
    const systemInfo = await getSystemInfo(handleID);
    console.log("System Info in Dashboard CDM", systemInfo);
    //const attachedSimplModuleSN = systemInfo.data.data.controls.moduleSN;
    const attachedSimplModuleSN = "simpl-000070";
    const attachedSimplModuleHandle = 183;
    const moduleDataValid = 0;
    // const attachedSimplModuleHandle =
    //  systemInfo.data.data.controls.moduleHandleId;
    //console.log("Module Handle ID:", attachedSimplModuleHandle);

    //const moduleDataValid = systemInfo.data.data.controls.AttachedModuleValid;

    const systemID = (await getSystemIDfromHandle(handleID)).data.data
      .system_id;
    console.log("System ID:", systemID);
    const nodeSystem = await getSystem(systemID);
    console.log("Node System", nodeSystem);
    const systemData = nodeSystem.data.data[0].systemID;
    const serialNumber = systemData.SN;
    const macAddress = systemData.MAC;
    const systemName = systemData.name;
    const systemControls = nodeSystem.data.data[0].systemControls;
    let systemControlsLength = 0;
    if (!systemControls) {
      console.log("System Controls is not defined......");
      systemControlsLength = systemControls.length;
    } else {
      console.log("System Controls:", systemControls);
    }
    const systemLimits = nodeSystem.data.data[0].systemLimits;

    let numOfAppliances = 0;

    if (systemControls) numOfAppliances = systemControls.length;
    console.log("Number of Appliances:", numOfAppliances);
    let i = 0;
    let openApplianceModal = this.state.openApplianceModal;
    let shedStatus = this.state.shedStatus;
    let doorStatus = this.state.doorStatus;
    let applianceStatus = this.state.applianceStatus;
    let perimeterShape = "Rectangle";
    for (i = 0; i < numOfAppliances; i++) {
      openApplianceModal[i] = false;
      shedStatus[i] = false;
      doorStatus[i] = true;
      applianceStatus[i] = true;

      if (systemControls[i].loadType === "Perimeter Outline")
        perimeterShape = systemControls[i].nodeName;
    }
    console.log("Perimeter Shape:", perimeterShape);
    console.log("Open Appliance Modal:", openApplianceModal);
    const systemControlsError = false;
    this.setState({
      systemControlsLength,
      perimeterShape,
      serialNumber,
      macAddress,
      systemName,
      systemControls,
      systemLimits,
      openApplianceModal,
      shedStatus,
      doorStatus,
      applianceStatus,
      numOfAppliances,
      systemControlsError,
      timeZone,
    });

    console.log("Serial Number", serialNumber);
    console.log("MAC Address:", macAddress);
    console.log("System Name", systemName);

    const systemStatus = systemInfo.data.data.status;
    console.log("system status", systemStatus);

    /*const backUpMode = parseInt(systemInfo.data.data.controls.backUpMode);
    console.log("BackUp Mode:", backUpMode);
    let acCoupledBackUp = 0;
    if (backUpMode === 3 || backUpMode === 4) acCoupledBackUp = 1;*/

    const systemBasics = systemInfo.data.data.basics;
    console.log("System Basics", systemBasics);
    const systemDescription = systemBasics.systemDescription;
    const systemConfiguration = systemBasics.systemConfiguration;
    const productSN = systemBasics.productSN;
    const emaSN = systemBasics.fourGserialNumber;
    const systemProduct = systemBasics.systemProduct;
    const systemType = systemBasics.systemType;
    console.log("System Type:", systemType);
    const paramUpdateRate = systemBasics.paramUpdateRate;
    const dataVerbosity = systemBasics.dataVerbosity;
    const dataFrequency = systemBasics.dataFrequency;

    let measPoint1 = "";
    let measPoint2 = "";

    if (systemConfiguration === "1") {
      const circuitName = systemBasics.circuitNameMeter1;
      if (circuitName === "0") measPoint1 = "Line/Grid";
      else if (circuitName === "1") measPoint1 = "Load/Building";
      else if (circuitName === "2") measPoint1 = "Solar";
      else measPoint1 = "Line/Grid";
      this.setState({ measPoint1 });
    } else if (
      systemConfiguration === "2" ||
      systemConfiguration === "3" ||
      systemConfiguration === "6" ||
      systemConfiguration === "7" ||
      systemConfiguration === "8"
    ) {
      let circuitName = systemBasics.circuitNameMeter1;
      if (circuitName === "0") measPoint1 = "Line/Grid";
      else if (circuitName === "1") measPoint1 = "Load/Building";
      else if (circuitName === "2") measPoint1 = "Solar";
      else measPoint1 = "Line/Grid";
      this.setState({ measPoint1 });
      circuitName = systemBasics.circuitNameMeter2;
      if (circuitName === "0") measPoint2 = "Line/Grid";
      else if (circuitName === "1") measPoint2 = "Load/Building";
      else if (circuitName === "2") measPoint2 = "Solar";
      else measPoint2 = "Line/Grid";
      this.setState({ measPoint1, measPoint2 });
    }
    console.log("Measurement Point for Acuvim 1", this.state.measPoint1);
    console.log("Measurement Point for Acuvim 2", this.state.measPoint2);

    localStorage.setItem("systemMapButton", "noButton");
    const displayWidth = "Wide";
    this.setState({
      userRole,
      systemID,
      handleID,
      companyName,
      systemStatus,
      systemDescription,
      systemConfiguration,
      systemProduct,
      systemType,
      paramUpdateRate,
      dataVerbosity,
      dataFrequency,
      productSN,
      emaSN,
      attachedSimplModuleSN,
      attachedSimplModuleHandle,
      moduleDataValid,
      //acCoupledBackUp,
      showNumbers,
      playPause: true,
      deskTopView,
      displayWidth,
    });
    this.setState({ firstLoading: true });
    const endDate = new Date().getTime();
    const startDate = endDate - 10000000;
    this.computeInterval(startDate, endDate);

    scroll.scrollToTop();
    const pageWidth =
      (document.body.scrollWidth - document.body.clientWidth) / 2;
    scroll.scrollTo(pageWidth, { horizontal: true });
  }

  async computeInterval(startDate, endDate) {
    console.log("Start Date", startDate);
    console.log("End Date", endDate);
    console.log("handleID", this.state.handleID);
    const systemIntervalDataInterim = await getSystemIntervalData(
      this.state.handleID,
      startDate,
      endDate
    );

    const systemIntervalData = systemIntervalDataInterim.data.data;
    console.log("System Interval Data Interim", systemIntervalDataInterim);
    console.log("System Inteval Data", systemIntervalData);
    const recordLength = systemIntervalData.length;
    console.log("Record Length", recordLength);
    let intervalPeriod = 0;

    let pvIntervalTotal = 0;
    let battIntervalTotal = 0;
    let gridIntervalTotal = 0;
    let bldgIntervalTotal = 0;

    let pvEnergyValue = 0;
    let pvDemandValue = 0;
    let battEnergyValue = 0;
    let battDemandValue = 0;
    let gridEnergyValue = 0;
    let gridDemandValue = 0;
    let bldgEnergyValue = 0;
    let bldgDemandValue = 0;
    let bldgDemandValueNew = 0;
    let savingsIntervalTotal = 0;
    let savingsEnergyValue = 0;
    let bldgMaxDemand = 0;
    let gridMaxDemand = 0;
    let bldgMaxKW = 0;
    let gridMaxKW = 0;

    let demandRate = 0;
    let gridDemand = 0;
    let bldgDemand = 0;

    let pvInterval = [0];
    let date = [0];
    let dataCount = 0;
    let inCompleteData = 0;

    if (recordLength <= 0) intervalPeriod = 900;
    else {
      intervalPeriod =
        systemIntervalData[recordLength - 1].data.intervalDataFreq;
      console.log("Interval Raw Period", intervalPeriod);
      if (intervalPeriod === "oneMinute") intervalPeriod = 60;
      if (intervalPeriod === "fiveMinute") intervalPeriod = 300;
      if (intervalPeriod === "fifteenMinute") intervalPeriod = 900;
      if (intervalPeriod === "thirtyMinute") intervalPeriod = 1800;
      if (intervalPeriod === "sixtyMinute") intervalPeriod = 3600;
      console.log("Interval Calculated", intervalPeriod);
      console.log("Record Length", recordLength);
      console.log("Slave Count in Compute Interval", this.state.slaveCount);
    }
    savingsIntervalTotal = pvIntervalTotal + battIntervalTotal;
    savingsEnergyValue = pvEnergyValue + battEnergyValue;

    let demandSavings = bldgMaxDemand - gridMaxDemand;
    let totalSavings = demandSavings + savingsEnergyValue;

    let totalUsage = bldgMaxDemand + bldgEnergyValue;
    let netCosts = totalUsage - totalSavings;
    let percentEnergySavings = (
      (savingsEnergyValue / bldgEnergyValue) *
      100
    ).toFixed(0);
    let percentDemandSavings = ((demandSavings / bldgMaxDemand) * 100).toFixed(
      0
    );
    let percentTotalSavings = ((totalSavings / totalUsage) * 100).toFixed(0);

    gridMaxDemand = gridMaxDemand
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    bldgMaxDemand = bldgMaxDemand
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    demandSavings = demandSavings
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    totalSavings = totalSavings
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    gridMaxKW = gridMaxKW
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    bldgMaxKW = bldgMaxKW
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    totalUsage = totalUsage
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    netCosts = netCosts
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    this.sigFigures(gridIntervalTotal);
    gridIntervalTotal = this.state.sigNumber;
    this.sigFigures(pvIntervalTotal);
    pvIntervalTotal = this.state.sigNumber;
    this.sigFigures(bldgIntervalTotal);
    bldgIntervalTotal = this.state.sigNumber;
    this.sigFigures(battIntervalTotal);
    battIntervalTotal = this.state.sigNumber;
    this.sigFigures(savingsIntervalTotal);
    savingsIntervalTotal = this.state.sigNumber;

    this.sigFigures(gridDemandValue);
    gridDemandValue = this.state.sigNumber;
    this.sigFigures(pvDemandValue);
    pvDemandValue = this.state.sigNumber;
    this.sigFigures(bldgDemandValue);
    bldgDemandValue = this.state.sigNumber;
    this.sigFigures(battDemandValue);
    battDemandValue = this.state.sigNumber;

    //this.sigFigures(gridEnergyValue);
    //gridEnergyValue = this.state.sigNumber;
    //this.sigFigures(pvEnergyValue);
    //pvEnergyValue = this.state.sigNumber;
    //this.sigFigures(bldgEnergyValue);
    // bldgEnergyValue = this.state.sigNumber;
    this.sigFigures(battEnergyValue);
    battEnergyValue = this.state.sigNumber;
    //this.sigFigures(savingsEnergyValue);
    // savingsEnergyValue = this.state.sigNumber;

    bldgEnergyValue = bldgEnergyValue
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    pvEnergyValue = pvEnergyValue
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    savingsEnergyValue = savingsEnergyValue
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    gridEnergyValue = gridEnergyValue
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    this.setState({
      inCompleteData,

      intervalPeriod,
      pvInterval,

      pvIntervalTotal,
      pvEnergyValue,
      pvDemandValue,

      battIntervalTotal,
      battEnergyValue,
      battDemandValue,

      gridIntervalTotal,
      gridEnergyValue,
      gridDemandValue,

      bldgIntervalTotal,
      bldgEnergyValue,
      bldgDemandValue,

      savingsIntervalTotal,
      savingsEnergyValue,

      gridMaxDemand,
      bldgMaxDemand,
      demandSavings,
      totalSavings,

      gridMaxKW,
      bldgMaxKW,

      totalUsage,
      netCosts,

      percentEnergySavings,
      percentDemandSavings,
      percentTotalSavings,

      dataCount,
      date,
    });
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async getSystemData() {
    let response = {};
    if (webSocketMode) {
      response = ws_getSystemLatestData(this.state.handleID);
      console.log("Websocket: Latest Data", response);
    } else {
      const response_temp = await getSystemLatestData(this.state.handleID);
      console.log("Response Full Info", response_temp);
      response = response_temp.data.data[0];
      console.log("Http: Latest Data", response);
    }
    if (response !== null && response !== undefined) {
      /////////////////////////////////////////////////////////////////////////////////////////////
      const realTimePower = response.data.deviceLedger.realTime.AggPower1;
      const intervalPower = response.data.deviceLedger.Interval.thisInterval_1;
      const globalPriority = response.data.deviceLedger.realTime.globalPriority;
      const peakPower = response.data.deviceLedger.realTime.peakPower;
      this.setState({
        realTimePower,
        intervalPower,
        globalPriority,
        peakPower,
      });
    }
    ////////////////////////////////////////////////////////////////////////////////////////

    let applianceInfo = [];
    let realTimeInfo = [];
    let loraDevices = [];
    if (response) {
      applianceInfo = response.data.deviceLedger.Appliances;
      realTimeInfo = response.data.deviceLedger.realTime;
      loraDevices = response.data.deviceLedger.LoraDeviceItems;

      console.log("Applinace Info:", applianceInfo);
      let doorStatus = [0];
      let shedStatus = [0];
      let applianceStatus = [0];
      for (let i = 0; i < applianceInfo.length; i++) {
        doorStatus[i] = applianceInfo[i].doorStatus;
        shedStatus[i] = applianceInfo[i].shedStatus;

        /* if (applianceInfo[i].relayNum !== "" )*/
        let applianceRelayNum = applianceInfo[i].relayNum;
        if (applianceRelayNum === "Relay1")
          applianceStatus[i] = applianceInfo[i].relaySetting & 0x0001;
        else if (applianceRelayNum === "Relay2")
          applianceStatus[i] = (applianceInfo[i].relaySetting & 0x0002) >> 1;
        else if (applianceRelayNum === "Relay3")
          applianceStatus[i] = (applianceInfo[i].relaySetting & 0x0004) >> 2;
        else if (applianceRelayNum === "Relay4")
          applianceStatus[i] = (applianceInfo[i].relaySetting & 0x0008) >> 3;
      }
      console.log("Real Time Info:", realTimeInfo);
      console.log("Lora Devices Info:", loraDevices);
      console.log(
        "Num of Appliances from Controls:",
        this.state.numOfAppliances
      );
      console.log("Num from Hardware:", applianceInfo.length);
      let systemControlsError = this.state.systemControlsError;
      console.log("systemControlsError", systemControlsError);
      if (
        this.state.numOfAppliances !== applianceInfo.length &&
        !systemControlsError
      ) {
        systemControlsError = true;
        toast.error("System Nodes MUST be uploaded to the Gateway!");
      }

      const dataTimeInvalid = (await checkConnectionInfo(this.state.handleID))
        .data.data.Connection;
      let connection = this.state.connection;
      if (
        dataTimeInvalid === null ||
        dataTimeInvalid === 2 ||
        dataTimeInvalid === 1
      ) {
        connection = 2;
      } else if (dataTimeInvalid === 0) {
        connection = 0;
      }
      this.setState({ connection });

      const tasks = (await getSystemTasks(this.state.handleID)).data.data[0]
        .tasks;
      const userObj = (await getUser()).data.data;
      const closedTasks = this.searchClosedTasks(tasks, userObj.username);

      this.setState({ closedTasks });

      const nodePriority = realTimeInfo.globalPriority;
      const nodePriorityHeight = 15 + nodePriority * 15;
      const nodePriorityTop = 195 - nodePriority * 15;

      const systemInfo = await getSystemInfo(this.state.handleID);
      console.log("System Info: ", systemInfo);
      const systemStatus = systemInfo.data.data.status;

      if (response !== undefined && response !== null) {
        const commMode = response.data.commMode;
        console.log("Comm Mode", commMode);

        ////////////////////////////////////////////////////////////////////////////////////////
        let smMeter1 = 0;
        let smMeter2 = 0;
        if (
          this.state.moduleDataValid === 1 &&
          this.state.attachedSimplModuleHandle !== 0
        ) {
          const responseAttachedModule = await getSystemLatestData(
            this.state.attachedSimplModuleHandle
          );
          console.log("Attached Module Response:", responseAttachedModule);
          smMeter1 =
            responseAttachedModule.data.data[0].data.deviceLedger.simplMeter_ii
              .sm2Ptotal1;
          smMeter2 =
            responseAttachedModule.data.data[0].data.deviceLedger.simplMeter_ii
              .sm2Ptotal2;
          if (smMeter2 < 0.0) smMeter2 = 0;
          console.log("Meter 1: ", smMeter1);
          console.log("Meter 2: ", smMeter2);
        } else {
          // toast.error("No SimplMeter Module is Attached...");
        }
        ///////////////////////////////////////////////////////////////////////////////
        const commProvider = response.data.commProvider;
        console.log("Comm Provider", commProvider);
        const signalStrength = response.data.deviceLedger.realTime.RSSI;
        const signalQuality = response.data.deviceLedger.realTime.sigQuality;
        const MeshId = response.data.MeshId;
        console.log("Mesh ID", MeshId);
        const wifiSSID = response.data.deviceLedger.SSID;
        let loadOne = false;
        let loadTwo = false;
        let loadThree = false;
        if (this.state.systemConfiguration === "5") {
          const loadsStatus = response.data.loadsStatus;

          if ((loadsStatus & 0x01) !== 0) loadOne = true;
          if ((loadsStatus & 0x02) !== 0) loadTwo = true;
          if ((loadsStatus & 0x04) !== 0) loadThree = true;
        }

        console.log("Load One", loadOne);
        console.log("Load Two", loadTwo);
        console.log("Load Three", loadThree);

        const realtimeEnergyRate =
          response.data.deviceLedger.realTime.energyRate;
        console.log("Real Time Energy Rate:", realtimeEnergyRate);

        const outageFlag = parseInt(
          response.data.deviceLedger.realTime.outageFlag
        );
        const outageCount =
          parseInt(response.data.deviceLedger.realTime.outageCount) * 10; //Count increases once every 10 second in the timer task in the master unit

        const systemTimeZone = parseInt(
          response.data.deviceLedger.realTime.TimeZone
        );
        const timeStampLatestData = parseInt(response.timestamp / 1000);
        console.log("Latest Data's Time Stamp", timeStampLatestData);
        const epochLocalTime = Math.round(new Date().getTime() / 1000);
        console.log("Epoch Time", epochLocalTime);
        const systemTime = parseInt(
          response.data.deviceLedger.realTime.SystemLocalTimeSeconds
        );
        console.log("System Time:", systemTime);

        //Deal with Old Data when reading info from latestData route
        const timeDelta = Math.abs(timeStampLatestData - epochLocalTime);
        let dataTimeInvalid = false;
        //calculate maxDelta = dataFrequency + 10 seconds
        //If wifi dataFrequncy = whatever is set in systemBasics
        //If 4G dataFrequency = dataFrequency from data plan
        const systemBasics = await getSystemBasics(this.state.handleID);
        console.log("System Basics", systemBasics);
        let circuitNameMeter1 = systemBasics.data.data.basics.circuitNameMeter1;
        if (circuitNameMeter1 !== "0" && circuitNameMeter1 !== "1")
          circuitNameMeter1 = "0";
        console.log("Grid or Bldg: ", circuitNameMeter1);
        const dataFrequency = systemBasics.data.data.basics.dataFrequency;
        console.log("Data Frequency", dataFrequency);
        let dataFrequency_delta = 0;
        if (dataFrequency === "1") dataFrequency_delta = 1;
        //"1", name: "once / Second"
        else if (dataFrequency === "2") dataFrequency_delta = 5;
        //"2", name: "once / 5 Seconds"
        else if (dataFrequency === "3") dataFrequency_delta = 15;
        //"3", name: "once / 15 Seconds"
        else if (dataFrequency === "4") dataFrequency_delta = 60;
        //"4", name: "Once / Minute"
        else if (dataFrequency === "5") dataFrequency_delta = 300;
        //"5", name: "Once / 5 Minutes"
        else if (dataFrequency === "6") dataFrequency_delta = 900;
        //"6", name: "Once / 15 Minutes"
        else if (dataFrequency === "7") dataFrequency_delta = 1800;
        //"7", name: "Once / 30 Minutes"
        else if (dataFrequency === "8") dataFrequency_delta = 3600; //"8", name: "Once / Hour"

        if (commMode === 2 && dataFrequency_delta < 15)
          dataFrequency_delta = 15; //If 4G, should not go any faster than 15 seconds
        console.log("dataFreqency_delta", dataFrequency_delta);
        if (timeDelta > 15 + dataFrequency_delta) {
          dataTimeInvalid = true;
        } else dataTimeInvalid = false;
        console.log("Delta Time :", timeDelta);
        console.log("Data Time Invalid", dataTimeInvalid);

        const loopCount = response.data.deviceLedger.realTime.loopCount;
        const freeHeap = response.data.freeHeapBytes;
        const minHeap = response.data.minHeapBytes;
        const appPartition = response.data.deviceLedger.appPartition;

        console.log("Loop Count ", loopCount);

        const fwVersionMaster = response.data.deviceLedger.fwVersion;
        const fwAppName = response.data.deviceLedger.appName;
        //console.log("Master Firmware Version", fwVersionMaster);

        const wifiSignalStrength = response.data.deviceLedger.realTime.RSSI;
        //console.log("Wifi Signal Strength", wifiSignalStrength);

        const slaveCount = response.data.deviceLedger.realTime.slaveCount;

        console.log("System Time Zone:", systemTimeZone);
        let sysT = new Date((systemTime - systemTimeZone * 3600) * 1000);
        const systemTimeOffset = systemTimeZone * 60 * 60 * 1000;
        const userTimeOffset = sysT.getTimezoneOffset() * 60 * 1000;
        sysT = new Date(sysT.getTime() + userTimeOffset + systemTimeOffset);

        let hourTime = sysT.getHours();
        if (hourTime < 10) hourTime = "0" + hourTime;
        let minuteTime = sysT.getMinutes();
        if (minuteTime < 10) minuteTime = "0" + minuteTime;
        let secTime = sysT.getSeconds();
        let dayTime = sysT.getDay();
        if (secTime < 10) secTime = "0" + secTime;
        const systemHumanTime = [hourTime, minuteTime, secTime].join(":");
        console.log("System Human Time:", systemHumanTime);
        this.setState({ dayTime, systemHumanTime });

        const endDate = new Date().getTime() - 10;
        const startDate = endDate - this.state.intervalPeriod * 1.2 * 1000;
        const systemIntervalDataInterim = await getSystemIntervalData(
          this.state.handleID,
          startDate,
          endDate
        );
        console.log(
          "Weather Interval Data Interim:",
          systemIntervalDataInterim
        );
        //////////////////////////////////////////////////////////////////////////////////////
        if (
          systemIntervalDataInterim !== null &&
          systemIntervalDataInterim !== undefined
        ) {
          const dataLength = systemIntervalDataInterim.data.data.length;
          if (dataLength > 0) {
            const weatherIcon =
              systemIntervalDataInterim.data.data[0].data.weatherIcon;
            console.log("Weather Icon: ", weatherIcon);
            const weatherTemp =
              systemIntervalDataInterim.data.data[0].data.weatherTemp;
            const weatherRH =
              systemIntervalDataInterim.data.data[0].data.weatherRH;
            this.setState({ weatherIcon, weatherTemp, weatherRH });
          }
        }
        /////////////////////////////////////////////////////////////////////////////////////
        console.log("Interval Start Time: ", startDate);
        console.log("Interval End Date: ", endDate);
        console.log("Interval Data", systemIntervalDataInterim);
        const systemIntervalData = systemIntervalDataInterim.data.data;
        const recordLength = systemIntervalData.length;
        console.log("System Interval Data Interim", systemIntervalDataInterim);
        let energyRate = 0;
        let demandRate = 0;
        let onPeakEnergy = 0;
        let onPeakDemand = 0;
        if (recordLength >= 1) {
          energyRate = systemIntervalData[recordLength - 1].data.EnergyRate;
          demandRate =
            systemIntervalData[recordLength - 1].data.DemandRate.toFixed(2);
          onPeakEnergy = systemIntervalData[recordLength - 1].data.OnPeakEnergy;
          onPeakDemand = systemIntervalData[recordLength - 1].data.OnPeakDemand;
        }

        //console.log("Energy Rate", energyRate);
        this.setState({ energyRate, demandRate, onPeakEnergy, onPeakDemand });

        this.setState({
          realTime: response.data.deviceLedger.realTime,
        });
        this.setState({
          acuvimData: response.data.deviceLedger.acuvim,
        });
        /////////////////////////////////////////////////////////////////////
        if (
          this.state.systemConfiguration === "3" ||
          this.state.systemConfiguration === "7"
        )
          this.setState({
            simplMeter_iiData: response.data.deviceLedger.simplMeter_ii,
          });
        if (
          this.state.systemConfiguration === "6" ||
          this.state.systemConfiguration === "8"
        )
          this.setState({
            simplMeter_slave: response.data.deviceLedger.realTime,
          });
        /////////////////////////////////////////////////////////////////
        this.setState({
          Interval: response.data.deviceLedger.Interval,
        });

        let pvPower = 0;
        let pvPowerUnits = this.state.pvPowerUnits;
        let batteryPower = 0;
        let batteryPowerUnits = this.state.batteryPowerUnits;
        let gridPower = 0;
        let bldgPower = 0;
        let gridPowerUnits = this.state.gridPowerUnits;
        let bldgPowerUnits = this.state.bldgPowerUnits;
        let aSVU = 0;
        let bSVU = 0;
        let cSVU = 0;
        let dSVU = 0;
        let eSVU = 0;
        let pHouse = 0;
        if (
          this.state.systemConfiguration === "0" &&
          this.state.realTime.slaveCount > 0 &&
          this.state.moduleDataValid === 0
        ) {
          pvPower = this.state.realTime.pvPower / 100;
          batteryPower = this.state.realTime.batteryPower / 100;
          gridPower = (-1.0 * this.state.realTime.inverterPower) / 100.0;
          bldgPower = this.state.realTime.buildingPower / 100; //0.0;
        }
        /////////////////////////////////////////////////////////////////////////
        else if (
          this.state.systemConfiguration === "0" &&
          this.state.realTime.slaveCount > 0 &&
          this.state.moduleDataValid === 1
        ) {
          pvPower = this.state.realTime.pvPower / 100;
          batteryPower = this.state.realTime.batteryPower / 100;
          gridPower = (-1.0 * this.state.realTime.inverterPower) / 100.0;
          bldgPower = this.state.realTime.buildingPower / 100; //0.0;
          if (batteryPower > 0.0) {
            //Discharging
            if (smMeter1 > 0) {
              //buying
              aSVU = batteryPower;
              bSVU = smMeter2 - batteryPower;
              cSVU = smMeter1;
              dSVU = 0;
              eSVU = 0;
              pHouse = smMeter1 + smMeter2;
            } else {
              //selling
              aSVU = batteryPower;
              bSVU = smMeter2 + smMeter1 - batteryPower;
              cSVU = 0;
              dSVU = 0;
              eSVU = -1.0 * smMeter1;
              pHouse = smMeter1 + smMeter2;
            }
          } else {
            //Charging

            if (smMeter1 > 0) {
              //buying
              aSVU = 0;
              bSVU = smMeter2;
              cSVU = smMeter1;
              dSVU = -1.0 * batteryPower;
              eSVU = 0;
              pHouse = smMeter1 + smMeter2;
            } else {
              //selling
              aSVU = 0;
              bSVU = smMeter1 + smMeter2;
              cSVU = 0.0;
              dSVU = -1.0 * batteryPower;
              eSVU = -1.0 * smMeter1;
              pHouse = smMeter1 + smMeter2;
            }
          }
        }
        /////////////////////////////////////////////////////////////////////////
        else if (
          this.state.systemConfiguration === "1" &&
          response.data.deviceLedger.acuvim !== undefined
        ) {
          gridPower = this.state.acuvimData.acuvimPower0;
          pvPower = 0.0;
          batteryPower = 0.0;
          bldgPower = 0.0;
        } else if (
          this.state.systemConfiguration === "2" &&
          response.data.deviceLedger.acuvim !== undefined
        ) {
          pvPower = this.state.acuvimData.acuvimPower1;
          if (circuitNameMeter1 === "0") {
            gridPower = this.state.acuvimData.acuvimPower0;
            bldgPower = gridPower + this.state.acuvimData.acuvimPower1;
          } else if (circuitNameMeter1 === "1") {
            bldgPower = this.state.acuvimData.acuvimPower0;
            gridPower = bldgPower - this.state.acuvimData.acuvimPower1;
          } else {
            gridPower = 0.0;
            bldgPower = 0.0;
          }
          batteryPower = 0.0;
          if (gridPower > 0) {
            //buying
            aSVU = 0;
            bSVU = pvPower;
            cSVU = gridPower;
            dSVU = 0.0;
            eSVU = 0;
            pHouse = gridPower + pvPower;
          } else {
            //selling
            aSVU = 0;
            bSVU = gridPower + pvPower;
            cSVU = 0.0;
            dSVU = 0.0;
            eSVU = -1.0 * gridPower;
            pHouse = gridPower + pvPower;
          }
        }

        ///////////////////////////////////////////////////////////////////////////
        else if (
          (this.state.systemConfiguration === "3" ||
            this.state.systemConfiguration === "7") &&
          response.data.deviceLedger.simplMeter_ii !== undefined
        ) {
          if (this.state.simplMeter_iiData.sm2Ptotal2 !== null)
            pvPower = this.state.simplMeter_iiData.sm2Ptotal2;
          else pvPower = 0;
          if (circuitNameMeter1 === "0") {
            gridPower = this.state.simplMeter_iiData.sm2Ptotal1;
            bldgPower = gridPower + this.state.simplMeter_iiData.sm2Ptotal2;
          } else if (circuitNameMeter1 === "1") {
            bldgPower = this.state.simplMeter_iiData.sm2Ptotal1;
            gridPower = bldgPower - this.state.simplMeter_iiData.sm2Ptotal2;
          } else {
            gridPower = 0.0;
            bldgPower = 0.0;
          }
          batteryPower = 0.0;

          if (gridPower > 0) {
            //buying
            aSVU = 0;
            bSVU = pvPower;
            cSVU = gridPower;
            dSVU = 0.0;
            eSVU = 0;
            pHouse = gridPower + pvPower;
          } else {
            //selling
            aSVU = 0;
            bSVU = gridPower + pvPower;
            cSVU = 0.0;
            dSVU = 0.0;
            eSVU = -1.0 * gridPower;
            pHouse = gridPower + pvPower;
          }
        } else if (
          (this.state.systemConfiguration === "6" ||
            this.state.systemConfiguration === "8") &&
          response.data.deviceLedger.slaveUnits !== undefined
        ) {
          if (!this.state.simplMeter_slave) {
            gridPower = 0.0;
            bldgPower = 0.0;
          } else {
            if (this.state.simplMeter_slave.AggPower2 !== null) {
              pvPower = this.state.simplMeter_slave.AggPower2;
              smMeter2 = pvPower;
            } else pvPower = 0;
            if (circuitNameMeter1 === "0") {
              gridPower = this.state.simplMeter_slave.AggPower1;
              smMeter1 = gridPower;
              bldgPower = gridPower + this.state.simplMeter_slave.AggPower2;
            } else if (circuitNameMeter1 === "1") {
              bldgPower = this.state.simplMeter_slave.AggPower1;
              gridPower = bldgPower - this.state.simplMeter_slave.AggPower2;
            } else {
              gridPower = 0.0;
              bldgPower = 0.0;
            }
          }
          batteryPower = 0.0;
          if (gridPower > 0) {
            //buying
            aSVU = 0;
            bSVU = pvPower;
            cSVU = gridPower;
            dSVU = 0.0;
            eSVU = 0;
            pHouse = gridPower + pvPower;
          } else {
            //selling
            aSVU = 0;
            bSVU = gridPower + pvPower;
            cSVU = 0.0;
            dSVU = 0.0;
            eSVU = -1.0 * gridPower;
            pHouse = gridPower + pvPower;
          }
        }
        /////////////////////////////////////////////////////////////////////////////
        let gridPowerProgress = 100;
        let solarPowerProgress = 0.0;
        let battPowerProgress = 0.0;
        let energyIndependence = 0.0;
        if (pHouse > 0.0) {
          gridPowerProgress = (cSVU / pHouse) * 100.0;
          solarPowerProgress = (bSVU / pHouse) * 100.0;
          battPowerProgress = (aSVU / pHouse) * 100.0;
          energyIndependence = (solarPowerProgress + battPowerProgress).toFixed(
            0
          );
          if (energyIndependence < 0.0001)
            energyIndependence = (0.0001).toFixed(0);
        }

        let overallSOC = 0.0;
        let rangeHours = 0.0;
        if (
          this.state.systemConfiguration === "0" &&
          this.state.realTime.slaveCount > 0 &&
          this.state.moduleDataValid === 1
        ) {
          overallSOC = response.data.deviceLedger.realTime.battSOC.toFixed(0);
          if (batteryPower > 0.0)
            rangeHours = (
              (this.state.realTime.slaveCount * (overallSOC / 100) * 1440) /
              batteryPower
            ).toFixed(1);
          else if (batteryPower <= 0.0 && pHouse !== 0.0)
            rangeHours = (
              (this.state.realTime.slaveCount * (overallSOC / 100) * 1440) /
              pHouse
            ).toFixed(1);
          else rangeHours = "--";
          if (rangeHours >= 99.0) rangeHours = 99.0;
        }

        let solarProgressBar = 0;
        let batteryProgressBar = 0;
        let gridProgressBar = 0;

        if (bldgPower > 0) {
          console.log("In here Bldg Power > 0");
          solarProgressBar = ((pvPower * 100) / bldgPower).toFixed(1);
          batteryProgressBar = ((batteryPower * 100) / bldgPower).toFixed(1);
          gridProgressBar = ((gridPower * 100) / bldgPower).toFixed(1);
        } else {
          gridProgressBar = 100.0;
          if (batteryPower > 0) {
            //if discharging
            solarProgressBar = (
              (parseFloat(pvPower) * 100) /
              (parseFloat(pvPower) + parseFloat(batteryPower))
            ).toFixed(1);
            batteryProgressBar = (
              (parseFloat(batteryPower) * 100) /
              (parseFloat(pvPower) + parseFloat(batteryPower))
            ).toFixed(1);
          } else {
            //if charging
            solarProgressBar = 100.0;
            batteryProgressBar = 0.0;
          }
        }
        /////////////////////////////////////////////
        this.setState({
          solarProgressBar,
          batteryProgressBar,
          gridProgressBar,
        });
        /////////////////////////////////////////////////////////

        /////////////////////////////////////////////////////////////////////////////
        let aSVUPowerUnits = "Watts";
        let bSVUPowerUnits = "Watts";
        let cSVUPowerUnits = "Watts";
        let dSVUPowerUnits = "Watts";
        let eSVUPowerUnits = "Watts";
        let pHousePowerUnits = "Watts";
        if (
          (this.state.systemConfiguration === "0" &&
            this.state.realTime.slaveCount > 0 &&
            this.state.moduleDataValid === 1) ||
          this.state.systemConfiguration === "2" ||
          this.state.systemConfiguration === "3" ||
          this.state.systemConfiguration === "6" ||
          this.state.systemConfiguration === "7" /*||
          this.state.systemConfiguration === "8"*/
        ) {
          this.powerNunits(aSVU);
          aSVU = this.state.power;
          aSVUPowerUnits = this.state.powerUnits;
          this.setState({ aSVU, aSVUPowerUnits });

          this.powerNunits(bSVU);
          bSVU = this.state.power;
          bSVUPowerUnits = this.state.powerUnits;
          this.setState({ bSVU, bSVUPowerUnits });

          if (this.state.systemConfiguration !== "8") {
            this.powerNunits(cSVU);
            cSVU = this.state.power;
            cSVUPowerUnits = this.state.powerUnits;
            this.setState({ cSVU, cSVUPowerUnits });
          }

          this.powerNunits(dSVU);
          dSVU = this.state.power;
          dSVUPowerUnits = this.state.powerUnits;
          this.setState({ dSVU, dSVUPowerUnits });

          this.powerNunits(eSVU);
          eSVU = this.state.power;
          eSVUPowerUnits = this.state.powerUnits;
          this.setState({ eSVU, eSVUPowerUnits });

          this.powerNunits(pHouse);
          pHouse = this.state.power;
          pHousePowerUnits = this.state.powerUnits;
          this.setState({ pHouse, pHousePowerUnits });
        }

        this.powerNunits(gridPower);
        gridPower = this.state.power;
        gridPowerUnits = this.state.powerUnits;
        this.setState({ gridPower, gridPowerUnits });
        this.powerNunits(bldgPower);
        bldgPower = this.state.power;
        bldgPowerUnits = this.state.powerUnits;
        this.setState({ bldgPower, bldgPowerUnits });
        this.powerNunits(pvPower);
        pvPower = this.state.power;
        pvPowerUnits = this.state.powerUnits;
        this.setState({ pvPower, pvPowerUnits });
        this.powerNunits(batteryPower);
        batteryPower = this.state.power;
        batteryPowerUnits = this.state.powerUnits;
        this.setState({ batteryPower, batteryPowerUnits });
        /////////////////////////////////////

        let pvEnergy = 0;
        let batteryEnergy = 0;
        if (pvEnergy && batteryEnergy) {
          pvEnergy = this.state.Interval.pvEnergy / 100;
          batteryEnergy = this.state.Interval.batteryEnergy / 100;
        }

        this.setState({
          pvEnergy,
          batteryEnergy,
          gridPowerProgress,
          solarPowerProgress,
          battPowerProgress,
          energyIndependence,
        });

        this.setState({
          soc: (
            ((this.state.realTime ? this.state.realTime.batteryPower : 0) /
              (this.state.realTime ? this.state.realTime.pvPower : 0)) *
            100
          ).toFixed(1),
        });

        this.setState({ pvPower, batteryPower, gridPower, bldgPower });

        this.setState({
          loopCount,
          fwAppName,
          fwVersionMaster,
          systemStatus,
          commMode,
          commProvider,
          signalStrength,
          signalQuality,
          MeshId,
          wifiSignalStrength,
          wifiSSID,
          slaveCount,
          dataTimeInvalid,
          loadOne,
          loadTwo,
          loadThree,
          circuitNameMeter1,
          outageFlag,
          outageCount,
          overallSOC,
          rangeHours,
          freeHeap,
          minHeap,
          appPartition,
        });
        this.setState({
          aSVU,
          bSVU,
          cSVU,
          dSVU,
          eSVU,
          pHouse,
          smMeter1,
          smMeter2,
        });
        this.setState({ nodePriority, nodePriorityHeight, nodePriorityTop });
        if (!this.state.realTime) this.setState({ slaveCount: 0 });
        else this.setState({ slaveCount: this.state.realTime.slaveCount });
        this.setState({
          applianceInfo,
          realTimeInfo,
          loraDevices,
          systemControlsError,
          doorStatus,
          shedStatus,
          applianceStatus,
        });
        let firstLoading = this.state.firstLoading;
        if (applianceInfo && realTimeInfo) firstLoading = false;

        this.setState({ firstLoading });
      }
    }
  }

  sigFigures(powerDollar) {
    let sigNumber = 0;
    if (powerDollar < 100) sigNumber = powerDollar.toFixed(2);
    else if (powerDollar < 1000) sigNumber = powerDollar.toFixed(1);
    else
      sigNumber = powerDollar
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.setState({ sigNumber });
  }

  powerNunits(power) {
    let powerUnits = "Watts";
    let powerAbs = Math.abs(power);
    if (powerAbs < 100) {
      if (power >= 0) power = power.toFixed(2);
      else power = power.toFixed(1);
      powerUnits = "Watts";
    } else if (powerAbs < 1000) {
      if (power >= 0) power = power.toFixed(1);
      else power = power.toFixed(0);
      powerUnits = "Watts";
    } else if (powerAbs < 10000) {
      if (power >= 0) power = (power / 1000).toFixed(3);
      else power = (power / 1000).toFixed(2);
      powerUnits = "kW";
    } else if (powerAbs < 100000) {
      if (power >= 0) power = (power / 1000).toFixed(2);
      else power = (power / 1000).toFixed(1);
      powerUnits = "kW";
    } else if (powerAbs < 1000000) {
      if (power >= 0) power = (power / 1000).toFixed(1);
      else power = (power / 1000).toFixed(0);
      powerUnits = "kW";
    } else if (powerAbs < 10000000) {
      if (power >= 0) power = (power / 1000000).toFixed(3);
      else power = (power / 1000000).toFixed(2);
      powerUnits = "MW";
    } else if (powerAbs < 100000000) {
      if (power >= 0) power = (power / 1000000).toFixed(2);
      else power = (power / 1000000).toFixed(1);
      powerUnits = "MW";
    } else if (powerAbs < 1000000000) {
      if (power >= 0) power = (power / 1000000).toFixed(1);
      else power = (power / 1000000).toFixed(0);
      powerUnits = "MW";
    } else if (powerAbs < 10000000000) {
      if (power >= 0) power = (power / 1000000000).toFixed(3);
      else power = (power / 1000000000).toFixed(2);
      powerUnits = "GW";
    }
    this.setState({ power, powerUnits });
  }

  tick() {
    this.setState({
      dateNow: new Date(),
    });

    this.getSystemData();
  }

  handleChangeStart = (event) => {
    const startDate = event.setHours(0, 0, 0);
    this.setState({ startDate });
  };

  handleChangeEnd = (event) => {
    const endDate = event.setHours(0, 0, 0);
    this.setState({ endDate });
  };

  handleClickMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseRT = () => {
    this.setState({ anchorEl: null });
    const timeZone = this.state.timeZone;
    const endDate = new Date().setUTCHours(0 - timeZone, 0, 0) - 10;
    const startDate = endDate - this.state.intervalPeriod * 1.2 * 1000;
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Real-time", startDate, endDate });
  };

  handleCloseInterval = () => {
    this.setState({ anchorEl: null });
    const endDate = new Date().getTime() - 10;
    const startDate = endDate - this.state.intervalPeriod * 1.2 * 1000;
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Last Interval", startDate, endDate });
  };
  handleCloseToday = () => {
    this.setState({ anchorEl: null });
    const timeZone = this.state.timeZone;
    let now_UTC = Date.now();
    const endDate = now_UTC;
    const UTCnumOfHrs = new Date(now_UTC).getUTCHours();
    let startDate = 0;
    if (UTCnumOfHrs < -timeZone) now_UTC = now_UTC - 24 * 3600 * 1000;
    startDate = new Date(now_UTC).setUTCHours(-timeZone, 0, 0);
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Today", startDate, endDate });
  };
  handleCloseYesterday = () => {
    this.setState({ anchorEl: null });
    const timeZone = this.state.timeZone;
    let now_UTC = Date.now();
    const UTCnumOfHrs = new Date(now_UTC).getUTCHours();
    if (UTCnumOfHrs < -timeZone) now_UTC = now_UTC - 24 * 3600 * 1000;
    const endDate = new Date(now_UTC).setUTCHours(-timeZone, 0, 0);
    const startDate = endDate - 24 * 3600 * 1000;
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Yesterday", startDate, endDate });
  };
  handleCloseWeek = () => {
    this.setState({ anchorEl: null });
    const timeZone = this.state.timeZone;
    const now_UTC = Date.now();
    let myDay = new Date(now_UTC).getUTCDay();
    let startDate = new Date(now_UTC);
    if (myDay !== 0)
      startDate = new Date(now_UTC).setUTCHours(
        -24 * (myDay - 1) - timeZone,
        0,
        0
      );
    else {
      myDay = 7;
      startDate = new Date(now_UTC).setUTCHours(
        -24 * (myDay - 1) - timeZone,
        0,
        0
      );
    }
    const endDate = new Date(now_UTC).setUTCHours(24 - timeZone, 0, 0);
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Week", startDate, endDate });
  };
  handleCloseLastWeek = () => {
    this.setState({ anchorEl: null });
    const timeZone = this.state.timeZone;
    const now_UTC = Date.now();
    let myDay = new Date(now_UTC).getUTCDay();
    let startDate = new Date(now_UTC);
    if (myDay !== 0)
      startDate = new Date(now_UTC).setUTCHours(
        -24 * (myDay - 1) - timeZone,
        0,
        0
      );
    else {
      myDay = 7;
      startDate = new Date(now_UTC).setUTCHours(
        -24 * (myDay - 1) - timeZone,
        0,
        0
      );
    }
    const endDate = startDate;
    startDate = startDate - 7 * 24 * 3600 * 1000;

    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Last Week", startDate, endDate });
  };
  handleCloseMonth = () => {
    this.setState({ anchorEl: null });
    const myDay = new Date(),
      y = myDay.getFullYear(),
      m = myDay.getMonth();
    const startDate = new Date(y, m, 1).setHours(0, 0, 0);
    const endDate = new Date().getTime();
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Month", startDate, endDate });
  };
  handleCloseLastMonth = () => {
    this.setState({ anchorEl: null });
    const myDay = new Date(),
      y = myDay.getFullYear(),
      m = myDay.getMonth();
    const endDate = new Date(y, m, 1).setHours(0, 0, 0);
    let startDate = new Date();
    if (m > 0) startDate = new Date(y, m - 1, 1).setHours(0, 0, 0);
    else startDate = new Date(y - 1, 11, 1).setHours(0, 0, 0);
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Last Month", startDate, endDate });
  };
  handleCloseQuarter = () => {
    this.setState({ anchorEl: null });
    const myDay = new Date();
    const y = myDay.getFullYear();
    let m = myDay.getMonth();
    if (m >= 0 && m <= 2) m = 0;
    else if (m >= 3 && m <= 5) m = 3;
    else if (m >= 6 && m <= 8) m = 6;
    else m = 9;
    const startDate = new Date(y, m, 1).setHours(0, 0, 0);
    const endDate = new Date().getTime();
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Quarter", startDate, endDate });
  };
  handleCloseYear = () => {
    this.setState({ anchorEl: null });
    const myDay = new Date();
    const y = myDay.getFullYear();
    const startDate = new Date(y, 0, 1).setHours(0, 0, 0);
    const endDate = new Date().getTime();
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Year", startDate, endDate });
  };
  handleCloseDR = () => {
    this.setState({ anchorEl: null });
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    console.log("Start Date", startDate);
    console.log("End Date", endDate);
    this.computeInterval(startDate, endDate);
    this.setState({ modeBox: "Date Range" });
  };
  handleCloseDaily = () => {
    this.setState({ anchorEl: null });
    console.log("Dashboard Daily");
    this.setState({ modeBox: "Daily" });
    this.props.history.push(
      `/systems/dashboardDaily/${this.state.handleID}/${this.state.systemConfiguration}`
    );
  };
  editLayout = () => {
    this.setState({ anchorEl: null });
    console.log("edit Icon Locations.....true.....................");
    this.setState({ editLayoutOn: true });
  };
  saveLayout = () => {
    this.setState({ anchorEl: null });
    console.log("Save Icon Locations....false......................");
    this.setState({ editLayoutOn: false });
  };
  handleApplianceData = () => {
    this.setState({ anchorEl: null });
    console.log("Show Appliance Data..........................");
  };

  showNumbers = (e) => {
    e.preventDefault();
    let showNumbers = this.state.showNumbers;
    showNumbers = !showNumbers;
    const showNumbersBoolean = showNumbers;
    localStorage.setItem(
      "showNumbersBoolean",
      JSON.stringify(showNumbersBoolean)
    );
    console.log("showNumbers", showNumbers);
    if (showNumbers) localStorage.setItem("showNumbers", "show");
    else localStorage.setItem("showNumbers", "dontShow");
    this.setState({ showNumbers });
  };
  onStart = (nodeName, index) => {
    console.log("Just started dragging...................");
    this.setState({
      deltaPosition: {
        x: 0,
        y: 0,
      },
    });
  };
  onDrop = (e) => {
    console.log("Just Dropped the element.................");
  };
  handleDrag = (e, ui) => {
    const { x, y } = this.state.deltaPosition;
    const xDir = x + ui.deltaX;
    const yDir = y + ui.deltaY;
    console.log("Xdir and Ydir", xDir, yDir);

    this.setState({
      deltaPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      },
    });
  };
  onStop = async (nodeName, index) => {
    const deltaPositionX = this.state.deltaPosition.x;
    const deltaPositionY = this.state.deltaPosition.y;

    console.log("Node Name:", nodeName);
    console.log("Index:", index);
    console.log("Just Stopped dragging the element............");

    let systemControls = this.state.systemControls[index];
    console.log("System Controls On STOP:", systemControls);

    const xDir = systemControls.nodePositionX + deltaPositionX;
    const yDir = systemControls.nodePositionY + deltaPositionY;

    console.log(" xDIR & yDIR", xDir, yDir);
    /*const rectView = document
      .getElementById("mainNodeVu")
      .getBoundingClientRect();
    console.log("rectView:", rectView);
    console.log(rectView.left, rectView.top, rectView.right, rectView.bottom);
    const rectElement = document.getElementById(index).getBoundingClientRect();
    console.log(
      rectElement.left,
      rectElement.top,
      rectElement.right,
      rectElement.bottom
    );
    console.log("rectElement:", rectElement);*/
    /*const target = systemControls;
    const source = {
      nodePositionX: rectElement.left,
      nodePositionY: rectElement.top,
    };*/
    const target = systemControls;
    const source = {
      nodePositionX: xDir,
      nodePositionY: yDir,
    };
    Object.assign(target, source);

    systemControls = (await getSystemControls(this.state.handleID)).data.data
      .controls;
    console.log("System Controls from DB:", systemControls);
    const newPositionControls = systemControls.splice(index, 1, target);
    console.log("New Position Controls Array:", systemControls);

    const systemControlsStr = JSON.stringify(systemControls);
    console.log("System Controls to be sent to DB:", systemControlsStr);
    await setSystemControlsObject(this.state.systemID, systemControlsStr);

    this.setState({
      systemControls,
      editModeOn: true,
      deltaPosition: {
        x: 0,
        y: 0,
      },
    });
    this.setState({
      editModeOn: false,
    });
  };

  handleOpenModal = async (nodeName, index) => {
    let openApplianceModalOld = this.state.openApplianceModal;
    console.log("...........Got here for Index:", index, openApplianceModalOld);
    if (openApplianceModalOld[index]) openApplianceModalOld[index] = false;
    else openApplianceModalOld[index] = true;

    this.setState({ openApplianceModal: openApplianceModalOld });
  };
  showLora = (e) => {
    let openLora = this.state.openLora;

    if (openLora) openLora = false;
    else openLora = true;

    this.setState({ openLora });
  };
  handleOverRide = async (nodeName) => {
    this.setState({ anchorEl: null });
    console.log("Node Name: ", nodeName);
    await setSystemSignals(
      this.state.systemID,
      "80",
      "TBD",
      "TBD",
      nodeName,
      "TBD"
    );

    await initSystemUpdate(this.props.match.params.systemHandleID);
  };

  searchClosedTasks = (tasks, username) => {
    try {
      if (!tasks || tasks.length === 0) {
        return true;
      }
      const userTasks = [];

      for (const element of tasks) {
        const userReply = element.replies.find((reply) => {
          return reply.assignee === username;
        });

        if (element.assignee === username || userReply) {
          userTasks.push(element);
        }
      }

      for (const task of userTasks) {
        const repliesFiltered = task.replies.filter((reply) => {
          return reply.status === "Closed";
        });

        if (task.status !== "Closed" && repliesFiltered.length === 0) {
          return false;
        }
      }

      return true;
    } catch (e) {
      console.log("Error:");
      console.log(e);
      return true;
    }
  };

  render() {
    //console.log(this.state.editModeOn, this.state.editLayoutOn);
    return (
      <React.Fragment>
        {" "}
        <ScrollView
          horizontal={true}
          style={{
            height: "200vh",
            width: "190vh",
            flexGrow: 1,
            justifyContent: "center",
          }}
        >
          <div
            id="mainNodeVu"
            className="containerSimplNodeVu"
            style={{
              position: "relative",
              marginTop: "120px",
              overflowY: "scrollY",
              zIndex: 1,
            }}
          >
            <div className="mainMenuNodesIcons">
              <MainMenuWide
                systemName={this.state.systemName}
                systemHumanTime={this.state.systemHumanTime}
                commMode={this.state.commMode}
                wifiSignalStrength={this.state.wifiSignalStrength}
                slaveCount={this.state.slaveCount}
                loopCount={this.state.loopCount}
                outageFlag={this.state.outageFlag}
                acCoupledBackUp={this.state.acCoupledBackUp}
                moduleDataValid={this.state.moduleDataValid}
                systemConfiguration={this.state.systemConfiguration}
                inCompleteData={this.state.inCompleteData}
                weatherIcon={this.state.weatherIcon}
                weatherTemp={this.state.weatherTemp}
                weatherRH={this.state.weatherRH}
                realTimePower={this.state.realTimePower}
                intervalPower={this.state.intervalPower}
                globalPriority={this.state.globalPriority}
                peakPower={this.state.peakPower}
              />

              <table style={{ transform: "translateX(750px)" }}>
                <tr style={{ height: "40px" }}>
                  {(this.state.userRole === "operatorOf" ||
                    this.state.userRole === "ownerOf" ||
                    this.state.userRole === "companyAdmin") && (
                    <td style={{ width: "140px", padding: "0", margin: "0" }}>
                      {this.state.modeBox === "Date Range" && (
                        <div>
                          {" "}
                          <DatePicker
                            className="ui-datepicker"
                            selected={this.state.startDate}
                            selectsStart
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeStart}
                          />
                          <DatePicker
                            className="ui-datepicker"
                            selected={this.state.endDate}
                            selectsEnd
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeEnd}
                          />
                        </div>
                      )}
                    </td>
                  )}

                  {(this.state.userRole === "operatorOf" ||
                    this.state.userRole === "ownerOf" ||
                    this.state.userRole === "companyAdmin") && (
                    <td>
                      <p
                        className="indicateParagraph"
                        style={{
                          position: "relative",
                          marginRight: "0px",
                          borderWidth: "0px",
                        }}
                      >
                        {" "}
                        {this.state.modeBox}
                      </p>
                    </td>
                  )}

                  {(this.state.userRole === "operatorOf" ||
                    this.state.userRole === "ownerOf" ||
                    this.state.userRole === "companyAdmin") && (
                    <td style={{ position: "relative", marginRight: "0px" }}>
                      <SimpleMenu
                        onClickingMenu={this.handleClickMenu}
                        onChoosingRT={this.handleCloseRT}
                        onChoosingInterval={this.handleCloseInterval}
                        onChoosingToday={this.handleCloseToday}
                        onChoosingYesterday={this.handleCloseYesterday}
                        onChoosingWeek={this.handleCloseWeek}
                        onChoosingLastWeek={this.handleCloseLastWeek}
                        onChoosingMonth={this.handleCloseMonth}
                        onChoosingLastMonth={this.handleCloseLastMonth}
                        onChoosingQuarter={this.handleCloseQuarter}
                        onChoosingYear={this.handleCloseYear}
                        onChoosingDR={this.handleCloseDR}
                        onChoosingDaily={this.handleCloseDaily}
                        anchorState={this.state.anchorEl}
                      />
                    </td>
                  )}

                  {(this.state.userRole === "operatorOf" ||
                    this.state.userRole === "ownerOf" ||
                    this.state.userRole === "companyAdmin") && (
                    <td style={{ position: "relative", marginRight: "0px" }}>
                      <button
                        className="btn btn-primary shadow-none"
                        style={{
                          borderWidth: "0px",
                          padding: "0px",
                          float: "right",
                          marginRight: "5px",
                          position: "relative",
                          right: "0px",
                          color: "transparent",
                          backgroundColor: "transparent",
                        }}
                      >
                        <Link
                          to={`/systems/dashboardChart/${this.state.handleID}
                        /${this.props.match.params.companyName}
                        /${this.state.startDate}
                        /${this.state.endDate}
                        /${this.props.match.params.userRole}`}
                        >
                          <FontAwesomeIcon
                            icon="chart-line"
                            size="sm"
                            color="orange"
                            title="System Information"
                          />
                        </Link>
                      </button>
                    </td>
                  )}

                  {(this.state.userRole === "operatorOf" ||
                    this.state.userRole === "ownerOf" ||
                    this.state.userRole === "companyAdmin") && (
                    <td style={{ position: "relative", marginRight: "0px" }}>
                      <CostNSavingsModal
                        pvIntervalTotal={this.state.pvIntervalTotal}
                        pvEnergyValue={this.state.pvEnergyValue}
                        pvDemandValue={this.state.pvDemandValue}
                        battIntervalTotal={this.state.battIntervalTotal}
                        battEnergyValue={this.state.battEnergyValue}
                        battDemandValue={this.state.battDemandValue}
                        gridIntervalTotal={this.state.gridIntervalTotal}
                        gridEnergyValue={this.state.gridEnergyValue}
                        gridDemandValue={this.state.gridDemandValue}
                        bldgIntervalTotal={this.state.bldgIntervalTotal}
                        bldgEnergyValue={this.state.bldgEnergyValue}
                        bldgDemandValue={this.state.bldgDemandValue}
                        savingsIntervalTotal={this.state.savingsIntervalTotal}
                        savingsEnergyValue={this.state.savingsEnergyValue}
                        gridMaxDemand={this.state.gridMaxDemand}
                        bldgMaxDemand={this.state.bldgMaxDemand}
                        demandSavings={this.state.demandSavings}
                        totalSavings={this.state.totalSavings}
                        gridMaxKW={this.state.gridMaxKW}
                        bldgMaxKW={this.state.bldgMaxKW}
                        totalUsage={this.state.totalUsage}
                        netCosts={this.state.netCosts}
                        percentEnergySavings={this.state.percentEnergySavings}
                        percentDemandSavings={this.state.percentDemandSavings}
                        percentTotalSavings={this.state.percentTotalSavings}
                      />
                    </td>
                  )}

                  {(this.state.userRole === "operatorOf" ||
                    this.state.userRole === "ownerOf" ||
                    this.state.userRole === "companyAdmin") && (
                    <td
                      style={{ right: "5px", marginTop: "7px", width: "30px" }}
                    >
                      <button
                        onClick={this.showLora}
                        className="btn btn-primary shadow-none"
                        style={{
                          padding: "0px",
                          borderWidth: "0px",
                          color: "transparent",
                          backgroundColor: "transparent",
                        }}
                      >
                        <LoraSvg />
                        {this.state.loraDevices && (
                          <LoraModal
                            show={this.state.openLora}
                            loraDevices={this.state.loraDevices}
                          />
                        )}
                      </button>
                    </td>
                  )}

                  {(this.state.userRole === "operatorOf" ||
                    this.state.userRole === "companyAdmin") && (
                    <td style={{ right: "5px", marginTop: "7px" }}>
                      <button
                        onClick={this.editLayout}
                        className="btn btn-primary shadow-none"
                        style={{
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          borderWidth: "0px",
                          color: "transparent",
                          backgroundColor: "transparent",
                        }}
                      >
                        <FontAwesomeIcon
                          icon="edit"
                          size="sm"
                          color="green"
                          title="Change Icons Locations"
                        />
                      </button>
                    </td>
                  )}
                  {(this.state.userRole === "operatorOf" ||
                    this.state.userRole === "companyAdmin") && (
                    <td style={{ position: "relative", marginRight: "0px" }}>
                      <button
                        onClick={this.saveLayout}
                        className="btn btn-primary shadow-none"
                        style={{
                          paddingLeft: "0",
                          borderWidth: "0px",
                          color: "transparent",
                          backgroundColor: "transparent",
                        }}
                      >
                        <FontAwesomeIcon
                          icon="save"
                          size="sm"
                          color="green"
                          title="Save Icons Locations"
                        />
                      </button>
                    </td>
                  )}

                  {/*  {this.state.userRole !== "ownerOf" && (*/}
                  <td
                    style={{
                      right: "5px",
                      marginTop: "7px",
                    }}
                  >
                    <button
                      className="btn btn-primary shadow-none"
                      style={{
                        borderWidth: "0px",
                        padding: "0px",
                        marginRight: "5px",
                        color: "transparent",
                        backgroundColor: "transparent",
                        right: "0px",
                      }}
                    >
                      <Link
                        to={`/systems/simplNodesVu/${this.state.handleID}
                        /${this.props.match.params.companyName}/${this.state.serialNumber}/${this.state.systemName}/${this.state.userRole}`}
                      >
                        <FontAwesomeIcon
                          icon="mobile-alt"
                          size="sm"
                          color="tomato"
                          title="Cell Phone View"
                        />
                      </Link>
                    </button>
                  </td>
                  {/*     )}*/}
                  {/*  {this.state.userRole !== "ownerOf" && (*/}
                  <td
                    style={{
                      right: "5px",
                      marginTop: "7px",
                    }}
                  >
                    <button
                      className="btn btn-primary shadow-none"
                      style={{
                        borderWidth: "0px",
                        padding: "0px",
                        marginRight: "5px",
                        color: "transparent",
                        backgroundColor: "transparent",
                        right: "0px",
                      }}
                    >
                      <Link
                        to={`/systems/listView/${this.state.handleID}
                        /${this.props.match.params.companyName}/${this.state.userRole}`}
                      >
                        <FontAwesomeIcon
                          icon="list-ul"
                          size="lg"
                          color="dodgerblue"
                          title="List View"
                        />
                      </Link>
                    </button>
                  </td>
                  {/*     )}*/}

                  {/*  {this.state.userRole !== "ownerOf" && (*/}
                  <td
                    style={{
                      right: "5px",
                      marginTop: "7px",
                    }}
                  >
                    <button
                      className="btn btn-primary shadow-none"
                      style={{
                        borderWidth: "0px",
                        padding: "0px",
                        marginRight: "5px",
                        color: "transparent",
                        backgroundColor: "transparent",
                        right: "0px",
                      }}
                    >
                      <Link
                        to={`/systems/dashboardWideSlaves/${this.state.handleID}
                        /${this.props.match.params.companyName}`}
                      >
                        <FontAwesomeIcon
                          icon="search"
                          size="sm"
                          color="brown"
                          title="Subsystems"
                        />
                      </Link>
                    </button>
                  </td>
                  {/*     )}*/}

                  <td
                    style={{
                      right: "5px",
                      marginTop: "7px",
                    }}
                  >
                    <SystemInfoModal
                      loopCount={this.state.loopCount}
                      fwVersionMaster={this.state.fwVersionMaster}
                      fwAppName={this.state.fwAppName}
                      slaveCount={this.state.slaveCount}
                      companyName={this.props.match.params.companyName}
                      energyRate={this.state.energyRate}
                      demandRate={this.state.demandRate}
                      onPeakEnergy={this.state.onPeakEnergy}
                      onPeakDemand={this.state.onPeakDemand}
                      systemStatus={this.state.systemStatus}
                      HandleID={this.state.handleID}
                      systemDescription={this.state.systemDescription}
                      systemConfiguration={this.state.systemConfiguration}
                      systemProduct={this.state.systemProduct}
                      systemType={this.state.systemType}
                      paramUpdateRate={this.state.paramUpdateRate}
                      dataVerbosity={this.state.dataVerbosity}
                      dataFrequency={this.state.dataFrequency}
                      serialNumber={this.state.serialNumber}
                      macAddress={this.state.macAddress}
                      systemName={this.state.systemName}
                      commProvider={this.state.commProvider}
                      signalStrength={this.state.signalStrength}
                      signalQuality={this.state.signalQuality}
                      productSN={this.state.productSN}
                      emaSN={this.state.emaSN}
                      attachedSimplModuleSN={this.state.attachedSimplModuleSN}
                      attachedSimplModuleHandle={
                        this.state.attachedSimplModuleHandle
                      }
                      moduleDataValid={this.state.moduleDataValid}
                      ssId={this.state.wifiSSID}
                      MeshId={this.state.MeshId}
                      outageFlag={this.state.outageFlag}
                      outageCount={this.state.outageCount}
                      acCoupledBackUp={this.state.acCoupledBackUp}
                      freeHeap={this.state.freeHeap}
                      minHeap={this.state.minHeap}
                      appPartition={this.state.appPartition}
                    />
                  </td>
                </tr>
              </table>
            </div>

            <BldgShape
              perimeterShape={this.state.perimeterShape}
              perimeterColor="black"
            />

            {this.state.systemControls && (
              <div>
                {this.state.editModeOn && this.state.editLayoutOn && (
                  <div>
                    {this.state.systemControls.map((node, index) => (
                      <tr key={node.id}>
                        <Draggable
                          onDrag={this.handleDrag}
                          onStart={() =>
                            this.onStart(`${node.nodeName}`, `${index}`)
                          }
                          onStop={() =>
                            this.onStop(`${node.nodeName}`, `${index}`)
                          }
                          onDrop={this.onDrop}
                        >
                          <button
                            style={{
                              borderWidth: "0px",
                              backgroundColor: "transparent",
                              outline: "none",
                              width: "50px",
                              height: "50px",
                              position: "absolute",
                              left: "0px",
                              top: "0px",
                            }}
                          >
                            <td
                              style={{
                                margin: "0px",
                                padding: "0px",
                                width: "40px",
                                height: "15px",
                                borderTop: "none",
                                borderBottom: "none",
                              }}
                            >
                              <ApplianceIcon
                                loadType={node.loadType}
                                nodeName={node.nodeName}
                                nodeShedStatus={this.state.shedStatus[index]}
                                nodeDoorStatus={this.state.doorStatus[index]}
                                nodeDoorOrientation={node.nodeDoorOrientation}
                                applianceStatus={
                                  this.state.applianceStatus[index]
                                }
                              />
                            </td>
                          </button>
                        </Draggable>
                      </tr>
                    ))}
                  </div>
                )}

                {!this.state.editModeOn && this.state.editLayoutOn && (
                  <div>
                    {this.state.systemControls.map((node, index) => (
                      <tr key={node.id}>
                        <Draggable
                          onDrag={this.handleDrag}
                          onStart={() =>
                            this.onStart(`${node.nodeName}`, `${index}`)
                          }
                          onStop={() =>
                            this.onStop(`${node.nodeName}`, `${index}`)
                          }
                          onDrop={this.onDrop}
                        >
                          <button
                            style={{
                              borderWidth: "0px",
                              outline: "none",
                              backgroundColor: "transparent",
                              width: "50px",
                              height: "50px",
                              position: "absolute",
                              left: `${node.nodePositionX}px`,
                              top: `${node.nodePositionY}px`,
                            }}
                          >
                            <td
                              style={{
                                margin: "0px",
                                padding: "0px",
                                width: "40px",
                                height: "15px",
                                borderTop: "none",
                                borderBottom: "none",
                              }}
                            >
                              <ApplianceIcon
                                loadType={node.loadType}
                                nodeName={node.nodeName}
                                nodeShedStatus={this.state.shedStatus[index]}
                                nodeDoorStatus={this.state.doorStatus[index]}
                                nodeDoorOrientation={node.nodeDoorOrientation}
                                applianceStatus={
                                  this.state.applianceStatus[index]
                                }
                              />
                            </td>
                          </button>
                        </Draggable>
                      </tr>
                    ))}
                  </div>
                )}

                {!this.state.editLayoutOn && (
                  <div>
                    {this.state.systemControls.map((node, index) => (
                      <tr key={node.id}>
                        <Draggable
                          onDrag={this.handleDrag}
                          onStart={() => false}
                          onStop={() =>
                            this.onStop(`${node.nodeName}`, `${index}`)
                          }
                          onDrop={this.onDrop}
                        >
                          <button
                            style={{
                              borderWidth: "0px",
                              outline: "none",
                              backgroundColor: "transparent",
                              width: "50px",
                              height: "50px",
                              position: "absolute",
                              left: `${node.nodePositionX}px`,
                              top: `${node.nodePositionY}px`,
                              backdrop: "false",
                            }}
                            onClick={() =>
                              this.handleOpenModal(
                                `${node.nodeName}`,
                                `${index}`
                              )
                            }
                          >
                            {this.state.applianceInfo &&
                              !this.state.systemControlsError && (
                                <ApplianceModal
                                  applianceIndex={index}
                                  show={this.state.openApplianceModal[index]}
                                  leftPosition={node.nodePositionX}
                                  topPosition={node.nodePositionY}
                                  nodeInfo={node}
                                  applianceInfo={this.state.applianceInfo}
                                  handleOverRide={this.handleOverRide}
                                />
                              )}
                            <td
                              style={{
                                margin: "0px",
                                padding: "0px",
                                width: "40px",
                                height: "15px",
                                borderTop: "none",
                                borderBottom: "none",
                              }}
                            >
                              <ApplianceIcon
                                loadType={node.loadType}
                                nodeName={node.nodeName}
                                nodeShedStatus={this.state.shedStatus[index]}
                                nodeDoorStatus={this.state.doorStatus[index]}
                                nodeDoorOrientation={node.nodeDoorOrientation}
                                applianceStatus={
                                  this.state.applianceStatus[index]
                                }
                              />
                            </td>
                          </button>
                        </Draggable>
                      </tr>
                    ))}
                  </div>
                )}
              </div>
            )}

            {!this.state.firstLoading &&
              !this.state.editLayoutOn &&
              this.state.realTimeInfo && (
                <div
                  style={{
                    position: "absolute",
                    top: "73px",
                    left: "0px",
                    width: "300px",
                  }}
                >
                  <EnergyIndepProg
                    gridPowerProgress={this.state.gridPowerProgress}
                    solarPowerProgress={this.state.solarPowerProgress}
                    battPowerProgress={this.state.battPowerProgress}
                  />
                  <GaugeGraphII
                    chartStartAngle={225}
                    chartEndAngle={0}
                    demandLimit={this.state.realTimeInfo.peakPower.toFixed(1)}
                    smMeter1={this.state.cSVU / 1000.0}
                    smMeter2={this.state.bSVU / 1000.0}
                    batteryPower={-1.0 * 0.0}
                  />

                  <NodeProgBars
                    realTimeNumber={(
                      parseFloat(this.state.cSVU) / 1000.0
                    ).toFixed(1)}
                    realTimeColor="grey"
                    intervalNumber={parseFloat(
                      this.state.Interval.thisInterval_1
                    ).toFixed(1)}
                    intervalColor="grey"
                    rollingIntervalNumber={parseFloat(
                      this.state.Interval.IntEnergy1
                    ).toFixed(1)}
                    rollingIntervalColor="grey"
                    powerUnit="kW"
                    demandLimitPercent="90%"
                    demandLimit={this.state.realTimeInfo.peakPower.toFixed(1)}
                  />
                  <div
                    className="progress-bar"
                    style={{
                      position: "absolute",
                      top: "90px",
                      left: "270px",
                      height: "120px",
                      backgroundColor: "grey",
                      width: "15px",
                      borderRadius: "3px",
                    }}
                  ></div>
                  <PriorityProgress
                    divTop={this.state.nodePriorityTop}
                    divHeight={this.state.nodePriorityHeight}
                  />
                </div>
              )}

            <div
              className="mainMenuNodesIcons"
              style={{ paddingBottom: "10px", transform: "translateY(450px)" }}
            >
              <SimplFooterWide
                handleID={this.props.match.params.systemHandleID}
                systemConfiguration={this.state.systemConfiguration}
                companyName={this.props.match.params.companyName}
                userRole={this.props.match.params.userRole}
                systemSN={this.props.match.params.systemSN}
                displayWidth={this.state.displayWidth}
                connection={this.state.connection}
                systemStatus={this.state.systemStatus}
                closedTasks={this.state.closedTasks}
              />
            </div>

            {this.state.firstLoading && (
              <div
                class="d-flex justify-content-center"
                style={{ transform: "translate(0px,100px)" }}
              >
                <div
                  class="spinner-border text-primary m-2"
                  role="status"
                  style={{ width: "3rem", height: "3rem" }}
                ></div>
              </div>
            )}
          </div>
        </ScrollView>
      </React.Fragment>
    );
  }
}
export default SimplNodesVuWide;
