import http from "./httpService";
import { apiUrl, OPEN_WEATHER_API_KEY, OPEN_WEATHER_URL } from "../config.json";
import axios from "axios";

const apiEndpoint = apiUrl + "/system";
//const fakeWsEndPoint = "http://192.168.2.9/ws";

/////////////  Websocket Addition used for LatestData  ////////////
/*const simplWS_client = new WebSocket("ws://127.0.0.1:8082");
simplWS_client.onopen = () => {
  console.log("WebSocket Client Connected to WebSocket Server!");
};
let wsValue = {};*/
////////////  End of Websocket Addition  ////////////////////////

export async function getSystems() {
  return await http.get(apiEndpoint);
}
export async function ws_get(ipAddress) {
  return await axios.get("http://" + ipAddress + "/ws");
}
export async function ws_post(ipAddress, message) {
  return await axios.post("http://" + ipAddress + "/ws", message);
}
export async function getSystem(systemId) {
  return await http.post(apiEndpoint, {
    systemID: systemId,
  });
}

export async function getSystemIDfromHandle(handleId) {
  return await http.post(apiEndpoint + "/find_id_by_handle", {
    handleID: handleId,
  });
}

export async function getSystemInfo(handleId) {
  return await http.get(apiEndpoint + `/${handleId}`);
}

export async function getSystemLatestData(handleId) {
  //console.log("Hello from SimplMeter get SystemLatestData!");
  return await http.get(apiEndpoint + `/${handleId}` + "/latestData");
  //return await http.get("http://127.0.0.1:3002/api/system/179/latestData");
}

export function ws_getSystemLatestData(handleId) {
  //{
  //simplWS_client.send("ws_getSystemLatestData" + `/${handleId}`);
  //simplWS_client.onmessage = function (e) {
  //  wsValue = e.data;
  // };
  return 0;
  //JSON.parse(wsValue);
}

export async function getSystemRealTimeData(handleId, startDate, endDate) {
  return await http.get(
    apiEndpoint +
      `/${handleId}` +
      "/realTimeData?startDate=" +
      `${startDate}&endDate=` +
      `${endDate}`
  );
}

export async function getSystemIntervalData(handleId, startDate, endDate) {
  return await http.get(
    apiEndpoint +
      `/${handleId}` +
      "/intervalData?startDate=" +
      `${startDate}&endDate=` +
      `${endDate}`
  );
}

export async function getSystemDailyData(handleId, startDate, endDate) {
  return await http.get(
    apiEndpoint +
      `/${handleId}` +
      "/dailyReports?startDate=" +
      `${startDate}&endDate=` +
      `${endDate}`
  );
}

export async function createSystem(
  mac_address,
  serial_number,
  productPN,
  productType
) {
  return await http.post(apiEndpoint + "/create", {
    macAddress: mac_address,
    serialNumber: serial_number,
    product: productPN,
    productType: productType,
  });
}

export async function installSystem(
  mac_address,
  serial_number,
  timezone,
  street,
  city,
  state,
  country,
  zip
) {
  return await http.post(apiEndpoint + "/install", {
    macAddress: mac_address,
    serialNumber: serial_number,
    timezone: timezone,
    address: {
      street: street,
      city: city,
      state: state,
      country: country,
      zip: zip,
    },
  });
}

export async function getSystemSignals(handleId) {
  return await http.get(apiEndpoint + `/${handleId}` + "/signals");
}

export async function setSystemSignals(
  systemId,
  performFunction,
  WiFi_SSID,
  WiFi_PSWD,
  slaveParams,
  slaveMAC,
  username
) {
  return await http.post(apiEndpoint + "/signals", {
    systemID: systemId,
    username: username,
    systemSignals: {
      performFunction: performFunction,
      WiFi_SSID: WiFi_SSID,
      WiFi_PSWD: WiFi_PSWD,
      slaveParams: slaveParams,
      slaveMAC: slaveMAC,
    },
  });
}

export async function getSystemLimits(handleId) {
  return await http.get(apiEndpoint + `/${handleId}` + "/limits");
}

export async function setSystemLimitsObject(systemId, systemLimits, username) {
  return await http.post(apiEndpoint + "/limits", {
    systemID: systemId,
    systemLimits: systemLimits,
    username: username,
  });
}

export async function setSystemLimits(
  systemId,
  inverterType,
  maxSolarVoltage,
  maxSolarInvPower,
  minSolarInvPower,
  solarMppV,
  solarInverterTimeOut,
  tSenseMaxTemp,
  tSenseMinTemp,
  heatSinkMaxTemp,
  heatSinkMinTemp,
  maxCellVoltage,
  minCellVoltage,
  maxCellDeltaV,
  maxPackVoltage,
  battNotFullV,
  battNotEmptyV,
  minPackVoltage,
  maxBatteryPower,
  minSOC
) {
  return await http.post(apiEndpoint + "/limits", {
    systemID: systemId,
    systemLimits: {
      inverterType: inverterType,
      maxSolarVoltage: maxSolarVoltage,
      maxSolarInvPower: maxSolarInvPower,
      minSolarInvPower: minSolarInvPower,
      solarMppV: solarMppV,
      solarInverterTimeOut: solarInverterTimeOut,
      tSenseMaxTemp: tSenseMaxTemp,
      tSenseMinTemp: tSenseMinTemp,
      heatSinkMaxTemp: heatSinkMaxTemp,
      heatSinkMinTemp: heatSinkMinTemp,
      maxCellVoltage: maxCellVoltage,
      minCellVoltage: minCellVoltage,
      maxCellDeltaV: maxCellDeltaV,
      maxPackVoltage: maxPackVoltage,
      battNotFullV: battNotFullV,
      battNotEmptyV: battNotEmptyV,
      minPackVoltage: minPackVoltage,
      maxBatteryPower: maxBatteryPower,
      minSOC: minSOC,
    },
  });
}

export async function getSystemControls(handleId) {
  return await http.get(apiEndpoint + `/${handleId}` + "/controls");
}

export async function setSystemControlsObject(
  systemId,
  systemControls,
  username,
  option,
  nodeName,
  prevTempHeatOc,
  tempHeatOc,
  prevTempCoolOc,
  TempCoolOc,
  prevTempHeatUn,
  tempHeatUn,
  prevTempCoolUn,
  tempCoolUn
) {
  return await http.post(apiEndpoint + "/controls", {
    systemID: systemId,
    systemControls: systemControls,
    username: username,
    option: option,
    nodeName,

    prevTempHeatOc,
    tempHeatOc,
    prevTempCoolOc,
    TempCoolOc,
    prevTempHeatUn,
    tempHeatUn,
    prevTempCoolUn,
    tempCoolUn,
  });
}

export async function setSystemControls(
  systemId,
  modeOfOperation,
  touStart,
  touStop,
  fiveDaysOr7,
  onPeakMonthsStart,
  onPeakMonthStop,
  powerReduction,
  maxGridPower,
  minGridPower,
  load1,
  load2,
  load3,
  moduleSN,
  moduleHandleId,
  methodOfUse,
  sm2Multiplier,
  disChargeSyncTime,
  socketName,
  socketConfig,
  backUpMode,
  dcVout
) {
  return await http.post(apiEndpoint + "/controls", {
    systemID: systemId,
    systemControls: {
      modeOfOperation: modeOfOperation,
      touStart: touStart,
      touStop: touStop,
      fiveDaysOr7: fiveDaysOr7,
      onPeakMonthsStart: onPeakMonthsStart,
      onPeakMonthStop: onPeakMonthStop,
      powerReduction: powerReduction,
      maxGridPower: maxGridPower,
      minGridPower: minGridPower,
      load1: load1,
      load2: load2,
      load3: load3,
      moduleSN: moduleSN,
      moduleHandleId: moduleHandleId,
      methodOfUse: methodOfUse,
      sm2Multiplier: sm2Multiplier,
      disChargeSyncTime: disChargeSyncTime,
      socketName: socketName,
      socketConfig: socketConfig,
      backUpMode: backUpMode,
      dcVout: dcVout,
    },
  });
}

export async function getSystemBasics(handleId) {
  return await http.get(apiEndpoint + `/${handleId}` + "/basics");
}

export async function setSystemBasicsObject(systemId, systemBasics) {
  return await http.post(apiEndpoint + "/basics", {
    systemID: systemId,
    systemBasics: systemBasics,
  });
}

export async function setSystemBasics(
  systemId,
  systemDescription,
  systemConfiguration,
  systemProduct,
  systemType,
  systemTimeZone,
  dataVerbosity,
  dataFrequency,
  localLogLength,
  localLogFrequency,
  localLogVerbosity,
  resetDelay,
  paramUpdateRate,
  intervalDataPeriod,
  circuitNameMeter1,

  ct1RatioMeter1,
  ct2RatioMeter1,

  ctDirectionMeter1A,
  ctDirectionMeter1B,
  ctDirectionMeter1C,
  voltageWiring1,
  ctWiring1,
  demandMode1,
  circuitNameMeter2,

  ct1RatioMeter2,
  ct2RatioMeter2,

  ctDirectionMeter2A,
  ctDirectionMeter2B,
  ctDirectionMeter2C,
  voltageWiring2,
  ctWiring2,
  demandMode2,

  productSN,
  slaveSN,
  fourGserialNumber,

  endProductPN,
  masterBoardSN,

  CTtype,
  GridFrequency,
  VmeasGain,
  ImeasGain1,
  ImeasDigitalGain1,
  ImeasGain2,
  ImeasDigitalGain2,

  meter1CTtype,
  meter2CTtype,

  heaterCheckBox,

  systemCity,
  systemState,
  systemCountry,
  systemZip,
  username,

  simplViewCheckBox
) {
  return await http.post(apiEndpoint + "/basics", {
    systemID: systemId,
    username: username,
    systemBasics: {
      systemDescription: systemDescription,
      systemConfiguration: systemConfiguration,
      systemProduct: systemProduct,
      systemType: systemType,
      systemTimeZone: systemTimeZone,
      dataVerbosity: dataVerbosity,
      dataFrequency: dataFrequency,
      localLogLength: localLogLength,
      localLogFrequency: localLogFrequency,
      localLogVerbosity: localLogVerbosity,
      resetDelay: resetDelay,
      paramUpdateRate: paramUpdateRate,
      intervalDataPeriod: intervalDataPeriod,
      circuitNameMeter1: circuitNameMeter1,

      ctRatioMeter1: ct1RatioMeter1,
      ct2RatioMeter1: ct2RatioMeter1,

      ctDirectionMeter1A: ctDirectionMeter1A,
      ctDirectionMeter1B: ctDirectionMeter1B,
      ctDirectionMeter1C: ctDirectionMeter1C,
      voltageWiring1: voltageWiring1,
      ctWiring1: ctWiring1,
      demandMode1: demandMode1,
      circuitNameMeter2: circuitNameMeter2,

      ctRatioMeter2: ct1RatioMeter2,
      ct2RatioMeter2: ct2RatioMeter2,

      ctDirectionMeter2A: ctDirectionMeter2A,
      ctDirectionMeter2B: ctDirectionMeter2B,
      ctDirectionMeter2C: ctDirectionMeter2C,
      voltageWiring2: voltageWiring2,
      ctWiring2: ctWiring2,
      demandMode2: demandMode2,

      productSN: productSN,
      slaveSN: slaveSN,
      fourGserialNumber: fourGserialNumber,

      endProductPN: endProductPN,
      masterBoardSN: masterBoardSN,

      CTtype: CTtype,
      GridFrequency: GridFrequency,
      VmeasGain: VmeasGain,
      ImeasGain1: ImeasGain1,
      ImeasDigitalGain1: ImeasDigitalGain1,
      ImeasGain2: ImeasGain2,
      ImeasDigitalGain2: ImeasDigitalGain2,

      meter1CTtype: meter1CTtype,
      meter2CTtype: meter2CTtype,

      heaterCheckBox: heaterCheckBox,

      systemCity: systemCity,
      systemState: systemState,
      systemCountry: systemCountry,
      systemZip: systemZip,

      simplViewCheckBox: simplViewCheckBox,
    },
  });
}

export async function uninstallSystem(systemId) {
  return await http.post(apiEndpoint + "/uninstall", {
    systemID: systemId,
  });
}

export async function deleteSystem(systemId) {
  return await http.post(apiEndpoint + "/remove", {
    systemID: systemId,
  });
}

export async function restoreSystem(systemId) {
  return await http.post(apiEndpoint + "/restore", {
    systemID: systemId,
  });
}

export async function changeSystemName(systemId, name) {
  return await http.post(apiEndpoint + "/name", {
    systemID: systemId,
    systemName: name,
  });
}

export async function findSystemBySerialNumber(serial_number) {
  return await http.post(apiEndpoint + "/find_id_by_serial", {
    serialNumber: serial_number,
  });
}

export async function findIdByMac(macAddress) {
  return await http.post(apiEndpoint + "/find_id_by_mac", {
    macAddress: macAddress,
  });
}

export async function editSystemLocation(
  serialNumber,
  street,
  city,
  state,
  zip,
  country,

  username,

  vicePresident,
  regionalManager,
  regionalStaffI,
  regionalStaffII,
  costCenter,
  region
) {
  return await http.post(apiEndpoint + "/updateAddress", {
    serialNumber: serialNumber,

    username: username,
    address: {
      street: street,
      city: city,
      state: state,
      country: country,
      zip: zip,

      vicePresident,
      regionalManager,
      regionalStaffI,
      regionalStaffII,
      costCenter,
      region,
    },
  });
}

export async function updateTimeZone(serialNumber, timezone) {
  return await http.post(apiEndpoint + "/updateTimezone", {
    serialNumber: serialNumber,
    timezone: timezone,
  });
}

export async function updateTariff(serialNumber, tariff) {
  return await http.post(apiEndpoint + "/updateTariff", {
    serialNumber: serialNumber,
    tariff: tariff,
  });
}

export async function initSystemUpdate(handleId) {
  return await http.post(apiEndpoint + `/${handleId}` + "/updates");
}

export async function setSystemAlive(handleId) {
  return await http.post(apiEndpoint + `/${handleId}` + "/status", {
    status: "ALIVE",
  });
}

export async function setSystemSuspend(handleId) {
  return await http.post(apiEndpoint + `/${handleId}` + "/status", {
    status: "SUSPENDED",
  });
}

export async function getSystemWeather(city, state, country) {
  return await axios.get(
    `${OPEN_WEATHER_URL}` +
      `${city},` +
      `${state},` +
      `${country}` +
      "&appid=" +
      `${OPEN_WEATHER_API_KEY}`
  );
}

// Notifications routes
export async function getAllAlarms(handleId) {
  return await http.get(apiEndpoint + `/${handleId}` + "/alarms/all");
}

export async function getSimplifiedSystemAlarms(handleId) {
  return await http.get(
    apiEndpoint + `/${handleId}` + "/alarms/simplifiedSystemAlarms"
  );
}

export async function getAlarm(handleId, nodeName) {
  return await http.post(apiEndpoint + `/${handleId}` + "/alarms", {
    nodeName,
  });
}

export async function getBellColors(handleId, emailAddress) {
  return await http.post(
    apiEndpoint + `/${handleId}` + "/alarms/allColorBells",
    {
      email: emailAddress,
    }
  );
}

export async function saveSystemAlarms(
  systemHandleID,
  alarms,
  systemName,
  fleetOrigin
) {
  return await http.post(apiEndpoint + "/alarms/setAlarms", {
    systemHandleID,
    alarms,
    systemName,
    fleetOrigin,
  });
}

export async function changeUserEmailAlarms(
  previousEmailAddress,
  newEmailAddress,
  phoneNumber,
  companyName
) {
  return await http.post(apiEndpoint + "/alarms/changeUserEmailAlarms", {
    previousEmailAddress,
    newEmailAddress,
    phoneNumber,
    companyName: companyName,
  });
}

// Equipments routes
export async function getSystemEquipment(handleId) {
  return await http.get(apiEndpoint + "/equipment" + `/${handleId}`);
}

export async function setSystemEquipment(systemHandleID, equipmentData) {
  return await http.post(apiEndpoint + "/equipment", {
    systemHandleID: parseInt(systemHandleID),
    equipmentData: equipmentData,
  });
}

// System history changes routes
export async function getSystemChangesHistory(handleId) {
  return await http.get(apiEndpoint + `/${handleId}` + "/changeHistory");
}

// Check connection of the system

export async function checkConnectionInfo(handleId) {
  return await http.get(apiEndpoint + `/${handleId}` + "/checkConnectionInfo");
}

// TASKS
export async function getSystemTasks(handleId) {
  return await http.get(apiEndpoint + `/${handleId}` + "/tasks");
}

export async function setSystemTasks(handleId, tasks) {
  return await http.post(
    apiEndpoint + `/${handleId}` + "/tasks/setSystemTasks",
    {
      tasks,
    }
  );
}

export async function setSystemTask(handleId, systemName, task) {
  return await http.post(
    apiEndpoint + `/${handleId}` + "/tasks/setSystemTask",
    {
      systemName,
      task,
    }
  );
}

export async function setSystemReply(handleId, systemName, reply) {
  return await http.post(apiEndpoint + `/${handleId}` + "/tasks/setReply", {
    systemName,
    reply,
  });
}

// GO BACK EVENT HANDLERS - SAVE AND GET CONTROL'S UNSAVED CHANGES
export async function getControlsUnsavedChanges(handleId) {
  return await http.get(apiEndpoint + `/${handleId}` + "/unsavedChanges");
}

export async function setControlsUnsavedChanges(handleId, unsavedChanges) {
  return await http.post(
    apiEndpoint + `/${handleId}` + "/controls/setUnsavedControls",
    {
      unsavedChanges,
    }
  );
}

export async function uploadImage(myForm) {
  return await axios.post(apiEndpoint + `/uploadImage`, myForm, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function getImageUrl(handleId, serialNumber, slot) {
  return await http.post(apiEndpoint + "/getImageUrl", {
    handleId,
    serialNumber,
    slot,
  });
}

export async function deleteImage(handleId, serialNumber, imageName, slot) {
  return await http.post(apiEndpoint + "/deleteImage", {
    handleId,
    serialNumber,
    imageName,
    slot,
  });
}

export async function changeImagesSN(
  handleId,
  oldSerialNumber,
  newSerialNumber,
  keepImages
) {
  return await http.post(apiEndpoint + "/changeImagesSN", {
    handleId,
    oldSerialNumber,
    newSerialNumber,
    keepImages,
  });
}

export async function getImagesSN(handleId, serialNumber) {
  return await http.post(apiEndpoint + "/getImagesSN", {
    handleId,
    serialNumber,
  });
}
