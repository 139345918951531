import React, { useEffect, useState, Component } from "react";
import {
  loadTypesSchema,
  NO_CONVERSION,
  CONVERT_SEC_TO_MIN,
  CONVERT_C100_TO_F,
  CONVERT_X100_TO_X
} from "../config.json";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

import { getUser } from "../services/userService";
import {
  getSystemLatestData,
  getSystemIDfromHandle,
  getSystem,
  getAllAlarms,
  getAlarm,
  saveSystemAlarms,
  checkConnectionInfo

} from "../services/systemService";

import "./dashboard.css";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import "react-datepicker/dist/react-datepicker.css";

import { toast } from "react-toastify";

// Table component containing the bar and the table showed with the respective functionalities
function NotificationsTable({nodeName, handleID, systemName, user}) {

  // Elements of the range dropdown
  const rangeElements = [
    "Between", "Not Between",
    "Less Than", "Greater Than"
  ]

  // Elements of the frecuency dropdown
  const frequencyElements = [
    "Every Minute", "Every 5 Minutes", "Every 15 Minutes", "Every Hour", "Every Three Hours",
    "Every Six Hours", "Every Twelve Hours", "Daily", "Weekly", "Monthly"
  ]

  const waitTimeOptions = [
    "1 min", "5 mins", "15 mins", "30 mins", "1 hour", "2 Hours"
  ]

  const healthRangeOptions = [
    "Better Than", "Worse Than", "Equal To"
  ]

  const healthStatuses = [
    "Poor", "Good", "Best",
  ]

  // Main notifications array that changes dinamycally on the user actions
  const [notificationSettings, setNotificationSettings] = useState([])

  const [applianceInfo, setApplianceInfo] = useState({})

  // Setting state on system checkbox change
  const [isSystemActive, setSystemActive] = useState(false)

  // Check and set connection for the system
  const [connectionStatus, setConnectionStatus] = useState(null)

  // array of objs to handle the alarms of the user
  const [userAlarms, setUserAlarms] = useState([])

  // State that waits until connection is established to show data
  const [isMounted, setIsMounted] = useState({systemMount: false, applianceMount: false, alarmsData: false, connectionStatus: false})

  // Info data for the current node appliance
  const [loadTypeObj, switchLoadTypeObj] = useState({})

  // Info data for the current system
  const [systemTypeObj, switchSystemTypeObj] = useState({})

  // Load type name added for every alarm
  const [loadType, setLoadType] = useState("")

  // State that is set according to the last action made by the user
  const [lastAction, setLastAction] = useState("")

  // Parameter that sets if current alarms are going to be activated or not
  // const [activatedAlarms, setActivatedAlarms] = useState(true)

  // State controlling inital declaration of activatedAlarms state
  const [isInitialDeclaration, setIsInitialDeclaration] = useState(true)

  // Counter tracking activated alarms state
  const [counter, setCounter] = useState(0)

  // Function to check valid phone numbers
  function checkPhoneNumer(phoneNumber){
    const regex = /^(\+1)?\s*\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return regex.test(phoneNumber);
  }

  function formatPhoneNumber(phoneNumber){
    let formattedPhoneNumber = phoneNumber
    if (phoneNumber.slice(0, 2) === "+1" ){
      formattedPhoneNumber = phoneNumber.slice(2)
    }

    return ( '+1' + formattedPhoneNumber.replace(/[^0-9]/g, ''))
  }

  // Function to convert the dropdown options in a descriptive way
  function transformToUpper(str){
    const myStr = str.split('_').join(' ')
    const arr = myStr.split(" ")
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");

    return str2
  }

  // Function to get the formula according the loadtype of the alarm
  function getFormula(obj, name){
    const tmp = obj.data.find(element => 
      element.name === name
    )
    const formula = tmp.formula
    let formulaVal = formula

    if (formula === "NO_CONVERSION"){
      formulaVal = NO_CONVERSION
    } else if (formula === "CONVERT_SEC_TO_MIN"){
      formulaVal = CONVERT_SEC_TO_MIN
    } else if (formula === "CONVERT_C100_TO_F"){
      formulaVal = CONVERT_C100_TO_F
    } else if (formula === "CONVERT_X100_TO_X"){
      formulaVal = CONVERT_X100_TO_X
    }

    return formulaVal
  }

  // Component that renders the range inputs depending the dropdown range value selected
  function renderElement(element, index){

    let unit = paramInfo(element.alarmName, element.type)

    if (unit !== undefined || unit === ""){
      unit = unit.unit
    } else {
      unit = ""
    }

    if ((element.range.name === "between" || element.range.name === "not_between") && element.alarmName !== ""){
      return  <div style={{width: "110px", display: "flex", flexFlow:"column wrap"}}>
                <div style={{display: "flex", flexFlow: "row nowrap", width: "100%", justifyContent: "space-between"}}>
                  <input style={{width: "80px"}} value={element.range.limit1} name={element.id + "-limit1"} onChange={event=>handleRangeInput(event, index)} type="text" className="form-control form-control-sm" />
                  <p className="pt-2 text-success">{unit}</p>
                </div>
                <div style={{display: "flex", flexFlow: "row nowrap", width: "100%", justifyContent: "space-between"}}>
                  <input style={{width: "80px"}} value={element.range.limit2} name={element.id + "-limit2"} onChange={event=>handleRangeInput(event, index)} type="text" className="form-control form-control-sm mt-2" />
                  <p className="pt-3 text-success">{unit}</p>
                </div>
              </div>
    } else if ((element.range.name === "greater_than" || element.range.name === "less_than") && element.alarmName !== "") {
      return  <div style={{width: "110px", display:"flex", flexFlow: "row nowrap", justifyContent: "space-between"}}>
                <input style={{width: "80px"}} value={element.range.limit3} name={element.id + "-limit3"} onChange={event=>handleRangeInput(event, index)} type="text" className="form-control form-control-sm" />
                <p className="pt-2 text-success">{unit}</p>
              </div>
    } else {
      return <div></div>
    }
  }

  // Function to get whole data by the alarm name and type
  const paramInfo = (name, type) => {
    try {
      if (type === "appliance"){
        return loadTypeObj.data.find(element => element.name === name);
      } else if (type === "system"){
        return systemTypeObj.data.find(element => element.name === name);
      } else {
        return ""
      }
    } catch (e) {
      return ""
    }

  }

  // Function to add a new alarm to the notificationSettings array
  const addNewRow = () => {
    try {

      if (!loadTypeObj){
        throw new Error("Problem")
      }

      const emptyRow = {
        id: null,
        checked: false,
        active: false,
        alarmName: "",
        alarmTitle: "",
        formula: "",
        type: !isSystemActive ? "appliance" : "system",
        loadType: !isSystemActive ? loadType : "System",
        waitTime: "",
        status: "untrigger",
        range: {
          name:"",
          limit1: 0,
          limit2: 0,
          limit3: 0
        },
        frequency:{
          name:"",
          cellphone: false,
          email:false
        },
        contactInfo: {
          emailAddress: user.emailAddress,
          phoneNumber: '+1' + user.phoneNumber,
          username: user.username
        }
      }
      setNotificationSettings([...notificationSettings, emptyRow])
      console.log("Row added!");
    } catch (e){
      toast.error("Alarms are not able to be used in this unit")
      console.log(e.message);
      setNotificationSettings([])
    }
  }

  // Function to remove the selected alarm from notificationSettings array
  const deleteRow = (index) => {
    try {
      let newArr = [...notificationSettings]
      newArr.splice(index, 1)
      setNotificationSettings(newArr)
      console.log("Row removed!");
    } catch(e){
      toast.error("Fail to delete row");
      console.log(e.message);
    }
  }

  const activeAlarm = (isActive, index) => {

    try {

      let newArr = [...notificationSettings]
  
      if (isActive){
        newArr[index].active = false
      } else {
        newArr[index].active = true
      }
  
      setNotificationSettings(newArr)
    } catch(e){
      console.log("ERROR");
      console.log(e);
    }


  }

  const handleWaitTime = (event, index) => {
    const handler = event.target.options[event.target.selectedIndex];
    const text = handler.text
    let newArr = [...notificationSettings]

    newArr[index].waitTime = text
    setNotificationSettings(newArr)

    console.log("Notification Settings:");
    console.log(notificationSettings);

  }

  // Function to select the alarm that will be triggered
  const handleAlarmCheckBox = (event, index) => {
    try {
      const tag = event.target.tagName;
      const check = event.target.checked;
      const value = event.target.value;
      let formula;

      let newArr = [...notificationSettings]

      if (tag === 'SELECT'){
        const handler = event.target.options[event.target.selectedIndex];
        const text = handler.text
        // const shortDescription = handler.title
        newArr[index].alarmTitle = text
        newArr[index].alarmName = value

        if (newArr[index].type === "system"){
          formula = getFormula(systemTypeObj, value)
          newArr[index].formula = formula
        } else {
          formula = getFormula(loadTypeObj, value)
          newArr[index].formula = formula
        }
      } else {
        newArr[index].checked = check
      }
      setNotificationSettings(newArr)
      console.log("Checkbox");
      console.log(notificationSettings);
    } catch (e){
      console.log(e.message);
      toast.error('Fail to set checkbox alarm into obj')
    }
  }

  // Function to handle the alarm range with its input values
  const handleRangeInput = (event, index) => {
    try {
      const tag = event.target.tagName;
      const value = event.target.value;
      let name = event.target.name;

      let newArr = [...notificationSettings]

      if (tag === "SELECT"){
        const newVal = value.split(' ').join('_').toLowerCase()
        newArr[index].range.name = newVal
        newArr[index].range["limit1"] = 0
        newArr[index].range["limit2"] = 0
        newArr[index].range["limit3"] = 0

      } else if (tag === "INPUT"){
        name = name.slice(name.length - 6)
        const parsedInt = parseInt(value, 10)

        if (name === "limit3"){
          newArr[index].range["limit1"] = null
          newArr[index].range["limit2"] = null
        } else {
          newArr[index].range["limit3"] = null
        }

        if (!isNaN(parsedInt)) {
          newArr[index].range[name] = parsedInt
        } else if (value === "") {
          newArr[index].range[name] = ""
        } else {
          console.log("Could not delete all characters")
          return false
        }
      }

      setNotificationSettings(newArr)
      console.log("Range");
      console.log(notificationSettings);

    } catch (e) {
      console.log(e.message);
      toast.error('Fail to handle select range into obj')
    }
  }

  // Function to handle the frequency of the alarm, the destination options, and if it'll be cheked or not
  const handleFrequency = (event, index) => {

    try {
      const tag = event.target.tagName;
      const check = event.target.checked;
      const value = event.target.value;
      let name = event.target.name;

      let newArr = [...notificationSettings]

      if (tag === "SELECT"){
        const newVal = value.split(' ').join('_').toLowerCase()
        newArr[index].frequency.name = newVal
      } else {
        if (name === "cellphone"){
          if (!checkPhoneNumer(newArr[index].contactInfo.phoneNumber)){
            newArr[index].frequency.cellphone = false
            toast.error('Please register a valid US Phone Number before selecting this checkbox')
          } else {
            newArr[index].frequency.cellphone = check
            newArr[index].contactInfo.phoneNumber = formatPhoneNumber(newArr[index].contactInfo.phoneNumber)
          }
        } else if (name === "email"){
          newArr[index].frequency.email = check
        }
      }

      if (!newArr[index].frequency.cellphone && !newArr[index].frequency.email) {
        newArr[index].checked = false
      } else {
        newArr[index].checked = true
      }

      setNotificationSettings(newArr)

      console.log("Frecuency");
      console.log(notificationSettings);
    } catch (e){
      console.log(e.message);
      toast.error('Frecuency Error')
    }
  }

  const handleStatusesOption = (event, index) => {
    const value = event.target.value;
    let newArr = [...notificationSettings]

    newArr[index].range.name = "healt_range"
    newArr[index].range.limit1 = value

    console.log("New Arr:");
    console.log(newArr);
    setNotificationSettings(newArr)

  }

  const showToastMessage = (action, notificationSettings) => {
    try {
      if (action === "empty"){
        toast.warning("No alarms are available for this device")
      } else if (action === "remove" && notificationSettings.length === 0){
        toast.success("All modifications have been successfully applied")
        setLastAction("empty")
      } else if (action === "add" || action === "" || action === "activate" || action === "unactivate"){
        if (notificationSettings.length > 0){
          notificationSettings.some((item, index)=> {
            if (item.checked){
              if (action === "activate" || action === "unactivate"){
                toast.success("All modifications have been successfully applied")
              } else {
                toast.success("The new alarm(s) has/have been successfully added and stored")
              }
              return true
            }
            if (!item.checked && index === notificationSettings.length - 1){
              toast.warning("No alarms are available for this device")
              return true
            }
          })
        } else {
          toast.warning("No alarms are available for this device")
        }
      } else if (action === "remove") {
        toast.success("All modifications have been successfully applied")
      } else {
        toast.success("All modifications have been successfully applied")
      }
    } catch (e){
      toast.error("It was not possible to do this")
    }
  }

  // Function to save the alarms in the database
  const saveAlarms = async (notificationSettings) => {
    try {
      let allSystemAlarms = (await getAllAlarms(handleID)).data.data
      let existingAlarm = (await getAlarm(handleID, nodeName)).data.data
      let username = (await getUser()).data.data.username;
      if (!username) {
        username = "Unknown"
      }

      console.log("All alarms:");
      console.log(allSystemAlarms);

      console.log("Existing Alarms:");
      console.log(existingAlarm);

      if (Array.isArray(existingAlarm)){
        console.log("Entra aca!");
        allSystemAlarms.push({
          Appliance: {
            nodeName,
            systemName,
            alarms: [...notificationSettings],
          }
        })
      }


      let newAlarmsObj = allSystemAlarms.map((item) => {
        if (item.Appliance.nodeName === nodeName){

          

          return {
            nodeName,
            systemName,
            alarms: notificationSettings
          }
        }
        return item.Appliance
      })

      newAlarmsObj = newAlarmsObj.filter(element => element.alarms.length > 0)

      console.log("Alarms OBJ that will be stored:");
      console.log(newAlarmsObj);

      const data = (await saveSystemAlarms(handleID, newAlarmsObj, systemName)).data

      console.log("DATA:");
      console.log(data);

      const result = data.alarms.alarms

      const newArr = result.find(element=> element.nodeName === nodeName)
      if (newArr){
        setNotificationSettings([...newArr.alarms])
      } else {
        setNotificationSettings([])
      } 
      showToastMessage(lastAction, notificationSettings)

      console.log(`RESULT`);
      console.log(result);
      console.log("-------------");
      console.log("\n");
      return result

    } catch (e){
      console.log("ERROR:");
      console.log(e);
      toast.error("It was not possible to save the alarms")
    }

  }

  // Run hook only once after the render of the NotificationsTable comnponent occurs
  useEffect( () => {

    // Function to list the existing Appliance alarms
    const getAlarmsData = async () => {
      try {
        let alarmNodeElements = await getAlarm(handleID, nodeName)
        alarmNodeElements = alarmNodeElements.data.data

        if (!Array.isArray(alarmNodeElements)) {

          const userAlarms = alarmNodeElements.alarms.filter((element)=> {
            console.log(element.contactInfo.emailAddress);
            console.log(user.emailAddress);
            return (element.contactInfo.emailAddress === user.emailAddress)
          })
          console.log("User Alarms");
          console.log(userAlarms);

          setNotificationSettings([...alarmNodeElements.alarms])
          // setActivatedAlarms(alarmNodeElements.active)
          setUserAlarms([...userAlarms])
        }

        console.log("Alarm Elements:");
        console.log(alarmNodeElements);

        setIsMounted(isMounted => ({...isMounted, alarmsData: true}))
      } catch(e){
        console.log(e);
      }
    }

    // Function to list the appliance params in the alarms dropdown
    const setApplianceDropdown = async () => {
      try {
        const data = await getSystemLatestData(handleID)
        const appliancesArr = data.data.data[0].data.deviceLedger.Appliances
        const appliance = appliancesArr.find(element => element.nodeName === nodeName)
        const loadTypeObj = loadTypesSchema.find(element => element.loadType === appliance.loadType)

        console.log("Appliance Info");
        console.log(appliance);

        setLoadType(appliance.loadType)
        switchLoadTypeObj(loadTypeObj)
        setApplianceInfo(appliance)
        setIsMounted(isMounted => ({...isMounted, applianceMount: true}))
      } catch(e){
        console.log(e);
      }
    }

    // Function to list the system params in the alarms dropdown
    const setSystemDropdown = async () => {
      try {
        const systemTypeObj = loadTypesSchema.find(element => element.loadType === "system")

        console.log("System type obj;");
        console.log(systemTypeObj);

        switchSystemTypeObj(systemTypeObj)
        setIsMounted(isMounted => ({...isMounted, systemMount: true}))
      } catch (e){
        console.log(e);
      }
    }

    const getSystemConnection = async () => {
      try{

        const myDataTimeInvalid = (await checkConnectionInfo(handleID)).data.data.Connection

        console.log("Status:");
        console.log(myDataTimeInvalid);
  
        setConnectionStatus(myDataTimeInvalid)
        setIsMounted(isMounted => ({...isMounted, connectionStatus: true}))
      } catch(e){
        console.log(e);
      }

    }

    getAlarmsData()
    setApplianceDropdown()
    setSystemDropdown()
    getSystemConnection()
  }, [])

  // Run hook preventing the inital declaration of activatedAlarmas and when activatedAlarms state changes
  useEffect(() => {
    if (!isInitialDeclaration){
      if (counter !== 0 ){
        saveAlarms(notificationSettings)
      }
    } else {
      setIsInitialDeclaration(false)
    }
  }, [isInitialDeclaration])


  useEffect(() => {

    const userAlarms = notificationSettings.filter((element)=> {
      return (element.contactInfo.emailAddress === user.emailAddress)
    })

    setUserAlarms([...userAlarms])

  }, [notificationSettings])

  return (
    <>
      {/* Bar to handle the actions: Coming back on the page, Saving selected alarms, and Adding new alarms(rows) into the Appliance  */}
      <div style={{
        width: "100%",
        display: "flex",
        justifyContent: "center"
      }}>
        <div
          style={{
            height: "25px",
            width: "475px",
            borderRadius: "5px",
            color: "#ffc000",
            backgroundColor: "#777575",
            fontWeight: "bold",
            fontSize: "11px",
            textAlign: "center",
            paddingTop: "5px",
            paddingBottom: "5px;"
          }}
        >
          <button
            className="btn btn-primary shadow-none"
            style={{
              padding: "0px",
              borderWidth: "0px",
              borderColor: "tranparent",
              marginRight: "12px",
              marginTop: "-7px",
              color: "transparent",
              backgroundColor: "transparent",
            }}
            onClick={()=> {
              addNewRow()
              setLastAction("add")
            }}
          >
            <FontAwesomeIcon
              icon="plus-circle"
              size="2x"
              color="#ffc000"
              title="Add a New Alarm"
            />
          </button>
          <button
            className="btn btn-primary shadow-none pt-1 pr-2"
            style={{
              float: "right",
              padding: "0px",
              borderWidth: "0px",
              marginRight: "12px",
              marginTop: "-7px",
              transform: "translate(10px, -2px)",
              color: "transparent",
              backgroundColor: "transparent",
            }}
            onClick={() => saveAlarms(notificationSettings)}
          >
            <FontAwesomeIcon
              icon="save"
              size="md"
              color="#ffc000"
              title="Save changes"
            />
          </button>
          <button
            className="btn btn-primary shadow-none"
            onClick={()=>{
              window.history.back();
            }}
            style={{
              float: "left",
              padding: "0px",
              borderWidth: "0px",
              marginLeft: "12px",
              marginTop: "-7px",
              color: "transparent",
              backgroundColor: "transparent",
            }}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              size="sm"
              color="#ffc000"
              title="Home"
            />
          </button>
        </div>
      </div>

      {/* bar containing the System Alarm checkbox */}
      <div style={{
          position: "relative",
          overflow: "hidden",
          zIndex: "-1",
          width: "475px",
          height: "30px",
          marginTop: "-5px",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "#ece9e9",
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "flex-end"
        }}>

          {/* { activatedAlarms ? (
              <a onClick={()=> {
                setActivatedAlarms(false)
                if (notificationSettings.length > 0){
                  setLastAction("unactivate")
                }
                setCounter((prevCounter)=> prevCounter + 1)
              }} style={{
                fontSize: "11px",
                fontWeight: "750",
                color: "#199b14",
                marginTop:"5.8px",
                marginLeft: "10px"
              }} href="javascript:void(0);">Alarms Activated</a>

            ) : (

              <a onClick={()=> {
                setActivatedAlarms(true)
                if (notificationSettings.length > 0){
                  setLastAction("activate")
                }
                setCounter((prevCounter)=> prevCounter + 1)
              }} style={{
                fontSize: "11px",
                fontWeight: "750",
                color: "#777575",
                marginTop:"5.8px",
                marginLeft: "10px"
              }} href="javascript:void(0);">Alarms Deactivated</a>
            )
          } */}
          <span>
            <label
              name={"system"}
              style={{
                fontSize: "11px",
                fontWeight: "750",
                color: "#199b14",
                marginTop:"5.5px"
              }}
              className="mr-4"
            >
              System Alarm
            </label>
            <input
              className="form-check-input mt-2"
              type="checkbox"
              name={"system"}
              checked={isSystemActive}
              onChange={()=> {
                if(!isSystemActive){
                  setSystemActive(true)
                } else {
                  setSystemActive(false)
                }
              }}
              style={{
                marginRight: "85px"
              }}
            ></input>
          </span>
      </div>

      {/* Main table container.. If there are not saved rows it shows a basic title*/}
      {isMounted.systemMount && isMounted.applianceMount && isMounted.alarmsData ? (
        <>
          {( notificationSettings.length > 0 && userAlarms.length > 0)? (
            <div style={{display:"flex", marginTop:"20px", justifyContent:"center"}}>

              <table
                className="table"
                style={{
                  marginTop: "20px",
                  width: "350px",
                }}
              >
                <thead>
                  <tr>
                    <th style={{ margin: "0px", padding: "0px", height: "15px" }}>
                      Alert
                    </th>
                    <th style={{ margin: "0px", padding: "0px", height: "15px" }}>
                      Range
                    </th>
                    <th style={{ margin: "0px", padding: "0px", height: "15px" }}>
                      Frequency
                    </th>
                    <th style={{ margin: "0px", padding: "0px", height: "15px" }}>
                      Action
                    </th>
                  </tr>
                </thead>

                {notificationSettings.map((item, index, array) =>
                  <>

                    {item.contactInfo.emailAddress === user.emailAddress && (
                      <tbody key={index}>
                        <tr>

                          {/* Title names Dropdown */}
                          <td>
                            <div className="form-check">
                              { item.type === "appliance" ? (
                                <>
                                  <div style={{
                                    display: "flex",
                                    flexFlow: "row nowrap",
                                    gap: "10px"
                                  }}>
                                    <LoadTypeInfoModal item={{...item}} loadTypeObj={{...loadTypeObj}}/>
                                    <select
                                      className="form-control2 mr-1"
                                      value={item.alarmName}
                                      name={item.alarmName}
                                      onChange={event => {
                                        handleAlarmCheckBox(event, index)
                                      }}
                                      style={{ fontSize: "12px", width:"110px", fontWeight: "bold", color:"#784feb" }}
                                    >
                                      {item.alarmName === "" ? (
                                        <option selected value={item.alarmName}>Choose here</option>
                                      ) : (
                                        <>
                                          <option selected value={item.alarmName}>{item.alarmTitle}</option>
                                        </>
                                      )}

                                      { loadTypeObj.data.map( (element) =>
                                        <>
                                          {element.name !== item.alarmName && element.shown && (
                                            <option value={element.name}>{element.shortDescription}</option>
                                          )}
                                        </>
                                      )}
                                    </select>
                                  </div>

                                  <hr />
                                  <p style={{
                                    fontSize: "11.5px",
                                    fontWeight: "750",
                                    color: "#199b14",
                                  }}>
                                    Wait Time
                                  </p>

                                  <div>

                                    {/* <LoadTypeInfoModal item={{...item}} loadTypeObj={{...loadTypeObj}}/> */}
                                    <select
                                      className="form-control2"
                                      value={item.waitTime}
                                      name={item.alarmName}
                                      onChange={event => {
                                        handleWaitTime(event, index)
                                      }}
                                      style={{ fontSize: "12px", width:"110px", fontWeight: "bold", color:"#784feb" }}
                                    >
                                      {item.waitTime === "" ? (
                                          <option selected value={item.waitTime}>Choose here</option>
                                      ) : (
                                        <option selected value={item.waitTime}>{item.waitTime}</option>
                                      )}
                                      {waitTimeOptions.map( (element) =>
                                        <>
                                          {element !== item.waitTime && (
                                            <option value={element}>{element}</option>
                                          )}
                                        </>
                                      )}

                                    </select>
                                  </div>

                                </>
                              ) : (
                                <>
                                  <div style={{
                                    display: "flex",
                                    flexFlow: "row nowrap",
                                    gap: "10px"
                                  }}>
                                    <LoadTypeInfoModal item={{...item}} loadTypeObj={{...systemTypeObj}}/>
                                    <select
                                      className="form-control2 mr-1"
                                      value={item.alarmName}
                                      name={item.alarmName}
                                      onChange={event => {
                                        handleAlarmCheckBox(event, index)
                                      }}
                                      style={{ fontSize: "12px", width:"110px", fontWeight: "bold", color:"#784feb" }}
                                    >
                                      {item.alarmName === "" ? (
                                        <option selected value={item.alarmName}>Choose here</option>
                                      ) : (
                                        <>
                                          <option selected value={item.alarmName}>{item.alarmTitle}</option>
                                        </>
                                      )}

                                      { systemTypeObj.data.map( (element) =>
                                        <>
                                          {element.name !== item.alarmName && element.name !== "connection" && element.shown && (
                                            <option value={element.name}>{element.shortDescription}</option>
                                          )}
                                        </>
                                      )}
                                    </select>
                                  </div>
                                  <p style={{
                                    fontSize: "11.5px",
                                    fontWeight: "750",
                                    color: "#199b14",
                                  }}>
                                    System Alarm
                                  </p>

                                  <hr />
                                  <p style={{
                                    fontSize: "11.5px",
                                    fontWeight: "750",
                                    color: "#199b14",
                                  }}>
                                    Wait Time
                                  </p>

                                  <div>
                                    <select
                                      className="form-control2"
                                      value={item.waitTime}
                                      name={item.alarmName}
                                      onChange={event => {
                                        handleWaitTime(event, index)
                                      }}
                                      style={{ fontSize: "12px", width:"110px", fontWeight: "bold", color:"#784feb" }}
                                    >
                                      {item.waitTime === "" ? (
                                          <option selected value={item.waitTime}>Choose here</option>
                                      ) : (
                                        <option selected value={item.waitTime}>{item.waitTime}</option>
                                      )}
                                      {waitTimeOptions.map( (element) =>
                                        <>
                                          {element !== item.waitTime && (
                                            <option value={element}>{element}</option>
                                          )}
                                        </>
                                      )}

                                    </select>
                                  </div>

                                </>
                              ) }
                            </div>
                          </td>

                          {/* Range dropdown and range numeric inputs */}
                          { item.alarmName === "health" ? (
                            <td>

                              <div style={{display: "flex", flexFlow:"column wrap",
                              justifyContent:"center", alignItems: "center"}}>
                                <div style={{display: "flex", marginBottom:"2px", justifyContent: "center"}}>
                                  Worse than
                                </div>

                                
                                <div style={{display: "flex", marginBottom:"2px", justifyContent: "center"}}>
                                  <select
                                      className="form-control2"
                                      value={item.range.limit1}
                                      name={item.alarmName}
                                      onChange={event=>handleStatusesOption(event, index)}
                                      style={{ fontSize: "12px", fontWeight: "bold", color:"#784feb", width:"100px" }}
                                    >
                                      {(item.range.limit1 === "" || item.range.limit1 === "0" || item.range.limit1 === 0) ? (
                                        <option selected value={item.range.limit1}>Choose here</option>
                                      ) : (
                                        <>
                                          <option selected value={item.range.limit1}>{item.range.limit1}</option>
                                        </>
                                      )}

                                      {healthStatuses.map( (element) =>
                                        <>
                                          {element !== item.range.limit1 && (
                                            <option value={element}>{element}</option>
                                          )}
                                        </>
                                      )}
                                  </select>
                                </div>
                                

                              </div>

                              <div style={{
                                width: "110px"
                              }}>
                                <hr />
                                <p style={{
                                  fontSize: "11.5px",
                                  fontWeight: "750",
                                  color: "#199b14",
                                }}>
                                  Current health
                                </p>
                                <span style={{
                                  display: "flex",
                                  justifyContent: "center"
                                }}>
                                  {applianceInfo.health === 0 &&
                                    (applianceInfo.relaySetting & 0x0010) >> 4 ===
                                      1 && (
                                      <p
                                        style={{
                                          width: "55px",
                                          textAlign: "center",
                                          height: "15px",
                                          color: "red",
                                        }}
                                      >
                                        Bad
                                      </p>
                                    )}
                                  {applianceInfo.health === 1 &&
                                    (applianceInfo.relaySetting & 0x0010) >> 4 ===
                                      1 && (
                                      <p
                                        style={{
                                          width: "55px",
                                          textAlign: "center",
                                          height: "15px",
                                          color: "orange",
                                        }}
                                      >
                                        Poor
                                      </p>
                                    )}
                                  {applianceInfo.health === 2 &&
                                    (applianceInfo.relaySetting & 0x0010) >> 4 ===
                                      1 && (
                                      <p
                                        style={{
                                          width: "55px",
                                          textAlign: "center",
                                          height: "15px",
                                          color: "dodgerblue",
                                        }}
                                      >
                                        Good
                                      </p>
                                    )}
                                  {applianceInfo.health === 3 &&
                                    (applianceInfo.relaySetting & 0x0010) >> 4 ===
                                      1 && (
                                      <p
                                        style={{
                                          width: "55px",
                                          textAlign: "center",
                                          height: "15px",
                                          color: "lime",
                                        }}
                                      >
                                        Best
                                      </p>
                                    )}
                                  {((applianceInfo.health !== 0 &&
                                    applianceInfo.health !== 1 &&
                                    applianceInfo.health !== 2 &&
                                    applianceInfo.health !== 3) ||
                                    (applianceInfo.relaySetting & 0x0010) >> 4 !==
                                      1) && (
                                      <p
                                        style={{
                                          width: "55px",
                                          textAlign: "center",
                                          position: "relative",
                                          height: "15px",
                                        }}
                                      >
                                        ----
                                      </p>
                                  )}
                                </span>
                              </div>
                            </td>
                          ) : (
                            <td>
                              <div style={{display: "flex", flexFlow:"column wrap",
                              justifyContent:"center", alignItems: "center"}}>


                                <div style={{display: "flex", marginBottom:"2px", justifyContent: "center"}}>
                                  <select
                                    className="form-control2"
                                    value={item.range.name}
                                    name={item.alarmName}
                                    onChange={event=>handleRangeInput(event, index)}
                                    style={{ fontSize: "12px", fontWeight: "bold", color:"#784feb", width:"100px" }}
                                  >
                                    {item.range.name === "" ? (
                                      <option selected value={item.alarmName}>Choose here</option>
                                    ) : (
                                      <>
                                        <option selected value={item.range.name}>{transformToUpper(item.range.name)}</option>
                                      </>
                                    )}

                                    {rangeElements.map( (element) =>
                                    <>
                                      {element !== transformToUpper(item.range.name) && (
                                        <option value={element}>{element}</option>
                                      )}
                                    </>
                                    )}
                                  </select>
                                </div>

                                {renderElement(notificationSettings[index], index)}

                              </div>
                            </td>

                          )}


                          {/* Frecuency dropdown and destination checkboxes */}
                          <td>
                            <div>

                              <select
                                className="form-control2"
                                value={item.frequency.name}
                                name={item.alarmName}
                                onChange={event => handleFrequency(event, index)}
                                style={{ fontSize: "12px", width:"110px", fontWeight: "bold", color:"#784feb" }}
                              >
                                {item.frequency.name === "" ? (
                                    <option selected value={item.frequency.name}>Choose here</option>
                                ) : (
                                  <option selected value={item.frequency.name}>{transformToUpper(item.frequency.name)}</option>
                                )}
                                {frequencyElements.map( (element) =>
                                  <>
                                    {element !== transformToUpper(item.frequency.name) && (
                                      <option value={element}>{element}</option>
                                    )}
                                  </>
                                )}

                              </select>

                              <div style={{display: "flex",
                                  flexFlow:"column wrap",
                                  alignItems:"end"}}>


                                <div className="form-check" style={{
                                  width: "90%", display: "flex"
                                  }} >
                                  
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name={"cellphone"}
                                    checked={item.frequency.cellphone}
                                    onChange={event=>handleFrequency(event, index)}
                                  ></input>
                                  <label
                                    name={"cellphone"}
                                    style={{
                                      font: "10px arial",
                                      fontWeight: "bold",
                                      marginTop:"5px"
                                    }}
                                  >
                                    Cellphone
                                  </label>

                                </div>


                                <div className="form-check" style={{
                                  width: "90%", display: "flex"
                                  }} >
                                  <input
                                    name={"email"}
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={item.frequency.email}
                                    onChange={event=>handleFrequency(event, index)}
                                  ></input>
                                  <label
                                    name={"email"}
                                    style={{
                                      font: "10px arial",
                                      fontWeight: "bold",
                                      display:"inline-block",
                                      marginTop: "6px"
                                    }}
                                  >
                                    Email
                                  </label>
                                </div>
                              </div>
                            </div>
                          </td>

                          {/* Trash and Enable Icons*/}
                          <td className="icon p-0">
                            <div style={{
                              flexFlow: "column wrap",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%"
                            }}>
                              <div class="custom-control custom-switch mt-4">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id={item.id ? item.id : `item-${index}`}
                                  checked={item.active}
                                  onChange={()=>{
                                    activeAlarm(item.active, index)
                                  }}
                                ></input>
                                <label class="custom-control-label" for={item.id ? item.id : `item-${index}`}>
                                </label>
                              </div>

                              <button
                                className="btn btn-outline-light mt-3"
                                style={{
                                  float: "left",
                                  padding: "0px",
                                  borderWidth: "0px",
                                  marginLeft: "10px",
                                  marginTop: "-7px",
                                }}
                                onClick={() => {
                                  setLastAction("remove")
                                  deleteRow(index)
                                }}
                              >
                                <FontAwesomeIcon
                                  icon="trash-alt"
                                  size="sm"
                                  color="red"
                                  title="Delete Alarm"
                                />
                              </button>

                            </div>

                          </td>

                        </tr>
                      </tbody>
                    )}

                  </>

                )}

              </table>

            </div>
          ) : (
            <div>
              <p className="text-center basicParagraph text-center"
                  style={{
                    width: "100%",
                    fontSize: "12px",
                    color: "#2d5c88",
                    marginTop: "20px"
                  }}
                >
                  There are not alarms in this Appliance
              </p>
            </div>
          )}
        </>
      ): (
        <>
          <div>
              <p className="text-center basicParagraph text-center"
                  style={{
                    width: "100%",
                    fontSize: "12px",
                    color: "#2d5c88",
                    marginTop: "20px"
                  }}
                >
                  Please stand by as connections are being established
              </p>
          </div>
        </>
      )}
    </>
  )
}

// Component containing the pop-up functionality
class LoadTypeInfoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      alarmInfo: {},
      isMounted: false // Initial state is false
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
      setTimeout(() => {this.setState({ isMounted: false })}, 500)
    };
  }

  setAlarmInfo = (item, loadTypeObj) => {
    try {
      const alarmInfo = loadTypeObj.data.find(element => element.name === item.alarmName)
      this.setState({ alarmInfo: alarmInfo })
      this.setState({ isMounted: true })

    } catch(e) {
      console.log(e);
    }

  }

  render() {

    const { isMounted, alarmInfo } = this.state

    return (
      <>
        <button
          onClick={() => {
            this.handleShow()
            this.setAlarmInfo(this.props.item, this.props.loadTypeObj)
          }}
          className="btn btn-outline-light"
          style={{ padding: "0", marginTop: "-5px", borderWidth: "0px" }}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="sm"
            color="orange"
            title="Alarm Information"
          />
        </button>

        {(isMounted && this.props.item.alarmName !== "") ? (
          <Modal
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                {alarmInfo.shortDescription}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <hr className="modalLine" style={{ backgroundColor: "orange" }} />
              <p className="modalTextSmall">
                {alarmInfo.longDescription}
              </p>
            </Modal.Body>

          </Modal>
        ) : (
          <Modal
            show={this.state.show}
            onHide={this.handleHide}
            dialogClassName="modal-50w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                Not found
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <hr className="modalLine" style={{ backgroundColor: "orange" }} />
              <p className="modalTextSmall">
                Please select an option to provide information for the alarm
              </p>
            </Modal.Body>

          </Modal>
        )}

      </>
    );
  }
}

// Main component which calls the NotificationsTable interactive component
class SimplAlarms extends Component{

  constructor(props) {
    super(props);
    this.state = {
      isMounted: false, // Initial state is false
      systemName: "",
      nodeName: "",
      systemHandleID: 0,
      user: ""
    };
  }

  // Runs componentDidMount after the render occurs
  async componentDidMount(){

    const systemHandleID = parseInt(this.props.match.params.systemHandleID);
    const nodeName = this.props.match.params.nodeName;
    const systemID = (await getSystemIDfromHandle(systemHandleID)).data.data.system_id;
    const system = (await getSystem(systemID)).data.data[0];
    const systemName = system.systemID.name;

    const userObj = await getUser()
    const user = userObj.data.data

    this.setState({nodeName});
    this.setState({systemName});
    this.setState({systemHandleID})
    this.setState({user})
    this.setState({isMounted: true})

  }

  render(){

    const {isMounted, systemName, nodeName, systemHandleID, user} = this.state

    return (
      <React.Fragment>
        <div
          style={{
            width: "1000px",
            minHeight: "400px",
            maxHeight: "auto",
            paddingBottom: "200px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            overflow: "auto",
            zIndex: 1
          }}
        >
          { isMounted ? (
            <>
              <div>
                {/* shows title after the call requests of the system */}
                <p className="text-center basicParagraph text-center"
                    style={{
                      width: "100%",
                      fontSize: "12px",
                      color: "#2d5c88",
                    }}
                  >
                    {systemName} | {nodeName}
                </p>
              </div>

              <NotificationsTable nodeName={nodeName} handleID={systemHandleID} systemName={systemName} user={user} />
            </>
          ) : (
            <div></div>
          )}

        </div>
      </React.Fragment>
    )
  }
}

export default SimplAlarms;
