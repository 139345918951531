import React, { Component, useEffect } from "react";

import "./dashboard.css";
import "../index.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";

function SimplFooter(props) {

  useEffect( ()=> {
    sessionStorage.removeItem("nodePassed");
  }, [])

  return (
    <React.Fragment>
      <p
        className="systemClock"
        style={{
          height: "30px",
          marginTop: "5px",
          marginBottom: "5px",
        }}
      >
        {props.userRole === "companyAdmin" && (
          <table style={{ margin: "0px" }}>
            <tr>
              <td className="icon">
                <Link
                  to={`/systems/signalStrength/${props.handleID}/${props.companyName}/${props.userRole}`}
                >
                  <FontAwesomeIcon
                    icon="wifi"
                    size="lg"
                    color="#ffc000"
                    title="Signal Strength"
                  />
                </Link>
              </td>
              {props.systemStatus !== "UPDATING" && (
                <td className="icon">
                  <Link
                    to={`/systems/signals/${props.handleID}/${
                      props.companyName
                    }/${props.userRole}/${"initiateUpdate"}`}
                  >
                    <FontAwesomeIcon
                      icon="cloud-upload-alt"
                      size="lg"
                      color="#ffc000"
                      title="Upload System Paremeters"
                    />
                  </Link>
                </td>
              )}
              {props.systemStatus === "UPDATING" && (
                <td className="icon">
                  <FontAwesomeIcon
                    icon="exclamation-triangle"
                    size="lg"
                    color="red"
                    title="Upload System Paremeters"
                  />
                </td>
              )}
              <td className="icon">
                <Link
                  to={`/tariffs/${props.systemSN}/${props.companyName}/${props.userRole}`}
                >
                  <FontAwesomeIcon
                    icon="dollar-sign"
                    size="lg"
                    color="#ffc000"
                    title="Update Tariff Data"
                  />
                </Link>
              </td>
              <td className="icon">
                <Link
                  to={`/systems/basics/${props.handleID}/${props.companyName}/${props.userRole}`}
                >
                  <FontAwesomeIcon
                    icon="edit"
                    size="lg"
                    color="#ffc000"
                    title="Set System Basic Parameters"
                  />
                </Link>
              </td>
              {props.systemConfiguration !== "8" && (
                <td className="icon">
                  <Link
                    to={`/systems/controls/${props.handleID}/${props.companyName}/${props.userRole}`}
                  >
                    <FontAwesomeIcon
                      icon="sliders-h"
                      size="lg"
                      color="#ffc000"
                      title="Adjust System Controls"
                    />
                  </Link>
                </td>
              )}
              {props.systemConfiguration !== "8" && (
                <td className="icon">
                  <Link
                    to={`/systems/limits/${props.handleID}/${props.companyName}/${props.userRole}`}
                  >
                    <FontAwesomeIcon
                      icon="arrows-alt-v"
                      size="lg"
                      color="#ffc000"
                      title="Change System Limits"
                    />
                  </Link>
                </td>
              )}
              {props.systemConfiguration === "8" && (
                <td className="icon">
                  <Link
                    to={`/systems/simplNodesControls/${props.handleID}/${props.companyName}/${props.userRole}/${props.displayWidth}`}
                  >
                    <FontAwesomeIcon
                      icon="sliders-h"
                      size="lg"
                      color="#ffc000"
                      title="Adjust System Controls"
                    />
                  </Link>
                </td>
              )}
              {props.systemConfiguration === "8" && (
                <td className="icon">
                  <Link
                    to={`/systems/simplNodesLimits/${props.handleID}/${props.companyName}/${props.userRole}`}
                  >
                    <FontAwesomeIcon
                      icon="arrows-alt-v"
                      size="lg"
                      color="#ffc000"
                      title="Change System Limits"
                    />
                  </Link>
                </td>
              )}

              <td className="icon">
                <Link
                  to={`/systems/location/${props.handleID}/${props.companyName}/${props.userRole}`}
                >
                  <FontAwesomeIcon
                    icon="map-marker-alt"
                    size="lg"
                    color="#ffc000"
                    title="Change System Location"
                  />
                </Link>
              </td>
              <td className="icon">
                <Link to={`/systems/users/${props.handleID}/${props.companyName}/${props.userRole}`}>
                  <FontAwesomeIcon
                    icon="user-friends"
                    size="lg"
                    color="#ffc000"
                    title="users"
                  />
                </Link>
              </td>
              <td className="icon">
                <Link
                  to={`/systems/statusAlive/${props.handleID}/${props.companyName}`}
                >
                  <FontAwesomeIcon
                    icon="heartbeat"
                    size="lg"
                    color="#ffc000"
                    title="Set system Status to ALIVE"
                  />
                </Link>
              </td>
            </tr>
          </table>
        )}

        {props.userRole === "companyAdmin" && (
          <table>
            <tr>
              <td
                style={{
                  fontSize: "9px",
                  fontWeight: "bold",
                  color: "#777575",
                  paddingTop: "5px",
                  paddingRight: "4px",
                }}
              >
                Signals
              </td>
              <td
                style={{
                  fontSize: "9px",
                  fontWeight: "bold",
                  color: "#777575",
                  paddingTop: "5px",
                  paddingRight: "4px",
                }}
              >
                Upload
              </td>
              <td
                style={{
                  fontSize: "9px",
                  fontWeight: "bold",
                  color: "#777575",
                  paddingTop: "5px",
                  paddingRight: "5px",
                }}
              >
                Tariff
              </td>
              <td
                style={{
                  fontSize: "9px",
                  fontWeight: "bold",
                  color: "#777575",
                  paddingTop: "5px",
                  paddingRight: "4px",
                }}
              >
                Basics
              </td>
              <td
                style={{
                  fontSize: "9px",
                  fontWeight: "bold",
                  color: "#777575",
                  paddingTop: "5px",
                  paddingRight: "4px",
                }}
              >
                Controls
              </td>
              <td
                style={{
                  fontSize: "9px",
                  fontWeight: "bold",
                  color: "#777575",
                  paddingTop: "5px",
                  paddingRight: "4px",
                }}
              >
                Limits
              </td>
              <td
                style={{
                  fontSize: "9px",
                  fontWeight: "bold",
                  color: "#777575",
                  paddingTop: "5px",
                  paddingRight: "4px",
                }}
              >
                Location
              </td>
              <td
                style={{
                  fontSize: "9px",
                  fontWeight: "bold",
                  color: "#777575",
                  paddingTop: "5px",
                  paddingRight: "9px",
                }}
              >
                Users
              </td>
              <td
                style={{
                  fontSize: "9px",
                  fontWeight: "bold",
                  color: "#777575",
                  paddingTop: "5px",
                  paddingRight: "0px",
                }}
              >
                Alive
              </td>
            </tr>
          </table>
        )}
      </p>
    </React.Fragment>
  );
}
export default SimplFooter;
