import React, { Component } from "react";
import { Link } from "react-router-dom";
//import simplWS_client from "./wsService";
import { toast } from "react-toastify";
import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystemIntervalData,
  getSystemInfo,
  getSystemBasics,
  getSystemIDfromHandle,
  getSystem,
  setSystemSignals,
  initSystemUpdate,
  ws_get,
  ws_post,
} from "../services/systemService";

import { setJwt, deleteHeaders } from "../services/httpService";
import { getUser } from "../services/userService";
import "./dashboard.css";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "react-bootstrap/ProgressBar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import BackGroundRectangle from "../components/backgroundRect";

import TstatSettingsModal from "./TstatSettingsModal";

//const simplWS_client = new WebSocket("ws://127.0.0.1:8082");

class NonThermoStatLocalClient extends Component {
  constructor(props) {
    super(props);
  }
  state = { systemControlsLocalLength: 0, setOffLine: 0 };

  async componentDidMount() {
    const handleID = this.props./*match.params.*/ systemHandleID;
    console.log("System Handle ID: ", handleID);
    const loadType = this.props./*match.params.*/ loadType;
    console.log("Load Type: ", loadType);
    const systemID = (await getSystemIDfromHandle(handleID)).data.data
      .system_id;
    //const systemID = localStorage.getItem("systemID");
    console.log("Got to NonThermostat Local Client: System ID", systemID);

    const systemName = (await getSystem(systemID)).data.data[0].systemID.name;
    console.log("System Name: ", systemName);

    this.setState({ handleID, systemID, loadType, systemName });
    this.timerID = setInterval(() => this.tick(), 1000);
  }
  async getSystemDataOnline() {
    const response_temp = await getSystemLatestData(
      this.props./*match.params.*/ systemHandleID
    );
    const response = response_temp.data.data[0];

    console.log("Build Data Response: ", response);
    let applianceData = [];
    if (response) {
      applianceData = response.data.deviceLedger.Appliances;
      let appliances = [];
      if (applianceData) {
        if (this.props./*match.params.*/ loadType === "Space Conditioning") {
          appliances = applianceData.filter(
            (thisAppliance) =>
              thisAppliance.loadType === "Space Heater" ||
              thisAppliance.loadType === "SimplHeater"
          );
        } else if (
          this.props./*match.params.*/ loadType === "Controlled HVAC"
        ) {
          appliances = applianceData.filter(
            (thisAppliance) => thisAppliance.loadType === "HVAC Unit"
          );
        } else if (
          this.props./*match.params.*/ loadType === "Interior Lights"
        ) {
          appliances = applianceData.filter(
            (thisAppliance) => thisAppliance.loadType === "Indoor Lights"
          );
        } else if (
          this.props./*match.params.*/ loadType === "Exterior Lights"
        ) {
          appliances = applianceData.filter(
            (thisAppliance) =>
              thisAppliance.loadType === "Pole Lights" ||
              thisAppliance.loadType === "Relay Driver" ||
              thisAppliance.loadType === "Relay Driver w Light Sensor" ||
              thisAppliance.loadType === "Quad 30A Relays" ||
              thisAppliance.loadType === "Quad 30A w Light Sensor" ||
              thisAppliance.loadType === "Outdoor Sensed Lights"
          );
        } else if (this.props./*match.params.*/ loadType === "Walk-ins") {
          appliances = applianceData.filter(
            (thisAppliance) => thisAppliance.loadType === "SimplWalkIn"
          );
        } else if (
          this.props./*match.params.*/ loadType === "Controlled Appliances"
        ) {
          appliances = applianceData.filter(
            (thisAppliance) =>
              thisAppliance.loadType === "Heat Lamp" ||
              thisAppliance.loadType === "Water Heater" ||
              thisAppliance.loadType === "Water Heater No Sensor" ||
              thisAppliance.loadType === "Reach-in" ||
              thisAppliance.loadType === "Counter Reach-in" ||
              thisAppliance.loadType === "Anti-sweat Heaters" ||
              thisAppliance.loadType === "Anti-sweat Heaters" ||
              thisAppliance.loadType === "EV Charger" ||
              thisAppliance.loadType === "Oven" ||
              thisAppliance.loadType === "Hood" ||
              thisAppliance.loadType === "Misc. Appliance" ||
              thisAppliance.loadType === "Misc. Sheddable"
          );
        }
      }
      console.log("All Appliances", applianceData);
      console.log("Appliances of this type", appliances);

      let appliancesLength = 0;
      if (!appliances) {
        appliances = {};
        appliancesLength = 0;
      } else appliancesLength = appliances.length;
      console.log("Appliances Length: ", appliancesLength);
      this.setState({ appliances, appliancesLength });
    }
  }
  tick() {
    this.setState({
      dateNow: new Date(),
    });
    this.getSystemDataOnline();
  }
  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }
  handleOverRide = async (nodeName) => {
    console.log("Dealing with Override on Appliance: ", nodeName);
    const setOffLine = this.state.setOffLine;
    toast.warning("Sending settings to the system...wait...");
    await setSystemSignals(
      this.state.systemID,
      "80",
      "TBD",
      "TBD",
      nodeName,
      "TBD"
    );

    await initSystemUpdate(this.props./*match.params.*/ systemHandleID);
    //this.sleep(15000);
    toast.success("Settings were updated...");
  };
  render() {
    return (
      <div
        style={{
          width: "350",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: "0px",
          marginBottom: "150px",
          zIndex: 1,
        }}
      >
        {/*  <p
          className="basicParagraph"
          style={{ width: "100%", textAlign: "center", fontSize: "12px" }}
        >
          {this.state.systemName} {this.props.match.params.loadType}
        </p>*/}
        <table
          className="table"
          style={{
            marginTop: "20px",
            width: "350px",
            //transform: "translateX(20px)",
          }}
        >
          <th
            style={{
              width: "100px",
              margin: "0px",
              padding: "0px",
              fontsize: "10px",
              height: "25px",
              verticalAlign: "middle",
            }}
          >
            Node Name
          </th>
          <th
            style={{
              width: "40px",
              margin: "0px",
              padding: "0px",
              fontsize: "10px",
              height: "25px",
              verticalAlign: "middle",
            }}
          >
            OverDrive Time Left
          </th>
          <th
            style={{
              width: "80px",
              margin: "0px",
              padding: "0px",
              fontsize: "10px",
              height: "25px",
              verticalAlign: "middle",
            }}
          >
            Status
          </th>
          <th
            style={{
              width: "40px",
              margin: "0px",
              padding: "0px",
              fontsize: "10px",
              height: "25px",
              verticalAlign: "middle",
            }}
          >
            Shed Status
          </th>
          <th
            style={{
              width: "60px",
              margin: "0px",
              padding: "0px",
              fontsize: "10px",
              height: "25px",
              verticalAlign: "middle",
            }}
          >
            On/Off
          </th>

          {this.state.appliances &&
            this.state.appliancesLength &&
            this.state.appliances.map((appliance) => (
              <tr key={appliance.id}>
                <td
                  style={{
                    margin: "0px",
                    padding: "0px",
                    width: "90px",
                    height: "20px",
                  }}
                >
                  {appliance.nodeName}
                </td>

                <td
                  style={{
                    margin: "0px",
                    padding: "0px",
                    width: "40px",
                    height: "20px",
                  }}
                >
                  {appliance.ORtimeRemaining}
                </td>

                {appliance.relayNum === "Relay1" &&
                  (appliance.relaySetting & 0x0001) === 0 && (
                    <td
                      style={{
                        margin: "0px",
                        padding: "0px",
                        height: "20px",
                        verticalAlign: "middle",
                        color: "lime",
                      }}
                    >
                      On
                    </td>
                  )}
                {appliance.relayNum === "Relay1" &&
                  (appliance.relaySetting & 0x0001) === 1 && (
                    <td
                      style={{
                        margin: "0px",
                        padding: "0px",
                        height: "20px",
                        verticalAlign: "middle",
                        color: "red",
                      }}
                    >
                      Off
                    </td>
                  )}
                {appliance.relayNum === "Relay2" &&
                  (appliance.relaySetting & 0x0002) >> 1 === 0 && (
                    <td
                      style={{
                        margin: "0px",
                        padding: "0px",
                        height: "20px",
                        verticalAlign: "middle",
                        color: "lime",
                      }}
                    >
                      On
                    </td>
                  )}
                {appliance.relayNum === "Relay2" &&
                  (appliance.relaySetting & 0x0002) >> 1 === 1 && (
                    <td
                      style={{
                        margin: "0px",
                        padding: "0px",
                        height: "20px",
                        verticalAlign: "middle",
                        color: "red",
                      }}
                    >
                      Off
                    </td>
                  )}
                {appliance.relayNum === "Relay3" &&
                  (appliance.relaySetting & 0x0004) >> 2 === 0 && (
                    <td
                      style={{
                        margin: "0px",
                        padding: "0px",
                        height: "20px",
                        verticalAlign: "middle",
                        color: "lime",
                      }}
                    >
                      On
                    </td>
                  )}
                {appliance.relayNum === "Relay3" &&
                  (appliance.relaySetting & 0x0004) >> 2 === 1 && (
                    <td
                      style={{
                        margin: "0px",
                        padding: "0px",
                        height: "20px",
                        verticalAlign: "middle",
                        color: "red",
                      }}
                    >
                      Off
                    </td>
                  )}
                {appliance.relayNum === "Relay4" &&
                  (appliance.relaySetting & 0x0008) >> 3 === 0 && (
                    <td
                      style={{
                        margin: "0px",
                        padding: "0px",
                        height: "20px",
                        verticalAlign: "middle",
                        color: "lime",
                      }}
                    >
                      On
                    </td>
                  )}
                {appliance.relayNum === "Relay4" &&
                  (appliance.relaySetting & 0x0008) >> 3 === 1 && (
                    <td
                      style={{
                        margin: "0px",
                        padding: "0px",
                        height: "20px",
                        verticalAlign: "middle",
                        color: "red",
                      }}
                    >
                      Off
                    </td>
                  )}
                {appliance.shedStatus === 1 && (
                  <td
                    style={{
                      margin: "0px",
                      padding: "0px",
                      height: "20px",
                      verticalAlign: "middle",
                      color: "red",
                    }}
                  >
                    Shed
                  </td>
                )}
                {appliance.shedStatus === 0 && (
                  <td
                    style={{
                      margin: "0px",
                      padding: "0px",
                      height: "20px",
                      verticalAlign: "middle",
                      color: "dodgerblue",
                    }}
                  >
                    Run
                  </td>
                )}
                <td
                  style={{
                    margin: "0px",
                    padding: "0px",
                    height: "20px",
                    verticalAlign: "middle",
                    color: "dodgerblue",
                  }}
                >
                  <button
                    className="btn btn-primary shadow-none"
                    style={{
                      height: "18px",
                      width: "25px",
                      padding: "0px",
                      margin: "0px",
                      borderWidth: "0px",
                      borderColor: "transparent",
                      color: "dodgerblue",
                      backgroundColor: "transparent",
                      fontSize: "10px",
                      fontWeight: "bolder",
                      textDecoration: "underline",
                    }}
                    onClick={() => this.handleOverRide(`${appliance.nodeName}`)}
                  >
                    On/Off
                  </button>
                </td>
              </tr>
            ))}
        </table>
      </div>
    );
  }
}
export default NonThermoStatLocalClient;
