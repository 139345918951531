import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getCompanies } from "../services/companyService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setJwt } from "../services/httpService";
import { masterCompany } from "../config.json";
import Modal from "react-bootstrap/Modal";
import BackGroundRectangle from "../components/backgroundRect";
import jwtDecode from "jwt-decode";

class Companies extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    companyId: "",
    isActive: true,
    allCompanies: [],
    activeCompanies: [],
  };

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    const decodedJWT = jwtDecode(jwt).exp * 1000;
    console.log("Decoded JWT", decodedJWT);
    const date = Date.now();
    console.log("EPOCH Now:", date);
    let mustLoginAgain = this.state.mustLoginAgain;
    if (date >= decodedJWT) {
      mustLoginAgain = true;
      console.log("Go to Login Page");
    } else {
      mustLoginAgain = false;
    }

    setJwt(jwt);

    const allCompanies = (await getCompanies()).data.data;
    const activeCompanies = allCompanies.filter(
      (thisCompany) => thisCompany.active === this.state.isActive
    );
    this.setState({ activeCompanies, mustLoginAgain });
    this.setState({ allCompanies });
    this.setState({ isActive: true });
  }

  showAll = (e) => {
    e.preventDefault();
    let whatState = this.state.isActive;
    whatState = !whatState;
    this.setState({ isActive: whatState });
  };
  render() {
    let companies = this.state.allCompanies;
    if (this.state.isActive) companies = this.state.activeCompanies;
    else companies = this.state.allCompanies;

    const companyCount = companies.length;
    console.log("Companies", companies);

    if (companyCount === 0)
      return (
        <React.Fragment>
          <div
            style={{
              width: "350",
              position: "fixed",
              left: "50%",
              transform: "translateX(-50%)",

              marginTop: "150px",
              marginBottom: "150px",
              zIndex: 1,
            }}
          >
            <table className="table">
              <thead style={{ fontSize: "25px" }}>{masterCompany}</thead>
            </table>
            <table className="table">
              <thead>
                <td>
                  {" "}
                  Create a new company
                  <Link style={{ marginLeft: "20px" }} to="/Companies/new">
                    <FontAwesomeIcon
                      icon="plus-circle"
                      size="lg"
                      color="dodgerblue"
                      title="Create a new company"
                    />
                  </Link>
                </td>
                <td>
                  {" "}
                  Manufacture a new unit
                  <Link style={{ marginLeft: "20px" }} to="/manufactures">
                    <FontAwesomeIcon
                      icon="plus-circle"
                      size="lg"
                      color="blue"
                      title="Create a new company"
                    />
                  </Link>
                </td>
              </thead>
            </table>
          </div>
          <BackGroundRectangle />
        </React.Fragment>
      );

    return (
      <React.Fragment>
        {this.state.mustLoginAgain && <Redirect from="/" exact to="/login" />}
        <div
          style={{
            width: "350px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            height: "inherit",
            marginTop: "150px",
            //marginBottom: "150px",
            paddingBottom: "150px",
            zIndex: 1,
          }}
        >
          <table className="table">
            <thead style={{ fontSize: "25px" }}>{masterCompany}</thead>
          </table>

          {/* <table className="table">
            <thead>
              <td>
                {" "}
                Animation 1
                <Link style={{ marginLeft: "20px" }} to="/systems/animation1">
                  <FontAwesomeIcon
                    icon="plus-circle"
                    size="lg"
                    color="dodgerblue"
                  />
                </Link>
              </td>
              <td>
                {" "}
                Animation 2
                <Link style={{ marginLeft: "20px" }} to="/systems/animation2">
                  <FontAwesomeIcon
                    icon="plus-circle"
                    size="lg"
                    color="dodgerblue"
                    title="Create a new company"
                  />
                </Link>
              </td>
            </thead>
        </table>*/}

          <table className="table">
            <thead>
              <td>
                {" "}
                Upload Firmware Update
                <Link style={{ marginLeft: "20px" }} to="/systems/fwUpload">
                  <FontAwesomeIcon
                    icon="plus-circle"
                    size="lg"
                    color="dodgerblue"
                  />
                </Link>
              </td>
              <td>
                {" "}
                Try out Websocket
                <Link
                  style={{ marginLeft: "20px" }}
                  to="/systems/webSocketComp"
                >
                  <FontAwesomeIcon
                    icon="plus-circle"
                    size="lg"
                    color="dodgerblue"
                    title="Create a new company"
                  />
                </Link>
              </td>
            </thead>
          </table>
          <table className="table">
            <thead>
              <td>
                {" "}
                Create a new company
                <Link style={{ marginLeft: "20px" }} to="/Companies/new">
                  <FontAwesomeIcon
                    icon="plus-circle"
                    size="lg"
                    color="dodgerblue"
                    title="Create a new company"
                  />
                </Link>
              </td>
              <td>
                {" "}
                Manufacture a new unit
                <Link style={{ marginLeft: "20px" }} to="/manufactures">
                  <FontAwesomeIcon
                    icon="plus-circle"
                    size="lg"
                    color="blue"
                    title="Create a new company"
                  />
                </Link>
              </td>
            </thead>
          </table>

          <table className="table">
            <thead>
              <td>
                {" "}
                Suspend A System
                <Link style={{ marginLeft: "20px" }} to="/systemSuspend">
                  <FontAwesomeIcon
                    icon="plus-circle"
                    size="lg"
                    color="dodgerblue"
                    title="Suspend a System"
                  />
                </Link>
              </td>
              <td>
                {" "}
                Reactivate a Suspended System
                <Link style={{ marginLeft: "20px" }} to="/systemReactivated">
                  <FontAwesomeIcon
                    icon="plus-circle"
                    size="lg"
                    color="blue"
                    title="Reactivate a System"
                  />
                </Link>
              </td>
            </thead>
          </table>

          <button
            onClick={this.showAll}
            className="btn btn-outline-light"
            style={{ borderWidth: "0px" }}
          >
            <FontAwesomeIcon
              icon="check"
              size="sm"
              color="grey"
              title="Toggle Active/All"
            />
          </button>

          <table className="table">
            <thead>
              <tr>
                <th>Company</th>
                {/*} <th>Admin Username</th>
                <th>email</th>*/}

                <th />
              </tr>
            </thead>
            <tbody>
              {companies.map((company) => (
                <tr key={company.companyName}>
                  <td>{company.companyName}</td>
                  {/*}  {company.companyAdmin && (
                    <td>{company.companyAdmin.username}</td>
                  )}
                  {!company.companyAdmin && <td />}
                  {company.companyAdmin && (
                    <td>{company.companyAdmin.emailAddress}</td>
                  )}
                  {!company.companyAdmin && <td />}*/}
                  <td>
                    <CompanyInfoModal
                      companyName={company.companyName}
                      companyActive={company.active}
                    />
                  </td>
                  <td>
                    <Link to={`/companies/edit/${company.companyName}`}>
                      <FontAwesomeIcon
                        icon="edit"
                        size="lg"
                        color="green"
                        title="Edit"
                      />
                    </Link>
                  </td>
                  {JSON.parse(localStorage.getItem("mapBoolean")) && (
                    <td>
                      <Link to={`/systems/simplMap`}>
                        <FontAwesomeIcon
                          icon="cogs"
                          size="lg"
                          color="Darkblue"
                          title="Company Systems"
                        />
                      </Link>
                    </td>
                  )}
                  {!JSON.parse(localStorage.getItem("mapBoolean")) && (
                    <td>
                      <Link to={`/companies/systems/${company.companyName}`}>
                        <FontAwesomeIcon
                          icon="cogs"
                          size="lg"
                          color="Darkblue"
                          title="Company Systems"
                        />
                      </Link>
                    </td>
                  )}
                  <td>
                    <Link to={`/companies/users/${company.companyName}`}>
                      <FontAwesomeIcon
                        icon="users-cog"
                        size="lg"
                        color="green"
                        title="Company Users"
                      />
                    </Link>
                  </td>
                  <td>
                    <Link to={`/companies/admin/${company.companyName}`}>
                      <FontAwesomeIcon
                        icon="user-lock"
                        size="lg"
                        color="DodgerBlue"
                        title="Set/Edit Admin"
                      />
                    </Link>
                  </td>
                  <td>
                    {company.active && (
                      <Link to={`/companies/delete/${company.companyName}`}>
                        <FontAwesomeIcon
                          icon="trash-alt"
                          size="lg"
                          color="red"
                          title="Delete Company"
                        />
                      </Link>
                    )}
                    {!company.active && (
                      <Link to={`/companies/restore/${company.companyName}`}>
                        <FontAwesomeIcon
                          icon="trash-restore"
                          size="lg"
                          color="Green"
                          title="Restore Company"
                        />
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>{" "}
        <BackGroundRectangle />
      </React.Fragment>
    );
  }
}

/*
companyAdmin={company.companyAdmin.username}
                    companyAdminFirstName={company.companyAdmin.firstName}
                    companyAdminLastName={company.companyAdmin.lastName}
                    companyAdminEmail={company.companyAdmin.emailAddress}
                    companyAdminPhone={company.companyAdmin.phoneNumber} 
companyContact={company.contactInfo.contactName}
                    companyContactEmail={company.contactInfo.contactEmail}
                companyContactPhone={company.contactInfo.contactPhone}*/

export default Companies;

class CompanyInfoModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <>
        <button
          onClick={this.handleShow}
          className="btn btn-outline-light"
          style={{ padding: "0", marginTop: "-5px", borderWidth: "0px" }}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="sm"
            color="orange"
            title="User Information"
          />
        </button>

        <Modal
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Company at a glance:
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <hr className="modalLine" style={{ backgroundColor: "orange" }} />
            <p className="modalTextSmall">
              Company Name: {this.props.companyName}
            </p>
            <hr className="modalLine" style={{ backgroundColor: "orange" }} />
            <p className="modalTextSmall">
              {this.props.companyActive && "Active: Yes"}
            </p>
            <p className="modalTextSmall">
              {!this.props.companyActive && "Active: No"}
            </p>
            {/*<hr className="modalLine" style={{ backgroundColor: "orange" }} />*/}
            <p style={{ fontWeight: "300" }}>Admin Information:</p>
            <hr className="modalLine" style={{ backgroundColor: "orange" }} />
            <p className="modalTextSmall">
              Name: {this.props.companyAdminFirstName}
              {this.props.companyAdminLastName}
            </p>

            <hr className="modalLine" style={{ backgroundColor: "orange" }} />
            <p className="modalTextSmall">
              Username: {this.props.companyAdmin}
            </p>

            <hr className="modalLine" style={{ backgroundColor: "orange" }} />
            <p className="modalTextSmall">{this.props.companyAdminEmail}</p>

            <hr className="modalLine" style={{ backgroundColor: "orange" }} />
            <p className="modalTextSmall">{this.props.companyAdminPhone}</p>

            {/*<hr className="modalLine" style={{ backgroundColor: "orange" }} />*/}
            <p style={{ fontWeight: "300" }}>Contact Information:</p>
            <hr className="modalLine" style={{ backgroundColor: "orange" }} />
            <p className="modalTextSmall">{this.props.companyContact}</p>
            <hr className="modalLine" style={{ backgroundColor: "orange" }} />
            <p className="modalTextSmall">{this.props.companyContactEmail}</p>
            <hr className="modalLine" style={{ backgroundColor: "orange" }} />
            <p className="modalTextSmall">{this.props.companyContactPhone}</p>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
