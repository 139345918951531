import React, { Component } from "react";

import "./dashboard.css";
import "../index.css";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import "react-datepicker/dist/react-datepicker.css";
import { ScrollView } from "@cantonjs/react-scroll-view";

class SystemInfoModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    let hwDefined = "";
    if ((parseInt(this.props.hwConfig) & 0x0001) === 1) hwDefined = "SimplHub";
    else if ((parseInt(this.props.hwConfig) & 0x0002) === 2)
      hwDefined = "SimplGateway";
    else if ((parseInt(this.props.hwConfig) & 0x0004) === 4)
      hwDefined = "SimplATS";
    else if ((parseInt(this.props.hwConfig) & 0x0008) === 8)
      hwDefined = "SimplMeter II";

    let fourgeeModule = "?";
    let fourgeeMAC = "?";
    let fourgeeLinkActive = "?";

    if ((parseInt(this.props.hwConfig) & 0x00010) >> 4 === 1)
      fourgeeModule = "4G Module Installed";
    else fourgeeModule = "No 4G Module Installed";
    if ((parseInt(this.props.hwConfig) & 0x0020) >> 5 === 1)
      fourgeeMAC = "4G MAC Initialized";
    else fourgeeMAC = "4G MAC not Initialized";
    if ((parseInt(this.props.hwConfig) & 0x0040) >> 6 === 1)
      fourgeeLinkActive = "4G Link is Active";
    else fourgeeLinkActive = "4G Link not Active";
    //console.log("Company Name:", this.props.companyName);

    // const height = window.innerHeight
    // console.log("Inner height", height);
    // let windowHeight = height > 900 ? 220 : height < 700 ? 850 : 420;
    return (
      <>
        <button
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            padding: "0px",

            marginRight: "5px",

            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="info-circle"
            size="sm"
            color="dodgerblue"
            title="System Information"
          />
        </button>

        <Modal
          style={{
            width: "250px",
            height: "100%",
            alignContent: "center",
          }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          {/* <div
              style={{
                height: windowHeight
              }}
            >
            </div> */}

          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px" }}
            >
              System Essentials:
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "10px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              System Name:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.systemName}
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.systemDescription}
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "10px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Communication Provider:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.commProvider}:{this.props.ssId}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "10px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Signal Strength/Quality:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.signalStrength}/{this.props.signalQuality}
            </p>
            <hr></hr>
            {/*<p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "10px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Hardware Configuration:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {hwDefined}
              <p>
                {fourgeeModule}
                <p>
                  {fourgeeMAC}
                  <p>{fourgeeLinkActive}</p>
                </p>
              </p>
            </p>
            <hr></hr>*/}
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              FW Application Name:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.fwAppName}
            </p>
            <p></p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Partition:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.appPartition}
            </p>
            <p></p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Master Firmware Version:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.fwVersionMaster}
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Number of WiFi Subsystem Nodes:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {" "}
              {this.props.slaveCount}
            </p>

            <hr></hr>
            {this.props.acCoupledBackUp === 1 &&
              this.props.outageFlag === 1 && (
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "center",
                    marginBottom: "1px",
                  }}
                >
                  Outage Count: {this.props.outageCount}
                </p>
              )}
            {this.props.acCoupledBackUp === 1 &&
              this.props.outageFlag === 0 && (
                <p
                  style={{
                    color: "#2d5c88",
                    fontWeight: "bold",
                    fontSize: "9px",
                    textAlign: "center",
                    marginBottom: "1px",
                  }}
                >
                  System in on Grid
                </p>
              )}

            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Company Name:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {" "}
              {this.props.companyName}
            </p>
            <p />
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              {" "}
              Electric Utility Rates:
            </p>
            {this.props.onPeakEnergy === 1 && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "center",
                  marginBottom: "1px",
                }}
              >
                Energy costs: &cent;
                {(this.props.energyRate * 100).toFixed(2)}/kWh | On-Peak hours
              </p>
            )}
            {this.props.onPeakEnergy === 0 && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "center",
                  marginBottom: "1px",
                }}
              >
                Energy costs: &cent;
                {(this.props.energyRate * 100).toFixed(2)}/kWh | Off-Peak hours
              </p>
            )}
            {this.props.onPeakDemand === 1 && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "center",
                  marginBottom: "5px",
                }}
              >
                Demand costs: ${this.props.demandRate}
                /kW | On-Peak hours
              </p>
            )}
            {this.props.onPeakDemand === 0 && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "center",
                  marginBottom: "5px",
                }}
              >
                Demand costs: ${this.props.demandRate}
                /kW | Off-Peak hours
              </p>
            )}
            <p></p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              WiFi Mesh ID: {this.props.MeshId}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              System Handle ID: {this.props.HandleID}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              System Status: {this.props.systemStatus}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              System Configuration: {this.props.systemConfiguration}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              System Product: {this.props.systemProduct}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              System Type: {this.props.systemType}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Parameter Update Rate: {this.props.paramUpdateRate}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Data Verbosity: {this.props.dataVerbosity}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Data Frequency: {this.props.dataFrequency}
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Attached SimplModule SN: {this.props.attachedSimplModuleSN}
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              {" "}
              Attached SimplModule Handle ID:{" "}
              {this.props.attachedSimplModuleHandle}
            </p>
            {this.props.moduleDataValid !== 0 && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "center",
                  marginBottom: "1px",
                }}
              >
                Valid SimplModule Data
              </p>
            )}
            {this.props.moduleDataValid == 0 && (
              <p
                style={{
                  color: "#2d5c88",
                  fontWeight: "bold",
                  fontSize: "9px",
                  textAlign: "center",
                  marginBottom: "1px",
                }}
              >
                Invalid SimplModule Data
              </p>
            )}
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Loop count since restart:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "12px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.loopCount}
              <p>
                Heap: {this.props.freeHeap}, Min: {this.props.minHeap}
              </p>
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Master Serial Number:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.serialNumber}
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Product Serial Number:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.productSN}
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              4G Module Serial Number:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.emaSN}
            </p>
            <hr></hr>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "1px",
              }}
            >
              Master MAC Address:
            </p>
            <p
              style={{
                color: "#2d5c88",
                fontWeight: "bold",
                fontSize: "9px",
                textAlign: "center",
                marginBottom: "2px",
              }}
            >
              {this.props.macAddress}
            </p>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default SystemInfoModal;
