import React, { Component } from "react";

import "./dashboard.css";
import "../index.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-datepicker/dist/react-datepicker.css";

function VerticalProgress(props) {
  return (
    <React.Fragment>
      <div
        className="progress-bar"
        style={{
          height: `${props.value}`,
          backgroundColor: `${props.color}`,
        }}
      >
        {/* {props.chartType === "NORMAL" && (
          <p
            classname="basicParagraph"
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              color: "#ffc000",
              padding: "0px",
              margin: "0px",
              paddingLeft: "2px",
              position: "relative",
              top: "100px",
            }}
          >
            {props.Number}
          </p>
        )}
        {props.chartType !== "NORMAL" && (
          <p
            classname="basicParagraph"
            style={{
              fontSize: "12px",
              fontWeight: "bold",
              color: "#ffc000",
              padding: "0px",
              margin: "0px",
              paddingLeft: "2px",
              position: "fixed",
              top: "50px",
              left: "50px",
            }}
          >
            {props.Number}
          </p>
        )}*/}
        {/* {props.chartType === "NORMAL" && (
        <p
          classname="basicParagraph"
          style={{
            fontSize: "10px",
            color: "#ffc000",
            padding: "0px",
            margin: "0px",
            position: "fixed",
            top: "50px",
            left: "50px",
          }}
        >
          ABCDEFGH
          {/* {props.unit}
        </p>
         )} */}
        {/* {props.chartType !== "NORMAL" && (
          <p
            classname="basicParagraph"
            style={{
              fontSize: "10px",
              color: "#ffc000",
              padding: "0px",
              margin: "0px",
              paddingLeft: "7px",
              position: "absolute",
              top: "10px",
            }}
          >
            {props.unit}
          </p>
        )}*/}
      </div>
    </React.Fragment>
  );
}
export default VerticalProgress;
