import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { getUser } from "../services/userService";
import { setJwt } from "../services/httpService";
import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystem,
  getSystemSignals,
  setSystemSignals,
  getSystemIDfromHandle,
  initSystemUpdate,
  getSystemInfo,
  getSystemBasics,
  findIdByMac,
} from "../services/systemService";

import "./dashboard.css";
import "../index.css";
import {
  DEACTIVATE_SLAVE,
  REACTIVATE_SLAVE,
  LOCATOR_ON,
  LOCATOR_OFF,
  SLAVE_RESET,
  SLAVE_FACTORY_RESET,
  SLAVE_HIBERNATE,
  CALIBRATE,
  UPLOAD_FW,
  masterCompany,
} from "../config.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import BackGroundRectangle from "../components/backgroundRect";
import { webSocketMode } from "../config.json"; //0: http; 1:websocket
import jwtDecode from "jwt-decode";
import { isNullOrUndefined } from "joi-browser";

import { animateScroll as scroll } from "react-scroll";
import { ScrollView } from "@cantonjs/react-scroll-view";

class SlaveWideTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slaves: [],
      acuvimNumbers: {},
      realTime: {},
      companyName: "",
      systemID: "",
      handleID: 0,
      activeState: true,
      locatorEnabled: false,
      systemStatus: "",
      dataVerbosity: 1,
      systemConfiguration: "0",
      fwUploadInitiated: false,
      slaveFunctionInitiated: false,
    };
    //this.getSystemData = this.getSystemData.bind(this);
  }

  async componentDidMount() {
    const jwt = localStorage.getItem("token");
    const decodedJWT = jwtDecode(jwt).exp * 1000;
    console.log("Decoded JWT", decodedJWT);
    const date = Date.now();
    console.log("EPOCH Now:", date);
    let mustLoginAgain = this.state.mustLoginAgain;
    if (date >= decodedJWT) {
      mustLoginAgain = true;
      console.log("Go to Login Page");
    } else {
      mustLoginAgain = false;
    }
    setJwt(jwt);
    const user = await getUser();
    console.log("This User", user);

    console.log("user", user.data.data);

    const isUserAdmin = user.data.data.admin;
    console.log("Is User Admin", isUserAdmin);

    const handleID = this.props.match.params.systemHandleID;
    const systemID = (await getSystemIDfromHandle(handleID)).data.data
      .system_id;
    const system = (await getSystem(systemID)).data.data[0];
    const systemName = system.systemID.name;
    const systemSignals = (await getSystemSignals(handleID)).data.data.signals;
    if (systemSignals) this.setState({ data: systemSignals });
    const systemInfo = await getSystemInfo(handleID);
    const systemConfiguration = systemInfo.data.data.basics.systemConfiguration;
    console.log("System Configuration", systemConfiguration);
    const companyName = this.props.match.params.companyName;
    const BT_message = "No SimplBox system is in BlueTooth mode.";
    this.setState({
      handleID,
      systemID,
      systemName,
      companyName,
      systemConfiguration,
      isUserAdmin,
      BT_message,
      mustLoginAgain,
      user: user.data.data,
    });
    this.getSystemData(); //First got to mount it and then run this.  Must take this out
    this.timerID = setInterval(() => this.tick(), 1000);
    scroll.scrollToTop();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async getSystemData() {
    let response = {};

    if (webSocketMode) {
      response = ws_getSystemLatestData(this.state.handleID);
      console.log("Websocket: Latest Data", response);
    } else {
      response = (await getSystemLatestData(this.state.handleID)).data.data[0];
      console.log("Http: Latest Data", response);
    }
    console.log("System Response Data", response);

    if (
      this.state.systemConfiguration === "0" &&
      response !== null &&
      response !== undefined
    ) {
      const slaves = response.data.deviceLedger.slaveUnits;

      const slaveCount = response.data.deviceLedger.realTime.slaveCount;
      let numOfSlaves = 0;
      if (slaves !== null && slaves !== undefined) numOfSlaves = slaves.length;
      let i = 0;
      let slaveSN = [];
      let newSlaves = [];
      // let BT_message = this.state.BT_message;
      for (i = 0; i < numOfSlaves; i++) {
        let slaveMAC = slaves[i].MAC;

        let slaveId = (await findIdByMac(slaveMAC)).data.data.system_id;
        let system = await getSystem(slaveId);
        slaveSN[i] = system.data.data[0].systemID.SN;
        /*if (slaveSN[i] === this.state.selectedSlaveSN) {
          if (BT_message !== "No SimplBox system is in BlueTooth mode.")
            BT_message = "No SimplBox system is in BlueTooth mode.";
        }*/
        newSlaves[i] = Object.assign(slaves[i], { SN: slaveSN[i] });
      }
      const realTimeData = response.data.deviceLedger.realTime;
      this.setState({
        slaves: newSlaves,
        slaveCount,
        realTimeData /*, BT_message*/,
      });
    } else if (
      this.state.systemConfiguration === "2" &&
      response !== null &&
      response !== undefined
    ) {
      const acuvimNumbers = response.data.deviceLedger.acuvim;
      console.log("Acuvim Numbers", acuvimNumbers);

      console.log(" System Configuration", this.state.systemConfiguration);
      this.setState({ acuvimNumbers });
    } else if (
      this.state.systemConfiguration === "3" &&
      response !== null &&
      response !== undefined
    ) {
      const simplMeter_ii_Numbers = response.data.deviceLedger.simplMeter_ii;
      console.log("SimplMeter II Numbers", simplMeter_ii_Numbers);

      console.log(" System Configuration", this.state.systemConfiguration);
      this.setState({ simplMeter_ii_Numbers });
    } else if (
      (this.state.systemConfiguration === "6" ||
        this.state.systemConfiguration === "8") &&
      response !== null &&
      response !== undefined
    ) {
      const slaves = response.data.deviceLedger.slaveUnits;

      const slaveCount = response.data.deviceLedger.realTime.slaveCount;
      let numOfSlaves = 0;
      if (slaves !== null && slaves !== undefined) numOfSlaves = slaves.length;

      let i = 0;
      let slaveSN = [];
      let newSlaves = [];
      let slaveRegistered = [];
      // let BT_message = this.state.BT_message;
      for (i = 0; i < numOfSlaves; i++) {
        let slaveMAC = slaves[i].MAC;
        let slaveResponse = await findIdByMac(slaveMAC);
        console.log("Slave Response: ", slaveResponse);
        if (slaveResponse.status === 200) {
          let slaveId = slaveResponse.data.data.system_id;
          let system = await getSystem(slaveId);
          slaveSN[i] = system.data.data[0].systemID.SN;
          slaveRegistered[i] = true;
        } else {
          slaveSN[i] = slaveMAC;
          slaveRegistered[i] = false;
        }

        newSlaves[i] = Object.assign(
          slaves[i],
          { SN: slaveSN[i] },
          { sReg: slaveRegistered[i] }
        );
      }
      const realTimeData = response.data.deviceLedger.realTime;
      console.log("Slaves", newSlaves);
      this.setState({
        slaves: newSlaves,
        slaveCount,
        realTimeData,
      });
    }
    const systemBasics = await getSystemBasics(this.state.handleID);
    if (systemBasics !== isNullOrUndefined) {
      const dataFrequency = systemBasics.data.data.basics.dataFrequency;
      let sleepTime = 0;
      if (dataFrequency === "1") sleepTime = 5000;
      //mSec
      //"1", name: "once / Second"
      else if (dataFrequency === "2") sleepTime = 15000;
      //5seconds + 5 seconds + 5 seconds
      //"2", name: "once / 5 Seconds"
      else sleepTime = 35000; //15 seconds + 15 seconds + 5 seconds
      const systemInfo = await getSystemInfo(this.state.handleID);
      const systemStatus = systemInfo.data.data.status;

      const dataVerbosity = systemInfo.data.data.basics.dataVerbosity;
      console.log("Data Verbosity", dataVerbosity);
      this.setState({ systemStatus, dataVerbosity, sleepTime });
      console.log("System Status in getSystemData:", this.state.systemStatus);
      console.log(
        "slaveFunctionInitiated in getSystemData:",
        this.state.slaveFunctionInitiated
      );
      if (this.state.slaveFunctionInitiated) {
        if (this.state.systemStatus === "UPDATING")
          console.log("System Status is UPDATING");
        //Don't need time-out...use UI to set to ALIVE if stuck here
        else if (this.state.systemStatus === "ALIVE") {
          console.log("Neutralize System Signals...");
          /*await setSystemSignals(
            this.state.systemID,
            "0",
            "",
            "",
            "",
            "FF:FF:FF:FF:FF:FF" 
          );
          await initSystemUpdate(this.props.match.params.systemHandleID);*/
          this.setState({ slaveFunctionInitiated: false });
        }
      }
      if (this.state.fwUploadInitiated) {
        console.log(
          "......Firmware Update was initiated and in progress   ....."
        );
        console.log("System Status: ", systemStatus);
        if (systemStatus === "ALIVE") {
          this.setState({ fwUploadInitiated: false });
          await setSystemSignals(
            this.state.systemID,
            "0",
            "",
            "",
            "",
            "FF:FF:FF:FF:FF:FF" /*slaveMAC*/
          );
          await initSystemUpdate(this.props.match.params.systemHandleID);
        }
      }
    }
  }

  tick() {
    this.setState({
      dateNow: new Date(),
    });
    this.getSystemData();
  }

  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  handleSlave = async (performFunction) => {
    const slaveMAC = this.state.slaveMAC;
    console.log("Slave MAC in Wide Tables:", slaveMAC);
    console.log("Perform Function:", performFunction);
    console.log("System Status:", this.state.systemStatus);
    const slaveBTStatus = this.state.slaveBTStatus;

    console.log("BTstatus: ", slaveBTStatus);

    if (slaveMAC !== undefined && this.state.systemStatus === "ALIVE") {
      await setSystemSignals(
        this.state.systemID,
        performFunction,
        "",
        "",
        "",
        slaveMAC
      );

      if (
        !this.state.isUserAdmin &&
        (performFunction === SLAVE_RESET ||
          performFunction === SLAVE_FACTORY_RESET ||
          performFunction === DEACTIVATE_SLAVE ||
          performFunction === SLAVE_HIBERNATE ||
          performFunction === UPLOAD_FW)
      )
        toast.error("Must be an ADMIN to use this function!");
      else {
        await initSystemUpdate(this.props.match.params.systemHandleID);

        /*if (
          performFunction === SLAVE_RESET ||
          performFunction === SLAVE_FACTORY_RESET ||
          performFunction === DEACTIVATE_SLAVE ||
          performFunction === SLAVE_HIBERNATE ||
          performFunction === LOCATOR_ON ||
          performFunction === LOCATOR_OFF
        ) {
          const sleepTime = this.state.sleepTime;
          console.log("sleepTime = ", sleepTime);
          this.sleep(sleepTime); //Wait for reset to take effect for the loop running as slow as 15000 mseconds
          await setSystemSignals(
            this.state.systemID,
            "0",
            "",
            "",
            "",
            "12:12:12:12:12:12"
          );
          await initSystemUpdate(this.props.match.params.systemHandleID);
        } else*/
        if (performFunction === UPLOAD_FW) {
          this.setState({ fwUploadInitiated: true });
        } else {
          this.setState({ slaveFunctionInitiated: true });
        }
      }
    } else toast.error("You must first select a Slave Unit.");
  };
  handleChangeRadio = (slaveMAC, selectedSlaveSN) => {
    this.setState({ slaveMAC, selectedSlaveSN });
  };
  render() {
    return (
      <React.Fragment>
        <ScrollView style={{ height: "200vh" }}>
          {this.state.mustLoginAgain && <Redirect from="/" exact to="/login" />}
          <div
            style={{
              width: "900px",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "150px",
              marginBottom: "150px",
              paddingBottom: "100px",
              overflowY: "scrollY",
              zIndex: 1,
            }}
          >
            <p
              className="basicParagraph"
              style={{ width: "900px", textAlign: "center", fontSize: "11px" }}
            >
              {this.state.systemName}
            </p>

            {this.state.dataVerbosity === "3" && (
              <div>
                {this.state.systemConfiguration === "2" && (
                  <div
                    style={{
                      width: "350px",
                      marginLeft: "260px",
                    }}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Grid Power Meter</th>
                          <th>Voltage (LL)</th>
                          <th>Current (A)</th>
                          <th>Power (W)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Phase AB</td>
                          <td>
                            {this.state.acuvimNumbers.acuvimVAB0.toFixed(1)}
                          </td>
                          <td>
                            {this.state.acuvimNumbers.acuvimIA0.toFixed(1)}
                          </td>
                          <td>
                            {this.state.acuvimNumbers.acuvimPA0
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                        </tr>
                        <tr>
                          <td>Phase BC</td>
                          <td>
                            {this.state.acuvimNumbers.acuvimVBC0.toFixed(1)}
                          </td>
                          <td>
                            {this.state.acuvimNumbers.acuvimIB0.toFixed(1)}
                          </td>
                          <td>
                            {this.state.acuvimNumbers.acuvimPB0
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                        </tr>
                        <tr>
                          <td>Phase CA</td>
                          <td>
                            {this.state.acuvimNumbers.acuvimVCA0.toFixed(1)}
                          </td>
                          <td>
                            {this.state.acuvimNumbers.acuvimIC0.toFixed(1)}
                          </td>
                          <td>
                            {this.state.acuvimNumbers.acuvimPC0
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Power (W)</td>
                          <td></td>
                          <td></td>
                          <td>
                            {this.state.acuvimNumbers.acuvimPower0
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Solar Power Meter</th>
                          <th>Voltage (LL)</th>
                          <th>Current (A)</th>
                          <th>Power (W)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Phase AB</td>
                          <td>
                            {this.state.acuvimNumbers.acuvimVAB1.toFixed(1)}
                          </td>
                          <td>
                            {this.state.acuvimNumbers.acuvimIA1.toFixed(1)}
                          </td>
                          <td>
                            {this.state.acuvimNumbers.acuvimPA1
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                        </tr>
                        <tr>
                          <td>Phase BC</td>
                          <td>
                            {this.state.acuvimNumbers.acuvimVBC1.toFixed(1)}
                          </td>
                          <td>
                            {this.state.acuvimNumbers.acuvimIB1.toFixed(1)}
                          </td>
                          <td>
                            {this.state.acuvimNumbers.acuvimPB1
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                        </tr>
                        <tr>
                          <td>Phase CA</td>
                          <td>
                            {this.state.acuvimNumbers.acuvimVCA1.toFixed(1)}
                          </td>
                          <td>
                            {this.state.acuvimNumbers.acuvimIC1.toFixed(1)}
                          </td>
                          <td>
                            {this.state.acuvimNumbers.acuvimPC1
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Power(W)</td>
                          <td></td>
                          <td></td>
                          <td>
                            {this.state.acuvimNumbers.acuvimPower1
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                {/*///////////////////////////////////////////////////////////////////////////////////*/}
                {this.state.systemConfiguration === "3" && (
                  <div
                    style={{
                      width: "350px",
                      marginLeft: "260px",
                    }}
                  >
                    <table className="table">
                      <tr>
                        <td>Temp.:</td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2temp1} &#8451;
                        </td>
                      </tr>
                      <thead>
                        <tr>
                          <th>Meter 1</th>
                          <th>Voltage (LN)</th>
                          <th>Current (A)</th>
                          <th>Power (W)</th>
                          <th>PF</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Phase AB</td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2VAN1.toFixed(
                              1
                            )}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2IA1.toFixed(1)}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2PA1
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2PFa1.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Phase BC</td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2VBN1.toFixed(
                              1
                            )}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2IB1.toFixed(1)}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2PB1
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2PFb1.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Phase CA</td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2VCN1.toFixed(
                              1
                            )}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2IC1.toFixed(1)}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2PC1
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2PFc1.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Power (W)</td>
                          <td></td>
                          <td></td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2Ptotal1
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="table">
                      <tr>
                        <td>Temp.:</td>
                        <td>
                          {this.state.simplMeter_ii_Numbers.sm2temp2} &#8451;
                        </td>
                      </tr>
                      <thead>
                        <tr>
                          <th>Meter 2</th>
                          <th>Voltage (LN)</th>
                          <th>Current (A)</th>
                          <th>Power (W)</th>
                          <th>PF</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Phase AB</td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2VAN2.toFixed(
                              1
                            )}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2IA2.toFixed(1)}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2PA2
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2PFa2.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Phase BC</td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2VBN2.toFixed(
                              1
                            )}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2IB2.toFixed(1)}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2PB2
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2PFb2.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Phase CA</td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2VCN2.toFixed(
                              1
                            )}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2IC2.toFixed(1)}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2PC2
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2PFc2.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Power(W)</td>
                          <td></td>
                          <td></td>
                          <td>
                            {this.state.simplMeter_ii_Numbers.sm2Ptotal2
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                {(this.state.systemConfiguration === "6" ||
                  this.state.systemConfiguration === "8") &&
                  this.state.slaveCount == 0 && (
                    <div>
                      <p className="basicParagraph">
                        {" "}
                        No Active subsystem is detected in the system...
                      </p>
                      <p className="basicParagraph">
                        {" "}
                        Please stand by as connections are being established.
                      </p>
                    </div>
                  )}
                {(this.state.systemConfiguration === "6" ||
                  this.state.systemConfiguration === "8") &&
                  this.state.slaveCount != 0 && (
                    <div>
                      {this.state.BT_message !==
                        "No SimplBox system is in BlueTooth mode." && (
                        <p
                          className="basicParagraph"
                          style={{
                            textAlign: "center",
                            color: "orange",
                            marginLeft: "300px",
                          }}
                        >
                          {this.state.selectedSlaveSN} at MAC Adress:{" "}
                          {this.state.slaveMAC}+2 {this.state.BT_message}
                        </p>
                      )}
                      {this.state.isUserAdmin &&
                        (this.state.user.company === masterCompany ||
                          this.state.user.company ===
                            "Simpl Manufacturing") && (
                          <div
                            style={{
                              float: "left",
                              width: "400px",
                            }}
                          >
                            <table
                              className="table"
                              style={{
                                width: "350px",
                                position: "absolute",
                                left: "52%",
                                transform: "translateX(-50%)",
                              }}
                            >
                              <tbody>
                                <div>
                                  <tr>
                                    <th>Reset</th>
                                    <th>Factory</th>
                                    <th>Locator</th>
                                    <th>Calibrate</th>
                                    <th>FW Upload</th>
                                  </tr>
                                  <tr>
                                    {this.state.systemStatus === "UPDATING" && (
                                      <td>Updating...</td>
                                    )}
                                    {this.state.systemStatus === "ALIVE" && (
                                      <td>
                                        <button
                                          className="btn btn-outline-light"
                                          style={{
                                            padding: "0px",
                                            borderWidth: "0px",
                                            marginRight: "2px",
                                            marginTop: "-7px",
                                            padding: "0px",
                                            position: "relative",
                                            right: "0px",
                                            boxShadow:
                                              "5px 5 px 5px 5px #888888",
                                          }}
                                          onClick={() =>
                                            this.handleSlave(SLAVE_RESET)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon="retweet"
                                            size="sm"
                                            color="orange"
                                            title="Reset Subsystems"
                                          />
                                        </button>
                                      </td>
                                    )}
                                    {this.state.systemStatus === "ALIVE" && (
                                      <td>
                                        <button
                                          className="btn btn-outline-light"
                                          style={{
                                            padding: "0px",
                                            borderWidth: "0px",
                                            marginRight: "2px",
                                            marginTop: "-7px",
                                            padding: "0px",
                                            position: "relative",
                                            right: "0px",
                                            boxShadow:
                                              "5px 5 px 5px 5px #888888",
                                          }}
                                          onClick={() =>
                                            this.handleSlave(
                                              SLAVE_FACTORY_RESET
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon="history"
                                            size="sm"
                                            color="magenta"
                                            title="Factory Reset Subsystems"
                                          />
                                        </button>
                                      </td>
                                    )}

                                    {this.state.systemStatus === "ALIVE" && (
                                      <td style={{ color: "white" }}>
                                        <button
                                          className="btn btn-outline-light"
                                          style={{
                                            padding: "0px",
                                            borderWidth: "0px",
                                            marginRight: "2px",
                                            marginTop: "-7px",
                                            padding: "0px",
                                            position: "relative",
                                            right: "0px",
                                          }}
                                          onClick={() =>
                                            this.handleSlave(LOCATOR_ON)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon="crosshairs"
                                            size="sm"
                                            color="red"
                                            title="Locate installed subsystem"
                                          />
                                        </button>
                                      </td>
                                    )}

                                    {this.state.systemStatus === "ALIVE" && (
                                      <td style={{ color: "white" }}>
                                        <Link
                                          to={`/systems/systemmodules/${this.state.handleID}/${this.state.companyName}/${this.state.slaveMAC}`}
                                        >
                                          <FontAwesomeIcon
                                            style={{
                                              padding: "0px",
                                              borderWidth: "0px",
                                              marginRight: "2px",
                                              marginBottom: "2px",
                                              paddingBottom: "2px",
                                              right: "0px",
                                            }}
                                            icon="balance-scale"
                                            size="lg"
                                            color="dodgerblue"
                                            title="Calibrate"
                                          />
                                        </Link>
                                      </td>
                                    )}
                                    {this.state.systemStatus === "ALIVE" && (
                                      <td style={{ color: "white" }}>
                                        <button
                                          className="btn btn-outline-light"
                                          style={{
                                            padding: "0px",
                                            borderWidth: "0px",
                                            marginRight: "2px",
                                            marginTop: "-7px",
                                            padding: "0px",
                                            position: "relative",
                                            right: "0px",
                                          }}
                                          onClick={() =>
                                            this.handleSlave(UPLOAD_FW)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon="cloud-upload-alt"
                                            size="sm"
                                            color="blue"
                                            title="Upload FW for Subsystem"
                                          />
                                        </button>
                                      </td>
                                    )}
                                  </tr>
                                </div>
                              </tbody>
                            </table>
                          </div>
                        )}

                      <table
                        className="table"
                        style={{
                          width: "350px",
                          position: "absolute",
                          left: "50%",
                          marginTop:
                            this.state.isUserAdmin &&
                            (this.state.user.company === masterCompany ||
                              this.state.user.company === "Simpl Manufacturing")
                              ? "70px"
                              : "0px",
                          transform: "translateX(-50%)",
                        }}
                      >
                        <div>
                          <thead
                            style={{
                              backgroundColor: "#ece9e9",
                              marginTop: "50px",
                            }}
                          >
                            <th
                              style={{
                                width: "3%",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                color: "magenta",
                              }}
                            >
                              Select
                            </th>
                            <th
                              style={{
                                width: "3%",
                                paddingLeft: "8px",
                                paddingRight: "0px",
                                marginRight: "5px",
                                color: "magenta",
                              }}
                            >
                              SN
                            </th>
                            <th
                              style={{
                                width: "3%",
                                paddingLeft: "18px",
                                marginLeft: "18px",
                                paddingRight: "25px",
                                marginRight: "25px",
                                color: "magenta",
                              }}
                            >
                              Type
                            </th>
                            <th
                              style={{
                                width: "3%",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                marginRight: "0px",
                                color: "magenta",
                              }}
                            >
                              Mesh Layer
                            </th>
                            <th
                              style={{
                                width: "3%",
                              }}
                            ></th>
                            <th
                              style={{
                                width: "3%",
                              }}
                            ></th>
                          </thead>
                        </div>

                        {this.state.slaves.map((slave) => (
                          <div>
                            <tr key={slave.id}>
                              <td style={{ width: "4%", padding: "0px" }}>
                                <input
                                  className="form-check-input"
                                  style={{
                                    marginTop: "12px",
                                    marginLeft: "1px",
                                  }}
                                  type="radio"
                                  name="registerReason"
                                  id="registerReason1"
                                  onClick={() =>
                                    this.handleChangeRadio(
                                      `${slave.MAC}`,
                                      `${slave.SN}`
                                    )
                                  }
                                  value="reason1"
                                  // checked
                                ></input>
                              </td>
                              {slave.sReg === true && (
                                <td
                                  style={{
                                    width: "10%",
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                  }}
                                >
                                  {slave.SN}
                                </td>
                              )}
                              {slave.sReg === false && (
                                <td
                                  style={{
                                    width: "10%",
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                    color: "red",
                                  }}
                                >
                                  {slave.SN}
                                </td>
                              )}
                              <td
                                style={{
                                  width: "10%",
                                  paddingLeft: "0px",
                                  paddingRight: "0px",
                                }}
                              >
                                {slave.deviceType}
                              </td>
                              <td
                                style={{
                                  width: "3%",
                                  paddingLeft: "0px",
                                  paddingRight: "0px",
                                }}
                              >
                                {slave.meshLayer}
                              </td>
                              <td style={{ width: "5%" }}>
                                <FontAwesomeIcon
                                  style={{
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                  }}
                                  icon="wifi"
                                  size="lg"
                                  color="green"
                                  title="Signal Strength"
                                />
                                {slave.deviceRSSI}
                              </td>
                              <td style={{ width: "3%", marginRight: "10px" }}>
                                <SM_II_SlaveInfoModal
                                  unitNumber={
                                    this.state.slaves.indexOf(slave) + 1
                                  }
                                  SN={slave.SN}
                                  macAddress={slave.MAC}
                                  fwVersionSlave={slave.fwVersionSlave}
                                  slaveState={slave.slaveState}
                                  loopCount={slave.slave_loopCount}
                                  appPartition={slave.appPartition}
                                  deviceType={slave.deviceType}
                                  sm2DMND1={slave.sm2DMND1}
                                  sm2DMND2={slave.sm2DMND2}
                                  sm2IA1={slave.sm2IA1}
                                  sm2IA2={slave.sm2IA2}
                                  sm2IB1={slave.sm2IB1}
                                  sm2IB2={slave.sm2IB2}
                                  sm2IC1={slave.sm2IC1}
                                  sm2IC2={slave.sm2IC2}
                                  sm2PA1={slave.sm2PA1}
                                  sm2PA2={slave.sm2PA2}
                                  sm2PB1={slave.sm2PB1}
                                  sm2PB2={slave.sm2PB2}
                                  sm2PC1={slave.sm2PC1}
                                  sm2PC2={slave.sm2PC2}
                                  sm2PFa1={slave.sm2PFa1}
                                  sm2PFa2={slave.sm2PFa2}
                                  sm2PFb1={slave.sm2PFb1}
                                  sm2PFb2={slave.sm2PFb2}
                                  sm2PFc1={slave.sm2PFc1}
                                  sm2PFc2={slave.sm2PFc2}
                                  sm2Ptotal1={slave.sm2Ptotal1}
                                  sm2Ptotal2={slave.sm2Ptotal2}
                                  sm2VAN1={slave.sm2VAN1}
                                  sm2VAN2={slave.sm2VAN2}
                                  sm2VBN1={slave.sm2VBN1}
                                  sm2VBN2={slave.sm2VBN2}
                                  sm2VCN1={slave.sm2VCN1}
                                  sm2VCN2={slave.sm2VCN2}
                                  sm2temp1={slave.sm2temp1}
                                  sm2temp2={slave.sm2temp2}
                                />
                              </td>
                            </tr>
                          </div>
                        ))}
                      </table>
                    </div>
                  )}

                {this.state.systemConfiguration === "0" &&
                  this.state.slaveCount == 0 && (
                    <div>
                      <p className="basicParagraph">
                        {" "}
                        No Active subsystem is detected in the system...
                      </p>
                      <p className="basicParagraph">
                        {" "}
                        Please stand by as connections are being established.
                      </p>
                    </div>
                  )}
                {this.state.systemConfiguration === "0" &&
                  this.state.slaveCount != 0 && (
                    <div>
                      {this.state.BT_message !==
                        "No SimplBox system is in BlueTooth mode." && (
                        <p
                          className="basicParagraph"
                          style={{
                            textAlign: "center",
                            color: "orange",
                            marginLeft: "300px",
                          }}
                        >
                          {this.state.selectedSlaveSN} at MAC Adress:{" "}
                          {this.state.slaveMAC}+2 {this.state.BT_message}
                        </p>
                      )}
                      <div
                        style={{
                          float: "left",
                          width: "400px",
                        }}
                      >
                        <table
                          className="table"
                          style={{
                            marginLeft: "50px",
                            width: "350px",
                          }}
                        >
                          <tbody>
                            <div>
                              <tr>
                                <th>Reset</th>
                                <th>Factory</th>
                                <th>Turn Off</th>
                                <th>Hibernate</th>
                                <th>Locator</th>
                                <th>Calibrate</th>
                                <th>FW Upload</th>
                              </tr>
                              <tr>
                                {this.state.systemStatus === "UPDATING" && (
                                  <td>Updating...</td>
                                )}
                                {this.state.systemStatus === "ALIVE" && (
                                  <td>
                                    <button
                                      className="btn btn-outline-light"
                                      style={{
                                        padding: "0px",
                                        borderWidth: "0px",
                                        marginRight: "2px",
                                        marginTop: "-7px",
                                        padding: "0px",
                                        position: "relative",
                                        right: "0px",
                                        boxShadow: "5px 5 px 5px 5px #888888",
                                      }}
                                      onClick={() =>
                                        this.handleSlave(SLAVE_RESET)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon="retweet"
                                        size="sm"
                                        color="orange"
                                        title="Reset Subsystems"
                                      />
                                    </button>
                                  </td>
                                )}
                                {this.state.systemStatus === "ALIVE" && (
                                  <td>
                                    <button
                                      className="btn btn-outline-light"
                                      style={{
                                        padding: "0px",
                                        borderWidth: "0px",
                                        marginRight: "2px",
                                        marginTop: "-7px",
                                        padding: "0px",
                                        position: "relative",
                                        right: "0px",
                                        boxShadow: "5px 5 px 5px 5px #888888",
                                      }}
                                      onClick={() =>
                                        this.handleSlave(SLAVE_FACTORY_RESET)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon="history"
                                        size="sm"
                                        color="magenta"
                                        title="Factory Reset Subsystems"
                                      />
                                    </button>
                                  </td>
                                )}
                                {this.state.systemStatus === "ALIVE" && (
                                  <td style={{ color: "white" }}>
                                    <button
                                      className="btn btn-outline-light"
                                      style={{
                                        padding: "0px",
                                        borderWidth: "0px",
                                        marginRight: "2px",
                                        marginTop: "-7px",
                                        padding: "0px",
                                        position: "relative",
                                        right: "0px",
                                        boxShadow: "5px 5 px 5px 5px #888888",
                                      }}
                                      onClick={() =>
                                        this.handleSlave(DEACTIVATE_SLAVE)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon="power-off"
                                        size="sm"
                                        color="green"
                                        title="PV Must Be Shorted"
                                      />
                                    </button>
                                  </td>
                                )}
                                {this.state.systemStatus === "ALIVE" && (
                                  <td>
                                    <button
                                      className="btn btn-outline-light"
                                      style={{
                                        padding: "0px",
                                        borderWidth: "0px",
                                        marginRight: "2px",
                                        marginTop: "-7px",
                                        padding: "0px",
                                        position: "relative",
                                        right: "0px",
                                        boxShadow: "5px 5 px 5px 5px #888888",
                                      }}
                                      onClick={() =>
                                        this.handleSlave(SLAVE_HIBERNATE)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon="stop"
                                        size="sm"
                                        color="tomato"
                                        title="Disable Batteries"
                                      />
                                    </button>
                                  </td>
                                )}
                                {this.state.systemStatus === "ALIVE" && (
                                  <td style={{ color: "white" }}>
                                    <button
                                      className="btn btn-outline-light"
                                      style={{
                                        padding: "0px",
                                        borderWidth: "0px",
                                        marginRight: "2px",
                                        marginTop: "-7px",
                                        padding: "0px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                      onClick={() =>
                                        this.handleSlave(LOCATOR_ON)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon="crosshairs"
                                        size="sm"
                                        color="red"
                                        title="Locate installed subsystem"
                                      />
                                    </button>
                                  </td>
                                )}

                                {this.state.systemStatus === "ALIVE" && (
                                  <td style={{ color: "white" }}>
                                    <Link
                                      to={`/systems/calibratePage/${this.state.handleID}/${this.state.slaveMAC}`}
                                    >
                                      <FontAwesomeIcon
                                        style={{
                                          padding: "0px",
                                          borderWidth: "0px",
                                          marginRight: "2px",
                                          marginBottom: "2px",
                                          paddingBottom: "2px",
                                          right: "0px",
                                        }}
                                        icon="balance-scale"
                                        size="lg"
                                        color="dodgerblue"
                                        title="Calibrate"
                                      />
                                    </Link>
                                  </td>
                                )}
                                {this.state.systemStatus === "ALIVE" && (
                                  <td style={{ color: "white" }}>
                                    <button
                                      className="btn btn-outline-light"
                                      style={{
                                        padding: "0px",
                                        borderWidth: "0px",
                                        marginRight: "2px",
                                        marginTop: "-7px",
                                        padding: "0px",
                                        position: "relative",
                                        right: "0px",
                                      }}
                                      onClick={() =>
                                        this.handleSlave(UPLOAD_FW)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon="cloud-upload-alt"
                                        size="sm"
                                        color="blue"
                                        title="Upload FW for Subsystem"
                                      />
                                    </button>
                                  </td>
                                )}
                              </tr>
                            </div>
                          </tbody>
                        </table>
                      </div>
                      <div
                        style={{
                          width: "350px",
                          float: "right",
                        }}
                      >
                        <table className="table">
                          <tbody>
                            <div>
                              <tr>
                                <th>SOC %</th>
                                <th>Solar (W)</th>
                                <th>Battery (W)</th>
                                <th>Inverter (W)</th>
                              </tr>
                              <tr>
                                <td>
                                  {this.state.realTimeData.battSOC.toFixed(1)}%
                                </td>
                                <td style={{ color: "orange" }}>
                                  {(
                                    this.state.realTimeData.pvPower / 100
                                  ).toFixed(1)}
                                </td>
                                {this.state.realTimeData.batteryPower >= 0 && (
                                  <td style={{ color: "dodgerblue" }}>
                                    {(
                                      this.state.realTimeData.batteryPower / 100
                                    ).toFixed(1)}
                                  </td>
                                )}
                                {this.state.realTimeData.batteryPower < 0 && (
                                  <td style={{ color: "red" }}>
                                    {(
                                      this.state.realTimeData.batteryPower / 100
                                    ).toFixed(1)}
                                  </td>
                                )}

                                {this.state.realTimeData.inverterPower >= 0 && (
                                  <td style={{ color: "purple" }}>
                                    {(
                                      this.state.realTimeData.inverterPower /
                                      100
                                    ).toFixed(1)}
                                  </td>
                                )}
                                {this.state.realTimeData.inverterPower < 0 && (
                                  <td style={{ color: "magenta" }}>
                                    {(
                                      this.state.realTimeData.inverterPower /
                                      100
                                    ).toFixed(1)}
                                  </td>
                                )}
                              </tr>
                            </div>
                          </tbody>
                        </table>
                      </div>
                      <table className="table">
                        <div>
                          <thead
                            style={{
                              backgroundColor: "#ece9e9",
                              marginTop: "50px",
                            }}
                          >
                            <th
                              style={{
                                width: "2%",
                                paddingLeft: "0px",
                                marginLeft: "0px",
                                paddingRight: "0px",
                                marginRight: "0px",
                                color: "magenta",
                              }}
                            >
                              Select
                            </th>
                            <th
                              style={{
                                width: "2%",
                                paddingLeft: "0px",
                                marginLeft: "0px",
                                paddingRight: "0px",
                                marginRight: "0px",
                                color: "magenta",
                              }}
                            >
                              Status
                            </th>
                            <th
                              style={{
                                width: "2%",
                                color: "magenta",
                                paddingLeft: "0px",
                                marginLeft: "0px",
                                paddingRight: "0px",
                                marginRight: "0px",
                              }}
                            >
                              Unit
                            </th>
                            <th
                              style={{
                                color: "magenta",
                                width: "4%",
                                textAlign: "left",
                                paddingLeft: "20px",
                                marginLeft: "0px",
                                paddingRight: "0px",
                                marginRight: "0px",
                              }}
                            >
                              Serial #
                            </th>
                            <th
                              style={{
                                width: "1%",
                                textAlign: "left",
                                color: "magenta",
                                paddingLeft: "20px",
                                marginLeft: "0px",
                                paddingRight: "0px",
                                marginRight: "0px",
                              }}
                            >
                              St.
                            </th>
                            <th
                              style={{
                                width: "3%",
                                textAlign: "left",
                                color: "magenta",
                                paddingLeft: "25px",
                                marginLeft: "0px",
                                paddingRight: "0px",
                                marginRight: "0px",
                              }}
                            >
                              SOC
                            </th>
                            <th
                              style={{
                                width: "3%",
                                textAlign: "left",
                                color: "magenta",
                                paddingLeft: "10px",
                                marginLeft: "0px",
                                paddingRight: "0px",
                                marginRight: "0px",
                              }}
                            >
                              cRatio
                            </th>
                            <th
                              style={{
                                width: "3%",
                                textAlign: "center",
                                color: "magenta",
                                paddingLeft: "0px",
                                marginLeft: "0px",
                                paddingRight: "0px",
                                marginRight: "0px",
                              }}
                            >
                              packV
                            </th>
                            <th
                              style={{
                                width: "3%",
                                textAlign: "center",
                                color: "magenta",
                                paddingLeft: "0px",
                                marginLeft: "0px",
                                paddingRight: "0px",
                                marginRight: "0px",
                              }}
                            >
                              CellDV
                            </th>
                            <th
                              style={{
                                width: "4%",
                                textAlign: "center",
                                color: "magenta",
                                paddingLeft: "0px",
                                marginLeft: "0px",
                                paddingRight: "0px",
                                marginRight: "0px",
                              }}
                            >
                              Vpv
                            </th>
                            <th
                              style={{
                                width: "5%",
                                textAlign: "center",
                                color: "magenta",
                                paddingLeft: "0px",
                                marginLeft: "0px",
                                paddingRight: "0px",
                                marginRight: "0px",
                                transform: "translate(-15px, 0px)",
                              }}
                            >
                              Solar (W)
                            </th>
                            <th
                              style={{
                                width: "4%",
                                textAlign: "center",
                                color: "magenta",
                                paddingLeft: "0px",
                                marginLeft: "0px",
                                paddingRight: "0px",
                                marginRight: "0px",
                                transform: "translate(-10px, 0px)",
                              }}
                            >
                              Battery (W)
                            </th>
                            <th
                              style={{
                                width: "4%",
                                textAlign: "center",
                                color: "magenta",
                                paddingLeft: "0px",
                                marginLeft: "0px",
                                paddingRight: "0px",
                                marginRight: "0px",
                                transform: "translate(0px, 0px)",
                              }}
                            >
                              Inverter (W)
                            </th>
                            <th
                              style={{
                                width: "4%",
                                textAlign: "center",
                                color: "magenta",
                                paddingLeft: "0px",
                                marginLeft: "0px",
                                paddingRight: "0px",
                                marginRight: "0px",
                                transform: "translate(15px, 0px)",
                              }}
                            >
                              Heatsink (F)
                            </th>
                            <th
                              style={{
                                width: "5%",
                                color: "magenta",
                                transform: "translate(50px, 0px)",
                              }}
                            >
                              Layer
                            </th>
                            <th
                              style={{
                                width: "5%",
                              }}
                            ></th>
                          </thead>
                        </div>

                        {this.state.slaves.map((slave) => (
                          <div>
                            <tr key={slave.id}>
                              <td style={{ width: "4%", padding: "0px" }}>
                                <input
                                  className="form-check-input"
                                  style={{
                                    marginTop: "12px",
                                    marginLeft: "1px",
                                  }}
                                  type="radio"
                                  name="registerReason"
                                  id="registerReason1"
                                  onClick={() =>
                                    this.handleChangeRadio(
                                      `${slave.MAC}`,
                                      `${slave.SN}`
                                    )
                                  }
                                  value="reason1"
                                  // checked
                                ></input>
                              </td>
                              {slave.deviceAlarm === 0 && (
                                <td style={{ width: "4%" }}>
                                  <SlaveAlarmModal
                                    unitNumber={
                                      this.state.slaves.indexOf(slave) + 1
                                    }
                                    tempData={slave.tempData}
                                    voltageData={slave.voltageData}
                                    dataVerbosity={this.state.dataVerbosity}
                                  />
                                </td>
                              )}
                              {slave.deviceAlarm === 1 && (
                                <td style={{ width: "3%" }}>
                                  <FontAwesomeIcon
                                    icon="check"
                                    size="sm"
                                    color="green"
                                    title="OK"
                                  />
                                </td>
                              )}
                              <td
                                style={{
                                  width: "3%",
                                  paddingLeft: "0px",
                                  paddingRight: "0px",
                                }}
                              >
                                {this.state.slaves.indexOf(slave) + 1}
                              </td>
                              <td
                                style={{
                                  width: "10%",
                                  paddingLeft: "0px",
                                  paddingRight: "0px",
                                }}
                              >
                                {slave.SN}
                              </td>
                              <td style={{ width: "2%", paddingLeft: "0px" }}>
                                {slave.slaveState}
                              </td>
                              <td style={{ width: "5%" }}>
                                {slave.SOC.toFixed(1)}
                              </td>
                              <td style={{ width: "5%" }}>
                                {slave.cRatio.toFixed(2)}
                              </td>
                              <td style={{ width: "5%" }}>
                                {(slave.packV / 100).toFixed(2)}
                              </td>
                              <td style={{ width: "5%" }}>
                                {(slave.cellDeltaV / 10000).toFixed(4)}
                              </td>
                              <td style={{ width: "5%" }}>
                                {(slave.pvVoltage / 100).toFixed(1)}
                              </td>
                              <td
                                style={{
                                  width: "7%",
                                  textAlign: "center",
                                }}
                              >
                                {(slave.pvPower / 100).toFixed(2)}
                              </td>
                              <td
                                style={{
                                  width: "7%",
                                  textAlign: "center",
                                }}
                              >
                                {(slave.battPower / 100).toFixed(2)}
                              </td>
                              <td
                                style={{
                                  width: "7%",
                                  textAlign: "center",
                                }}
                              >
                                {(slave.invPower / 100).toFixed(2)}
                              </td>
                              <td
                                style={{
                                  width: "7%",
                                  textAlign: "center",
                                }}
                              >
                                {(slave.tempData.HeatSinkTemp / 100).toFixed(1)}
                              </td>
                              <td style={{ width: "7%" }}>
                                <FontAwesomeIcon
                                  style={{
                                    marginLeft: "0px",
                                    marginRight: "0px",
                                  }}
                                  icon="wifi"
                                  size="lg"
                                  color="green"
                                  title="Signal Strength"
                                />
                                {slave.deviceRSSI}
                              </td>

                              <td style={{ width: "1%" }}>{slave.meshLayer}</td>
                              <td style={{ width: "1%", marginRight: "10px" }}>
                                <SlaveInfoModal
                                  unitNumber={
                                    this.state.slaves.indexOf(slave) + 1
                                  }
                                  macAddress={slave.MAC}
                                  fwVersionSlave={slave.fwVersionSlave}
                                  slaveState={slave.slaveState}
                                  battMode={slave.battMode}
                                  pvVolts={slave.pvVoltage}
                                  invConnected={slave.inverter_connected}
                                  loopCount={slave.slave_loopCount}
                                  batteryCells={slave.batteryCells}
                                  tempData={slave.tempData}
                                  voltageData={slave.voltageData}
                                />
                              </td>
                            </tr>
                          </div>
                        ))}
                      </table>
                    </div>
                  )}

                <hr></hr>
              </div>
            )}
            {/*//////////////////////////////////////////////////////////////////////////////////////////*/}

            {this.state.dataVerbosity === "2" &&
              this.state.slaveCount != 0 &&
              this.state.systemConfiguration === "0" && (
                <div>
                  {this.state.BT_message !==
                    "No SimplBox system is in BlueTooth mode." && (
                    <p
                      className="basicParagraph"
                      style={{
                        textAlign: "center",
                        color: "orange",
                        marginLeft: "300px",
                      }}
                    >
                      {this.state.selectedSlaveSN} at MAC Adress:{" "}
                      {this.state.slaveMAC}+2 {this.state.BT_message}
                    </p>
                  )}
                  <div
                    style={{
                      float: "left",
                      width: "400px",
                    }}
                  >
                    <table
                      className="table"
                      style={{
                        marginLeft: "50px",
                        width: "350px",
                      }}
                    >
                      <tbody>
                        <div>
                          <tr>
                            <th>Reset</th>
                            <th>Factory</th>
                            <th>Turn Off</th>
                            <th>Hibernate</th>
                            <th>Locator</th>
                          </tr>
                          <tr>
                            {this.state.systemStatus === "UPDATING" && (
                              <td>Updating...</td>
                            )}
                            {this.state.systemStatus === "ALIVE" && (
                              <td>
                                <button
                                  className="btn btn-outline-light"
                                  style={{
                                    padding: "0px",
                                    borderWidth: "0px",
                                    marginRight: "2px",
                                    marginTop: "-7px",
                                    padding: "0px",
                                    position: "relative",
                                    right: "0px",
                                    boxShadow: "5px 5 px 5px 5px #888888",
                                  }}
                                  onClick={() => this.handleSlave(SLAVE_RESET)}
                                >
                                  <FontAwesomeIcon
                                    icon="retweet"
                                    size="sm"
                                    color="orange"
                                    title="Reset Subsystems"
                                  />
                                </button>
                              </td>
                            )}
                            {this.state.systemStatus === "ALIVE" && (
                              <td>
                                <button
                                  className="btn btn-outline-light"
                                  style={{
                                    padding: "0px",
                                    borderWidth: "0px",
                                    marginRight: "2px",
                                    marginTop: "-7px",
                                    padding: "0px",
                                    position: "relative",
                                    right: "0px",
                                    boxShadow: "5px 5 px 5px 5px #888888",
                                  }}
                                  onClick={() =>
                                    this.handleSlave(SLAVE_FACTORY_RESET)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="history"
                                    size="sm"
                                    color="magenta"
                                    title="Factory Reset Subsystems"
                                  />
                                </button>
                              </td>
                            )}
                            {this.state.systemStatus === "ALIVE" && (
                              <td style={{ color: "white" }}>
                                <button
                                  className="btn btn-outline-light"
                                  style={{
                                    padding: "0px",
                                    borderWidth: "0px",
                                    marginRight: "2px",
                                    marginTop: "-7px",
                                    padding: "0px",
                                    position: "relative",
                                    right: "0px",
                                    boxShadow: "5px 5 px 5px 5px #888888",
                                  }}
                                  onClick={() =>
                                    this.handleSlave(DEACTIVATE_SLAVE)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="power-off"
                                    size="sm"
                                    color="green"
                                    title="PV Must Be Shorted"
                                  />
                                </button>
                              </td>
                            )}
                            {this.state.systemStatus === "ALIVE" && (
                              <td>
                                <button
                                  className="btn btn-outline-light"
                                  style={{
                                    padding: "0px",
                                    borderWidth: "0px",
                                    marginRight: "2px",
                                    marginTop: "-7px",
                                    padding: "0px",
                                    position: "relative",
                                    right: "0px",
                                    boxShadow: "5px 5 px 5px 5px #888888",
                                  }}
                                  onClick={() =>
                                    this.handleSlave(SLAVE_HIBERNATE)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="stop"
                                    size="sm"
                                    color="tomato"
                                    title="Disable Batteries"
                                  />
                                </button>
                              </td>
                            )}
                            {this.state.systemStatus === "ALIVE" && (
                              <td style={{ color: "white" }}>
                                <button
                                  className="btn btn-outline-light"
                                  style={{
                                    padding: "0px",
                                    borderWidth: "0px",
                                    marginRight: "2px",
                                    marginTop: "-7px",
                                    padding: "0px",
                                    position: "relative",
                                    right: "0px",
                                  }}
                                  onClick={() => this.handleSlave(LOCATOR_ON)}
                                >
                                  <FontAwesomeIcon
                                    icon="crosshairs"
                                    size="sm"
                                    color="red"
                                    title="Locate installed subsystem"
                                  />
                                </button>
                              </td>
                            )}
                          </tr>
                        </div>
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      width: "350px",
                      float: "right",
                    }}
                  >
                    <table className="table">
                      <tbody>
                        <div>
                          <tr>
                            <th>SOC %</th>
                            <th>Solar (W)</th>
                            <th>Battery (W)</th>
                            <th>Inverter (W)</th>
                          </tr>
                          <tr>
                            <td>
                              {this.state.realTimeData.battSOC.toFixed(1)}%
                            </td>
                            <td style={{ color: "orange" }}>
                              {(this.state.realTimeData.pvPower / 100).toFixed(
                                1
                              )}
                            </td>
                            {this.state.realTimeData.batteryPower >= 0 && (
                              <td style={{ color: "dodgerblue" }}>
                                {(
                                  this.state.realTimeData.batteryPower / 100
                                ).toFixed(1)}
                              </td>
                            )}
                            {this.state.realTimeData.batteryPower < 0 && (
                              <td style={{ color: "red" }}>
                                {(
                                  this.state.realTimeData.batteryPower / 100
                                ).toFixed(1)}
                              </td>
                            )}

                            {this.state.realTimeData.inverterPower >= 0 && (
                              <td style={{ color: "purple" }}>
                                {(
                                  this.state.realTimeData.inverterPower / 100
                                ).toFixed(1)}
                              </td>
                            )}
                            {this.state.realTimeData.inverterPower < 0 && (
                              <td style={{ color: "magenta" }}>
                                {(
                                  this.state.realTimeData.inverterPower / 100
                                ).toFixed(1)}
                              </td>
                            )}
                          </tr>
                        </div>
                      </tbody>
                    </table>
                  </div>
                  <table className="table">
                    <div>
                      <thead
                        style={{
                          backgroundColor: "#ece9e9",
                          marginTop: "50px",
                        }}
                      >
                        <th
                          style={{
                            width: "3%",
                            paddingLeft: "0px",
                            paddingRight: "0px",
                            color: "magenta",
                          }}
                        >
                          Select
                        </th>
                        <th
                          style={{
                            width: "3%",
                            paddingLeft: "3px",
                            paddingRight: "0px",
                            marginRight: "5px",
                            color: "magenta",
                          }}
                        >
                          Status
                        </th>
                        <th
                          style={{
                            width: "3%",
                            color: "magenta",
                            marginLeft: "7px",
                            paddingLeft: "7px",
                          }}
                        >
                          Unit
                        </th>
                        <th
                          style={{
                            color: "magenta",
                            width: "10%",
                            textAlign: "center",
                            paddingRight: "0px",
                            paddingLeft: "0px",
                          }}
                        >
                          Serial #
                        </th>
                        <th
                          style={{
                            width: "2%",
                            textAlign: "left",
                            color: "magenta",
                            paddingLeft: "0px",
                            marginLeft: "0px",
                          }}
                        >
                          St.
                        </th>
                        <th
                          style={{
                            width: "5%",
                            textAlign: "left",
                            color: "magenta",
                            paddingLeft: "5px",
                          }}
                        >
                          SOC
                        </th>
                        <th
                          style={{
                            width: "5%",
                            textAlign: "left",
                            color: "magenta",
                          }}
                        >
                          cRatio
                        </th>
                        <th
                          style={{
                            width: "5%",
                            textAlign: "left",
                            color: "magenta",
                            paddingLeft: "10px",
                          }}
                        >
                          packV
                        </th>
                        <th
                          style={{
                            width: "5%",
                            textAlign: "left",
                            color: "magenta",
                          }}
                        >
                          CellDV
                        </th>
                        <th
                          style={{
                            width: "5%",
                            textAlign: "left",
                            color: "magenta",
                            marginRight: "20px",
                          }}
                        >
                          Vpv
                        </th>
                        <th
                          style={{
                            width: "7%",
                            textAlign: "center",
                            color: "magenta",
                            paddingLeft: "12px",
                          }}
                        >
                          Solar (W)
                        </th>
                        <th
                          style={{
                            width: "7%",
                            textAlign: "center",
                            color: "magenta",
                          }}
                        >
                          Battery (W)
                        </th>
                        <th
                          style={{
                            width: "7%",
                            textAlign: "center",
                            color: "magenta",
                          }}
                        >
                          Inverter (W)
                        </th>
                        <th
                          style={{
                            width: "7%",
                            textAlign: "center",
                            color: "magenta",
                          }}
                        >
                          Heatsink (F)
                        </th>
                        <th
                          style={{
                            width: "7%",
                          }}
                        ></th>
                        <th
                          style={{
                            width: "5%",
                          }}
                        ></th>
                      </thead>
                    </div>

                    {this.state.slaves.map((slave) => (
                      <div>
                        <tr key={slave.id}>
                          <td style={{ width: "4%", padding: "0px" }}>
                            <input
                              className="form-check-input"
                              style={{
                                marginTop: "12px",
                                marginLeft: "1px",
                              }}
                              type="radio"
                              name="registerReason"
                              id="registerReason1"
                              onClick={() =>
                                this.handleChangeRadio(
                                  `${slave.MAC}`,
                                  `${slave.SN}`
                                )
                              }
                              value="reason1"
                              // checked
                            ></input>
                          </td>
                          {slave.deviceAlarm === 0 && (
                            <td style={{ width: "4%" }}>
                              <SlaveAlarmModal
                                unitNumber={
                                  this.state.slaves.indexOf(slave) + 1
                                }
                                tempData={slave.tempData}
                                voltageData={slave.voltageData}
                                dataVerbosity={this.state.dataVerbosity}
                              />
                            </td>
                          )}
                          {slave.deviceAlarm === 1 && (
                            <td style={{ width: "3%" }}>
                              <FontAwesomeIcon
                                icon="check"
                                size="sm"
                                color="green"
                                title="OK"
                              />
                            </td>
                          )}
                          <td
                            style={{
                              width: "3%",
                              paddingLeft: "0px",
                              paddingRight: "0px",
                            }}
                          >
                            {this.state.slaves.indexOf(slave) + 1}
                          </td>
                          <td
                            style={{
                              width: "10%",
                              paddingLeft: "0px",
                              paddingRight: "0px",
                            }}
                          >
                            {slave.SN}
                          </td>
                          <td style={{ width: "2%", paddingLeft: "0px" }}>
                            {slave.slaveState}
                          </td>
                          <td style={{ width: "5%" }}>
                            {slave.SOC.toFixed(1)}
                          </td>
                          <td style={{ width: "5%" }}>
                            {slave.cRatio.toFixed(2)}
                          </td>
                          <td style={{ width: "5%" }}>
                            {(slave.packV / 100).toFixed(2)}
                          </td>
                          <td style={{ width: "5%" }}>
                            {(slave.cellDeltaV / 10000).toFixed(4)}
                          </td>
                          <td style={{ width: "5%" }}>
                            {(slave.pvVoltage / 100).toFixed(1)}
                          </td>
                          <td
                            style={{
                              width: "7%",
                              textAlign: "center",
                            }}
                          >
                            {(slave.pvPower / 100).toFixed(2)}
                          </td>
                          <td
                            style={{
                              width: "7%",
                              textAlign: "center",
                            }}
                          >
                            {(slave.battPower / 100).toFixed(2)}
                          </td>
                          <td
                            style={{
                              width: "7%",
                              textAlign: "center",
                            }}
                          >
                            {(slave.invPower / 100).toFixed(2)}
                          </td>
                          <td
                            style={{
                              width: "7%",
                              textAlign: "center",
                            }}
                          >
                            {(slave.tempData.HeatSinkTemp / 100).toFixed(1)}
                          </td>
                          <td style={{ width: "7%" }}>
                            <FontAwesomeIcon
                              style={{
                                marginLeft: "0px",
                                marginRight: "0px",
                              }}
                              icon="wifi"
                              size="lg"
                              color="green"
                              title="Signal Strength"
                            />
                            {slave.deviceRSSI}
                          </td>
                          <td style={{ width: "5%", marginRight: "10px" }}>
                            <SlaveInfoModalMedium
                              unitNumber={this.state.slaves.indexOf(slave) + 1}
                              unitNumber={this.state.slaves.indexOf(slave) + 1}
                              slaveState={slave.slaveState}
                              battMode={slave.battMode}
                            />
                          </td>
                        </tr>
                      </div>
                    ))}
                  </table>
                </div>
              )}

            {this.state.dataVerbosity === "1" &&
              this.state.slaveCount != 0 &&
              this.state.systemConfiguration === "0" && (
                <div>
                  {this.state.BT_message !==
                    "No SimplBox system is in BlueTooth mode." && (
                    <p
                      className="basicParagraph"
                      style={{
                        textAlign: "center",
                        color: "orange",
                        marginLeft: "300px",
                      }}
                    >
                      {this.state.selectedSlaveSN} at MAC Adress:{" "}
                      {this.state.slaveMAC}+2 {this.state.BT_message}
                    </p>
                  )}
                  <div
                    style={{
                      float: "left",
                      width: "400px",
                    }}
                  >
                    <table
                      className="table"
                      style={{
                        marginLeft: "50px",
                        width: "350px",
                      }}
                    >
                      <tbody>
                        <div>
                          <tr>
                            <th>Reset</th>
                            <th>Factory</th>
                            <th>Turn Off</th>
                            <th>Hibernate</th>
                            <th>Locator</th>
                          </tr>
                          <tr>
                            {this.state.systemStatus === "UPDATING" && (
                              <td>Updating...</td>
                            )}
                            {this.state.systemStatus === "ALIVE" && (
                              <td>
                                <button
                                  className="btn btn-outline-light"
                                  style={{
                                    padding: "0px",
                                    borderWidth: "0px",
                                    marginRight: "2px",
                                    marginTop: "-7px",
                                    padding: "0px",
                                    position: "relative",
                                    right: "0px",
                                    boxShadow: "5px 5 px 5px 5px #888888",
                                  }}
                                  onClick={() => this.handleSlave(SLAVE_RESET)}
                                >
                                  <FontAwesomeIcon
                                    icon="retweet"
                                    size="sm"
                                    color="orange"
                                    title="Reset Subsystems"
                                  />
                                </button>
                              </td>
                            )}
                            {this.state.systemStatus === "ALIVE" && (
                              <td>
                                <button
                                  className="btn btn-outline-light"
                                  style={{
                                    padding: "0px",
                                    borderWidth: "0px",
                                    marginRight: "2px",
                                    marginTop: "-7px",
                                    padding: "0px",
                                    position: "relative",
                                    right: "0px",
                                    boxShadow: "5px 5 px 5px 5px #888888",
                                  }}
                                  onClick={() =>
                                    this.handleSlave(SLAVE_FACTORY_RESET)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="history"
                                    size="sm"
                                    color="magenta"
                                    title="Factory Reset Subsystems"
                                  />
                                </button>
                              </td>
                            )}
                            {this.state.systemStatus === "ALIVE" && (
                              <td style={{ color: "white" }}>
                                <button
                                  className="btn btn-outline-light"
                                  style={{
                                    padding: "0px",
                                    borderWidth: "0px",
                                    marginRight: "2px",
                                    marginTop: "-7px",
                                    padding: "0px",
                                    position: "relative",
                                    right: "0px",
                                    boxShadow: "5px 5 px 5px 5px #888888",
                                  }}
                                  onClick={() =>
                                    this.handleSlave(DEACTIVATE_SLAVE)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="power-off"
                                    size="sm"
                                    color="green"
                                    title="PV Must Be Shorted"
                                  />
                                </button>
                              </td>
                            )}
                            {this.state.systemStatus === "ALIVE" && (
                              <td>
                                <button
                                  className="btn btn-outline-light"
                                  style={{
                                    padding: "0px",
                                    borderWidth: "0px",
                                    marginRight: "2px",
                                    marginTop: "-7px",
                                    padding: "0px",
                                    position: "relative",
                                    right: "0px",
                                    boxShadow: "5px 5 px 5px 5px #888888",
                                  }}
                                  onClick={() =>
                                    this.handleSlave(SLAVE_HIBERNATE)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon="stop"
                                    size="sm"
                                    color="tomato"
                                    title="Disable Batteries"
                                  />
                                </button>
                              </td>
                            )}
                            {this.state.systemStatus === "ALIVE" && (
                              <td style={{ color: "white" }}>
                                <button
                                  className="btn btn-outline-light"
                                  style={{
                                    padding: "0px",
                                    borderWidth: "0px",
                                    marginRight: "2px",
                                    marginTop: "-7px",
                                    padding: "0px",
                                    position: "relative",
                                    right: "0px",
                                  }}
                                  onClick={() => this.handleSlave(LOCATOR_ON)}
                                >
                                  <FontAwesomeIcon
                                    icon="crosshairs"
                                    size="sm"
                                    color="red"
                                    title="Locate installed subsystem"
                                  />
                                </button>
                              </td>
                            )}
                          </tr>
                        </div>
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      width: "350px",
                      float: "right",
                    }}
                  >
                    <table className="table">
                      <tbody>
                        <div>
                          <tr>
                            <th>SOC %</th>
                            <th>Solar (W)</th>
                            <th>Battery (W)</th>
                            <th>Inverter (W)</th>
                          </tr>
                          <tr>
                            <td>
                              {this.state.realTimeData.battSOC.toFixed(1)}%
                            </td>
                            <td style={{ color: "orange" }}>
                              {(this.state.realTimeData.pvPower / 100).toFixed(
                                1
                              )}
                            </td>
                            {this.state.realTimeData.batteryPower >= 0 && (
                              <td style={{ color: "dodgerblue" }}>
                                {(
                                  this.state.realTimeData.batteryPower / 100
                                ).toFixed(1)}
                              </td>
                            )}
                            {this.state.realTimeData.batteryPower < 0 && (
                              <td style={{ color: "red" }}>
                                {(
                                  this.state.realTimeData.batteryPower / 100
                                ).toFixed(1)}
                              </td>
                            )}

                            {this.state.realTimeData.inverterPower >= 0 && (
                              <td style={{ color: "purple" }}>
                                {(
                                  this.state.realTimeData.inverterPower / 100
                                ).toFixed(1)}
                              </td>
                            )}
                            {this.state.realTimeData.inverterPower < 0 && (
                              <td style={{ color: "magenta" }}>
                                {(
                                  this.state.realTimeData.inverterPower / 100
                                ).toFixed(1)}
                              </td>
                            )}
                          </tr>
                        </div>
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      width: "350px",
                      float: "right",
                      marginRight: "260px",
                    }}
                  >
                    <table className="table">
                      <div>
                        <thead
                          style={{
                            backgroundColor: "#ece9e9",
                            marginTop: "50px",
                          }}
                        >
                          <th
                            style={{
                              width: "3%",
                              paddingLeft: "0px",
                              paddingRight: "0px",
                              color: "magenta",
                            }}
                          >
                            Select
                          </th>
                          <th
                            style={{
                              width: "3%",
                              paddingLeft: "3px",
                              paddingRight: "0px",
                              marginRight: "5px",
                              color: "magenta",
                            }}
                          >
                            Status
                          </th>
                          <th
                            style={{
                              width: "3%",
                              color: "magenta",
                              marginLeft: "0px",
                              paddingLeft: "0px",
                              paddingRight: "0px",
                            }}
                          >
                            Unit
                          </th>
                          <th
                            style={{
                              color: "magenta",
                              width: "10%",
                              textAlign: "center",
                              paddingRight: "0px",
                              paddingLeft: "0px",
                            }}
                          >
                            Serial #
                          </th>
                          <th
                            style={{
                              width: "2%",
                              textAlign: "left",
                              color: "magenta",
                              paddingLeft: "0px",
                              marginLeft: "0px",
                            }}
                          >
                            St.
                          </th>
                          <th
                            style={{
                              width: "5%",
                              textAlign: "left",
                              color: "magenta",
                              paddingLeft: "5px",
                            }}
                          >
                            SOC
                          </th>

                          <th
                            style={{
                              width: "5%",
                            }}
                          ></th>
                        </thead>
                      </div>

                      {this.state.slaves.map((slave) => (
                        <div>
                          <tr key={slave.id}>
                            <td style={{ width: "4%", padding: "0px" }}>
                              <input
                                className="form-check-input"
                                style={{
                                  marginTop: "12px",
                                  marginLeft: "1px",
                                }}
                                type="radio"
                                name="registerReason"
                                id="registerReason1"
                                onClick={() =>
                                  this.handleChangeRadio(
                                    `${slave.MAC}`,
                                    `${slave.SN}`
                                  )
                                }
                                value="reason1"
                                // checked
                              ></input>
                            </td>
                            {slave.deviceAlarm === 0 && (
                              <td style={{ width: "4%" }}>
                                <SlaveAlarmModal
                                  unitNumber={
                                    this.state.slaves.indexOf(slave) + 1
                                  }
                                  tempData={slave.tempData}
                                  voltageData={slave.voltageData}
                                  dataVerbosity={this.state.dataVerbosity}
                                />
                              </td>
                            )}
                            {slave.deviceAlarm === 1 && (
                              <td style={{ width: "8%" }}>
                                <FontAwesomeIcon
                                  icon="check"
                                  size="sm"
                                  color="green"
                                  title="OK"
                                />
                              </td>
                            )}
                            <td
                              style={{
                                width: "4%",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                              }}
                            >
                              {this.state.slaves.indexOf(slave) + 1}
                            </td>
                            <td
                              style={{
                                width: "12%",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                              }}
                            >
                              {slave.SN}
                            </td>
                            <td style={{ width: "2%", paddingLeft: "0px" }}>
                              {slave.slaveState}
                            </td>
                            <td style={{ width: "5%" }}>
                              {slave.SOC.toFixed(1)}
                            </td>

                            <td style={{ width: "5%", marginRight: "10px" }}>
                              <SlaveInfoModalMedium
                                unitNumber={
                                  this.state.slaves.indexOf(slave) + 1
                                }
                                slaveState={slave.slaveState}
                                battMode={slave.battMode}
                              />
                            </td>
                          </tr>
                        </div>
                      ))}
                    </table>
                  </div>
                </div>
              )}
          </div>
          {/* <BackGroundRectangle />*/}
        </ScrollView>
      </React.Fragment>
    );
  }
}
export default SlaveWideTable;

class SM_II_SlaveInfoModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <React.Fragment>
        <button
          className="btn btn-outline-light"
          style={{
            padding: "0px",
            borderWidth: "0px",
            float: "right",
            marginRight: "2px",
            marginTop: "-7px",
            padding: "0px",
            position: "relative",
            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="external-link-alt"
            size="sm"
            color="dodgerblue"
            title="More Info on subsystems"
          />
        </button>

        <Modal
          style={{ height: "800px", width: "380px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px", fontWeight: "600" }}
            >
              {this.props.deviceType}: {this.props.SN}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p
              className="basicParagraph"
              style={{
                fontSize: "10px",
                width: "380px",
                textAlign: "center",
              }}
            >
              {this.props.macAddress}
            </p>

            <p
              className="basicParagraph"
              style={{
                fontSize: "10px",
                width: "380px",
                textAlign: "center",
              }}
            >
              {this.props.fwVersionSlave}
            </p>
            <p
              className="basicParagraph"
              style={{
                fontSize: "10px",
                width: "380px",
                textAlign: "center",
              }}
            >
              {this.props.loopCount}
            </p>
            <p
              className="basicParagraph"
              style={{
                fontSize: "10px",
                width: "380px",
                textAlign: "center",
              }}
            >
              {this.props.appPartition}
            </p>
            {this.props.deviceType === "SimplMeter II Slave" && (
              <div>
                <table className="table">
                  <tr>
                    <td>Temp.:</td>
                    <td>{this.props.sm2temp1} &#8451;</td>
                    <td> Demand (W): </td>
                    <td>{(this.props.sm2DMND1 / 1000).toFixed(1)}</td>
                  </tr>
                  <thead>
                    <tr>
                      <th>Meter 1</th>
                      <th>Voltage (LN)</th>
                      <th>Current (A)</th>
                      <th>Power (W)</th>
                      <th>PF</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Phase AB</td>
                      <td>{this.props.sm2VAN1.toFixed(1)}</td>
                      <td>{this.props.sm2IA1.toFixed(1)}</td>
                      <td>
                        {this.props.sm2PA1
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td>{this.props.sm2PFa1.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Phase BC</td>
                      <td>{this.props.sm2VBN1.toFixed(1)}</td>
                      <td>{this.props.sm2IB1.toFixed(1)}</td>
                      <td>
                        {this.props.sm2PB1
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td>{this.props.sm2PFb1.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Phase CA</td>
                      <td>{this.props.sm2VCN1.toFixed(1)}</td>
                      <td>{this.props.sm2IC1.toFixed(1)}</td>
                      <td>
                        {this.props.sm2PC1
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td>{this.props.sm2PFc1.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Total Power (W)</td>
                      <td></td>
                      <td></td>
                      <td>
                        {this.props.sm2Ptotal1
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>

                <table className="table">
                  <tr>
                    <td>Temp.:</td>
                    <td>{this.props.sm2temp2} &#8451;</td>
                    <td> Demand (kW): </td>
                    <td>{(this.props.sm2DMND2 / 1000).toFixed(1)}</td>
                  </tr>
                  <thead>
                    <tr>
                      <th>Meter 2</th>
                      <th>Voltage (LN)</th>
                      <th>Current (A)</th>
                      <th>Power (W)</th>
                      <th>PF</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Phase AB</td>
                      <td>{this.props.sm2VAN2.toFixed(1)}</td>
                      <td>{this.props.sm2IA2.toFixed(1)}</td>
                      <td>
                        {this.props.sm2PA2
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td>{this.props.sm2PFa2.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Phase BC</td>
                      <td>{this.props.sm2VBN2.toFixed(1)}</td>
                      <td>{this.props.sm2IB2.toFixed(1)}</td>
                      <td>
                        {this.props.sm2PB2
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td>{this.props.sm2PFb2.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Phase CA</td>
                      <td>{this.props.sm2VCN2.toFixed(1)}</td>
                      <td>{this.props.sm2IC2.toFixed(1)}</td>
                      <td>
                        {this.props.sm2PC2
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td>{this.props.sm2PFc2.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Total Power(W)</td>
                      <td></td>
                      <td></td>
                      <td>
                        {this.props.sm2Ptotal2
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

class SlaveInfoModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <React.Fragment>
        <button
          className="btn btn-outline-light"
          style={{
            padding: "0px",
            borderWidth: "0px",
            float: "right",
            marginRight: "2px",
            marginTop: "-7px",
            padding: "0px",
            position: "relative",
            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="external-link-alt"
            size="sm"
            color="dodgerblue"
            title="More Info on subsystems"
          />
        </button>

        <Modal
          style={{ width: "300px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px", fontWeight: "600" }}
            >
              SimplBox #{this.props.unitNumber}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.props.battMode == 0 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery is Fully Charged.
              </p>
            )}
            {this.props.battMode == 1 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery is OK.
              </p>
            )}
            {this.props.battMode == 2 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery is OK.
              </p>
            )}
            {this.props.battMode == 3 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery is Empty.
              </p>
            )}

            {this.props.slaveState == 0 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Off-peak: Standby...
              </p>
            )}
            {this.props.slaveState == 1 && (
              <p className="basicParagraph" style={{ fontSize: "10px" }}>
                Off-peak: Subsystem stopped absorb charging.
              </p>
            )}
            {this.props.slaveState == 2 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Off-peak: Absorb charging (CV)
              </p>
            )}
            {this.props.slaveState == 3 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Off-peak: Bulk charging (CC)
              </p>
            )}
            {this.props.slaveState == 4 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                On-peak: Discharging
              </p>
            )}
            {this.props.slaveState == 5 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                On-peak: All solar going to inverter
              </p>
            )}

            {this.props.slaveState == 6 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Low Power Flow: Check Connections
              </p>
            )}

            {this.props.slaveState == 7 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Low Power Flow: System OK
              </p>
            )}
            {this.props.slaveState == 8 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery or heat sink Temp is out of range.
              </p>
            )}
            <p />
            {this.props.slaveState == 9 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery Hibernation Mode.
              </p>
            )}
            <p />
            {this.props.slaveState == 10 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                IDLE: Self-supply; No Export.
              </p>
            )}
            <p />
            {this.props.slaveState == 27 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                DC Voltage Out Mode: Charging
              </p>
            )}
            <p />
            {this.props.slaveState == 28 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                DC Voltage Out Mode: Discharging
              </p>
            )}
            <p />

            {this.props.slaveState == 20 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Smooth Power: Bulk Charging
              </p>
            )}
            <p />
            {this.props.slaveState == 21 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Smooth Power: Charging
              </p>
            )}
            <p />
            {this.props.slaveState == 22 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Smooth Power: Fully Charged
              </p>
            )}
            <p />
            {this.props.slaveState == 23 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Smooth Power: Discharging
              </p>
            )}
            <p />
            {this.props.slaveState == 24 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Smooth Power: Discharging with Solar
              </p>
            )}
            <p />
            {this.props.slaveState == 25 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Smooth Power: Idling
              </p>
            )}
            <p />
            {this.props.slaveState == 26 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                SimplBackUp Mode
              </p>
            )}
            <p />
            <p
              className="basicParagraph"
              style={{
                fontSize: "10px",
                width: "225px",
                textAlign: "center",
              }}
            >
              PV and Inverter Voltage: {this.props.pvVolts / 100.0}
            </p>
            {this.props.invConnected === "1" && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Inverter is Connected.
              </p>
            )}
            {this.props.invConnected === "0" && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                No inverter is Connected.
              </p>
            )}

            <p
              className="basicParagraph"
              style={{
                fontSize: "10px",
                width: "225px",
                textAlign: "center",
              }}
            >
              Loop Count: {this.props.loopCount}
            </p>
            <p
              className="basicParagraph"
              style={{
                fontSize: "10px",
                width: "225px",
                textAlign: "center",
              }}
            >
              {this.props.fwVersionSlave}
            </p>
            <p
              className="basicParagraph"
              style={{
                fontSize: "10px",
                width: "225px",
                textAlign: "center",
              }}
            >
              {this.props.macAddress}
            </p>

            <table className="table">
              <thead>
                <tr>
                  <th>Temp. Sensor</th>
                  <th>(&#176;F)</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ lineHeight: "3px" }}>
                  <td>Heat Sink</td>
                  <td>{this.props.tempData.HeatSinkTemp / 100}</td>
                </tr>
                <tr style={{ lineHeight: "3px" }}>
                  <td>Battery</td>
                  <td>{this.props.tempData.TempSensorA / 100}</td>
                </tr>
                {/*<tr style={{ lineHeight: "3px" }}>
                  <td>Battery Temp B</td>
                  <td>{this.props.tempData.TempSensorB / 100}</td>
                </tr>
                <tr style={{ lineHeight: "3px" }}>
                  <td>Battery Temp C</td>
                  <td>{this.props.tempData.TempSensorC / 100}</td>
              </tr>*/}
              </tbody>
            </table>

            <table className="table">
              <thead>
                <tr>
                  <th>Alarms</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr style={{ lineHeight: "3px" }}>
                  <td>Heat Sink Over-Temp</td>
                  {this.props.tempData.HeatSinkOverTemp === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.tempData.HeatSinkOverTemp === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Heat Sink Under-Temp</td>
                  {this.props.tempData.HeatSinkUnderTemp === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.tempData.HeatSinkUnderTemp === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>
                <tr style={{ lineHeight: "3px" }}>
                  <td>Battery Over-Temp</td>
                  {this.props.tempData.TempSensorOverTemp === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.tempData.TempSensorOverTemp === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Battery Under-Temp</td>
                  {this.props.tempData.TempSensorUnderTemp === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.tempData.TempSensorUnderTemp === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Cell Over-Voltage</td>
                  {this.props.voltageData.CellOverVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.CellOverVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Cell Under-Voltage</td>
                  {this.props.voltageData.CellUnderVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.CellUnderVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Pack Over-Voltage</td>
                  {this.props.voltageData.PackOverVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.PackOverVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Pack Under-Voltage</td>
                  {this.props.voltageData.PackUnderVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.PackUnderVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Cell Delta Over-Voltage</td>
                  {this.props.voltageData.CellDeltaOverVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.CellDeltaOverVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>State-of-charge</td>
                  {this.props.voltageData.LowStateofCharge === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.LowStateofCharge === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>
              </tbody>
            </table>

            <table className="table">
              <thead>
                <tr>
                  <th>Cell</th>
                  <th>Battery Cell Voltage</th>
                </tr>
              </thead>
              <tbody>
                {this.props.batteryCells.map((cell) => (
                  <tr key={cell.id} style={{ lineHeight: "1px" }}>
                    <td>{this.props.batteryCells.indexOf(cell) + 1}</td>
                    <td>{(cell.cellV / 1000).toFixed(3)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

class SlaveInfoModalMedium extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <React.Fragment>
        <button
          className="btn btn-outline-light"
          style={{
            padding: "0px",
            borderWidth: "0px",
            float: "right",
            marginRight: "2px",
            marginTop: "-7px",
            padding: "0px",
            position: "relative",
            right: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="external-link-alt"
            size="sm"
            color="dodgerblue"
            title="More Info on subsystems"
          />
        </button>

        <Modal
          style={{ width: "300px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px", fontWeight: "600" }}
            >
              Subsystem Temperatures, Alarms and Cell Voltages for Unit #
              {this.props.unitNumber}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p
              className="basicParagraph"
              style={{
                fontSize: "12px",
                width: "225px",
                textAlign: "center",
                textDecoration: "underline",
              }}
            >
              State of Subsystem:
            </p>
            {this.props.slaveState == 0 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Off-peak: Subsystem is idling.
              </p>
            )}
            {this.props.slaveState == 1 && (
              <p className="basicParagraph" style={{ fontSize: "10px" }}>
                Off-peak: Subsystem stopped absorb charging.
              </p>
            )}
            {this.props.slaveState == 2 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Off-peak: Subsystem absorb charging (CV charge).
              </p>
            )}
            {this.props.slaveState == 3 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Off-peak: Subsystem bulk charging (CC charge).
              </p>
            )}
            {this.props.slaveState == 4 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                On-peak: Subsystem is discharging.
              </p>
            )}
            {this.props.slaveState == 5 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                On-peak: Standby with all available solar going to inverter.
              </p>
            )}

            {this.props.slaveState == 6 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Low Power Flow: Check PV and Inverter connectedness.
              </p>
            )}

            {this.props.slaveState == 7 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Low power flow is not due to PV or inverter not being connected.
              </p>
            )}
            {this.props.slaveState == 8 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery or heat sink temperature is out of range.
              </p>
            )}

            {this.props.slaveState == 9 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery Hibernation Mode.
              </p>
            )}

            {this.props.slaveState == 10 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                IDLE: Self-supply; No Export.
              </p>
            )}

            <p />

            <p
              className="basicParagraph"
              style={{
                fontSize: "12px",
                width: "225px",
                textAlign: "center",
                textDecoration: "underline",
              }}
            >
              State of Subsystem Battery:
            </p>
            {this.props.battMode == 0 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery is Fully Charged.
              </p>
            )}
            {this.props.battMode == 1 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery is OK.
              </p>
            )}
            {this.props.battMode == 2 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery is OK.
              </p>
            )}
            {this.props.battMode == 3 && (
              <p
                className="basicParagraph"
                style={{
                  fontSize: "10px",
                  width: "225px",
                  textAlign: "center",
                }}
              >
                Battery is Empty.
              </p>
            )}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

class SlaveAlarmModal extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  render() {
    return (
      <React.Fragment>
        <button
          className="btn btn-outline-light"
          style={{
            borderWidth: "0px",
            marginTop: "-7px",
            marginLeft: "0px",
            padding: "0px",
          }}
          onClick={this.handleShow}
        >
          <FontAwesomeIcon
            icon="exclamation-triangle"
            size="sm"
            color="red"
            title="Subsystem Alarms"
          />
        </button>

        <Modal
          style={{ width: "300px", alignContent: "center" }}
          show={this.state.show}
          onHide={this.handleHide}
          dialogClassName="modal-50w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "14px", fontWeight: "600" }}
            >
              Subsystem Alarms for Unit #{this.props.unitNumber}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <table className="table">
              <thead>
                <tr>
                  <th>Alarms</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr style={{ lineHeight: "3px" }}>
                  <td>Heat Sink Over-Temp</td>
                  {this.props.tempData.HeatSinkOverTemp === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.tempData.HeatSinkOverTemp === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Heat Sink Under-Temp</td>
                  {this.props.tempData.HeatSinkUnderTemp === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.tempData.HeatSinkUnderTemp === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>
                <tr style={{ lineHeight: "3px" }}>
                  <td>Battery Over-Temp</td>
                  {this.props.tempData.TempSensorOverTemp === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.tempData.TempSensorOverTemp === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Battery Under-Temp</td>
                  {this.props.tempData.TempSensorUnderTemp === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.tempData.TempSensorUnderTemp === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Cell Over-Voltage</td>
                  {this.props.voltageData.CellOverVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.CellOverVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Cell Under-Voltage</td>
                  {this.props.voltageData.CellUnderVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.CellUnderVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Pack Over-Voltage</td>
                  {this.props.voltageData.PackOverVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.PackOverVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Pack Under-Voltage</td>
                  {this.props.voltageData.PackUnderVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.PackUnderVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>Cell Delta Over-Voltage</td>
                  {this.props.voltageData.CellDeltaOverVoltage === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.CellDeltaOverVoltage === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>

                <tr style={{ lineHeight: "3px" }}>
                  <td>State-of-charge</td>
                  {this.props.voltageData.LowStateofCharge === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.LowStateofCharge === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                </tr>
                {/*<tr style={{ lineHeight: "3px" }}>
                  <td>cRatio</td>
                  {this.props.voltageData.LowSlaveContribution === 0 && (
                    <td>
                      <FontAwesomeIcon
                        icon="exclamation-triangle"
                        size="lg"
                        color="red"
                        title="Alarm"
                      />
                    </td>
                  )}
                  {this.props.voltageData.LowSlaveContribution === 1 && (
                    <td>
                      <FontAwesomeIcon
                        icon="check"
                        size="lg"
                        color="green"
                        title="OK"
                      />
                    </td>
                  )}
                  </tr>*/}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
