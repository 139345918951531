import React, { Component } from "react";
import * as echarts from "echarts/dist/echarts.js";
import "bootstrap/dist/css/bootstrap.css";
import "./dashboard.css";
import {
  getSystemLatestData,
  ws_getSystemLatestData,
  getSystemInfo,
} from "../services/systemService";
import { zIndex } from "@material-ui/core/styles/zIndex";
import { webSocketMode } from "../config.json"; //0: http; 1:websocket
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";

class GaugeGraphII extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pvPower: 0,
      batteryPower: 0,
    };
  }

  async componentDidMount() {
    this.chart = echarts.init(document.getElementById("gauge-area-2"));
    this.timerID = setInterval(() => this.tick(), 1000);
    this.generateGraph();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async getSystemData() {
    let smMeter1 = 0;
    let smMeter2 = 0;
    let pvPower = 0;
    let batteryPower = 0;
    let gridPower = 0;
    let bldgPower = 0;
    let overallPower = 0;
    let batterySOC = 0;

    smMeter1 = this.props.smMeter1; //grid
    smMeter2 = this.props.smMeter2; //solar net of battery
    batteryPower = this.props.batteryPower;
    pvPower = smMeter2 - batteryPower;
    gridPower = smMeter1;
    bldgPower = smMeter1 + pvPower;
    overallPower = ((bldgPower * 100.0001) / 100).toFixed(0);
    batterySOC = 80;

    this.setState({
      pvPower,
      batteryPower,
      gridPower,
      bldgPower,
      overallPower,
      batterySOC,
    });
  }

  tick() {
    this.getSystemData();
    console.log("PV Power", this.state.pvPower);
    console.log("Battery Power", this.state.batteryPower);
    console.log("Grid Power", this.state.gridPower);
    //Basic Chart Parameters
    const chartStartAngle = this.props.chartStartAngle;
    const chartEndAngle = this.props.chartEndAngle;
    const chartSpanAngle = chartStartAngle - chartEndAngle; //Defines the total angle of the gauge
    const demandLimit = this.props.demandLimit; //The total angle is this many watts.  1 kW must be adjusted in a real system
    const chartMaxSpan = demandLimit * 2.0; //Add 20%
    const chartAnglePerWatt = chartSpanAngle / chartMaxSpan;
    const chartMaxAngle = chartStartAngle - demandLimit * chartAnglePerWatt;

    const gridStartAngle = chartStartAngle;
    let gridAngle = gridStartAngle - this.state.gridPower * chartAnglePerWatt;
    let gridPositiveAngle = 0;
    let gridNegativeAngle = 0;
    if (this.state.gridPower > 0) {
      if (gridAngle < 0) gridAngle = 0;
      gridPositiveAngle = gridAngle;
      gridNegativeAngle = gridStartAngle;
    } else {
      if (gridAngle > 0) gridAngle = 0;
      gridNegativeAngle = gridAngle;
      gridPositiveAngle = gridStartAngle;
    }

    const solarStartAngle = gridAngle;
    let solarAngle = 0.0;
    if (this.state.pvPower < 0.0) solarAngle = solarStartAngle;
    else solarAngle = solarStartAngle - this.state.pvPower * chartAnglePerWatt;

    if (solarAngle < chartEndAngle) solarAngle = chartEndAngle;

    const batteryStartAngle = solarAngle;
    let batteryAngle =
      batteryStartAngle - this.state.batteryPower * chartAnglePerWatt;
    let batteryPositiveAngle = 0;
    let batteryNegativeAngle = 0;
    if (this.state.batteryPower > 0) {
      if (batteryAngle < chartEndAngle) batteryAngle = chartEndAngle;
      batteryPositiveAngle = batteryAngle;
      batteryNegativeAngle = batteryStartAngle;
    } else {
      if (batteryAngle > chartStartAngle) batteryAngle = chartStartAngle;
      batteryNegativeAngle = batteryAngle;
      batteryPositiveAngle = batteryStartAngle;
    }

    const bldgStartAngle = batteryAngle;
    const bldgAngle = chartStartAngle;

    this.chart.setOption({
      series: [
        {
          clockwise: false,
          startAngle: chartMaxAngle,
          endAngle: chartStartAngle,
          axisLine: {
            lineStyle: {
              color: [[1, "gray"]],
            },
          },
        },
        {
          clockwise: false,
          startAngle: bldgStartAngle,
          endAngle: bldgAngle,
          axisLine: {
            lineStyle: {
              color: [[1, "green"]],
            },
          },
        },
        {
          startAngle: solarStartAngle,
          endAngle: solarAngle,
          axisLine: {
            lineStyle: {
              color: [[1, "orange"]],
            },
          },
          //  data: [{ value: this.state.pvPower.toFixed(1) }]
        },
        {
          startAngle: batteryStartAngle,
          endAngle: batteryPositiveAngle,
          axisLine: {
            lineStyle: {
              color: [[1, "dodgerblue"]],
            },
          },
        },
        {
          clockwise: false,
          startAngle: batteryStartAngle,
          endAngle: batteryNegativeAngle,
          axisLine: {
            lineStyle: {
              color: [[1, "red"]],
            },
          },
        },
        {
          startAngle: gridStartAngle,
          endAngle: gridPositiveAngle,
          axisLine: {
            lineStyle: {
              color: [[1, "purple"]],
            },
          },
        },
        {
          clockwise: false,
          startAngle: gridStartAngle,
          endAngle: gridNegativeAngle,
          axisLine: {
            lineStyle: {
              color: [[1, "magenta"]],
            },
          },
        },
      ],
    });
  }

  generateGraph() {
    var option = {
      backgroundColor: "#ffffff",
      tooltip: {
        formatter: "{a} <br/>{c} {b}",
      },
      series: [
        {
          name: "The gauge itself",
          type: "gauge",
          min: 0,
          max: this.props.demandLimit,
          startAngle: this.props.chartStartAngle,
          endAngle: this.props.chartEndAngle,
          splitNumber: 5,
          center: ["130px", "100px"],
          radius: "67px",
          axisLine: {
            show: true,
            lineStyle: {
              color: [[1, "grey"]],
              width: 1,
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          axisLabel: {
            show: false,
            textStyle: {
              fontWeight: "normal",
              color: "grey",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },

          axisTick: {
            show: false,
            length: 8,
            lineStyle: {
              color: "auto",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          splitLine: {
            //show: false,
            length: 5,
            //distance: 30,
            lineStyle: {
              width: 1,
              color: "grey",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },

          pointer: {
            show: false,
            width: 3,
            shadowColor: "#000",
            shadowBlur: 5,
          },
          title: {
            show: false,
            textStyle: {
              fontWeight: "bold",
              fontSize: 16,
              fontStyle: "normal",
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 5,
            },
          },
          detail: {
            show: false,
            backgroundColor: "#242424",
            borderWidth: 0,
            borderColor: "#000",
            shadowColor: "#000",
            shadowBlur: 5,
            offsetCenter: [0, "50%"],
            textStyle: {
              fontWeight: "bold",
              color: "#000",
              fontSize: 16,
            },
          },
          //data: [{ value: 40, name: "% Solar" }],
        },
        {
          name: "bldg",
          type: "gauge",
          min: 0,
          max: this.props.demandLimit,
          startAngle: this.props.chartStartAngle,
          endAngle: this.props.chartEndAngle,
          splitNumber: 11,
          center: ["130px", "100px"],
          radius: "95x",
          axisLine: {
            show: true,
            lineStyle: {
              color: [[1, "orange"]],
              width: 10,
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          axisLabel: {
            show: false,
            textStyle: {
              fontWeight: "bolder",
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          axisTick: {
            show: false,
            length: 15,
            lineStyle: {
              color: "auto",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          splitLine: {
            show: false,
            length: 25,
            lineStyle: {
              width: 3,
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          pointer: {
            show: false,
            width: 3,
            shadowColor: "#000",
            shadowBlur: 5,
          },
          title: {
            show: false,
            textStyle: {
              fontWeight: "bold",
              fontSize: 16,
              fontStyle: "normal",
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 5,
            },
          },
          detail: {
            show: false,
            backgroundColor: "#242424" /*#1a1a1a"*/,
            borderWidth: 0,
            borderColor: "#000",
            shadowColor: "#000",
            shadowBlur: 5,
            offsetCenter: [0, "50%"],
            textStyle: {
              fontWeight: "bold",
              color: "#000",
              fontSize: 16,
            },
          },
          //data: [{ value: 40, name: "% Solar" }],
        },
        {
          name: "Solar",
          type: "gauge",
          min: 0,
          max: this.props.demandLimit,
          startAngle: this.props.chartStartAngle,
          endAngle: this.props.chartEndAngle,
          splitNumber: 11,
          center: ["130px", "100px"],
          radius: "80px",
          axisLine: {
            show: true,
            lineStyle: {
              color: [[1, "orange"]],
              width: 5,
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          axisLabel: {
            show: false,
            textStyle: {
              fontWeight: "bolder",
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          axisTick: {
            show: false,
            length: 15,
            lineStyle: {
              color: "auto",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          splitLine: {
            show: false,
            length: 25,
            lineStyle: {
              width: 3,
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          pointer: {
            show: false,
            width: 3,
            shadowColor: "#000",
            shadowBlur: 5,
          },
          title: {
            show: false,
            textStyle: {
              fontWeight: "bold",
              fontSize: 16,
              fontStyle: "normal",
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 5,
            },
          },
          detail: {
            show: false,
            backgroundColor: "#242424" /*#1a1a1a"*/,
            borderWidth: 0,
            borderColor: "#000",
            shadowColor: "#000",
            shadowBlur: 5,
            offsetCenter: [0, "50%"],
            textStyle: {
              fontWeight: "bold",
              color: "#000",
              fontSize: 16,
            },
          },
          data: [{ value: 40, name: "% Solar" }],
        },
        {
          name: "Batteries Discharge",
          type: "gauge",
          center: ["130px", "100px"],
          radius: "85px",
          min: 0,
          max: this.props.demandLimit,
          startAngle: this.props.chartStartAngle,
          endAngle: this.props.chartEndAngle,
          splitNumber: 5,
          axisLine: {
            lineStyle: {
              color: [
                [0.5, "lime"],
                [1, "dodgerblue"],
              ],
              width: 5,
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          axisLabel: {
            show: false,
            textStyle: {
              fontWeight: "bold",
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          axisTick: {
            show: false,
            length: 8,
            lineStyle: {
              color: "auto",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          splitLine: {
            show: false,
            length: 10,
            lineStyle: {
              width: 3,
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          pointer: {
            show: false,
            width: 2,
            shadowColor: "#000",
            shadowBlur: 5,
          },
          title: {
            show: false,
            offsetCenter: [0, "-30%"],

            textStyle: {
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: 14,
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },

          detail: {
            show: false,
            borderColor: "#242424" /*#1a1a1a"*/,
            borderWidth: 0,
            borderColor: "#000",
            shadowColor: "#000",
            shadowBlur: 5,

            offsetCenter: [0, "40%"],
            textStyle: {
              fontWeight: "bold",
              color: "#000",
              fontSize: 12,
            },
          },
          data: [{ value: 0, name: "Discharge" }],
        },
        {
          name: "Batteries Charge",
          type: "gauge",
          center: ["130px", "100px"],
          radius: "85px",
          min: 0,
          max: this.props.demandLimit,
          startAngle: this.props.chartStartAngle,
          endAngle: this.props.chartEndAngle,
          splitNumber: 10,
          axisLine: {
            lineStyle: {
              color: [
                [0.5, "lime"],
                [1, "dodgerblue"],
              ],
              width: 5,
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          axisLabel: {
            show: false,
            textStyle: {
              fontWeight: "bold",
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          axisTick: {
            show: false,
            length: 8,
            lineStyle: {
              color: "auto",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          splitLine: {
            show: false,
            length: 10,
            lineStyle: {
              width: 3,
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          pointer: {
            show: false,
            width: 2,
            shadowColor: "#000",
            shadowBlur: 5,
          },
          title: {
            show: false,
            offsetCenter: [0, "-30%"],

            textStyle: {
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: 14,
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },

          detail: {
            show: false,
            borderColor: "#242424",
            borderWidth: 0,
            borderColor: "#000",
            shadowColor: "#000",
            shadowBlur: 5,
            offsetCenter: [0, "40%"],
            textStyle: {
              fontWeight: "bold",
              color: "#000",
              fontSize: 12,
            },
          },
          data: [{ value: 0, name: "Charge" }],
        },

        {
          name: "Grid Buying",
          type: "gauge",
          center: ["130px", "100px"],
          radius: "80px",
          min: 0,
          max: this.props.demandLimit,
          startAngle: this.props.chartStartAngle,
          endAngle: this.props.chartEndAngle,
          splitNumber: 10,
          axisLine: {
            lineStyle: {
              color: [
                [0.5, "lime"],
                [1, "dodgerblue"],
              ],
              width: 10,
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          axisLabel: {
            show: false,
            textStyle: {
              fontWeight: "bold",
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          axisTick: {
            show: false,
            length: 8,
            lineStyle: {
              color: "auto",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          splitLine: {
            show: false,
            length: 10,
            lineStyle: {
              width: 3,
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          pointer: {
            show: false,
            width: 2,
            shadowColor: "#000",
            shadowBlur: 5,
          },
          title: {
            show: false,
            offsetCenter: [0, "-30%"],

            textStyle: {
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: 14,
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },

          detail: {
            show: false,
            borderColor: "#242424",
            borderWidth: 0,
            borderColor: "#000",
            shadowColor: "#000",
            shadowBlur: 5,
            offsetCenter: [0, "40%"],
            textStyle: {
              fontWeight: "bold",
              color: "#000",
              fontSize: 12,
            },
          },
          data: [{ value: 0, name: "Discharge" }],
        },
        {
          name: "Grid Selling",
          type: "gauge",
          center: ["130px", "100px"],
          radius: "75px",
          min: -100,
          max: 100,
          startAngle: 135,
          endAngle: 45,
          splitNumber: 10,
          axisLine: {
            lineStyle: {
              color: [
                [0.5, "lime"],
                [1, "dodgerblue"],
              ],
              width: 5,
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          axisLabel: {
            show: false,
            textStyle: {
              fontWeight: "bold",
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          axisTick: {
            show: false,
            length: 8,
            lineStyle: {
              color: "auto",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          splitLine: {
            show: false,
            length: 10,
            lineStyle: {
              width: 3,
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },
          pointer: {
            show: false,
            width: 2,
            shadowColor: "#000",
            shadowBlur: 5,
          },
          title: {
            show: false,
            offsetCenter: [0, "-30%"],
            textStyle: {
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: 14,
              color: "#000",
              shadowColor: "#000",
              shadowBlur: 10,
            },
          },

          detail: {
            show: false,
            borderColor: "#242424",
            borderWidth: 0,
            borderColor: "#000",
            shadowColor: "#000",
            shadowBlur: 5,
            // width: 80,
            //height:30,
            offsetCenter: [0, "40%"],
            textStyle: {
              fontWeight: "bold",
              color: "#000",
              fontSize: 12,
            },
          },
          data: [{ value: 0, name: "Charge" }],
        },
      ],
    };
    this.chart.setOption(option);
  }

  render() {
    return (
      <div>
        {/*} <button
          type="button"
          class="btn btn-secondary"
          data-toggle="tooltip"
          data-placement="top"
          title="Tooltip on top"
        >
          Tooltip on top
    </button>*/}
        <button
          style={{
            backgroundColor: "white",
            borderColor: "none",
            borderWidth: "0px",
            height: "150px",
            opacity: "1.0",
            width: "10px",
            position: "absolute",
            top: "30px",
            left: "0px",
          }}
        >
          <button
            data-toggle="tooltip"
            data-placement="top"
            title="Building Power Consumption"
            style={{
              backgroundColor: "green",
              borderColor: "none",
              borderWidth: "0px",
              outline: "none",
              height: "8px",
              opacity: "1.0",
              width: "6px",
              borderRadius: "3px",
            }}
          ></button>
          <button
            data-toggle="tooltip"
            data-placement="top"
            title="Power Supplied by the Grid"
            style={{
              backgroundColor: "purple",
              borderColor: "none",
              borderWidth: "0px",
              outline: "none",
              height: "8px",
              opacity: "1.0",
              width: "6px",
              borderRadius: "3px",
            }}
          />
          <button
            data-toggle="tooltip"
            data-placement="top"
            title="Power Sold back to the Grid"
            style={{
              backgroundColor: "magenta",
              borderColor: "none",
              borderWidth: "0px",
              outline: "none",
              height: "8px",
              opacity: "1.0",
              width: "6px",
              borderRadius: "3px",
            }}
          />
          <button
            data-toggle="tooltip"
            data-placement="top"
            title="Power Generated by Solar"
            style={{
              backgroundColor: "orange",
              borderColor: "none",
              borderWidth: "0px",
              outline: "none",
              height: "8px",
              opacity: "1.0",
              width: "6px",
              borderRadius: "3px",
            }}
          />
          <button
            data-toggle="tooltip"
            data-placement="top"
            title="Power Supplied from the Batteries"
            style={{
              backgroundColor: "dodgerblue",
              borderColor: "none",
              borderWidth: "0px",
              outline: "none",
              height: "8px",
              opacity: "1.0",
              width: "6px",
              borderRadius: "3px",
            }}
          />
          <button
            data-toggle="tooltip"
            data-placement="top"
            title="Solar Power Used to Charge the Batteries"
            style={{
              backgroundColor: "red",
              borderColor: "none",
              borderWidth: "0px",
              outline: "none",
              height: "8px",
              opacity: "1.0",
              width: "6px",
              borderRadius: "3px",
            }}
          />
        </button>
        <div
          id="gauge-area-2"
          style={{
            position: "absolute",
            top: "50px",
            left: "50px",
          }}
        ></div>
        <p
          classname="basicParagraph"
          style={{
            position: "absolute",
            top: "115px",
            left: "195px",
            fontSize: "10px",
            color: "#595959",
            fontWeight: "bold",
            textAlign: "right",
            padding: "0px",
            margin: "0px",
            //transform: "translate(-100px,-120px)",
          }}
        >
          Demand
          <p>
            Set Limit:<p>{this.props.demandLimit} kW</p>
          </p>
        </p>

        <p
          classname="basicParagraph"
          style={{
            position: "absolute",
            top: "43px",
            left: "30px",
            fontSize: "10px",
            color: "#595959",
            fontWeight: "bold",
            textAlign: "right",
            padding: "0px",
            margin: "0px",
          }}
        >
          Grid Consumption
        </p>
        <p
          classname="basicParagraph"
          style={{
            position: "absolute",
            top: "65px",
            left: "30px",
            fontSize: "10px",
            color: "#595959",
            fontWeight: "bold",
            textAlign: "right",
            padding: "0px",
            margin: "0px",
          }}
        >
          Grid Selling
        </p>
        <p
          classname="basicParagraph"
          style={{
            position: "absolute",
            top: "87px",
            left: "30px",
            fontSize: "10px",
            color: "#595959",
            fontWeight: "bold",
            textAlign: "right",
            padding: "0px",
            margin: "0px",
          }}
        >
          Grid Buying
        </p>
        <p
          classname="basicParagraph"
          style={{
            position: "absolute",
            top: "110px",
            left: "30px",
            fontSize: "10px",
            color: "#595959",
            fontWeight: "bold",
            textAlign: "right",
            padding: "0px",
            margin: "0px",
          }}
        >
          Solar Generation
        </p>
        <p
          classname="basicParagraph"
          style={{
            position: "absolute",
            top: "131px",
            left: "30px",
            fontSize: "10px",
            color: "#595959",
            fontWeight: "bold",
            textAlign: "right",
            padding: "0px",
            margin: "0px",
          }}
        >
          Battery Discharging
        </p>
        <p
          classname="basicParagraph"
          style={{
            position: "absolute",
            top: "152px",
            left: "30px",
            fontSize: "10px",
            color: "#595959",
            fontWeight: "bold",
            textAlign: "right",
            padding: "0px",
            margin: "0px",
          }}
        >
          Battery Charging
        </p>
      </div>
    );
  }
}
export default GaugeGraphII;
