import React from "react";
import Form from "./common/form";
import {
  getSystemIDfromHandle,
  getSystem,
  setSystemSuspend,
  findSystemBySerialNumber,
  setSystemSignals,
  initSystemUpdate,
  setSystemAlive,
  getSystemInfo,
} from "../services/systemService";
import { masterCompany } from "../config.json";
import BackGroundRectangle from "../components/backgroundRect";
import { toast } from "react-toastify";

class SystemReactivated extends Form {
  state = {
    systemSN: "",
  };

  async componentDidMount() {}
  handleSN = (e) => {
    const systemSN = e.currentTarget.value;
    this.setState({ systemSN });
  };

  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  handleSetUnSuspend = async (e) => {
    e.preventDefault();
    console.log("System Gateway Serial Number:", this.state.systemSN);

    const systemId = (await findSystemBySerialNumber(this.state.systemSN)).data
      .data.system_id;
    console.log("system ID:", systemId);
    const handleID = (await getSystem(systemId)).data.data[0].systemID.id;
    console.log("Handle ID", handleID);
    await setSystemSignals(systemId, "91", "TBD", "0", "0", "0");
    await initSystemUpdate(handleID);
    let systemInfo = await getSystemInfo(handleID);
    let systemStatus = systemInfo.data.data.status;
    let whileLoopCntr = 0;
    console.log("System Status:", systemStatus);
    while (systemStatus === "UPDATING" && whileLoopCntr < 20) {
      this.sleep(1000);
      systemInfo = await getSystemInfo(handleID);
      systemStatus = systemInfo.data.data.status;
      whileLoopCntr++;
    }
    if (whileLoopCntr > 20)
      toast.error("Reactivating Suspended System FAILED!");
    else {
      toast.warning("This system was REACTIVATED!");
      this.props.history.goBack();
    }
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            width: "350px",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "150px",
            marginBottom: "150px",
            zIndex: 1,
          }}
        >
          <p className="basicParagraph">
            You are about to reactivate a suspended system:
          </p>
          <p className="basicParagraph" style={{ color: "red" }}>
            Caution: This operation could ONLY be performed by an Admin at{" "}
            {masterCompany}.
          </p>
          <label className="basicParagraph" htmlFor="">
            Specify the Serial number for the system's SimplGateway:
          </label>
          <input
            className="form-control2"
            style={{
              marginLeft: "80px",
              width: "150px",
              marginTop: "20px",
            }}
            value={this.state.systemSN}
            onChange={this.handleSN}
            id="productSN"
            type="text"
          />
          <button
            className="btn btn-success btn-sm"
            style={{
              marginLeft: "100px",
              marginTop: "30px",
            }}
            onClick={this.handleSetUnSuspend}
          >
            Raactivate System
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default SystemReactivated;
