import React, { Component } from "react";
import { toast } from "react-toastify";
// import Form from "./common/form";
import {
  getSystem,
//   getSystemControls,
//   setSystemControls,
//   setSystemControlsObject,
//   setSystemLimitsObject,
  getSystemIDfromHandle,
//   findSystemBySerialNumber,
//   getSystems,
//   getSystemInfo,
//   getSystemLimits,
  getSystemEquipment,
  setSystemEquipment
} from "../services/systemService";
// import { setJwt } from "../services/httpService";
import { getUser } from "../services/userService";
// import {
//   createCompany,
//   updateCompanyContact,
//   getCompanies,
//   updateCompanyObject,
// } from "../services/companyService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Modal from "react-bootstrap/Modal";
// import BackGroundRectangle from "../components/backgroundRect";
// import Clipboard from "react-clipboard.js";
// import ReactExport from "react-data-export";
// import { OutTable, ExcelRenderer } from "react-excel-renderer";
import "../excel-2007.css";
// import { Empty } from "antd";
// import QrContainer from "./qrContainer";
// import { animateScroll as scroll } from "react-scroll";
// import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
//import { node } from "react-svg/node_modules/@types/prop-types";

class SimplEquipment extends Component {
    // constructor() {
    //     super();
    // }
    state = {
        systemHandleID: "",
        systemName: "",

        nodeName: "",
        basic: {},
        lease: {},
        manufactured: {},
        heating: {},
        cooling: {},
        serviceCalls: [],

        selectedTable: "equipment", // used in getrows()

        // 0 -> shows no forms, 1-> Shows serviceCall form, 2-> shows equipment form. 6-> shows edit service call, 8-> shows add eqiupment
        selectedOption: 0,
        equipmentObj: {},
        serviceCallObj: {},
        systemEquipments: {},
        changedData: [],
        service: false, // Set to true if service is empty
        errors: {}
    };

    async componentDidMount() {
        const handleId = this.props.match.params.systemHandleID;
        const nodeName = sessionStorage.getItem("nodePassed");

        this.setState({nodeName});
        const systemID = (await getSystemIDfromHandle(handleId)).data.data
        .system_id;
        const system = (await getSystem(systemID)).data.data[0];
        const systemName = system.systemID.name;
        this.setState({systemName});

        const systemEquipment = (await getSystemEquipment(handleId));
        this.setState({systemHandleID: handleId});
        this.setState({systemEquipments: systemEquipment});
        if (systemEquipment.data.equipments === null || systemEquipment.data.equipments.equipmentData === null) {
            toast.info(`There is no equipment specified for ${nodeName}`)
        } else {
            const equipmentsData = systemEquipment.data.equipments
            for (let i = 0; i < equipmentsData.length; i++) {
                if (equipmentsData[i].nodeName === nodeName) {
                    this.setState({equipmentObj: equipmentsData[i]});
                }
            }
        }

        // Setting the system equipments to the state

        this.setState({
            basic: this.state.equipmentObj.basic,
            lease: this.state.equipmentObj.lease,
            manufactured: this.state.equipmentObj.manufactured,
            heating: this.state.equipmentObj.heating,
            cooling: this.state.equipmentObj.cooling,
            serviceCalls: this.state.equipmentObj.serviceCall
        });
        // this.setState({lease: this.state.equipmentObj.lease});
        // this.setState({manufactured: this.state.equipmentObj.manufactured});
        // this.setState({heating: this.state.equipmentObj.heating});
        // this.setState({cooling: this.state.equipmentObj.cooling});
        // this.setState({serviceCalls: this.state.equipmentObj.serviceCall});
    }

    // Function to show or hidde equipment table - False -> shows service call table / True -> shows equipment table

    getRows() {
        if (Object.keys(this.state.equipmentObj).length === 0) {
            return <p
            className="basicParagraph text-center"
            style={{
            width: "350px",
            fontSize: "12px",
            color: "#777575",
            marginTop: "50px"
            }}> No equipment found for {this.state.nodeName} in the system: {this.state.systemName}</p>
        }

        let service;
        if (this.state.selectedTable === "serviceCall") {
            if (this.state.equipmentObj.serviceCall === undefined || this.state.equipmentObj.serviceCall === null || this.state.equipmentObj.serviceCall.length === 0) {
                this.state.service = true
                return <p
                    className="basicParagraph text-center"
                    style={{
                    width: "350px",
                    fontSize: "12px",
                    color: "#777575",
                    marginTop: "50px"
                    }}> No Service Calls found for {this.state.nodeName} </p>;
            }
            this.state.service = false
            return false
        }
       this.state.service = false
        return true;
    }

    // Function to Add service call of a specific equipment

    async addServiceCall(e) {
        e.preventDefault();

        const serviceCallsObj = {
            id: 0,
            serviceDate: "",
            techName: "",
            techId: "",
            phone: "",
            companyName: "",
            email: "",
            cost: "",
            notes: "",
            changedBy: ""
        }

        if (this.state.equipmentObj.serviceCall === undefined || this.state.equipmentObj.serviceCall === null || this.state.equipmentObj.serviceCall.length === 0) {
            this.state.equipmentObj.serviceCall = [];
        }

        // Setting values to the new service call object.
        let newId = 0;
        for (const key in serviceCallsObj) {

            // Creating the id to the new service call
            if (key === "id") {
                this.state.equipmentObj.serviceCall.forEach(serviceCall => {
                    if (serviceCall.id > newId) {newId = serviceCall.id;}

                    newId++;
                })
            }

            if (key === "serviceDate") {
                let date = new Date(e.target.serviceDate.value);
                date = this.formatDate(date);
                serviceCallsObj.serviceDate = date;
                continue;
            }

            if (key === "changedBy") {continue}

            eval(`serviceCallsObj.${key} = e.target.${key}.value`);
        }

        // Getting the username who is creating the new service call and setting the id to it.
        const user = await getUser();
        serviceCallsObj.changedBy = user.data.data.username;
        serviceCallsObj.id = newId;

        if (this.state.equipmentObj.serviceCall === undefined || this.state.equipmentObj.serviceCall === null || this.state.equipmentObj.serviceCall.length === 0) {
            this.state.equipmentObj.serviceCall.push(serviceCallsObj);
        } else {
            this.state.equipmentObj.serviceCall.unshift(serviceCallsObj);
        }

        const changedData = (await setSystemEquipment(this.state.systemHandleID, this.state.systemEquipments.data.equipments));
        this.setState({changedData: changedData});
        if (this.state.changedData.data.status === "Success") {
            toast.success('New Service Call Added');
            this.setState({selectedOption: 0});
        }

    }

    dateHtml(date) {
        let format = new Date(date)
        let day = format.getDate();
        let month = format.getMonth() + 1;
        let year = format.getFullYear();
    
        format = `${year}-${month > 9 ? month : "0" + month}-${day > 9 ? day : "0" + day}`;
        return format;
      }

    formatDate(unformatted) {
        if (isNaN(unformatted)) return "";

        let day = unformatted.getDate();
        let month = unformatted.getMonth() + 1;
        let year = unformatted.getFullYear();
    
        let formattedDate = `${month}/${day + 1}/${year}`;
        return formattedDate
      }

    // Function for handling the service call button check

    async handleRadio(id) {
        const selectedId = parseInt(id)
        const selectedServiceCall = this.state.equipmentObj.serviceCall.find(serviceCall => serviceCall.id === selectedId);
        this.setState({serviceCallObj: selectedServiceCall});
        this.setState({selectedOption: 0});
    }

    // Function for editting service calls

    async editServiceCall(e) {
        e.preventDefault();

        // Setting values from form to service call in state
        for (const key in this.state.serviceCallObj) {
            if ( key === "id" || key === "changedBy") {
                continue
            }
            if (key === "serviceDate") {
                let date = new Date(e.target.serviceDate.value);
                console.log("Date to be submitted before formatted: " , date);
                date = this.formatDate(date);
                console.log("Date to be submitted: " , date);
                this.state.serviceCallObj.serviceDate = date;
                continue;
            }
            eval(`this.state.serviceCallObj.${key} = e.target.${key}.value`);
        }

        // Getting the user that made the changes
        const user = await getUser();
        this.state.serviceCallObj.changedBy = user.data.data.username;

        const changedData = (await setSystemEquipment(this.state.systemHandleID, this.state.systemEquipments.data.equipments));
        this.setState({changedData: changedData});
        if (this.state.changedData.data.status === "Success") {
            this.setState({selectedOption: 0});
        }
    }

    // Function to Edit equipment information.

    async editEquipment(e) {
        e.preventDefault();

        // Update equipment object
        this.state.equipmentObj.basic.type = e.target.type.value;
        this.state.equipmentObj.basic.economizer = e.target.economizer.value;

        let date = new Date(e.target.leaseStartDate.value);
        date = this.formatDate(date);
        this.state.equipmentObj.lease.leaseStartDate = date;
        this.state.equipmentObj.lease.responsibility = e.target.responsibility[0].value;
        this.state.equipmentObj.lease.leaseVerbiage = e.target.leaseVerbiage.value;

        date = new Date(e.target.leaseEndDate.value);
        date = this.formatDate(date);
        this.state.equipmentObj.lease.leaseEndDate = date;
        this.state.equipmentObj.lease.leaseOptions = e.target.leaseOptions.value;
        this.state.equipmentObj.lease.specialNotes = e.target.specialNotes.value;

        this.state.equipmentObj.manufactured.responsibility = e.target.responsibility[1].value;
        this.state.equipmentObj.manufactured.servicedBy = e.target.servicedBy.value;
        this.state.equipmentObj.manufactured.phone = e.target.phone.value;
        this.state.equipmentObj.manufactured.email = e.target.email.value;
        this.state.equipmentObj.manufactured.account = e.target.account.value;

        this.state.equipmentObj.heating.heatingMake = e.target.heatingMake.value;
        this.state.equipmentObj.heating.model = e.target.model[0].value;
        this.state.equipmentObj.heating.yearMfg = e.target.yearMfg[0].value;
        this.state.equipmentObj.heating.warrantyExp = e.target.warrantyExp[0].value;
        this.state.equipmentObj.heating.serialNumber = e.target.serialNumber[0].value;
        this.state.equipmentObj.heating.assetTag = e.target.assetTag[0].value;
        this.state.equipmentObj.heating.size = e.target.size[0].value;
        this.state.equipmentObj.heating.cost = e.target.cost[0].value;

        this.state.equipmentObj.cooling.coolingMake = e.target.coolingMake.value;
        this.state.equipmentObj.cooling.model = e.target.model[1].value;
        this.state.equipmentObj.cooling.yearMfg = e.target.yearMfg[1].value;
        this.state.equipmentObj.cooling.warrantyExp = e.target.warrantyExp[1].value;
        this.state.equipmentObj.cooling.serialNumber = e.target.serialNumber[1].value;
        this.state.equipmentObj.cooling.assetTag = e.target.assetTag[1].value;
        this.state.equipmentObj.cooling.size = e.target.size[1].value;
        this.state.equipmentObj.cooling.cost = e.target.cost[1].value;

        const changedData = (await setSystemEquipment(this.state.systemHandleID, this.state.systemEquipments.data.equipments));
        this.setState({changedData: changedData});
        if (this.state.changedData.data.status === "Success") {
            this.setState({selectedOption: 0});
        }
    }

    // Function to add Equipment to a specific nodeName

    async addEquipment(e) {
        e.preventDefault();

        const equipmentObj = {
            nodeName: this.state.nodeName,
            basic: {},
            lease: {},
            manufactured: {},
            heating: {},
            cooling: {},
            serviceCall: []
        }

        equipmentObj.basic.type = e.target.type.value;
        equipmentObj.basic.economizer = e.target.economizer.value;

        let date = new Date(e.target.leaseStartDate.value);
        date = this.formatDate(date);
        equipmentObj.lease.leaseStartDate = date;
        equipmentObj.lease.responsibility = e.target.responsibility[0].value;
        equipmentObj.lease.leaseVerbiage = e.target.leaseVerbiage.value;

        date = new Date(e.target.leaseEndDate.value);
        date = this.formatDate(date);
        equipmentObj.lease.leaseEndDate = date;
        equipmentObj.lease.leaseOptions = e.target.leaseOptions.value;
        equipmentObj.lease.specialNotes = e.target.specialNotes.value;

        equipmentObj.manufactured.responsibility = e.target.responsibility[1].value;
        equipmentObj.manufactured.servicedBy = e.target.servicedBy.value;
        equipmentObj.manufactured.phone = e.target.phone.value;
        equipmentObj.manufactured.email = e.target.email.value;
        equipmentObj.manufactured.account = e.target.account.value;

        equipmentObj.heating.heatingMake = e.target.heatingMake.value;
        equipmentObj.heating.model = e.target.model[0].value;
        equipmentObj.heating.yearMfg = e.target.yearMfg[0].value;
        equipmentObj.heating.warrantyExp = e.target.warrantyExp[0].value;
        equipmentObj.heating.serialNumber = e.target.serialNumber[0].value;
        equipmentObj.heating.assetTag = e.target.assetTag[0].value;
        equipmentObj.heating.size = e.target.size[0].value;
        equipmentObj.heating.cost = e.target.cost[0].value;

        equipmentObj.cooling.coolingMake = e.target.coolingMake.value;
        equipmentObj.cooling.model = e.target.model[1].value;
        equipmentObj.cooling.yearMfg = e.target.yearMfg[1].value;
        equipmentObj.cooling.warrantyExp = e.target.warrantyExp[1].value;
        equipmentObj.cooling.serialNumber = e.target.serialNumber[1].value;
        equipmentObj.cooling.assetTag = e.target.assetTag[1].value;
        equipmentObj.cooling.size = e.target.size[1].value;
        equipmentObj.cooling.cost = e.target.cost[1].value;

        if (this.state.systemEquipments.data.equipments === null || this.state.systemEquipments.data.equipments === undefined) {
            this.state.systemEquipments.data.equipments = [];
        }

        this.state.systemEquipments.data.equipments.push(equipmentObj);

        const changedData = (await setSystemEquipment(this.state.systemHandleID, this.state.systemEquipments.data.equipments));
        this.setState({changedData: changedData});
        if (this.state.changedData.data.status === "Success") {
            toast.success(`Equipment added for ${this.state.nodeName}`);
            this.setState({selectedOption: 0});
        }

        this.props.history.goBack();     
    }

    render () {
        return (
            <React.Fragment>
                <div
                style={{
                    minWidth: "1000px",
                    maxWidth: "auto",
                    minHeight: "1000px",
                    maxHeight: "auto",
                    paddingBottom: "100px",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    marginTop: "150px",
                    marginBottom: "150px",
                    overflow: "auto",
                    zIndex: 1
                }}>
                    <div className="d-flex justify-content-center pb-2 mb-0">
                        <p
                        className="basicParagraph text-center"
                        style={{
                            width: "350px",
                            fontSize: "12px",
                            color: "#2D5C88"
                        }}> {this.state.systemName} | { this.state.nodeName }
                        </p>
                    </div>

                    {/* Form to insert new service call */}

                    {this.state.selectedOption === 1 && (
                        <div style={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                            {/* form controls for setting service calls */}
                            <form style={{width: "350px"}} onSubmit={e => this.addServiceCall(e)}>
                                <p
                                className="basicParagraph text-center"
                                style={{
                                width: "350px",
                                fontSize: "12px",
                                color: "#777575",
                                marginBottom: "10px"
                                }}> Add Service Call
                                </p>
                                <div className="d-flex flex-row">
                                    <label className="basicParagraph" style={{width: "200px"}}> Service Date:</label>
                                    <input
                                    style={{width: "200px"}}
                                    type="date"
                                    name="serviceDate"
                                    className="form-control2"
                                    />
                                </div>
                                
                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Technician's Name:</label>
                                    <input
                                    type="text"
                                    name="techName"
                                    className="form-control2"
                                    />
                                </div>
                                
                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Technician's ID:</label>
                                    <input
                                    type="text"
                                    name="techId"
                                    className="form-control2"
                                    />
                                </div>
                                
                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Phone:</label>
                                    <input
                                    type="text"
                                    name="phone"
                                    className="form-control2"
                                    />
                                </div>
                                
                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Company Name:</label>
                                    <input
                                    type="text"
                                    name="companyName"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Email:</label>
                                    <input
                                    type="text"
                                    name="email"
                                    className="form-control2"
                                    />
                                </div>
                                
                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Cost:</label>
                                    <input
                                    type="text"
                                    name="cost"
                                    className="form-control2"
                                    />
                                </div>

                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <label className="basicParagraph">Notes:</label>
                                    <input
                                    type="text"
                                    name="notes"
                                    className="form-control2"
                                    style={{
                                        width: "200px",
                                        height: "65px"
                                    }}
                                    />
                                </div>

                                <br></br>

                                <button
                                    className="basicButton"
                                    style={{
                                    width: "200px",
                                    marginBottom: "10px"
                                    }}
                                >
                                    Save Service Call
                                </button>
                                <button
                                    onClick={()=>{this.setState({selectedOption: 0});}}
                                    className="btn btn-outline-warning btn-sm"
                                    style={{
                                    width: "200px",
                                    marginBottom: "10px"
                                    }}
                                >
                                    Cancel
                                </button>
                            </form>
                        </div>
                    )}

                    {/* Edit a service call */}

                    {this.state.selectedOption === 6 && (
                        <div style={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                            {/* Form controls to edit service calls */}
                            <form style={{width: "350px"}} onSubmit={e => this.editServiceCall(e)}>
                                <p
                                className="basicParagraph text-center"
                                style={{
                                width: "350px",
                                fontSize: "12px",
                                color: "#777575",
                                marginBottom: "10px"
                                }}> Edit Service Call
                                </p>
                                <div className="d-flex flex-row">
                                    <label className="basicParagraph" style={{width: "200px"}}> Service Date:</label>
                                    <input
                                    style={{width: "200px"}}
                                    type="date"
                                    name="serviceDate"
                                    className="form-control2"
                                    defaultValue={this.dateHtml(this.state.serviceCallObj.serviceDate)}
                                    />
                                </div>
                                
                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Technician's Name:</label>
                                    <input
                                    type="text"
                                    name="techName"
                                    className="form-control2"
                                    defaultValue={this.state.serviceCallObj.techName}
                                    />
                                </div>
                                
                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Technician's ID:</label>
                                    <input
                                    type="text"
                                    name="techId"
                                    className="form-control2"
                                    defaultValue={this.state.serviceCallObj.techId}
                                    />
                                </div>
                                
                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Phone:</label>
                                    <input
                                    type="text"
                                    name="phone"
                                    className="form-control2"
                                    defaultValue={this.state.serviceCallObj.phone}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Company Name:</label>
                                    <input
                                    type="text"
                                    name="companyName"
                                    className="form-control2"
                                    defaultValue={this.state.serviceCallObj.companyName}
                                    />
                                </div>
                                
                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Email:</label>
                                    <input
                                    type="text"
                                    name="email"
                                    className="form-control2"
                                    defaultValue={this.state.serviceCallObj.email}
                                    />
                                </div>
                                
                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Cost:</label>
                                    <input
                                    type="text"
                                    name="cost"
                                    className="form-control2"
                                    defaultValue={this.state.serviceCallObj.cost}
                                    />
                                </div>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <label className="basicParagraph">Notes:</label>
                                    <input
                                    type="text"
                                    name="notes"
                                    className="form-control2"
                                    defaultValue={this.state.serviceCallObj.notes}
                                    style={{
                                        width: "200px",
                                        height: "65px"
                                    }}
                                    />
                                </div>

                                <br></br>

                                <button
                                    className="basicButton"
                                    style={{
                                    width: "200px",
                                    marginBottom: "10px"
                                    }}
                                >
                                    Update Service Call
                                </button>
                                <button
                                    onClick={()=>{this.setState({selectedOption: 0});}}
                                    className="btn btn-outline-warning btn-sm"
                                    style={{
                                    width: "200px",
                                    marginBottom: "10px"
                                    }}
                                >
                                    Cancel
                                </button>
                            </form>
                        </div>
                    )}

                    {/* Add equipment form input */}

                    {this.state.selectedOption === 8 && (
                        <div style={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                            {/* Form to add equipment */}
                            <form style={{width: "350px"}} onSubmit={e => this.addEquipment(e)}>
                                <p
                                className="basicParagraph text-center"
                                style={{
                                width: "350px",
                                fontSize: "12px",
                                color: "#777575"
                                }}> Add Equipment
                                </p>
                                <div className="d-flex flex-row">
                                    <label className="basicParagraph" style={{width: "200px"}}>Type:</label>
                                    <select
                                        style={{width: "200px"}}
                                        name="type"
                                        className="form-control2"
                                    >
                                        <option value=""></option>
                                        <option value="Roof Top Unit">Roof Top Unit</option>
                                        <option value="Option 1">Option 1</option>
                                        <option value="Option 2">Option 2</option>
                                    </select>
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph" style={{width: "200px"}}>Economizer:</label>
                                    <select
                                        style={{width: "200px"}}
                                        name="economizer"
                                        className="form-control2"
                                    >
                                        <option value=""></option>
                                        <option value="Not Installed">Not Installed</option>
                                        <option value="Installed">Installed</option>
                                    </select>
                                </div>

                                <br></br>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph" style={{width: "200px"}}>Lease Start Date:</label>
                                    <input
                                    style={{width: "200px"}}
                                    type="date"
                                    name="leaseStartDate"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Responsibility:</label>
                                    <input
                                    type="text"
                                    name="responsibility"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Lease Verbiage:</label>
                                    <input
                                    type="text"
                                    name="leaseVerbiage"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph" style={{width: "200px"}}>Lease End Date:</label>
                                    <input
                                    style={{width: "200px"}}
                                    type="date"
                                    name="leaseEndDate"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph" style={{width: "200px"}}>Lease Options:</label>
                                    <select
                                        style={{width: "200px"}}
                                        name="leaseOptions"
                                        className="form-control2"

                                    >
                                        <option value=""></option>
                                        <option value="Option 1">Option 1</option>
                                        <option value="Option 2">Option 2</option>
                                    </select>
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Special Notes:</label>
                                    <input
                                    type="text"
                                    name="specialNotes"
                                    className="form-control2"
                                    style={{
                                        width: "200px",
                                        height: "65px"
                                    }}
                                    />
                                </div>

                                <br></br>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Responsibility:</label>
                                    <input
                                    type="text"
                                    name="responsibility"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Serviced By:</label>
                                    <input
                                    type="text"
                                    name="servicedBy"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Phone Number:</label>
                                    <input
                                    type="text"
                                    name="phone"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Email:</label>
                                    <input
                                    type="text"
                                    name="email"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Account:</label>
                                    <input
                                    type="text"
                                    name="account"
                                    className="form-control2"
                                    />
                                </div>

                                <br></br>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Heating Make:</label>
                                    <input
                                    type="text"
                                    name="heatingMake"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Model:</label>
                                    <input
                                    type="text"
                                    name="model"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Year Mfg:</label>
                                    <input
                                    type="text"
                                    name="yearMfg"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Warranty Exp:</label>
                                    <input
                                    type="text"
                                    name="warrantyExp"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Serial Number:</label>
                                    <input
                                    type="text"
                                    name="serialNumber"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Asset tag:</label>
                                    <input
                                    type="text"
                                    name="assetTag"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Size:</label>
                                    <input
                                    type="text"
                                    name="size"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Cost:</label>
                                    <input
                                    type="text"
                                    name="cost"
                                    className="form-control2"

                                    />
                                </div>

                                <br></br>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Cooling Make:</label>
                                    <input
                                    type="text"
                                    name="coolingMake"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Model:</label>
                                    <input
                                    type="text"
                                    name="model"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Year Mfg:</label>
                                    <input
                                    type="text"
                                    name="yearMfg"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Warranty Exp:</label>
                                    <input
                                    type="text"
                                    name="warrantyExp"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Serial Number:</label>
                                    <input
                                    type="text"
                                    name="serialNumber"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Asset tag:</label>
                                    <input
                                    type="text"
                                    name="assetTag"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Size:</label>
                                    <input
                                    type="text"
                                    name="size"
                                    className="form-control2"
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Cost:</label>
                                    <input
                                    type="text"
                                    name="cost"
                                    className="form-control2"
                                    />
                                </div>

                                <br></br>

                                <button
                                    className="basicButton"
                                    style={{
                                    width: "200px",
                                    marginBottom: "10px"
                                    }}
                                >
                                    Add Equipment
                                </button>
                                <button
                                    onClick={()=>{this.setState({selectedOption: 0});}}
                                    className="btn btn-outline-warning btn-sm"
                                    style={{
                                    width: "200px",
                                    marginBottom: "10px"
                                    }}
                                >
                                    Cancel
                                </button>
                            </form>
                        </div>
                    )}

                    {/* Edit equipment form */}

                    {this.state.selectedOption === 2 && (
                        <div style={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                            {/* Form to edit equipment information */}
                            <form style={{width: "350px"}} onSubmit={e => this.editEquipment(e)}>
                                <p
                                className="basicParagraph text-center"
                                style={{
                                width: "350px",
                                fontSize: "12px",
                                color: "#777575"
                                }}> Edit Equipment
                                </p>
                                <div className="d-flex flex-row">
                                    <label className="basicParagraph" style={{width: "200px"}}>Type:</label>
                                    <select
                                        style={{width: "200px"}}
                                        name="type"
                                        className="form-control2"
                                        defaultValue={this.state.basic.type}
                                    >
                                        <option value=""></option>
                                        <option value="Roof Top Unit">Roof Top Unit</option>
                                        <option value="Option 1">Option 1</option>
                                        <option value="Option 2">Option 2</option>
                                    </select>
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph" style={{width: "200px"}}>Economizer:</label>
                                    <select
                                        style={{width: "200px"}}
                                        name="economizer"
                                        className="form-control2"
                                        defaultValue={this.state.basic.economizer}
                                    >
                                        <option value=""></option>
                                        <option value="Not Installed">Not Installed</option>
                                        <option value="Installed">Installed</option>
                                    </select>
                                </div>

                                <br></br>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph" style={{width: "200px"}}>Lease Start Date:</label>
                                    <input
                                    style={{width: "200px"}}
                                    type="date"
                                    name="leaseStartDate"
                                    className="form-control2"
                                    defaultValue={this.dateHtml(this.state.lease.leaseStartDate)}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Responsibility:</label>
                                    <input
                                    type="text"
                                    name="responsibility"
                                    className="form-control2"
                                    defaultValue={this.state.lease.responsibility}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Lease Verbiage:</label>
                                    <input
                                    type="text"
                                    name="leaseVerbiage"
                                    className="form-control2"
                                    defaultValue={this.state.lease.leaseVerbiage}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph" style={{width: "200px"}}>Lease End Date:</label>
                                    <input
                                    style={{width: "200px"}}
                                    type="date"
                                    name="leaseEndDate"
                                    className="form-control2"
                                    defaultValue={this.dateHtml(this.state.lease.leaseEndDate)}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph" style={{width: "200px"}}>Lease Options:</label>
                                    <select
                                        style={{width: "200px"}}
                                        name="leaseOptions"
                                        className="form-control2"
                                        defaultValue={this.state.lease.leaseOptions}
                                    >
                                        <option value=""></option>
                                        <option value="op1">Option 1</option>
                                        <option value="op2">Option 2</option>
                                    </select>
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Special Notes:</label>
                                    <input
                                    type="text"
                                    name="specialNotes"
                                    className="form-control2"
                                    style={{
                                        width: "200px",
                                        height: "65px"
                                    }}
                                    />
                                </div>

                                <br></br>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Responsibility:</label>
                                    <input
                                    type="text"
                                    name="responsibility"
                                    className="form-control2"
                                    defaultValue={this.state.manufactured.responsibility}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Serviced By:</label>
                                    <input
                                    type="text"
                                    name="servicedBy"
                                    className="form-control2"
                                    defaultValue={this.state.manufactured.servicedBy}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Phone Number:</label>
                                    <input
                                    type="text"
                                    name="phone"
                                    className="form-control2"
                                    defaultValue={this.state.manufactured.phone}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Email:</label>
                                    <input
                                    type="text"
                                    name="email"
                                    className="form-control2"
                                    defaultValue={this.state.manufactured.email}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Account:</label>
                                    <input
                                    type="text"
                                    name="account"
                                    className="form-control2"
                                    defaultValue={this.state.manufactured.account}
                                    />
                                </div>

                                <br></br>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Heating Make:</label>
                                    <input
                                    type="text"
                                    name="heatingMake"
                                    className="form-control2"
                                    defaultValue={this.state.heating.heatingMake}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Model:</label>
                                    <input
                                    type="text"
                                    name="model"
                                    className="form-control2"
                                    defaultValue={this.state.heating.model}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Year Mfg:</label>
                                    <input
                                    type="text"
                                    name="yearMfg"
                                    className="form-control2"
                                    defaultValue={this.state.heating.yearMfg}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Warranty Exp:</label>
                                    <input
                                    type="text"
                                    name="warrantyExp"
                                    className="form-control2"
                                    defaultValue={this.state.heating.warrantyExp}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Serial Number:</label>
                                    <input
                                    type="text"
                                    name="serialNumber"
                                    className="form-control2"
                                    defaultValue={this.state.heating.serialNumber}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Asset tag:</label>
                                    <input
                                    type="text"
                                    name="assetTag"
                                    className="form-control2"
                                    defaultValue={this.state.heating.assetTag}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Size:</label>
                                    <input
                                    type="text"
                                    name="size"
                                    className="form-control2"
                                    defaultValue={this.state.heating.size}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Cost:</label>
                                    <input
                                    type="text"
                                    name="cost"
                                    className="form-control2"
                                    defaultValue={this.state.heating.cost}
                                    />
                                </div>

                                <br></br>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Cooling Make:</label>
                                    <input
                                    type="text"
                                    name="coolingMake"
                                    className="form-control2"
                                    defaultValue={this.state.cooling.coolingMake}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Model:</label>
                                    <input
                                    type="text"
                                    name="model"
                                    className="form-control2"
                                    defaultValue={this.state.cooling.model}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Year Mfg:</label>
                                    <input
                                    type="text"
                                    name="yearMfg"
                                    className="form-control2"
                                    defaultValue={this.state.cooling.yearMfg}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Warranty Exp:</label>
                                    <input
                                    type="text"
                                    name="warrantyExp"
                                    className="form-control2"
                                    defaultValue={this.state.cooling.warrantyExp}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Serial Number:</label>
                                    <input
                                    type="text"
                                    name="serialNumber"
                                    className="form-control2"
                                    defaultValue={this.state.cooling.serialNumber}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Asset tag:</label>
                                    <input
                                    type="text"
                                    name="assetTag"
                                    className="form-control2"
                                    defaultValue={this.state.cooling.assetTag}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Size:</label>
                                    <input
                                    type="text"
                                    name="size"
                                    className="form-control2"
                                    defaultValue={this.state.cooling.size}
                                    />
                                </div>

                                <div className="d-flex flex-row">
                                    <label className="basicParagraph">Cost:</label>
                                    <input
                                    type="text"
                                    name="cost"
                                    className="form-control2"
                                    defaultValue={this.state.cooling.cost}
                                    />
                                </div>

                                <br></br>

                                <button
                                    className="basicButton"
                                    style={{
                                    width: "200px",
                                    marginBottom: "10px"
                                    }}
                                >
                                    Update Equipment Info
                                </button>
                                <button
                                    onClick={()=>{this.setState({selectedOption: 0});}}
                                    className="btn btn-outline-warning btn-sm"
                                    style={{
                                    width: "200px",
                                    marginBottom: "10px"
                                    }}
                                >
                                    Cancel
                                </button>
                            </form>
                        </div>
                    )}

                    {/* Helper Bar */}

                    <div
                        className="systemClock"
                        style={{
                        height: "25px",
                        width: "400px",
                        marginLeft: "450px"
                        }}
                    >
                        {Object.keys(this.state.equipmentObj).length !== 0 && (
                            <div>
                                <button
                                className="btn btn-primary shadow-none"
                                style={{
                                    float: "right",
                                    padding: "0px",
                                    borderWidth: "0px",
                                    borderColor: "tranparent",
                                    marginRight: "12px",
                                    marginTop: "-7px",
                                    color: "transparent",
                                    backgroundColor: "transparent",
                                }}
                                onClick={()=>{this.getRows() === true ? this.setState({selectedOption: 2}) : this.setState({selectedOption: 6});}}
                                >
                                <FontAwesomeIcon
                                    icon="edit"
                                    size="sm"
                                    color="#ffc000"
                                    title="Edit equipment / Edit Service Call"
                                />
                                </button>

                                {/* Shows add button when service table is not undefined */}
                                {(this.getRows() === false) && (
                                    <button
                                    className="btn btn-primary shadow-none"
                                    style={{
                                        padding: "0px",
                                        borderWidth: "0px",
                                        marginRight: "12px",
                                        marginTop: "-7px",
                                        // transform: "translate(10px, -2px)",
                                        color: "transparent",
                                        backgroundColor: "transparent",
                                    }}
                                    onClick={()=>{this.setState({selectedOption: 1});}}
                                    >
                                    <FontAwesomeIcon
                                        icon="plus-circle"
                                        size="2x"
                                        color="#ffc000"
                                        title="Add a service call"
                                    />
                                    </button>
                                )}

                                {/* show the add button when service call is empty */}
                                {(this.state.service === true) && (
                                    <button
                                    className="btn btn-primary shadow-none"
                                    style={{
                                        padding: "0px",
                                        borderWidth: "0px",
                                        marginRight: "12px",
                                        marginTop: "-7px",
                                        // transform: "translate(10px, -2px)",
                                        color: "transparent",
                                        backgroundColor: "transparent",
                                    }}
                                    onClick={()=>{this.setState({selectedOption: 1});}}
                                    >
                                    <FontAwesomeIcon
                                        icon="plus-circle"
                                        size="2x"
                                        color="#ffc000"
                                        title="Add a service call"
                                    />
                                    </button>
                                )}

                                <button
                                    className="btn btn-primary shadow-none"
                                    style={{
                                        float: "left",
                                        padding: "0px",
                                        borderWidth: "0px",
                                        marginLeft: "12px",
                                        marginTop: "-7px",
                                        color: "transparent",
                                        backgroundColor: "transparent",
                                    }}
                                    onClick={()=>{
                                            this.state.selectedTable !== "equipment" ? this.setState({selectedTable: "equipment"}) : this.setState({selectedTable: "serviceCall"});
                                            this.setState({selectedOption: 0});
                                            this.setState({serviceCallObj: {}});
                                    }}
                                >
                                <FontAwesomeIcon
                                    icon="list-ul"
                                    size="sm"
                                    color="#ffc000"
                                    title="Service Calls / Equipment Info"
                                />
                                </button>
                            </div>
                        )}

                        {Object.keys(this.state.equipmentObj).length === 0 && (
                            <button
                            className="btn btn-primary shadow-none"
                            style={{
                                padding: "0px",
                                borderWidth: "0px",
                                marginRight: "12px",
                                marginTop: "-7px",
                                // transform: "translate(10px, -2px)",
                                color: "transparent",
                                backgroundColor: "transparent",
                            }}
                            onClick={()=>{this.setState({selectedOption: 8});}}
                            >
                            <FontAwesomeIcon
                                icon="plus-circle"
                                size="2x"
                                color="#ffc000"
                                title="Add equipment"
                            />
                            </button>
                        )}

                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>

                        {/* Equipment table */}

                        {this.getRows()}
                        {this.getRows() === true && (
                            <div>
                                <p
                                className="basicParagraph text-center"
                                style={{
                                marginTop: "10px",
                                width: "350px",
                                fontSize: "12px",
                                color: "#2D5C88"
                                }}> Equipment Info
                                </p>

                                <table
                                className="table"
                                style={{
                                    marginTop: "10px",
                                    width: "350px",
                                }}
                                >
                                    <tbody>
                                        <tr>
                                            <th>Type:</th>
                                            <td>{this.state.basic.type}</td>
                                        </tr>
                                        <tr>
                                            <th>Economizer:</th>
                                            <td>{this.state.basic.economizer}</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <th>Lease Start Date:</th>
                                            <td>{this.state.lease.leaseStartDate}</td>
                                        </tr>
                                        <tr>
                                            <th>Responsibility:</th>
                                            <td>{this.state.lease.responsibility}</td>
                                        </tr>
                                        <tr>
                                            <th>Lease Verbiage:</th>
                                            <td>{this.state.lease.leaseVerbiage}</td>
                                        </tr>
                                        <tr>
                                            <th>Lease End date:</th>
                                            <td>{this.state.lease.leaseEndDate}</td>
                                        </tr>
                                        <tr>
                                            <th>Lease Options:</th>
                                            <td>{this.state.lease.leaseOptions}</td>
                                        </tr>
                                        <tr>
                                            <th>Special Notes:</th>
                                            <td>{this.state.lease.specialNotes}</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <th>Responsibility:</th>
                                            <td>{this.state.manufactured.responsibility}</td>
                                        </tr>
                                        <tr>
                                            <th>Serviced By:</th>
                                            <td>{this.state.manufactured.servicedBy}</td>
                                        </tr>
                                        <tr>
                                            <th>Phone:</th>
                                            <td>{this.state.manufactured.phone}</td>
                                        </tr>
                                        <tr>
                                            <th>Email:</th>
                                            <td>{this.state.manufactured.email}</td>
                                        </tr>
                                        <tr>
                                            <th>Account:</th>
                                            <td>{this.state.manufactured.account}</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <th>Heating Make:</th>
                                            <td>{this.state.heating.heatingMake}</td>
                                        </tr>
                                        <tr>
                                            <th>Model:</th>
                                            <td>{this.state.heating.model}</td>
                                        </tr>
                                        <tr>
                                            <th>Year Mfg:</th>
                                            <td>{this.state.heating.yearMfg}</td>
                                        </tr>
                                        <tr>
                                            <th>Warranty Exp:</th>
                                            <td>{this.state.heating.warrantyExp}</td>
                                        </tr>
                                        <tr>
                                            <th>Serial Number:</th>
                                            <td>{this.state.heating.serialNumber}</td>
                                        </tr>
                                        <tr>
                                            <th>Asset Tag:</th>
                                            <td>{this.state.heating.assetTag}</td>
                                        </tr>
                                        <tr>
                                            <th>Size:</th>
                                            <td>{this.state.heating.size}</td>
                                        </tr>
                                        <tr>
                                            <th>Cost:</th>
                                            <td>{this.state.heating.cost}</td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <th>Cooling Make:</th>
                                            <td>{this.state.cooling.coolingMake}</td>
                                        </tr>
                                        <tr>
                                            <th>Model:</th>
                                            <td>{this.state.cooling.model}</td>
                                        </tr>
                                        <tr>
                                            <th>Year Mfg:</th>
                                            <td>{this.state.cooling.yearMfg}</td>
                                        </tr>
                                        <tr>
                                            <th>Warranty Exp:</th>
                                            <td>{this.state.cooling.warrantyExp}</td>
                                        </tr>
                                        <tr>
                                            <th>Serial Number:</th>
                                            <td>{this.state.cooling.serialNumber}</td>
                                        </tr>
                                        <tr>
                                            <th>Asset Tag:</th>
                                            <td>{this.state.cooling.assetTag}</td>
                                        </tr>
                                        <tr>
                                            <th>Size:</th>
                                            <td>{this.state.cooling.size}</td>
                                        </tr>
                                        <tr>
                                            <th>Cost:</th>
                                            <td>{this.state.cooling.cost}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {/* Service Calls table */}

                        {this.getRows() === false && (
                            <div>
                                <p
                                className="basicParagraph text-center"
                                style={{
                                marginTop: "10px",
                                width: "auto",
                                fontSize: "12px",
                                color: "#2D5C88"
                                }}> Service Calls
                                </p>

                                <table
                                className="table"
                                style={{
                                    marginTop: "20px",
                                    minWidth: "1000px",
                                    maxWidth: "auto"
                                }}
                                >
                                    <thead style={{backgroundColor: "#ece9e9"}}>
                                        <tr>
                                            <th style={{ margin: "0px", padding: "5px", height: "15px", verticalAlign: "middle" }}>
                                                Select
                                            </th>
                                            <th style={{ margin: "0px", padding: "0px", height: "15px", verticalAlign: "middle" }}>
                                                Date of Service
                                            </th>
                                            <th style={{ margin: "0px", padding: "0px", height: "15px", verticalAlign: "middle" }}>
                                                Technician's Name
                                            </th>

                                            <th style={{ margin: "0px", padding: "0px", height: "15px", verticalAlign: "middle" }}>
                                                Technician's ID
                                            </th>
                                            <th style={{ margin: "0px", padding: "0px", height: "15px", verticalAlign: "middle" }}>
                                                Phone Number
                                            </th>
                                            <th style={{ margin: "0px", padding: "0px", height: "15px", verticalAlign: "middle" }}>
                                                Company Name
                                            </th>
                                            <th style={{ margin: "0px", padding: "0px", height: "15px", verticalAlign: "middle" }}>
                                                Email
                                            </th>
                                            <th style={{ margin: "0px", padding: "0px", height: "15px", verticalAlign: "middle" }}>
                                                Cost
                                            </th>
                                            <th style={{ margin: "0px", padding: "0px", height: "15px", verticalAlign: "middle" }}>
                                                Notes
                                            </th>
                                            <th style={{ margin: "0px", padding: "0px", height: "15px", verticalAlign: "middle" }}>
                                                Created / Updated By
                                            </th>
                                        </tr>
                                    </thead>
                                {this.state.equipmentObj.serviceCall.map((call, index) => (
                                    <tbody key={index}>
                                        <tr key={index + 1000}>
                                            <td
                                            style={{
                                                margin: "0px",
                                                padding: "5px",
                                                width: "10px",
                                                height: "15px",
                                            }}
                                            >
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="serviceCall"
                                                id="registerReason1"
                                                onClick={() =>
                                                    this.handleRadio(`${call.id}`)
                                                }
                                                value="reason1"
                                                style={{
                                                display: "table-cell",
                                                verticalAlign: "middle",
                                                transform: "translate(25px,-5px)",
                                                }}
                                                // checked
                                            ></input>
                                            </td>
                                            <td
                                            style={{
                                                margin: "0px",
                                                padding: "0px",
                                                width: "70px",
                                                verticalAlign: "middle",
                                                height: "25px",
                                            }}
                                            >
                                            {call.serviceDate}
                                            </td>
                                            <td
                                            style={{
                                                margin: "0px",
                                                padding: "0px",
                                                verticalAlign: "middle",
                                                height: "25px",
                                            }}
                                            >
                                            {call.techName}
                                            </td>

                                            <td
                                            style={{
                                                margin: "0px",
                                                padding: "0px",
                                                verticalAlign: "middle",
                                                height: "25px",
                                            }}
                                            >
                                            {call.techId}
                                            </td>
                                            <td
                                            style={{
                                                margin: "0px",
                                                padding: "0px",
                                                verticalAlign: "middle",
                                                height: "25px",
                                            }}
                                            >
                                            {call.phone}
                                            </td>
                                            <td
                                            style={{
                                                margin: "0px",
                                                padding: "0px",
                                                verticalAlign: "middle",
                                                height: "15px",
                                            }}
                                            >
                                            {call.companyName}
                                            </td>
                                            <td
                                            style={{
                                                margin: "0px",
                                                padding: "0px",
                                                verticalAlign: "middle",
                                                height: "15px",
                                            }}
                                            >
                                            {call.email}
                                            </td>
                                            <td
                                            style={{
                                                margin: "0px",
                                                padding: "0px",
                                                verticalAlign: "middle",
                                                height: "15px",
                                            }}
                                            >
                                            {call.cost}
                                            </td>
                                            <td
                                            style={{
                                                margin: "0px",
                                                padding: "0px",
                                                verticalAlign: "middle",
                                                height: "15px",
                                            }}
                                            >
                                            {call.notes}
                                            </td>
                                            <td
                                            style={{
                                                margin: "0px",
                                                padding: "0px",
                                                verticalAlign: "middle",
                                                height: "15px",
                                            }}
                                            >
                                            {call.changedBy}
                                            </td>
                                        </tr>
                                    </tbody>
                                    ))}
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default SimplEquipment;
